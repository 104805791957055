import React from 'react';
import css from './CreateOpeningNavigation.module.scss';
import classes from 'classnames';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { Button } from 'components/Button/Button';
import { useCreateOpening } from 'providers/CreateOpening/CreateOpeningProvider';

export interface CreateOpeningNavigationProps {
	className?: string;
}

export const CreateOpeningNavigation: React.FC<CreateOpeningNavigationProps> = props => {
	const { className } = props;
	const {
		onPreviousStepHandler,
		onNextStepHandler,
		progressCurrentStep,
		progressTotalSteps,
		currentCreateOpeningStep,
		templateEstimatedOrder,
		saveForLater
	} = useCreateOpening();
	return (
		<div className={classes([css.createOpeningNavigation, className])}>
			<ProgressBar total={progressTotalSteps} progress={progressCurrentStep} />
			<div className={css.createOpeningNavigationContent}>
				<span>
					<span>*</span> required
				</span>
				<div className={css.createOpeningNavigationControls}>
					{currentCreateOpeningStep === 'estimate' && templateEstimatedOrder?.status === 'success' && (
						<Button
							type={'link'}
							className={css.createOpeningButton}
							onClick={async () => await saveForLater()}
						>
							Save for later
						</Button>
					)}
					<Button
						type={'secondary'}
						className={css.createOpeningButton}
						onClick={event => onPreviousStepHandler(event)}
					>
						Back
					</Button>
					{currentCreateOpeningStep !== 'quote' && (
						<Button
							type={'primary'}
							className={css.createOpeningButton}
							onClick={async event => await onNextStepHandler(event)}
						>
							{currentCreateOpeningStep === 'estimate' ? 'Continue to order' : 'Next'}
						</Button>
					)}
				</div>
			</div>
		</div>
	);
};
