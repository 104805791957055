import React from 'react';
import css from './StatusBar.module.scss';
import classes from 'classnames';
import { Icon } from 'components/Icon/Icon';
import { OrderStatus } from 'api/order';

export interface IStatusBarProps {
	className?: string;
	type?: OrderStatus;
}

export const StatusBar: React.FC<IStatusBarProps> = props => {
	const { type = 'NotSubmitted', className } = props;

	const iconContent = (() => {
		switch (type) {
			case 'NotSubmitted':
				return (
					<>
						<div className={css.centerFlex}>
							<Icon
								size='small'
								color='black'
								className={classes(css.icon, css.marginRight)}
								type={'Edit'}
							/>
							<span className={css.textBold}>Not Submitted</span>
						</div>
					</>
				);
			case 'Submitted':
				return (
					<>
						<div className={css.centerFlex}>
							<Icon
								size='small'
								color='black'
								className={classes(css.icon, css.marginRight)}
								type={'CheckCircle'}
							/>
							<span className={css.textBold}>Submitted</span>
						</div>
					</>
				);
			case 'InReview':
				return (
					<>
						<div className={css.centerFlex}>
							<Icon
								size='small'
								color='white'
								className={classes(css.icon, css.marginRight)}
								type={'Message'}
							/>
							<span className={css.textBold}>In Review</span>
						</div>
					</>
				);
			case 'OrderPlaced':
				return (
					<>
						<div className={css.centerFlex}>
							<Icon
								size='small'
								color='black'
								className={classes(css.icon, css.marginRight)}
								type={'Cart'}
							/>
							<span className={css.textBold}>Order Placed</span>
						</div>
					</>
				);
			default:
				break;
		}
	})();

	return (
		<div className={classes(css.alert, css[type], css.orderStatus, className)}>
			<span className={css.marginRight}>STATUS:</span>
			<strong className={css.statusValue}>{iconContent}</strong>
		</div>
	);
};
