import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router';
import { isUserOfPermission } from 'common/utils/isUserOfPermission';
import { useAuth } from 'providers/Auth/AuthProvider';
import { Spinner } from 'components/Spinner';

interface IIsUserOfPermission {
	userPermissionsToCheck: string[];
}

export const IsUserOfPermissionRouteGuard: React.FC<IIsUserOfPermission> = props => {
	const { isLoading: isAuthLoading, getPermissions } = useAuth();
	const { userPermissionsToCheck } = props;
	const navigate = useNavigate();

	useEffect(() => {
		if (getPermissions().length > 0) {
			const permissions = getPermissions();
			const containsUserPermission = isUserOfPermission(userPermissionsToCheck, permissions);
			// Some Unique Auth0 Scopes to Identify the roles
			const userIsCustomer = isUserOfPermission(['company:read:own'], permissions);
			const userIsCustomerService = isUserOfPermission(['lineItem:modify:all'], permissions);
			const userIsAdmin = isUserOfPermission(['create:users'], permissions);

			if (!containsUserPermission && userIsCustomer) {
				navigate('/');
			} else if (!containsUserPermission && userIsCustomerService) {
				navigate('/cs/quotes');
			} else if (!containsUserPermission && userIsAdmin) {
				navigate('/admin/referencenumbermanagement');
			}
		}
	}, [getPermissions, userPermissionsToCheck, navigate]);

	if (getPermissions().length > 0 && !isAuthLoading) {
		return <Outlet />;
	} else {
		return <Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>;
	}
};
