import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router';
import { LoadingDelay } from 'components/LoadingDelay/LoadingDelay';
import { Spinner } from 'components/Spinner';

// A route component that extends Route
// Uses the auth custom hook to check if current user is authenticated
// IF not authenticated then redirects to the /login page.
// Passes the last visited location to the Login state so upon successful login
// The user can be Redirected back to desired route
export const PublicRoute: React.FC = () => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isAuthenticatedLoaded, setIsAuthenticatedLoaded] = useState(false);

	useEffect(() => {
		(async () => {
			const isAuthenticated = true; //await getIsAuthenticated();
			setIsAuthenticated(isAuthenticated);
			setIsAuthenticatedLoaded(true);
		})();
	}, []);

	if (!isAuthenticatedLoaded) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	return (
		<>
			{!isAuthenticated ? (
				<Outlet />
			) : (
				<Navigate
					replace
					to={{
						pathname: '/'
					}}
				/>
			)}
		</>
	);
};
