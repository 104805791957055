import { intersection } from 'ramda';

export type UserPermissions = string[];

/**
 * Returns true if permissionsToCheck and currentUserPermissions have any permissions in common using intersection
 *
 * @param permissionsToCheck
 * @param currentUserPermissions
 * @returns boolean
 */
export const isUserOfPermission = (
	permissionsToCheck: UserPermissions,
	currentUserPermissions: UserPermissions
): boolean => intersection(permissionsToCheck, currentUserPermissions).length > 0;
