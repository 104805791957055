import React from 'react';
import css from './MessageBubbles.module.scss';
import classes from 'classnames';
import { TMessageBubblesTypes } from 'common/types/TMessageBubblesTypes';
import { OrderComment } from 'api/orderComment';
import { Split } from 'components/Split/Split';
import { formatDate } from 'common/utils/formatDate';
import { formatTimestamp } from 'common/utils/formatTimestamp';

type IMessageBubblesProps = {
	className?: string;
	type?: TMessageBubblesTypes;
	commentText?: string;
} & OrderComment;

export const MessageBubbles: React.FC<IMessageBubblesProps> = props => {
	const { type = 'NotSubmitted', className, ...comment } = props;

	return (
		<Split
			type={'oneFourth_threeFourth'}
			contentLeft={
				<>
					{/* Name & Date Tag */}
					<div className={css.nameAndDateTimeContainer}>
						{/* User's Name (as it is saved in the application database) */}
						<div>
							<span className={css.nameText}>
								{comment?.createdByUser
									? comment?.createdByUser?.firstName
									: comment?.createdBy ?? 'unknown user'}
							</span>
							<span className={css.nameText}>
								{' '}
								{comment?.createdByUser ? comment?.createdByUser?.lastName : ''}
							</span>
						</div>
						{/* Created Date */}
						<div>
							<span>{comment?.createdAt ? formatDate(comment?.createdAt, true, true) : ''}</span>
						</div>
						{/* Created Time */}
						<div>
							<span>{comment?.createdAt ? 'at ' + formatTimestamp(`${comment?.createdAt}`) : ''}</span>
						</div>
					</div>
				</>
			}
			contentRight={
				<>
					{/* Message Bubble */}
					<div
						className={classes(css.alert, css.arrowLeft, css[type], className, css.container)}
						role='tooltip'
					>
						{comment?.commentText ?? 'empty'}
					</div>
				</>
			}
		/>
	);
};
