import React, { PropsWithChildren } from 'react';
import css from './Detail.module.scss';
import classes from 'classnames';
import { Icon } from 'components/Icon/Icon';

interface IDetailContent {
	title: string;
	type?: 'detail' | 'form';
	titleTooltip?: string;
}

export const Detail: React.FC<PropsWithChildren<IDetailContent>> = props => {
	const { children, title, type = 'detail', titleTooltip } = props;

	return (
		<>
			<h3 className={classes(css.detailHeadline)}>
				{title}
				{titleTooltip && (
					<Icon
						tooltip={titleTooltip}
						className={css.titleIcon}
						marginSize='left-medium'
						type='Info'
						size='small'
						color='blue'
					/>
				)}
			</h3>
			<div className={classes(css.detailContent, css[type])}>{children}</div>
		</>
	);
};
