import { useMatch, useNavigate } from 'react-router';

export const useRoutePageNumber = (parameter: string) => {
	const navigate = useNavigate();
	const route = useMatch(parameter);
	const pageNumber = Number(route?.params?.pageNumber || 1);
	if (isNaN(pageNumber)) {
		navigate('/');
	}
	return pageNumber;
};
