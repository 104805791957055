import { useMutation, useQuery, useQueryClient } from 'react-query';
import { handleResponse } from './api';
import { useAxios } from 'providers/Axios/AxiosProvider';
import { RequestTemplateFromObjectAPIRequest } from 'components/CreateOpening/common/types';
import { TDoorTemplateIconTypes } from 'components/DoorTemplateIcon';
import { ProductPricingResponse } from './product';

export type Opening = {
	visibleOutcomePath: string;
	name: string;
	icon: TDoorTemplateIconTypes;
	numberId: number;
	isInvalid: boolean;

	// questionnaire type
	application: string;

	// questionnaire end result object
	doorLayout: any;
	glassThickness: any;
	// finish: string; // not used in seed
	type: string;
	closerType: any;
	mountingType: any;
	header: any;
	additionalFunction: any;
	bottomLock: any;
	channelHeightTop: any;
	channelHeightBottom: any;
	railHeightTop: any;
	railHeightBottom: any;
	railProfile: any;
	pullHandle: any;
	// pullHandleFinish: any; // not used in seed

	// comma separated reference numbers
	referenceNumberList: string;

	// base db record values
	id: string;
	isDeleted: boolean;
	createdBy: string;
	createdAt: Date;
	updatedBy: string;
	updatedAt: Date;
	deletedBy: string;
	deletedAt: Date;
};

export type OpeningPagedResponse = {
	pageNumber: number;
	pageSize: number;
	totalPages: number;
	totalRecords: number;
	openings: Opening[];
};

// used for Admin Reference Number Screens
export type OpeningReferenceNumbersWithProductInfoResponse = {
	// the opening that was updated
	opening: Opening;
	// any reference numbers that were invalid
	invalidReferenceNumbers: string;
	validReferenceNumbers: string;
	// needed to display to the user
	validReferenceNumbersResponses: ValidReferenceNumbersResponse[];
};

export type ValidReferenceNumbersResponse = {
	referenceNumber: string;
	productName: string;
	productDescription: string;
};

// used for the Customer Create an Opening flow
export type OpeningReferenceNumbersWithProductPricingResponse = {
	// the opening that was updated
	opening: Opening;
	// any reference numbers that were invalid
	invalidReferenceNumbers: string;
	validReferenceNumbers: string;
	// needed to display to the user
	validReferenceNumbersPricingResponses: ValidReferenceNumbersPricingResponse[];
};

export type ValidReferenceNumbersPricingResponse = {
	referenceNumber: string;
	productPricingRsp: ProductPricingResponse;
};

type ApiOpeningResponse = OpeningPagedResponse[];

// Gets info about a folder and its immediate children
export const useGetOpenings = (
	pageNumber: number,
	pageSize: number,
	noPullHandles: boolean,
	isInvalid: boolean,
	isCompleted: boolean,
	application: string,
	glassThickness: string
) => {
	const axios = useAxios();
	return useQuery(
		[
			'openings',
			pageNumber,
			pageSize,
			noPullHandles,
			isInvalid,
			isCompleted,
			application,
			glassThickness
		],
		async () => {
			return handleResponse(
				axios.get<ApiOpeningResponse>(`/opening`, {
					params: {
						pageNumber,
						pageSize,
						noPullHandles,
						isInvalid,
						isCompleted,
						application,
						glassThickness
					}
				})
			);
		},
		{
			keepPreviousData: true
		}
	);
};

export const useGetOpening = (openingId: string) => {
	const axios = useAxios();
	return useQuery(['opening', `${openingId}`], async () => {
		return handleResponse(
			axios.get<OpeningReferenceNumbersWithProductInfoResponse>(`/opening/${openingId}`)
		);
	});
};

export const useGetOpeningByTemplateObj = () => {
	const axios = useAxios();

	return useMutation(async (templateObject: RequestTemplateFromObjectAPIRequest) => {
		// templateObject comes in from formik an contains all the properties even properties with empty string. The API expects only props with value get sent
		const parsedForRequestTemplateObject = Object.keys(templateObject)
			.filter(
				templateQuestion =>
					// @ts-ignore
					templateObject[templateQuestion] !== '' && templateQuestion !== 'pullHandleFinish'
			)
			.reduce(
				(newObject, question) => ({
					...newObject,
					// @ts-ignore
					[question]: templateObject[question]
				}),
				{}
			);

		return handleResponse(
			axios.post<OpeningReferenceNumbersWithProductPricingResponse>(
				`/opening/questionnaireObj`,
				parsedForRequestTemplateObject
			)
		);
	});

	// return useQuery(['templateObj', `${templateSelectionOutcome}`], async () => {
	// 	return handleResponse(
	// 		axios.post<OpeningReferenceNumbersWithProductInfoResponse>(
	// 			`/api/opening/questionnaireObj`,
	// 			templateSelectionOutcome
	// 		)
	// 	);
	// });
};

export const useUpdateOpening = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (opening: Partial<Opening>) => {
			let formattedOpenings = null;
			if (opening.referenceNumberList) {
				formattedOpenings = opening.referenceNumberList.replace(/(\r\n|\n|\r)/gm, '');
			}
			return handleResponse(
				axios.put<Partial<OpeningReferenceNumbersWithProductInfoResponse>>(`/opening/${opening.id}`, {
					...opening,
					referenceNumberList: formattedOpenings ? formattedOpenings : opening.referenceNumberList
				})
			);
		},
		{
			onSuccess: (data, opening) => {
				queryClient.invalidateQueries(['openings']);
				queryClient.invalidateQueries(['opening', `${opening.id}`]);
			}
		}
	);
};
