import React from 'react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { config } from 'config/config';
import { useNavigate } from 'react-router-dom';

const AuthProvider = ({ children }: React.PropsWithChildren<{}>) => {
	const navigate = useNavigate();

	const onRedirectCallback = (appState: any) => {
		navigate(appState?.returnTo || window.location.pathname);
	};

	return (
		<Auth0Provider
			domain={config.authDomain}
			clientId={config.authClientId}
			redirectUri={window.location.origin}
			audience={config.authAudience}
			scope='openid profile email'
			onRedirectCallback={onRedirectCallback}
		>
			{children}
		</Auth0Provider>
	);
};

export const useAuth = () => {
	const { loginWithRedirect, logout, user, isAuthenticated, isLoading } = useAuth0();

	const getCompanyId = () => {
		return user[`${config.authCustomClaimNamespace}company_id`];
	};

	const getPermissions = (): string[] => {
		return user[`${config.authCustomClaimNamespace}permissions`];
	};

	return {
		login: () =>
			loginWithRedirect({
				appState: {
					returnTo: `${window.location.pathname}${window.location.search}`
				}
			}),
		logout: () => logout({ returnTo: window.location.origin }),
		user,
		isAuthenticated,
		getCompanyId,
		getPermissions,
		isLoading
	};
};

export default AuthProvider;
