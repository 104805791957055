const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];

const monthsAbbreviated = [
	'Jan.',
	'Feb.',
	'Mar.',
	'Apr.',
	'May',
	'June',
	'July',
	'Aug.',
	'Sept.',
	'Oct.',
	'Nov.',
	'Dec.'
];

export const formatDate = (
	timestamp: Date,
	includeYear: boolean = true,
	abbreviated: boolean = false
) => {
	const newDate = new Date(timestamp);
	const { monthName, day, year } = (() => {
		const monthName = abbreviated ? monthsAbbreviated[newDate.getMonth()] : months[newDate.getMonth()];
		const day = newDate.getDate();
		const year = newDate.getFullYear();
		return { monthName, day, year };
	})();
	return includeYear ? `${monthName} ${day}, ${year}` : `${monthName} ${day}`;
};
