import { OpeningQuestions, OpeningTemplateQuestions } from './types';
import * as yup from 'yup';

export const fixedOpeningTemplateQuestions: OpeningTemplateQuestions = [
	{
		stepTitle: 'Select your desired system:',
		label: '',
		key: 'type',
		validator: {
			type: yup.string().required()
		},
		values: [
			{
				label: 'Dri-Fit Rail',
				labelTooltip:
					'Dri-Fit Rail: Dri-Fit glazing systems simplify installation using intelligently designed Dri-Fit gasket. Dri-Fit sidelite rails are offered to match door profiles, plus a complete range of saddles to match clearances',
				key: 'driFitRail',
				questions: [
					{
						type: 'select',
						label: 'What is your top saddle height?',
						key: 'saddleHeightTop',
						validator: {
							saddleHeightTop: yup.string().required('Saddle height top is required')
						},
						options: [
							{
								label: '1/8 inch',
								key: '1-8inch',
								value: '1-8inch'
							},
							{
								label: '3/8 and 1/2 inch',
								key: '3-8-and-1-2-inch',
								value: '3-8-and-1-2-inch'
							},
							{
								label: '1/4 and 3/4 inch',
								key: '1-4-and-3-4-inch',
								value: '1-4-and-3-4-inch'
							},
							{
								label: 'Less Saddle',
								key: 'less-saddle',
								value: 'less-saddle'
							}
						]
					},
					{
						type: 'select',
						label: 'What is your bottom saddle height?',
						key: 'saddleHeightBottom',
						validator: {
							saddleHeightBottom: yup.string().required('Saddle height bottom is required')
						},
						options: [
							{
								label: '1/8 inch',
								key: '1-8inch',
								value: '1-8inch'
							},
							{
								label: '3/8 and 1/2 inch',
								key: '3-8-and-1-2-inch',
								value: '3-8-and-1-2-inch'
							},
							{
								label: '1/4 and 3/4 inch',
								key: '1-4-and-3-4-inch',
								value: '1-4-and-3-4-inch'
							},
							{
								label: 'Less Saddle',
								key: 'less-saddle',
								value: 'less-saddle'
							}
						]
					}
				]
			},
			{
				label: 'Dri-Fit Header',
				labelTooltip:
					'Dri-Fit Header: Dri-Fit glazing systems simplify installation using intelligently designed Dri-Fit gasket. Dri-Fit headers with glazing pocket are offered in a complete range of header widths',
				key: 'driFitHeader'
			},
			{
				label: 'Dri-Fit Channel',
				labelTooltip:
					'Dri-Fit Channel: U Channel in a variety of heights and widths with Dri-Fit gasket that eliminates the need to use silicone during glass installation',
				key: 'driFitChannel'
			},
			{
				label: 'Mechanical Channel',
				labelTooltip:
					'Mechanical Channel: All around clamping frame system for fixed glass applications',
				key: 'mechanicalChannel'
			}
		]
	},
	{
		stepTitle: 'Select your desired Glass Thickness:',
		label:
			'Please note if you are looking for a different thickness, specify other below and you can let our customer service team know before submitting your quote.',
		key: 'glassThickness',
		validator: {
			glassThickness: yup.string().required()
		},
		values: [
			{
				label: '3/8 inch',
				key: '3-8inch'
			},
			{
				label: '1/2 inch',
				key: '1-2inch'
			},
			{
				label: '9/16 inch',
				key: '9-16inch',
				conditional: {
					type: 'OR',
					condition: ['type:driFitChannel', 'type:driFitHeader', 'type:driFitRail']
				}
			},
			{
				label: '5/8 inch',
				key: '5-8inch'
			},
			{
				label: '11/16 inch',
				key: '11-16inch',
				conditional: {
					type: 'OR',
					condition: ['type:driFitChannel', 'type:driFitHeader', 'type:driFitRail']
				}
			},
			{
				label: '3/4 inch',
				key: '3-4inch'
			},
			{
				label: '13/16 inch',
				key: '13-16inch',
				conditional: {
					type: 'OR',
					condition: ['type:driFitChannel', 'type:driFitHeader', 'type:driFitRail']
				}
			}
		]
	},
	{
		stepTitle: 'Select your desired header:',
		label: '',
		key: 'header',
		validator: {
			header: yup.string().required()
		},
		conditional: {
			type: 'OR',
			condition: ['type:driFitRail', 'type:driFitHeader']
		},
		values: [
			{
				label: '4-1/8 inch',
				key: '4-1-8inch'
			},
			{
				label: '4-1/2 inch',
				key: '4-1-2inch'
			},
			{
				label: '6 inch',
				key: '6inch'
			},
			{
				label: 'No Header',
				key: 'none',
				conditional: {
					type: 'OR',
					condition: ['type:driFitRail']
				}
			}
		]
	},
	{
		stepTitle: 'Select your desired finish:',
		label: 'Available finishes can vary depending on the type of system.',
		key: 'finish',
		validator: {
			finish: yup.string().required()
		},
		values: [
			{
				label: '101 - Clear Anodized',
				key: '101'
			},
			{
				label: '108 - Dark Bronze Anodized',
				key: '108'
			},
			{
				label: '109 - Black Anodized',
				key: '109'
			},
			{
				label: '113 - Sim. Satin Stainless Steel',
				key: '113',
				conditional: {
					type: 'OR',
					condition: [
						'type:driFitChannel',
						{
							type: 'AND',
							condition: ['type:driFitHeader', 'header:4-1-8inch']
						}
					]
				}
			},
			{
				label: '199 - Special Anodized',
				key: '199'
			},
			{
				label: '299 - Special Plating',
				key: '299'
			},
			{
				label: '399 - Special Coating',
				key: '399'
			},
			{
				label: '503 - Polished Brass without Lacquer',
				key: '503',
				conditional: {
					type: 'OR',
					condition: ['type:driFitRail', 'type:driFitHeader', 'type:driFitChannel']
				}
			},
			{
				label: '599 - Satin Brass without Lacquer',
				key: '599',
				conditional: {
					type: 'OR',
					condition: ['type:driFitRail', 'type:driFitHeader', 'type:driFitChannel']
				}
			},
			{
				label: '600 - Satin Bronze without Lacquer',
				key: '600',
				conditional: {
					type: 'OR',
					condition: ['type:driFitRail', 'type:driFitHeader', 'type:driFitChannel']
				}
			},
			{
				label: '601 - Polished Bronze without Lacquer',
				key: '601',
				conditional: {
					type: 'OR',
					condition: ['type:driFitRail', 'type:driFitHeader', 'type:driFitChannel']
				}
			},
			{
				label: '700 - Satin Stainless',
				key: '700'
			},
			{
				label: '701 - Polished Stainless',
				key: '701'
			}
		]
	},
	{
		// This is the first railProfile question specifically for type:driFitRail
		stepTitle: 'Select your desired rail profile:',
		label:
			'Rails come in square and tapered options, plus different heights/sizes. Tapered options provide an ADA-compliant solution for bottom rails under 10" high.',
		key: 'railProfile',
		validator: {
			railProfile: yup.string().required()
		},
		conditional: {
			type: 'OR',
			condition: ['type:driFitRail']
		},
		values: [
			{
				label: 'Square',
				key: 'square'
			},
			{
				label: 'Tapered',
				key: 'tapered'
			}
		]
	},
	{
		stepTitle: 'Select your desired rail height on top:',
		label: 'A variety of rail heights are available for top rails.',
		key: 'railHeightTop',
		validator: {
			railHeightTop: yup.string().required()
		},
		conditional: {
			type: 'OR',
			condition: ['type:driFitRail']
		},
		values: [
			{
				label: '2-1/2 inch',
				key: '2-1-2inch'
			},
			{
				label: '3-5/8 inch',
				key: '3-5-8inch', // remove tapered rail profile if glass thickness is above 9/16
				conditional: {
					type: 'OR',
					condition: [
						'railProfile:square',
						{
							type: 'AND',
							condition: [
								'railProfile:tapered',
								{
									type: 'OR',
									condition: [
										'glassThickness:3-8inch',
										'glassThickness:1-2inch',
										'glassThickness:9-16inch'
									]
								}
							]
						}
					]
				}
			},
			{
				label: '4 inch',
				key: '4inch', // remove tapered rail profile if glass thickness is above 9/16
				conditional: {
					type: 'OR',
					condition: [
						{
							type: 'AND',
							condition: [
								'railProfile:square',
								{
									type: 'OR',
									condition: [
										'glassThickness:3-8inch',
										'glassThickness:1-2inch',
										'glassThickness:9-16inch',
										'glassThickness:11-16inch',
										'glassThickness:3-4inch',
										'glassThickness:13-16inch'
									]
								}
							]
						},
						{
							type: 'AND',
							condition: [
								'railProfile:tapered',
								{
									type: 'OR',
									condition: [
										'glassThickness:3-8inch',
										'glassThickness:1-2inch',
										'glassThickness:9-16inch'
									]
								}
							]
						}
					]
				}
			}
		]
	},
	{
		// This is a the railHeightBottom specifically for type:driFitRail
		stepTitle: 'Select your desired rail height on bottom:',
		label: 'A variety of rail heights are available for bottom rails.',
		key: 'railHeightBottom',
		validator: {
			railHeightBottom: yup.string().required()
		},
		conditional: {
			type: 'OR',
			condition: ['type:driFitRail']
		},
		values: [
			{
				label: 'U-channel (1x1 typical)',
				key: 'u-channel-1-1',
				conditional: {
					type: 'OR',
					condition: ['type:driFitHeader']
				}
			},
			{
				label: '2-1/2 inch',
				key: '2-1-2inch'
			},
			{
				label: '3-5/8 inch',
				key: '3-5-8inch', // remove tapered rail profile if glass thickness is above 9/16
				conditional: {
					type: 'OR',
					condition: [
						'railProfile:square',
						{
							type: 'AND',
							condition: [
								'railProfile:tapered',
								{
									type: 'OR',
									condition: [
										'glassThickness:3-8inch',
										'glassThickness:1-2inch',
										'glassThickness:9-16inch'
									]
								}
							]
						}
					]
				}
			},
			{
				label: '4 inch',
				key: '4inch', // remove tapered rail profile if glass thickness is above 9/16
				conditional: {
					type: 'OR',
					condition: [
						{
							type: 'AND',
							condition: [
								'railProfile:square',
								{
									type: 'OR',
									condition: [
										'glassThickness:3-8inch',
										'glassThickness:1-2inch',
										'glassThickness:9-16inch',
										'glassThickness:11-16inch',
										'glassThickness:3-4inch',
										'glassThickness:13-16inch'
									]
								}
							]
						},
						{
							type: 'AND',
							condition: [
								'railProfile:tapered',
								{
									type: 'OR',
									condition: [
										'glassThickness:3-8inch',
										'glassThickness:1-2inch',
										'glassThickness:9-16inch'
									]
								}
							]
						}
					]
				}
			},
			{
				label: '6 inch',
				key: '6inch', // There are no tapered rail
				conditional: {
					type: 'OR',
					condition: ['railProfile:square']
				}
			},
			{
				label: '10 inch',
				key: '10inch', // There are no tapered rail, if 13/16 glassThickness is chosen then hide 10inch option
				conditional: {
					type: 'AND',
					condition: [
						'railProfile:square',
						{
							type: 'OR',
							condition: [
								'glassThickness:3-8inch',
								'glassThickness:1-2inch',
								'glassThickness:9-16inch',
								'glassThickness:5-8inch',
								'glassThickness:11-16inch',
								'glassThickness:3-4inch'
							]
						}
					]
				}
			}
		]
	},
	{
		// This is a railHeightBottom questions specifically for type:driFitHeader
		stepTitle: 'Select your desired rail height on bottom:',
		label: 'A variety of rail heights are available for bottom rails.',
		key: 'railHeightBottom',
		validator: {
			railHeightBottom: yup.string().required()
		},
		conditional: {
			type: 'OR',
			condition: ['type:driFitHeader']
		},
		values: [
			{
				label: 'U-channel (1x1 typical)',
				key: 'u-channel-1-1'
			},
			{
				label: '2-1/2 inch',
				key: '2-1-2inch'
			},
			{
				label: '3-5/8 inch',
				key: '3-5-8inch' // remove tappered rail profile if glass thickness is above 9/16
			},
			{
				label: '4 inch',
				key: '4inch', // remove tappered rail profile if glass thickness is above 9/16
				conditional: {
					type: 'OR',
					condition: [
						'glassThickness:3-8inch',
						'glassThickness:1-2inch',
						'glassThickness:9-16inch',
						'glassThickness:11-16inch',
						'glassThickness:3-4inch',
						'glassThickness:13-16inch'
					]
				}
			},
			{
				label: '6 inch',
				key: '6inch' // There are no tappered rail
			},
			{
				label: '10 inch',
				key: '10inch', // There are no tappered rail, if 13/16 glassThickness is chosen then hide 10inch option
				conditional: {
					type: 'OR',
					condition: [
						'glassThickness:3-8inch',
						'glassThickness:1-2inch',
						'glassThickness:9-16inch',
						'glassThickness:5-8inch',
						'glassThickness:11-16inch',
						'glassThickness:3-4inch'
					]
				}
			}
		]
	},
	{
		// This is the railProfile questions for type:driFitHeader specifically
		stepTitle: 'Select your desired rail profile:',
		label:
			'Rails come in square and tapered options, plus different heights/sizes. Tapered options provide an ADA-compliant solution for bottom rails under 10" high.',
		key: 'railProfile',
		validator: {
			railProfile: yup.string().required()
		},
		conditional: {
			type: 'AND',
			condition: [
				'type:driFitHeader',
				{
					type: 'OR',
					condition: [
						'railHeightBottom:2-1-2inch',
						'railHeightBottom:3-5-8inch',
						'railHeightBottom:4inch',
						'railHeightBottom:6inch',
						'railHeightBottom:10inch'
					]
				}
			]
		},
		values: [
			{
				label: 'Square',
				key: 'square'
			},
			{
				label: 'Tapered',
				key: 'tapered',
				conditional: {
					type: 'OR',
					condition: [
						'railHeightBottom:2-1-2inch',
						{
							type: 'AND',
							condition: [
								'railHeightBottom:3-5-8inch',
								{
									type: 'OR',
									condition: [
										'glassThickness:3-8inch',
										'glassThickness:1-2inch',
										'glassThickness:9-16inch'
									]
								}
							]
						},
						{
							type: 'AND',
							condition: [
								'railHeightBottom:4inch',
								{
									type: 'OR',
									condition: [
										'glassThickness:3-8inch',
										'glassThickness:1-2inch',
										'glassThickness:9-16inch'
									]
								}
							]
						}
					]
				}
			}
		]
	},
	{
		stepTitle: 'Select your desired channel height on top:',
		label: '',
		key: 'channelHeightTop',
		validator: {
			channelHeightTop: yup.string().required()
		},
		conditional: {
			type: 'OR',
			condition: ['type:driFitChannel']
		},
		values: [
			{
				label: '1x1-3/4 inch', // 3/8-9/16 glassThickness
				key: '1x1-3-4inch',
				conditional: {
					type: 'OR',
					condition: ['glassThickness:3-8inch', 'glassThickness:1-2inch', 'glassThickness:9-16inch']
				}
			},
			{
				label: '1-1/4 x 1-3/4 inch', // 5/8-13/16 glassThickness
				key: '1-1-4x1-3-4inch',
				conditional: {
					type: 'OR',
					condition: [
						'glassThickness:5-8inch',
						'glassThickness:11-16inch',
						'glassThickness:3-4inch',
						'glassThickness:13-16inch'
					]
				}
			}
		]
	},
	{
		stepTitle: 'Select your desired channel height on bottom:',
		label: '',
		key: 'channelHeightBottom',
		validator: {
			channelHeightBottom: yup.string().required()
		},
		conditional: {
			type: 'OR',
			condition: ['type:driFitChannel', 'type:mechanicalChannel']
		},
		values: [
			{
				label: 'Mechanical Channel',
				key: 'mechanicalChannel',
				conditional: {
					type: 'OR',
					condition: ['type:mechanicalChannel']
				}
			},
			{
				label: '1 x 1 inch', // 3/8-9/16 glassThickness
				key: '1x1inch',
				conditional: {
					type: 'OR',
					condition: ['glassThickness:3-8inch', 'glassThickness:1-2inch', 'glassThickness:9-16inch']
				}
			},
			{
				label: '1 x 1-3/4 inch', // 3/8-9/16 glassThickness
				key: '1x1-3-4inch',
				conditional: {
					type: 'OR',
					condition: ['glassThickness:3-8inch', 'glassThickness:1-2inch', 'glassThickness:9-16inch']
				}
			},
			{
				label: '1-1/4 x 1 inch', // 5/8-13/16 glassThickness
				key: '1-1-4x1inch',
				conditional: {
					type: 'OR',
					condition: [
						'glassThickness:5-8inch',
						'glassThickness:11-16inch',
						'glassThickness:3-4inch',
						'glassThickness:13-16inch'
					]
				}
			},
			{
				label: '1-1/4 x 1-3/4 inch', // 5/8-13/16 glassThickness
				key: '1-1-4x1-3-4inch',
				conditional: {
					type: 'OR',
					condition: [
						'glassThickness:5-8inch',
						'glassThickness:11-16inch',
						'glassThickness:3-4inch',
						'glassThickness:13-16inch'
					]
				}
			}
		]
	}
];

export const fixedOpeningGlobalQuestions: OpeningQuestions = [
	{
		type: 'text',
		label: 'Number of Fixed Panels',
		key: 'NumberOfFixedPanels',
		validator: {
			NumberOfFixedPanels: yup.string().required('Number of fixed panels is required')
		}
	}
];
