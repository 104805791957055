import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'providers/Auth';
import css from './CustomerServiceQuoteDetailView.module.scss';
import classes from 'classnames';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from 'components/Container';
import { Alert } from 'components/Alert/Alert';
import { Spinner } from 'components/Spinner';
import { LoadingDelay } from 'components/LoadingDelay';
import { PageFrame } from 'components/PageFrame';
import { useGetOrderById, useUpdateOrder } from 'api/order';
import { useGetUsersByRole } from 'api/user';
import { useCreateOrderQuoteLineItem } from 'api/lineItem';
import { QuoteDetailLineItems } from '../common/QuoteDetailLineItems/QuoteDetailLineItems';
import { QuoteDetailMessages } from '../common/QuoteDetailMessages/QuoteDetailMessages';
import { QuoteDetailEditForm } from '../common/QuoteDetailEditForm/QuoteDetailEditForm';
import { QuoteDetails } from '../common/QuoteDetails/QuoteDetails';
import { QuoteAdditionalDetails } from '../common/QuoteAdditionalDetails/QuoteAdditionalDetails';
import { QuoteDetailSidebarStatusAndPrice } from '../common/QuoteDetailSidebarStatusAndPrice/QuoteDetailSidebarStatusAndPrice';
import { Button } from 'components/Button';
import { Panel } from 'components/Panel';
import { ErrorView } from 'views/views';
import { useModal } from 'providers/Modal/ModalProvider';
import { useSingleQuote } from 'providers/SingleQuote/SingleQuoteProvider';
import { Select } from 'components/Form/Select/Select';
import { InputCheckbox } from 'components/Form/InputCheckbox/InputCheckbox';
import { formatDate } from 'common/utils/formatDate';
import { Input } from 'components/Form/Input/Input';
import { FormFooter } from 'components/Form/FormFooter/FormFooter';
import { formatAsUSD } from 'common/utils/formatAsUSD';
import { QuoteUploads } from '../common/QuoteUploads/QuoteUploads';

export const CustomerServiceQuoteDetailView = () => {
	const { isLoading: isAuthLoading, user } = useAuth();
	const {
		setIsQuoteReadyToSubmit,
		setIsOrderBeingPlaced,
		isQuoteDataValid,
		singleQuoteData,
		setSingleQuoteData,
		showSaveMessage
	} = useSingleQuote();
	const [quoteSaver, setQuoteSaver] = useState<() => Promise<void> | null>();
	const navigate = useNavigate();
	const { createModal, removeModal } = useModal();
	const { orderId } = useParams<{ orderId: string }>();
	const skipSubmitWarning = localStorage.getItem('skipSubmitWarning'); // should equal string 'skip'
	const skipClaimedByWarning = localStorage.getItem('skipClaimedByWarning'); // should equal string 'skip'

	const { data: customerServiceUsersData } = useGetUsersByRole('Customer Service');
	const currentCustomerServiceIdFromUserId = (userId: string) =>
		customerServiceUsersData?.filter(user => user.userId === userId)[0]?.id;
	const claimedCustomerServiceIdFromUserId = (id: string) =>
		customerServiceUsersData?.filter(user => user.id === id)[0];

	const { mutateAsync: updateOrder, isLoading: isUpdateOrderLoading } = useUpdateOrder();

	const { mutateAsync: createQuoteLineItem, error: createQuoteLineItemError } =
		useCreateOrderQuoteLineItem();

	const {
		data: getOrderData,
		isLoading: isGetOrderLoading,
		error: getOrderError
	} = useGetOrderById(orderId);

	// Set the context with the updated order data.
	useEffect(() => {
		if (!isGetOrderLoading) {
			setSingleQuoteData(getOrderData);
		}
	}, [isGetOrderLoading, getOrderData, setSingleQuoteData]);

	const handleSaveOrder = async (event: any) => {
		await quoteSaver();
	};

	const handleSwitchClaimedBy = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		const customerServiceRepUserId = event.target.value;

		if (skipClaimedByWarning === 'skip') {
			await updateOrder({
				...singleQuoteData,
				status: 'InReview',
				claimedById: customerServiceRepUserId
			});
			navigate(`/cs/orders/active/order/${singleQuoteData.id}`, {
				replace: true
			});
		} else {
			createModal({
				sourceEvent: event,
				heading: 'Are you sure want to claim this quote?',
				Content: () => {
					return (
						<>
							<p>
								Once you have claimed this quote you will be able to edit any details which will update
								what the customer sees on their side.
								{singleQuoteData?.status === 'Submitted'
									? ` Claiming this quote will cause the order status to switch to In Review and the customer will receive a notification that their order is now In Review.`
									: ''}
							</p>
							<Button
								type={'link'}
								onClick={() => {
									localStorage.setItem('skipClaimedByWarning', 'skip');
								}}
							>
								Don't show me this again.
							</Button>
						</>
					);
				},
				primaryButtonLabel: 'Claim Quote',
				primaryButtonAction: async () => {
					await updateOrder({
						...singleQuoteData,
						status: 'InReview',
						claimedById: customerServiceRepUserId
					});
					navigate(`/cs/orders/active/order/${singleQuoteData.id}`, {
						replace: true
					});
					removeModal();
				},
				isModalLoading: isUpdateOrderLoading
			});
		}
	};

	const handleReviewOrder = async (id: string) => {
		await updateOrder({
			...singleQuoteData,
			reviewedOn: new Date(),
			reviewedById: id
		});
	};

	const handleSubmitOrder = useCallback(
		async (event: any) => {
			if (skipSubmitWarning === 'skip') {
				if (isQuoteDataValid) {
					setIsOrderBeingPlaced(true);
					setIsQuoteReadyToSubmit(true);
					await quoteSaver();
					navigate(`/cs/orders/completed/order/${singleQuoteData.id}`, {
						replace: true
					});
				} else {
					setIsOrderBeingPlaced(false);
					setIsQuoteReadyToSubmit(false);
					await quoteSaver();
				}
			} else {
				createModal({
					sourceEvent: event,
					heading: 'You are about to submit your quote',
					Content: () => {
						return (
							<>
								<p>
									Once submitting your quote you will not be able to change any details or line items. If
									any updates need to be made then use the messages below on your order.{' '}
								</p>
								<Button
									type={'link'}
									onClick={() => {
										localStorage.setItem('skipSubmitWarning', 'skip');
									}}
								>
									Don't show me this again.
								</Button>
							</>
						);
					},
					primaryButtonLabel: 'Confirm submit',
					primaryButtonAction: async () => {
						if (isQuoteDataValid) {
							setIsOrderBeingPlaced(true);
							setIsQuoteReadyToSubmit(true);
							await quoteSaver();
							navigate(`/cs/orders/completed/order/${singleQuoteData.id}`, {
								replace: true
							});
						} else {
							setIsOrderBeingPlaced(false);
							setIsQuoteReadyToSubmit(false);
							await quoteSaver();
						}
						removeModal();
					},
					isModalLoading: isUpdateOrderLoading
				});
			}
		},
		[
			skipSubmitWarning,
			isQuoteDataValid,
			singleQuoteData,
			quoteSaver,
			createModal,
			isUpdateOrderLoading,
			navigate,
			removeModal,
			setIsOrderBeingPlaced,
			setIsQuoteReadyToSubmit
		]
	);

	// Boolean logic to determine id the current order is in a editable state should be InReview and claimed by a user to edit
	const canEditOrder = (): boolean => {
		if (
			singleQuoteData &&
			singleQuoteData.status &&
			singleQuoteData.status === 'InReview' &&
			singleQuoteData.claimedById
		) {
			return true;
		} else {
			return false;
		}
	};

	const isOrderReviewed = (): boolean => {
		if (singleQuoteData && singleQuoteData.claimedById && singleQuoteData.reviewedByUser) {
			return true;
		} else {
			return false;
		}
	};

	const isOrderPlaced = (): boolean => {
		if (singleQuoteData && singleQuoteData.status && singleQuoteData.status === 'OrderPlaced') {
			return true;
		} else {
			return false;
		}
	};

	// Loading & Errors
	if (getOrderError) {
		return <ErrorView isInAppFrame={true} />;
	}
	if (isAuthLoading || isGetOrderLoading) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	return (
		<>
			<PageFrame title={singleQuoteData?.name ?? 'New Order'} isContentManagedTitle={true}>
				<Container className={classes(css.appframe)}>
					{/* Left side content */}
					<div className={classes(css.main)}>
						{/* Top Title & Pricing toggle */}
						<div className={classes(css.flexContainer, css.justifySpaceBetween)}>
							{/* Top title and back button */}
							<div>
								{/* Order Name */}
								<h1 className={classes('h3', css.orderTitle)}>{singleQuoteData?.name ?? 'New Order'}</h1>
								{/* Back to all Quotes */}
							</div>
							{/* View pricing toggle */}
							<div className={classes(css.flexContainer, css.hideFromPrint)}>
								<p className={css.viewPricingLabel}>{'CLAIMED BY: '} </p>{' '}
								{isOrderPlaced() ? (
									<p>
										{claimedCustomerServiceIdFromUserId(singleQuoteData?.claimedById)?.firstName}{' '}
										{claimedCustomerServiceIdFromUserId(singleQuoteData?.claimedById)?.lastName}
									</p>
								) : (
									<Select
										label=''
										isLabelVisible={false}
										name='claimedById'
										onChange={event => handleSwitchClaimedBy(event)}
										value={singleQuoteData?.claimedById}
										className={css.customerServiceUserFilter}
									>
										<option value=''>Unclaimed</option>
										{customerServiceUsersData?.map(customerServiceUser => (
											<option value={customerServiceUser.id} key={customerServiceUser.id}>
												{customerServiceUser.firstName} {customerServiceUser.lastName}
											</option>
										))}
									</Select>
								)}
							</div>
						</div>

						<Button
							type={'link'}
							className={css.backLink}
							iconLeft={{
								type: 'CaretLeft',
								color: 'blue'
							}}
							onClick={() => {
								navigate(-1);
							}}
						>
							{singleQuoteData?.status === 'OrderPlaced' || singleQuoteData?.status === 'InReview'
								? 'Back to all orders'
								: 'Back to all quotes'}
						</Button>

						{/* Formik form for the order OR Sections of labels */}
						{showSaveMessage && showSaveMessage === 'success' && (
							<Alert type={'success-light'}>The quote was saved successfully.</Alert>
						)}
						{canEditOrder() ? (
							<QuoteDetailEditForm
								setFormSubmitter={setQuoteSaver}
								companyId={singleQuoteData.customerId}
							/>
						) : (
							<QuoteDetails orderData={singleQuoteData} />
						)}

						<QuoteDetailLineItems
							orderId={orderId}
							orderData={singleQuoteData}
							canEditLineItems={canEditOrder()}
							showDiscount={true}
						>
							{/* Adds the ability to add a line item to the quote */}
							{canEditOrder() && (
								<Panel className={css.addProductToOrder}>
									<Formik
										initialValues={{
											materialNumber: '',
											title: '',
											pricePerUnit: '0',
											lineItemDetails: '',
											quantity: '1'
										}}
										onSubmit={async (data, { setSubmitting }) => {
											setSubmitting(true);
											try {
												await createQuoteLineItem({
													orderId: singleQuoteData.id,
													materialNumber: data.materialNumber,
													title: data.title,
													pricePerUnit: parseFloat(data.pricePerUnit),
													discountedPricePerUnit: parseFloat(data.pricePerUnit), // customer service is responsible for making sure the discount is applied in pricePerUnit for adjustments so always set to null
													quantity: parseInt(data.quantity),
													lineItemDetails:
														data.lineItemDetails !== ''
															? JSON.stringify({ details: data.lineItemDetails })
															: ''
												});
												setSubmitting(false);

												// Reset form data for next add
												data.materialNumber = '';
												data.title = '';
												data.pricePerUnit = '';
												data.lineItemDetails = '';
												data.quantity = '';
											} catch {
												setSubmitting(false);
											}
										}}
										validationSchema={yup.object().shape({
											materialNumber: yup.string().required('Material number is required'),
											title: yup.string().required('Title is required'),
											pricePerUnit: yup.string().required('Price per unit is required'),
											lineItemDetails: yup.string(),
											quantity: yup.string().required('Qty is required')
										})}
									>
										{props => {
											return (
												<>
													{createQuoteLineItemError && !props.isSubmitting && (
														<Alert type='danger-light'>
															There was an error adding a line item to this order, please try again.
														</Alert>
													)}
													<Form>
														<h2 className={css.panelTitle}>Add Additional Product To Order</h2>
														<div className={classes(css.flexContainer, css.alignFlexStart)}>
															<div
																className={classes(css.flexContainer, css.alignFlexStart, css.stacked)}
															>
																<Input
																	name={'materialNumber'}
																	formikProps={props}
																	label={'Product #'}
																	className={css.largeFlex}
																/>
																<Input
																	name={'title'}
																	formikProps={props}
																	label={'Title'}
																	className={css.largeFlex}
																/>
																<Input
																	name={'lineItemDetails'}
																	formikProps={props}
																	label={'Description (optional)'}
																	className={css.largeFlex}
																	inputMessage={
																		'Mention any specifications if applied (Eg: thickness, finish, etc.)'
																	}
																/>
															</div>

															<Input
																name={'pricePerUnit'}
																formikProps={props}
																className={classes(css.mediumFlex, css.flexItem)}
																label={'Price'}
																inputMessage={'Discount will not automatically be applied'}
															/>
															<Input
																type={'number'}
																name={'quantity'}
																className={classes(css.smallFlex, css.flexItem)}
																min={1}
																formikProps={props}
																label={'Quantity'}
															/>
															<div className={classes(css.smallFlex, css.flexItem)}>
																<span className={css.inputLabel}>Total</span>
																<span>
																	{formatAsUSD(
																		parseFloat(props.values.pricePerUnit) *
																			parseInt(props.values.quantity),
																		true
																	)}
																</span>
															</div>
														</div>
														<FormFooter>
															<Button
																type={'primary'}
																htmlType={'submit'}
																className={css.addProductToOrderButton}
																isLoading={props.isSubmitting}
															>
																Add product to order
															</Button>
														</FormFooter>
													</Form>
												</>
											);
										}}
									</Formik>
								</Panel>
							)}
						</QuoteDetailLineItems>

						{/* Additional Details */}
						{singleQuoteData?.orderType === 'createopening' && singleQuoteData?.openingSaveStateObj && (
							<QuoteAdditionalDetails orderData={singleQuoteData} />
						)}

						{/* If order type is create an opening or has uploads */}
						{singleQuoteData &&
							singleQuoteData.orderUploads &&
							singleQuoteData.orderUploads.length > 0 && <QuoteUploads orderData={singleQuoteData} />}

						{/* Messages */}
						<QuoteDetailMessages orderId={orderId} orderData={singleQuoteData} />
					</div>

					{/* right side content */}
					<div className={classes(css.rightSidePanel)}>
						<QuoteDetailSidebarStatusAndPrice orderData={singleQuoteData} showDiscount={true} />

						{singleQuoteData && singleQuoteData.claimedById && (
							<>
								{singleQuoteData.claimedById !== currentCustomerServiceIdFromUserId(user.sub) && (
									<Panel className={css.orderReview}>
										{isOrderReviewed() ? (
											<>
												<p>Additional review was completed</p>
												<p className={css.smallSideBarText}>
													<span className={css.boldText}>
														By: {singleQuoteData?.reviewedByUser.firstName}{' '}
														{singleQuoteData?.reviewedByUser.lastName}
													</span>{' '}
													<br />
													on {formatDate(singleQuoteData.reviewedOn)}
												</p>
											</>
										) : (
											<InputCheckbox
												label={'Review this order'}
												checked={singleQuoteData.reviewedById ? true : false}
												onChange={event =>
													handleReviewOrder(currentCustomerServiceIdFromUserId(user.sub))
												}
											/>
										)}
									</Panel>
								)}
								{!isOrderPlaced() && (
									<Panel className={css.orderControl}>
										<>
											<p className={classes(css.orderReminderText, css.smallSideBarText)}>
												Reminder: ensure you've entered this order into AS400 before clicking approve &
												order above as this will alert the customer.
												{!isOrderReviewed() &&
													' This button will be disabled until another customer service representative has reviewed the order'}
											</p>
											<div className={css.orderButtons}>
												<Button
													type='primary'
													className={classes(css.submit, css.hideFromPrint)}
													disabled={singleQuoteData.reviewedById ? false : true}
													onClick={event => {
														event.persist();
														handleSubmitOrder(event);
													}}
												>
													I have entered into AS400
												</Button>
												<Button
													type={'link'}
													className={css.saveOpening}
													onClick={async event => {
														handleSaveOrder(event);
													}}
												>
													Save for later
												</Button>
											</div>
										</>
									</Panel>
								)}
							</>
						)}
					</div>
				</Container>
			</PageFrame>
		</>
	);
};
