import React from 'react';
import css from './QuoteAdditionalDetails.module.scss';
import { Order } from 'api/order';
import { GridContainer } from 'components/Grid/GridContainer';
import { GridItem } from 'components/Grid/GridItem';
import { Panel } from 'components/Panel';
import { Section } from 'components/Section';
import { keyToLabel } from 'common/utils/keyToLabel';
import { CreateOpeningSavedLocationState } from 'components/CreateOpening/common/types';

type FormattedSavedState = { key: string; label: string; value: string }[];

interface QuoteAdditionalDetailsProps {
	orderData: Order;
}

export const QuoteAdditionalDetails: React.FC<QuoteAdditionalDetailsProps> = props => {
	const { orderData } = props;

	const detailsFromSavedStateObject = (savedState: string): FormattedSavedState => {
		try {
			const parseSavedState: CreateOpeningSavedLocationState = JSON.parse(savedState);
			// Only metadata atm that displays via this component is create opening metadata
			const savedStateData = parseSavedState?.createOpeningInitialState?.createOpeningValues;
			if (savedStateData) {
				let savedStateArray: FormattedSavedState = [];

				// Manually pull them off in this order so the array order makes some sense
				const applicationKeys = Object.keys(savedStateData.application);
				applicationKeys?.forEach(applicationKey => {
					savedStateArray.push({
						key: applicationKey,
						label: keyToLabel(applicationKey),
						// @ts-ignore
						value: savedStateData.application[applicationKey]
					});
				});

				const templateKeys = Object.keys(savedStateData.template);
				templateKeys?.forEach(templateKey => {
					savedStateArray.push({
						key: templateKey,
						label: keyToLabel(templateKey),
						// @ts-ignore
						value: savedStateData.template[templateKey]
					});
				});

				const questionKeys = Object.keys(savedStateData.questions);
				questionKeys?.forEach(questionKey => {
					savedStateArray.push({
						key: questionKey,
						label: keyToLabel(questionKey),
						// @ts-ignore
						value: savedStateData.questions[questionKey]
					});
				});

				const filteredForNonEmptyValues = savedStateArray.filter(
					savedItem =>
						savedItem.value !== '' && savedItem.value !== null && savedItem.value !== undefined
				);

				return filteredForNonEmptyValues;
			} else {
				return [];
			}
		} catch {
			console.warn('Saved state could not be parsed');
			return [];
		}
	};

	return (
		<Section>
			<Panel title={'Additional Details'}>
				<GridContainer columnAmount='3'>
					{detailsFromSavedStateObject(orderData.openingSaveStateObj).map(savedItem => (
						<GridItem key={savedItem.key}>
							<p className={css.flexContainer}>{savedItem.label}</p>
							<div>{savedItem.value}</div>
						</GridItem>
					))}
				</GridContainer>
			</Panel>
		</Section>
	);
};
