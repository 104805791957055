import { useAxios } from 'providers/Axios/AxiosProvider';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { handleResponse } from './api';
import { Company } from './company';

export type User = {
	userId: string;
	username?: string;
	email: string;
	firstName: string;
	lastName: string;
	fullName: string;
	companyId?: string;
	company?: Company;
	dateCached: Date;
	internalUser: boolean;
	id: string;
	isDeleted: boolean;
	role: string;
	roleId: string;
	createdBy?: Date;
	createdAt?: Date;
	updatedBy?: Date;
	updatedAt?: Date;
	deletedBy?: Date;
	deletedAt?: Date;
	lastLogin?: Date;
};

export type UserAdd = {
	username: string;
	companyId?: string;
	email: string;
	firstName: string;
	lastName: string;
	verifyEmail: boolean;
	roleId: string;
	password: string;
};

export type AuthUpdateUserPasswordReq = {
	password: string;
};

type Users = User[];

// CREATE
export const useAddUser = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (user: UserAdd) => {
			return handleResponse(axios.post<UserAdd>('/user', user));
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['users']);
			}
		}
	);
};

// READ
export const useGetAllUsers = () => {
	const axios = useAxios();

	return useQuery(['users'], async () => {
		return handleResponse(axios.get<Users>('/user'));
	});
};

export const useGetUser = (userId: string) => {
	const axios = useAxios();

	return useQuery(['user', `${userId}`], async () => {
		return handleResponse(axios.get<User>(`/user/${userId}`));
	});
};

export const useGetUsersByRole = (role: string) => {
	/** Users Roles: Administrator, Customer Service, Customer */
	const axios = useAxios();

	return useQuery(['users', `${role}`], async () => {
		return handleResponse(axios.get<Users>(`/user/role/${role}`));
	});
};

// UPDATE
export const useUpdateUser = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (user: Partial<User>) => {
			return handleResponse(axios.put<Partial<User>>(`/user/${user.userId}`, user));
		},
		{
			onSuccess: (data, user) => {
				queryClient.invalidateQueries(['users']);
				queryClient.invalidateQueries(['user', `${user.userId}`]);
			}
		}
	);
};

export const useRequestPasswordReset = () => {
	const axios = useAxios();

	return useMutation(
		async (user: Partial<User>) => {
			return handleResponse(axios.put(`/userEmail/${user.email}/password`));
		},
		{ onSuccess: (data, user) => {} }
	);
};

export const usePasswordReset = (userId: string) => {
	const axios = useAxios();

	return useMutation(
		async (updateUserPasswordReq: Partial<AuthUpdateUserPasswordReq>) => {
			return handleResponse(axios.put(`/user/${userId}/password`, updateUserPasswordReq));
		},
		{ onSuccess: (data, user) => {} }
	);
};

// DELETE
export const useDeleteUser = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (user: Partial<User>) => {
			return handleResponse(axios.delete(`/user/${user.userId}`));
		},
		{
			onSuccess: (data, user) => {
				queryClient.invalidateQueries(['users']);
				queryClient.invalidateQueries(['user', user.userId]);
			}
		}
	);
};
