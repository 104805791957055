import React from 'react';
import { AppFrame } from 'components/AppFrame';
import { Navigate, Route, Routes } from 'react-router';
import * as Views from 'views';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { IsUserOfPermissionRouteGuard } from './IsUserOfPermissionRouteGuard';
import { CreateOpeningProvider } from 'providers/CreateOpening/CreateOpeningProvider';
import { SingleQuoteProvider } from 'providers/SingleQuote/SingleQuoteProvider';

const userIsCustomer = ['company:read:own'];
const userIsCustomerService = ['lineItem:modify:all'];
const userIsAdmin = ['create:users'];
const userIsAny = [...userIsCustomer, ...userIsCustomerService, ...userIsAdmin];

// Definition for all the routes for the whole app
export const AppRoutes = (
	<Routes>
		<Route path={'/public-route'} element={<PublicRoute />}>
			<Route path='' element={<Views.ErrorView />} />
		</Route>

		<Route path='/' element={<PrivateRoute />}>
			<Route path='/' element={<AppFrame />}>
				<Route
					path='/'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsCustomer} />}
				>
					<Route path='/' element={<Views.DashboardView />} />
				</Route>

				<Route
					path='/cs/orders/active'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsCustomerService} />}
				>
					<Route path='' element={<Views.CustomerServiceActiveOrdersView />} />
				</Route>

				<Route
					path='/cs/orders/active/:pageNumber'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsCustomerService} />}
				>
					<Route path='' element={<Views.CustomerServiceActiveOrdersView />} />
				</Route>

				<Route
					path='/cs/orders/active/order/:orderId'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsCustomerService} />}
				>
					<Route
						path=''
						element={
							<SingleQuoteProvider>
								<Views.CustomerServiceQuoteDetailView />
							</SingleQuoteProvider>
						}
					/>
				</Route>

				<Route
					path='/cs/orders/completed'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsCustomerService} />}
				>
					<Route path='' element={<Views.CustomerServiceCompletedOrdersView />} />
				</Route>

				<Route
					path='/cs/orders/completed/:pageNumber'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsCustomerService} />}
				>
					<Route path='' element={<Views.CustomerServiceCompletedOrdersView />} />
				</Route>

				<Route
					path='/cs/orders/completed/order/:orderId'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsCustomerService} />}
				>
					<Route
						path=''
						element={
							<SingleQuoteProvider>
								<Views.CustomerServiceQuoteDetailView />
							</SingleQuoteProvider>
						}
					/>
				</Route>

				<Route
					path='/cs/quotes/'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsCustomerService} />}
				>
					<Route path='' element={<Views.CustomerServiceQuotesView />} />
				</Route>

				<Route
					path='/cs/quotes/:pageNumber'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsCustomerService} />}
				>
					<Route path='' element={<Views.CustomerServiceQuotesView />} />
				</Route>

				<Route
					path='/cs/quotes/quote/:orderId'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsCustomerService} />}
				>
					<Route
						path=''
						element={
							<SingleQuoteProvider>
								<Views.CustomerServiceQuoteDetailView />
							</SingleQuoteProvider>
						}
					/>
				</Route>

				<Route
					path='/products/search'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsCustomer} />}
				>
					<Route path='' element={<Views.ProductSearchView />} />
				</Route>

				<Route
					path='/products/search/:pageNumber'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsCustomer} />}
				>
					<Route path='' element={<Views.ProductSearchView />} />
				</Route>

				<Route
					path='/products/product/:id'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsCustomer} />}
				>
					<Route path='' element={<Views.ProductSingleView />} />
				</Route>

				<Route
					path='/createopening'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsCustomer} />}
				>
					<Route path='' element={<CreateOpeningProvider />}>
						<Route path='' element={<Views.CreateOpeningView />} />
						<Route
							path='/createopening/:orderId'
							element={
								<SingleQuoteProvider>
									<Views.CreateOpeningView />
								</SingleQuoteProvider>
							}
						/>
					</Route>
				</Route>

				<Route
					path='/order-history'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsCustomer} />}
				>
					<Route path='' element={<Views.OrderHistoryView />} />
				</Route>

				<Route
					path='/order-history/:pageNumber'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsCustomer} />}
				>
					<Route path='' element={<Views.OrderHistoryView />} />
				</Route>

				<Route
					path='/order-history/order/:orderId'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsCustomer} />}
				>
					<Route
						path=''
						element={
							<SingleQuoteProvider>
								<Views.CustomerQuoteDetailView />
							</SingleQuoteProvider>
						}
					/>
				</Route>

				<Route
					path='/accountbasicdetails'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsAny} />}
				>
					<Route path='' element={<Views.AccountBasicDetailsView />} />
				</Route>

				<Route
					path='/accountpassword'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsAny} />}
				>
					<Route path='' element={<Views.AccountPasswordView />} />
				</Route>

				<Route
					path='/accountcompanydetails'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsCustomer} />}
				>
					<Route path='' element={<Views.AccountCompanyDetailsView />} />
				</Route>

				<Route
					path='/quotes'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsCustomer} />}
				>
					<Route path='' element={<Views.QuoteView />} />
				</Route>

				<Route
					path='/quotes/:pageNumber'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsCustomer} />}
				>
					<Route path='' element={<Views.QuoteView />} />
				</Route>

				<Route
					path='/quotes/quote/:orderId'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsCustomer} />}
				>
					<Route
						path=''
						element={
							<SingleQuoteProvider>
								<Views.CustomerQuoteDetailView />
							</SingleQuoteProvider>
						}
					/>
				</Route>

				<Route
					path='/admin/productmanagement/'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsAdmin} />}
				>
					<Route path='/admin/productmanagement/' element={<Views.ProductManagementView />} />
					<Route path='/admin/productmanagement/*' element={<Views.ProductManagementView />} />
				</Route>

				<Route
					path='/admin/productcodemanagement/'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsAdmin} />}
				>
					<Route path='/admin/productcodemanagement/' element={<Views.ProductCodeManagementView />} />
					<Route path='/admin/productcodemanagement/*' element={<Views.ProductCodeManagementView />} />
				</Route>

				<Route
					path='/admin/productfinishpricingmanagement/'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsAdmin} />}
				>
					<Route
						path='/admin/productfinishpricingmanagement/'
						element={<Views.ProductFinishPricingManagementView />}
					/>
					<Route
						path='/admin/productfinishpricingmanagement/*'
						element={<Views.ProductFinishPricingManagementView />}
					/>
				</Route>

				<Route
					path='/admin/companymanagement/'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsAdmin} />}
				>
					<Route path='/admin/companymanagement/' element={<Views.CompanyManagementView />} />
					<Route path='/admin/companymanagement/*' element={<Views.CompanyManagementView />} />
				</Route>

				<Route
					path='/admin/companypricingmanagement/'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsAdmin} />}
				>
					<Route
						path='/admin/companypricingmanagement/'
						element={<Views.CompanyPricingManagementView />}
					/>
					<Route
						path='/admin/companypricingmanagement/*'
						element={<Views.CompanyPricingManagementView />}
					/>
				</Route>

				<Route
					path='/admin/salesrepmanagement/'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsAdmin} />}
				>
					<Route path='/admin/salesrepmanagement/' element={<Views.SalesRepManagementView />} />
					<Route path='/admin/salesrepmanagement/*' element={<Views.SalesRepManagementView />} />
				</Route>

				<Route
					path='/admin/usermanagement'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsAdmin} />}
				>
					<Route path='' element={<Views.UsersManagementView />} />
				</Route>

				<Route
					path='/admin/usermanagement/:pageNumber'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsAdmin} />}
				>
					<Route path='' element={<Views.UsersManagementView />} />
				</Route>

				<Route
					path='/admin/usermanagement/user/add'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsAdmin} />}
				>
					<Route path='' element={<Views.UserAddManagementView />} />
				</Route>

				<Route
					path='/admin/usermanagement/user/:userId'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsAdmin} />}
				>
					<Route path='' element={<Views.UserManagementView />} />
				</Route>

				<Route
					path='/admin/referencenumbermanagement'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsAdmin} />}
				>
					<Route path='' element={<Views.ReferenceNumberManagementView />} />
				</Route>

				<Route
					path='/admin/referencenumbermanagement/:pageNumber'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsAdmin} />}
				>
					<Route path='' element={<Views.ReferenceNumberManagementView />} />
				</Route>

				<Route
					path='/admin/referencenumbermanagement/opening/:openingId'
					element={<IsUserOfPermissionRouteGuard userPermissionsToCheck={userIsAdmin} />}
				>
					<Route path='' element={<Views.ReferenceNumberOutcomeManagementView />} />
				</Route>
			</Route>
		</Route>

		<Route path='/*' element={<Navigate to='/' />}></Route>
	</Routes>
);
