import React, { useCallback } from 'react';
import { DropEvent, FileRejection, useDropzone, Accept } from 'react-dropzone';
import classes from 'classnames';
import { useModal } from 'providers/Modal/ModalProvider';
import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';

import css from './UploadDropZone.module.scss';

const MEGABYTE = 1048576;

export interface UploadDropZoneProps extends React.PropsWithChildren {
	maxFiles: number;
	allowedFileCount: number;
	maxFileMegabytes: number;
	acceptFileTypes: Accept;
	onDropSuccess: (acceptedFiles: File[]) => void;
	restrictionDescription?: string;
	showIcon?: boolean;
	style?: any;
	className?: string;
}

export const UploadDropZone = ({
	maxFiles,
	allowedFileCount,
	maxFileMegabytes,
	acceptFileTypes,
	onDropSuccess,
	restrictionDescription,
	showIcon = true,
	className,
	style,
	children
}: UploadDropZoneProps) => {
	const { createModal, removeModal } = useModal();

	const handleFileDrop = useCallback(
		async (acceptedFiles: File[], fileRejection: FileRejection[], event: DropEvent) => {
			if (fileRejection && fileRejection.length > 0) {
				// Determine the type of errors detected
				let isTooManyFiles = false;
				let isFileTooLarge = false;
				let isFileWrongType = false;

				fileRejection.forEach(rejectedFile => {
					rejectedFile.errors.forEach(error => {
						switch (error.code) {
							case 'too-many-files':
								isTooManyFiles = true;
								break;
							case 'file-too-large':
								isFileTooLarge = true;
								break;
							case 'file-invalid-type':
								isFileWrongType = true;
								break;
						}
					});
				});

				// Pop a modal describing the problems
				createModal({
					sourceEvent: event,
					heading: 'Problem uploading files',
					Content: () => (
						<>
							{isTooManyFiles && <div>A maximum of {maxFiles} files per type are allowed.</div>}
							{isFileTooLarge && <div>Files must be less than {maxFileMegabytes} MB to upload.</div>}
							{isFileWrongType && (
								<div>One or more of the selected files are the wrong type for this section.</div>
							)}
						</>
					),
					primaryButtonLabel: 'Ok',
					hasSecondaryButton: false,
					primaryButtonAction: () => removeModal()
				});
			} else {
				onDropSuccess(acceptedFiles);
			}
		},
		[createModal, removeModal, maxFileMegabytes, maxFiles, onDropSuccess]
	);

	// This validator is needed to cover the case when allowed files hits 0, which
	// disables the "maxFiles" feature of drop zone
	const fileCountValidator = (file: File) => {
		if (allowedFileCount <= 0) {
			return {
				code: 'too-many-files',
				message: `A maximum of ${maxFiles} files per type are allowed.`
			};
		}
	};

	const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
		onDrop: handleFileDrop,
		accept: acceptFileTypes,
		maxFiles: allowedFileCount,
		maxSize: maxFileMegabytes * MEGABYTE,
		validator: fileCountValidator
	});

	const containerClasses =
		className ??
		classes(css.uploadDropZone, {
			[css.active]: isDragActive,
			[css.success]: isDragAccept,
			[css.reject]: isDragReject
		});

	return (
		<div className={containerClasses} style={style} {...getRootProps()}>
			<input {...getInputProps()} />
			{showIcon && (
				<div>
					{/* TODO: Need to get an upload icon */}
					<Icon type='CaretUp' color='black' />
				</div>
			)}
			<div>
				<div>
					{children && <>{children}</>}
					{!children && (
						<>
							Drag &amp; drop files here to upload, or <Button type='link'>select a file</Button>
						</>
					)}
				</div>
				{restrictionDescription && <div>{restrictionDescription}</div>}
			</div>
		</div>
	);
};
