import React from 'react';

import { useAuth } from 'providers/Auth/AuthProvider';
import css from './AccountPasswordView.module.scss';
import classes from 'classnames';
import { ErrorView } from 'views/ErrorView';
import { Link } from 'components/Link';
import { Panel } from 'components/Panel';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { Spinner } from 'components/Spinner';
import { LoadingDelay } from 'components/LoadingDelay';
import { PageFrame } from 'components/PageFrame';
import { Alert } from 'components/Alert';
import { useGetUser, useRequestPasswordReset } from 'api/user';
import { Button } from 'components/Button/Button';
import { isUserOfPermission } from 'common/utils/isUserOfPermission';

export const AccountPasswordView = () => {
	const { isLoading, user, getPermissions } = useAuth();
	const userId = user.sub;
	const {
		mutateAsync: requestPasswordReset,
		error: requestPasswordUpdateError,
		data: requestPasswordUpdateData,
		isLoading: requestPasswordUpdateIsLoading
	} = useRequestPasswordReset();
	const { data: getUserData, isLoading: isGetUserLoading, error: getUserError } = useGetUser(userId);

	if (isLoading || isGetUserLoading) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	if (getUserError) {
		return <ErrorView isInAppFrame={true} />;
	}

	return (
		<PageFrame title={'Account'}>
			<Section className={css.sectionSearchResults}>
				<Container>
					<div className={css.searchContainer}>
						{/* Side Nav */}
						<div className={css.linksContainer}>
							<div className={css.linksContent} role='region'>
								<div className={css.linksHeader}>
									<span className={classes('h6', css.linksHeaderText)}>Your Account</span>
								</div>
								<div className={css.dropdownList}>
									<li key={1}>
										<Link to={'/accountbasicdetails'} classNames={{ buttonContent: css.buttonContent }}>
											Basic Details
										</Link>
									</li>
									<li key={2}>
										<Link
											to={'/accountpassword'}
											classNames={{ buttonContent: classes(css.buttonContent, css.active) }}
										>
											Password
										</Link>
									</li>
									{isUserOfPermission(['company:read:own'], getPermissions()) && (
										<li key={3}>
											<Link
												to={'/accountcompanydetails'}
												classNames={{ buttonContent: css.buttonContent }}
											>
												Company Details
											</Link>
										</li>
									)}
								</div>
							</div>
						</div>
						{/* Your Password */}
						<div className={css.resultsContainer}>
							<Panel title={'Password Reset'}>
								{requestPasswordUpdateData && !requestPasswordUpdateIsLoading && (
									<Alert type='success-light'>Password reset was successfully sent.</Alert>
								)}
								{requestPasswordUpdateError && !requestPasswordUpdateIsLoading && (
									<Alert type='danger-light'>
										There was an error requesting a new password for the user, please try again.
									</Alert>
								)}
								<p>
									Clicking the button below will send the user a password reset to the email address
									listed above.
								</p>
								<Button onClick={() => requestPasswordReset(getUserData)}>Send Password Reset</Button>
							</Panel>
						</div>
					</div>
				</Container>
			</Section>
		</PageFrame>
	);
};
