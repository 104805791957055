import React from 'react';
import css from './ProductCard.module.scss';
import classes from 'classnames';
import { formatAsUSD } from 'common/utils/formatAsUSD';
import { Link } from 'components/Link';
import { NoImage } from 'components/NoImage';
import { ProductPricingResponse } from 'api/product';
import { renderBulletList } from 'common/utils/renderBulletList';

type IProductCard = {
	className?: string;
} & ProductPricingResponse;

export const ProductCard: React.FC<IProductCard> = props => {
	const { className, product, productPricing } = props;
	return (
		<div className={classes(css.productCard, className)}>
			<div className={css.productCardLeft}>
				<div className={css.productImageContainer}>
					{product?.imageFileLocation ? (
						<img
							src={product?.imageFileLocation}
							alt={product?.productName}
							className={css.productImage}
						/>
					) : (
						<span className={classes(css.productImage, css.imageComingSoon)}>
							<NoImage size={'largest'} />
						</span>
					)}
				</div>

				<div className={css.productDetails}>
					<h3 className={'h5'}>{product?.productName}</h3>
					<small>Part number: {product?.materialNumber}</small>
					{renderBulletList([
						product?.bulletPoint1,
						product?.bulletPoint2,
						product?.bulletPoint3,
						product?.bulletPoint4,
						product?.bulletPoint5
					])}
					<span className={css.productDiscount}>
						<h3 className='h5'>
							{productPricing != null
								? formatAsUSD(
										productPricing[0]?.discountedPrice ??
											productPricing[0]?.priceByMasterData ??
											productPricing[0]?.priceByFinish,
										true
								  )
								: 'ERROR'}{' '}
							{productPricing != null ? (
								productPricing[0].discountedPrice ? (
									<small>with your multiplier</small>
								) : (
									<small>list price</small>
								)
							) : (
								'ERROR'
							)}{' '}
						</h3>
					</span>
					{productPricing != null
						? productPricing[0].discountedPrice && (
								<small className={css.listPrice}>
									{productPricing
										? formatAsUSD(
												productPricing[0]?.priceByMasterData ?? productPricing[0]?.priceByFinish,
												true
										  )
										: 'ERROR'}{' '}
									list price
								</small>
						  )
						: 'ERROR'}{' '}
					<Link
						type={'primary'}
						to={`/products/product/${product?.materialNumber}`}
						className={css.mobileProductLink}
					>
						View Product
					</Link>
				</div>
			</div>

			<Link
				type={'primary'}
				to={`/products/product/${product?.materialNumber}`}
				className={css.productLink}
			>
				View Product
			</Link>
		</div>
	);
};
