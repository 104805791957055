import React, { useEffect } from 'react';
import { RouteProps, Outlet } from 'react-router';
import { LoadingDelay } from 'components/LoadingDelay/LoadingDelay';
import { Spinner } from 'components/Spinner';
import { useAuth } from 'providers/Auth';

// A route component that extends Route
// Uses the auth custom hook to check if current user is authenticated
// IF not authenticated then redirects to the /login page.
// Passes the last visited location to the Login state so upon successful login
// The user can be Redirected back to desired route
export const PrivateRoute: React.FC<RouteProps> = () => {
	const { isAuthenticated, isLoading: isAuthLoading, login } = useAuth();

	// Doing this based off this: https://github.com/auth0/auth0-react/issues/226#issuecomment-984844253
	useEffect(() => {
		if (isAuthLoading || isAuthenticated) {
			return;
		}

		const redirectToLogin = async () => {
			await login();
		};

		redirectToLogin();
	}, [isAuthLoading, isAuthenticated, login]);

	if (isAuthLoading) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	return (
		<>
			{' '}
			{isAuthenticated ? (
				<Outlet />
			) : (
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			)}{' '}
		</>
	);
};
