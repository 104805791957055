import React, { useEffect } from 'react';
import css from './QuoteDetailEditForm.module.scss';
import { Order, useUpdateOrder, OrderBaseDetails } from 'api/order';
import { GridContainer } from 'components/Grid/GridContainer';
import { GridItem } from 'components/Grid/GridItem';
import { Input } from 'components/Form/Input';
import { Formik, Form, useFormikContext } from 'formik';
import * as yup from 'yup';
import { InputTextArea } from 'components/Form/InputTextArea';
import { Panel } from 'components/Panel';
import { Section } from 'components/Section';
import { useGetCompanyById } from 'api/company';
import { useSingleQuote } from 'providers/SingleQuote/SingleQuoteProvider';

interface QuoteDetailEditFormProps {
	companyId: string;
	setFormSubmitter: React.Dispatch<React.SetStateAction<() => Promise<void> | null>>;
}

const QuoteDetailValidator: React.FC = props => {
	const { setIsQuoteDataValid } = useSingleQuote();
	const { values, isValid } = useFormikContext<OrderBaseDetails>();

	useEffect(() => {
		setIsQuoteDataValid(isValid);
	}, [values, isValid, setIsQuoteDataValid]);

	return null;
};

const saveValidatorSchema = yup.object().shape({
	poNumber: yup.string(),
	shipToAddressLine1: yup.string(),
	shipToAddressLine12: yup.string(),
	shipToCity: yup.string(),
	shipToState: yup.string(),
	shipToZipCode: yup.string(),
	shippingTopComment: yup.string()
});

const submitValidatorSchema = yup.object().shape({
	poNumber: yup.string().required('PO Number is required'),
	shipToAddressLine1: yup.string().required('Shipping address is required'),
	shipToAddressLine12: yup.string(),
	shipToCity: yup.string().required('Shipping city is required'),
	shipToState: yup.string().required('Shipping state is required'),
	shipToZipCode: yup.string().required('Shipping Zip code is required'),
	shippingTopComment: yup.string()
});

export const QuoteDetailEditForm: React.FC<QuoteDetailEditFormProps> = props => {
	const { setFormSubmitter, companyId } = props;
	const {
		singleQuoteData,
		setSingleQuoteData,
		setShowSaveMessage,
		isQuoteReadyToSubmit,
		isOrderBeingPlaced
	} = useSingleQuote();
	const { data: getCompanyData } = useGetCompanyById(companyId);
	const { mutateAsync: updateOrder } = useUpdateOrder();
	return (
		<Formik
			initialValues={{
				poNumber: singleQuoteData?.poNumber ?? '',
				shipToAddressLine1: singleQuoteData?.shipToAddressLine1 ?? getCompanyData?.addressLine1 ?? '',
				shipToAddressLine2: singleQuoteData?.shipToAddressLine2 ?? getCompanyData?.addressLine2 ?? '',
				shipToCity: singleQuoteData?.shipToCity ?? getCompanyData?.addressCity ?? '',
				shipToState: singleQuoteData?.shipToState ?? getCompanyData?.addressState ?? '',
				shipToZipCode: singleQuoteData?.shipToZipCode ?? getCompanyData?.addressZipCode ?? '',

				shippingTopComment: singleQuoteData?.shippingTopComment ?? ''
			}}
			validateOnChange={true}
			onSubmit={async (data, { setSubmitting, setErrors }) => {
				setSubmitting(true);
				try {
					setSingleQuoteData({
						...singleQuoteData,
						...data
					});

					if (isQuoteReadyToSubmit) {
						await submitValidatorSchema.validate(data, { abortEarly: false });

						const submittedQuoteData: Order = {
							...singleQuoteData,
							...data,
							status: isOrderBeingPlaced ? 'OrderPlaced' : 'Submitted',
							submittedOn: new Date(),
							...(isOrderBeingPlaced
								? {
										enteredIntoAS400: true
								  }
								: {})
						};

						setSingleQuoteData(submittedQuoteData);
						await updateOrder(submittedQuoteData);
					} else {
						await saveValidatorSchema.validate(data, { abortEarly: false });
						await updateOrder({
							...singleQuoteData,
							...data
						});
					}
					setShowSaveMessage('success');
				} catch (err) {
					// Handle validation errors
					const validationErrors = {};
					// @ts-ignore
					err.inner.forEach(error => {
						// @ts-ignore
						validationErrors[error.path] = error.message;
					});
					setErrors(validationErrors);
					setShowSaveMessage('error');
				} finally {
					setSubmitting(false);
				}
			}}
			validationSchema={saveValidatorSchema}
		>
			{props => {
				setTimeout(() => {
					setFormSubmitter(() => props.submitForm);
				}, 0);
				return (
					<>
						<Form>
							{/* Order Details */}
							<Section hasPaddingTop={false}>
								<Panel title={'Order Details'}>
									<GridContainer columnAmount='2'>
										{/* PO Number */}
										<GridItem>
											<Input
												formikProps={props}
												label={'PO #'}
												name={'poNumber'}
												placeholder={'Enter your PO #'}
											></Input>
										</GridItem>
									</GridContainer>
									<p className={css.width100percent}>Enter your shipping address</p>
									{/* Address Line 1 */}
									<Input
										formikProps={props}
										label={'Address line 1'}
										name={'shipToAddressLine1'}
										placeholder={'Enter address line 1'}
									></Input>
									{/* Address Line 2 */}
									<Input
										formikProps={props}
										label={'Address line 2 (optional)'}
										name={'shipToAddressLine2'}
										placeholder={'Enter address line 2'}
									></Input>
									{/* City & State & Zip Code */}
									<GridContainer columnAmount='2' className={css.flexWrapNone}>
										{/* City */}
										<GridItem>
											<Input
												formikProps={props}
												label={'City'}
												name={'shipToCity'}
												placeholder={'Enter city'}
											></Input>
										</GridItem>
										{/* State & Zip */}
										<GridContainer columnAmount='2'>
											{/* State */}
											<GridItem>
												<Input
													formikProps={props}
													label={'State'}
													name={'shipToState'}
													placeholder={'Enter state'}
												></Input>
											</GridItem>
											{/* Zip */}
											<GridItem>
												<Input
													formikProps={props}
													label={'Zip/Postal Code'}
													name={'shipToZipCode'}
													placeholder={'Enter zip/postal'}
												></Input>
											</GridItem>
										</GridContainer>
									</GridContainer>
									<InputTextArea
										label={'Top Comments (optional)'}
										className={css.hideFromPrint}
										name={'shippingTopComment'}
										placeholder={'Add a short description of your project to identify later'}
										formikProps={props}
										tooltip='Top comments allow you to add project specific labels to your order to make delivery easier. These labels are printed and placed on the outside of the box when shipping.'
										inputMessage='To add product specific labels, please use the “Tag Comments” section
															below.'
									></InputTextArea>
								</Panel>
							</Section>

							<QuoteDetailValidator />
						</Form>
					</>
				);
			}}
		</Formik>
	);
};
