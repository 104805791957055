import { TObjectWithStringAsProps } from 'common/types/TObjectWithStringAsProps';

export const coerceObjectPropertyToNumber = (object: TObjectWithStringAsProps, property: string) => {
	if (object[property]) {
		return {
			...object,
			[property]: Number(object[property])
		};
	} else {
		return object;
	}
};
