import React from 'react';
import { Order } from 'api/order';
import { Panel } from 'components/Panel';
import { Section } from 'components/Section';
import { Button } from 'components/Button/Button';
import { useGetOrderDownload } from 'api/orderUpload';

interface QuoteUploadsProps {
	orderData: Order;
}

export const QuoteUploads: React.FC<QuoteUploadsProps> = props => {
	const { orderData } = props;

	const { mutateAsync: getOrderDownload } = useGetOrderDownload();

	const downloadOrderUpload = async (
		orderId: string,
		orderUploadId: string,
		orderUploadFilename: string
	) => {
		try {
			const response = await getOrderDownload({ orderId, orderUploadId });
			// Create a blob from the response
			const url = window.URL.createObjectURL(
				new Blob([response.data], { type: response.headers['content-type'] })
			);
			const link = document.createElement('a');
			link.href = url;

			link.setAttribute('download', orderUploadFilename);
			document.body.appendChild(link);

			link.click();

			// Remove the link after triggering the download
			link.parentNode.removeChild(link);
		} catch (error) {
			console.error('File download failed:', error);
		}
	};

	return (
		<Section>
			<Panel title={'Uploads'} description={'Uploaded elevation drawings:'}>
				<ul>
					{orderData.orderUploads?.map(upload => (
						<li key={upload.id}>
							<Button
								type={'link'}
								onClick={() => downloadOrderUpload(orderData.id, upload.id, upload.fileName)}
							>
								{upload.fileName}
							</Button>
						</li>
					))}
				</ul>
			</Panel>
		</Section>
	);
};
