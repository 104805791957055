import { Product } from 'api/product';

export const calculateDisplayedProductPrice = (
	productPrice: number,
	quantity: number = 1,
	unitOfMeasure: Product['unitOfMeasure'] = 'EA',
	width: number
): number | null => {
	if (unitOfMeasure === 'EA') {
		return productPrice * quantity;
	} else if (unitOfMeasure === 'FT' || unitOfMeasure === 'CLEAR OPENING FT') {
		if (width > 0) {
			return productPrice * Math.ceil(width) * quantity;
		} else {
			console.error('Width is required for FT pricing');
			return null;
		}
	} else if (unitOfMeasure === 'FREE') {
		return 0;
	} else {
		console.error(`Unknown unit of measure ${unitOfMeasure}`);
		return null;
	}
};
