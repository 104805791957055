import React from 'react';
import css from './QuoteDetailMessages.module.scss';
import classes from 'classnames';
import { useAuth } from 'providers/Auth';
import { Order } from 'api/order';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { InputTextArea } from 'components/Form/InputTextArea';
import { Button } from 'components/Button';
import { Panel } from 'components/Panel';
import { Section } from 'components/Section';
import { FormFooter } from 'components/Form/FormFooter';
import { useAddOrderComment, useGetOrderCommentsByOrderId } from 'api/orderComment';
import { Alert } from 'components/Alert/Alert';
import { MessageBubblesList } from 'components/Order/MessageBubblesList/MessageBubblesList';
import { isUserOfPermission } from 'common/utils/isUserOfPermission';

interface QuoteDetailMessagesProps {
	orderId: string;
	orderData: Order;
}

export const QuoteDetailMessages: React.FC<QuoteDetailMessagesProps> = props => {
	const { orderId, orderData } = props;
	const { getPermissions } = useAuth();
	const { data: getOrderComments } = useGetOrderCommentsByOrderId(orderId);

	const {
		mutateAsync: addOrderComment,
		error: addOrderCommentError,
		data: addOrderCommentData,
		isLoading: isAddingOrderCommentLoading
	} = useAddOrderComment();
	return (
		<Section className={css.hideFromPrint}>
			<Panel title={''} description={''}>
				{/* Title and Description */}
				<div className={css.hasSpaceBelowTopArea}>
					<div className={classes(css.flexContainer, css.panelTitle)}>
						<h2>Messages</h2>
						<span className={css.panelCount}>
							{`(${orderData?.orderComments?.length ?? 0} messages)`}
						</span>
					</div>
					<p className={css.panelDescription}>
						{isUserOfPermission(['lineItem:modify:all'], getPermissions()) ? (
							<>
								<span className={css.marginBottomZero}>
									Respond or ask questions to the customer below and keep your conversations organized
									within this order. The customer will be notified they have a message by email.{' '}
								</span>
								<span className={classes(css.italicText, css.greyColor3)}>(optional)</span>
								<span className={classes(css.subTextLabelMargin, css.greyColor2)}>
									{' '}
									Please note, we recommend you do not add any sensitive information in this messages
									(such as payment, etc){' '}
								</span>
							</>
						) : (
							<>
								<span className={css.marginBottomZero}>
									Email customer service below and keep your conversations organized within this order.{' '}
								</span>
								<span className={classes(css.italicText, css.greyColor3)}>(optional)</span>
								<span className={classes(css.subTextLabelMargin, css.greyColor2)}>
									{' '}
									Please note, we recommend you do not add any sensitive information in this messages
									(such as payment, etc){' '}
								</span>
							</>
						)}
					</p>
				</div>
				{addOrderCommentData && !isAddingOrderCommentLoading && (
					<Alert type='success-light'>Message successfully sent.</Alert>
				)}
				<Formik
					initialValues={{
						commentText: '',
						orderId: orderData?.id
					}}
					onSubmit={async (data, { setSubmitting }) => {
						setSubmitting(true);
						try {
							await addOrderComment(data);
							setSubmitting(false);
							data.commentText = '';
						} catch {
							setSubmitting(false);
						}
					}}
					validationSchema={yup.object().shape({
						commentText: yup.string().required('A message is required')
					})}
				>
					{props => {
						return (
							<>
								{addOrderCommentError && !props.isSubmitting && (
									<Alert type='danger-light'>
										There was an error adding a message to this order, please try again.
									</Alert>
								)}
								<Form>
									<InputTextArea
										formikProps={props}
										label={'Messages'}
										isLabelVisible={false}
										name={'commentText'}
										placeholder={'Enter message'}
									></InputTextArea>
									<FormFooter>
										<Button type='primary' htmlType='submit' isLoading={props.isSubmitting}>
											Send Message
										</Button>
									</FormFooter>
								</Form>
							</>
						);
					}}
				</Formik>
			</Panel>
			{getOrderComments?.length > 0 && (
				<Panel className={css.messagesPanel}>
					<MessageBubblesList messages={getOrderComments} />
				</Panel>
			)}
		</Section>
	);
};
