import React, { useState } from 'react';
import { TInput } from 'common/types/TInput';
import classes from 'classnames';
import { RemoveProp } from 'common/types/TypeHelpers';
import { getUniqueId } from 'common/utils/getUniqueId';
import css from './InputCheckbox.module.scss';
import { FormikProps } from 'formik';
import { removePropertiesFromObjects } from 'common/utils/removePropertiesFromObjects';
import { replacePropsWithFormikProps } from 'common/utils/replacePropsWithFormikProps';

export type IInputCheckbox = {
	label: string;
	isLabelHidden?: boolean;
	checked?: boolean;
	formikProps?: FormikProps<any>;
	className?: string;
	checkboxSize?: 'small' | 'medium';
} & RemoveProp<TInput, 'type'>;

export const InputCheckbox: React.FC<IInputCheckbox> = props => {
	const {
		className,
		label,
		id,
		formikProps,
		name,
		value,
		isLabelHidden,
		checked: passedInCheckedValue,
		checkboxSize = 'medium'
	} = props;
	const [inputId] = useState(id ? id : getUniqueId());

	const inputProps: any = removePropertiesFromObjects(
		['label', 'formikProps', 'isLabelHidden', 'className', 'checkboxSize'],
		props
	);

	const inputPropsWithFormik = replacePropsWithFormikProps(inputProps, formikProps, name);

	const isInputAnArray = Array.isArray(formikProps?.values[inputPropsWithFormik.name]);

	const hasCheckValuePassedIn = typeof passedInCheckedValue !== 'undefined';

	const checkValueToRender = (() => {
		if (hasCheckValuePassedIn) {
			return passedInCheckedValue;
		} else if (isInputAnArray) {
			return formikProps?.values[inputPropsWithFormik.name].includes(value);
		} else {
			return undefined;
		}
	})();

	return (
		<div className={classes(css.checkbox, className, css[checkboxSize])}>
			<input
				{...inputPropsWithFormik}
				id={inputId}
				type='checkbox'
				value={value}
				checked={checkValueToRender}
				className={classes(className, css.input)}
			/>
			<label htmlFor={inputId} className={classes(css.label, { [css.isLabelHidden]: isLabelHidden })}>
				<span className={isLabelHidden && 'screenReaderText'}>{label}</span>
			</label>
		</div>
	);
};
