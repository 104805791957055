import React from 'react';
import css from './CreateOpeningView.module.scss';
import { useAuth } from 'providers/Auth/AuthProvider';
import { useCreateOpening } from 'providers/CreateOpening';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { Spinner } from 'components/Spinner';
import { LoadingDelay } from 'components/LoadingDelay';
import { PageFrame } from 'components/PageFrame';
import { TemplateSelection } from 'components/CreateOpening/TemplateSelection';
import { TemplateEstimate } from 'components/CreateOpening/TemplateEstimate';
import { TemplateQuestions } from 'components/CreateOpening/TemplateQuestions';
import { TemplateComments } from 'components/CreateOpening/TemplateComments';
import { TemplateApplication } from 'components/CreateOpening/TemplateApplication/TemplateApplication';
import { CreateOpeningNavigation } from 'components/CreateOpening/CreateOpeningNavigation/CreateOpeningNavigation';
import { CustomerQuoteDetailView } from 'views';

export const CreateOpeningView = () => {
	const { isLoading: isAuthLoading } = useAuth();
	const { currentCreateOpeningStep } = useCreateOpening();

	if (isAuthLoading) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	return (
		<PageFrame
			title={'Create Opening'}
			isContentManagedTitle={true}
			showFooter={false}
			classNames={{
				pageFrame: css.createOpeningViewPageFrame,
				content: css.createOpeningViewPageFrameContent
			}}
		>
			<Container>
				<Section hasPaddingTop={currentCreateOpeningStep !== 'quote'}>
					{currentCreateOpeningStep === 'application' && <TemplateApplication />}
					{currentCreateOpeningStep === 'template' && <TemplateSelection />}
					{currentCreateOpeningStep === 'estimate' && <TemplateEstimate />}
					{currentCreateOpeningStep === 'question' && <TemplateQuestions />}
					{currentCreateOpeningStep === 'comments' && <TemplateComments />}
					{currentCreateOpeningStep === 'quote' && (
						<CustomerQuoteDetailView showFooter={false} mode={'createopening'} />
					)}
				</Section>
			</Container>
			<CreateOpeningNavigation className={css.createOpeningNavigation} />
		</PageFrame>
	);
};
