import { Product } from './product';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { handleResponse } from './api';
import { useAxios } from 'providers/Axios/AxiosProvider';

//#region Models

export type LineItem = {
	orderId: string;
	materialNumber: string;
	title: string;
	product?: Product;
	lineNumber?: number;
	pricePerUnit: number;
	discountedPricePerUnit: number;
	lineItemDetails?: string;
	quantity: number;
	shippingTagComment?: string;

	// Generic Fields
	id?: string;
	isDeleted?: boolean;
	createdBy?: string;
	createdAt?: Date;
	updatedBy?: string;
	updatedAt?: Date;
	deletedBy?: string;
	deletedAt?: Date;
};

type ApiLineItemsResponse = LineItem[];

//#endregion models

//#region LINE ITEM API CRUD OPERATIONS - created using Axios Provider to manage the Requests

// Create
export const useCreateOrderQuoteLineItem = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();
	return useMutation(
		async (lineItem: LineItem) => {
			return handleResponse(axios.post('lineItem', lineItem));
		},
		{
			onSuccess: (data, lineItem) => {
				queryClient.invalidateQueries(['lineItems']);
				queryClient.invalidateQueries(['lineItems', `${lineItem.orderId}`]);
				queryClient.invalidateQueries(['order', `${lineItem.orderId}`]);
			}
		}
	);
};

// Read
export const useGetLineItemByOrderIdAsync = (orderId: string) => {
	const axios = useAxios();
	return useQuery(['lineItems', `${orderId}`], async () => {
		return handleResponse(axios.get<ApiLineItemsResponse>(`/lineItem/order/${orderId}`));
	});
};

// Update
export const useUpdateLineItem = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (lineItem: Partial<LineItem>) => {
			return handleResponse(axios.put<Partial<LineItem>>(`/lineItem/${lineItem.id}`, lineItem));
		},
		{
			onSuccess: (data, lineItem) => {
				queryClient.invalidateQueries(['lineItems']);
				queryClient.invalidateQueries(['lineItems', `${lineItem.id}`]);
				queryClient.invalidateQueries(['order', `${lineItem.orderId}`]);
			}
		}
	);
};

// Delete
export const useDeleteLineItem = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (lineItem: Partial<LineItem>) => {
			return handleResponse(axios.delete(`/lineItem/${lineItem.id}`));
		},
		{
			onSuccess: (data, lineItem) => {
				queryClient.invalidateQueries(['lineItems']);
				queryClient.invalidateQueries(['lineItems', `${lineItem.orderId}`]);
				queryClient.invalidateQueries(['order', `${lineItem.orderId}`]);
				queryClient.invalidateQueries(['lineItem', lineItem.id]);
			}
		}
	);
};

//#endregion
