import React from 'react';
import { TDropdownItem } from 'common/types/TDropdownItem';
import { DropdownList } from 'components/DropdownList';
import css from './DropdownSupport.module.scss';
import classes from 'classnames';

// INTERNAL DEV NOTE: this is essentially a copy of DropdownAccount :) with some tweaks

interface IDropdownSupportProps {
	isOpen?: boolean;
}

export const DropdownSupport: React.FC<IDropdownSupportProps> = props => {
	const { isOpen } = props;

	const accountDropdownItems: TDropdownItem[] = [
		{
			title: 'Purchase Orders related questions: glassorders.us@dormakaba.com',
			action: () => {
				window.open('mailto:glassorders.us@dormakaba.com', '_blank');
			}
		},
		{
			title: 'Quote Requests related questions: glassestimates.us@dormakaba.com',
			action: () => {
				window.open('mailto:glassestimates.us@dormakaba.com', '_blank');
			}
		},
		{
			title:
				'Technical Request related questions: glasstechserv.us@dormakaba.com or call 800-523-8483 ext. 8887',
			action: () => {
				window.open('mailto:glasstechserv.us@dormakaba.com', '_blank');
			}
		},
		{
			title: 'Customer Assistance requests to: glasscustserv.us@dormakaba.com or call 800-523-8483',
			action: () => {
				window.open('mailto:glasscustserv.us@dormakaba.com', '_blank');
			}
		}
	];

	return (
		<DropdownList
			type='control'
			items={accountDropdownItems}
			iconRight={{
				type: 'CaretDown',
				size: 'small',
				color: 'blue'
			}}
			buttonClassName={css.dropdownSupportButton}
			size='small'
			className={css.dropdownSupport}
			ContentTop={() => (
				<div className={css.profileContent}>
					<h2 className={classes(css.name, 'h5')}>Please reach out to our support team as needed.</h2>
				</div>
			)}
			dropdownPosition='right'
			isOpen={isOpen}
		>
			Support
			<span className='screenReaderText'>Support Dropdown</span>
		</DropdownList>
	);
};
