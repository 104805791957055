import React, { useRef, PropsWithChildren } from 'react';
import css from './QuoteDetailLineItems.module.scss';
import classes from 'classnames';
import { UseQueryResult } from 'react-query';
import { Order, useGetOrderById } from 'api/order';
import { Input } from 'components/Form/Input';
import { InputTextArea } from 'components/Form/InputTextArea';
import { TTableHeader } from 'components/Table';
import { Button } from 'components/Button';
import { Panel } from 'components/Panel';
import { Section } from 'components/Section';
import { Alert } from 'components/Alert/Alert';
import { TableController } from 'components/Table/TableController';
import { LineItem, useDeleteLineItem, useUpdateLineItem } from 'api/lineItem';
import { NoImage } from 'components/NoImage/NoImage';
import { formatAsUSD } from 'common/utils/formatAsUSD';
import { debounce } from 'common/utils/debounce';

interface QuoteDetailLineItemsProps {
	orderId: string;
	orderData: Order;
	showDiscount?: boolean;
	canEditLineItems?: boolean;
}

export const QuoteDetailLineItems: React.FC<PropsWithChildren<QuoteDetailLineItemsProps>> = props => {
	const { orderId, orderData, showDiscount = false, canEditLineItems = false, children } = props;
	const tableBaseRoute = `/quotes/quote/${orderId}/lineItems/`;
	const useQuery = (): UseQueryResult => {
		return useGetOrderById(orderId);
	};

	const dataSelector = (data: Order) => {
		return data?.lineItems.filter(lineItem => !lineItem.isDeleted);
	};

	// Update line Item
	const { mutateAsync: updateLineItem } = useUpdateLineItem();
	const debouncedUpdateLineItem = useRef(
		debounce(async (lineItem: Partial<LineItem>) => await updateLineItem(lineItem), 500, false)
	).current;

	// Remove Line Item
	const {
		mutateAsync: requestDeleteLineItem,
		error: requestDeleteLineItemError,
		data: requestDeleteLineItemData,
		isLoading: requestDeleteLineItemIsLoading
	} = useDeleteLineItem();

	// Line Item Table Variables
	const headersLineItems: TTableHeader[] = [
		{
			label: 'Product Name',
			property: 'name',
			className: css.firstColumn,
			renderFunction: (data: LineItem) => (
				<>
					<div className={classes(css.flex, css.font14)}>
						{/* Product Image */}
						<div className={css.productImageContainer}>
							{data?.product?.imageFileLocation ? (
								<img
									src={data?.product?.imageFileLocation}
									alt={data?.title}
									className={css.productImage}
								/>
							) : (
								<span className={classes(css.productImage, css.imageComingSoon)}>
									<NoImage size={'small'} />
								</span>
							)}
						</div>
						{/* Product Info Blurb */}
						<div>
							<div className={css.nameText}>
								{data?.title !== '' ? data?.title : data?.materialNumber}
							</div>
							<div className={css.subText}>{data?.materialNumber}</div>
							{(() => {
								if (data?.lineItemDetails) {
									const parsedLineItemDetail = JSON.parse(data.lineItemDetails);
									const lineItemDetailsKeys = Object.keys(parsedLineItemDetail);
									return (
										<ul className={css.lineItemDetails}>
											{lineItemDetailsKeys.map(lineItemDetail => {
												const lineItemDetailValue = parsedLineItemDetail[lineItemDetail];
												return (
													<li key={`${data.id}-${lineItemDetail}`}>
														<span className={classes(css.boldText, css.lineItemDetailKey)}>
															{lineItemDetail}:
														</span>{' '}
														{lineItemDetailValue}
													</li>
												);
											})}
										</ul>
									);
								}
							})()}
						</div>
					</div>
				</>
			)
		},
		{
			label: 'List Price',
			property: 'pricePerUnit',
			className: css.smallColumn,
			renderFunction: (data: LineItem) => (
				<>{data?.pricePerUnit != null ? formatAsUSD(data?.pricePerUnit, true) : 'ERROR'} </>
			)
		},
		{
			label: 'Qty',
			property: 'quantity',
			className: css.smallColumn,
			renderFunction: (data: LineItem) => (
				<>
					{canEditLineItems ? (
						<div>
							<Input
								label={''}
								className={css.hideFromPrint}
								isLabelVisible={false}
								name={'quantity'}
								placeholder={'Enter a quantity'}
								maxLength={6}
								min={1}
								defaultValue={data?.quantity ?? 0}
								type={'number'}
								onChange={async event => {
									await updateLineItem({ ...data, quantity: parseInt(event.target.value) });
								}}
							></Input>
							<span className={classes(css.hideScreen)}>{data?.quantity ?? 0}</span>
						</div>
					) : (
						<div>{data?.quantity ?? 0}</div>
					)}
				</>
			)
		},
		{
			label: 'Total',
			property: 'total',
			className: css.mediumColumn,
			renderFunction: (data: LineItem) => (
				<>
					{showDiscount &&
					data?.discountedPricePerUnit !== 0 &&
					data?.pricePerUnit * data?.quantity - data?.discountedPricePerUnit * data?.quantity !== 0 ? (
						<>
							<span>{formatAsUSD(data?.discountedPricePerUnit * data?.quantity, true)}</span>
							<div className={css.subText}>
								<span>You Save: </span>{' '}
								{formatAsUSD(
									data?.pricePerUnit * data?.quantity - data?.discountedPricePerUnit * data?.quantity,
									true
								)}{' '}
								<span>after discount</span>
							</div>
						</>
					) : (
						<span>{formatAsUSD(data?.pricePerUnit * data?.quantity, true)}</span>
					)}
				</>
			)
		},
		{
			label: 'Tag comments',
			tooltip:
				'Tag comments allow you to add specific printed labels to individual items within your order to make delivery easier.',
			property: 'shippingTagComment',
			className: classes(css.columnPadding, css.hideFromPrint),
			renderFunction: (data: LineItem) => (
				<>
					{canEditLineItems ? (
						<div className={css.wordWrapBreak}>
							<InputTextArea
								label={'Top Comments'}
								isLabelVisible={false}
								name={'shippingTopComment'}
								placeholder={'Add a short description of your project to identify later'}
								defaultValue={data?.shippingTagComment ?? ''}
								classNameDomInput={css.tagCommentInput}
								onChange={event => {
									// @ts-ignore
									debouncedUpdateLineItem({ ...data, shippingTagComment: event.target.value });
								}}
							></InputTextArea>
						</div>
					) : (
						<div>{data?.shippingTagComment ?? ''}</div>
					)}
				</>
			)
		}
	];

	if (canEditLineItems) {
		headersLineItems.push({
			label: '',
			property: 'remove',
			className: classes(css.smallColumn, css.hideFromPrint),
			renderFunction: (data: LineItem) => (
				<>
					<div className={css.flexContainer}>
						<Button
							className={css.paddingZero}
							type='control'
							onClick={() => requestDeleteLineItem(data)}
						>
							Remove
						</Button>
					</div>
				</>
			)
		});
	}

	return (
		<Section>
			<Panel hasPadding={false} className={css.lineItemsPanel}>
				{/* Items - title - styles copied from the Panel title */}
				<div className={classes(css.flexContainer, css.panelTitle)}>
					<h2>Items</h2>
					<span className={css.panelCount}>
						{`(${orderData?.lineItems?.filter(lineItem => !lineItem.isDeleted).length ?? 0}
                    products total)`}
					</span>
				</div>
				{/* TODO these success or error messages do not show up ? */}
				{/* Line Item Remove Messages */}
				{requestDeleteLineItemData && !requestDeleteLineItemIsLoading && (
					<Alert type='success-light'>Delete line item was successful.</Alert>
				)}
				{requestDeleteLineItemError && !requestDeleteLineItemIsLoading && (
					<Alert type='danger-light'>There was an error deleting the line item, please try again.</Alert>
				)}
				<TableController
					headers={headersLineItems}
					baseRoute={tableBaseRoute}
					itemsPerPage={1000000}
					useQuery={useQuery}
					isUrlUpdated={false}
					hasPagination={false}
					emptyMessage={'No line items found!'}
					dataSelector={dataSelector}
					totalCountSelector={(data: any) => data?.length}
					hasSearch={false}
					tableHandlesPaginatingData={false}
					isTitleInTable={true}
				/>
			</Panel>
			{children}
		</Section>
	);
};
