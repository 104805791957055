import React from 'react';
import css from './MessageBubblesList.module.scss';
import classes from 'classnames';
import { ApiOrderCommentsResponse } from 'api/orderComment';
import { labelToKey } from 'common/utils/labelToKey';
import { MessageBubbles } from '../MessageBubbles/MessageBubbles';

interface IMessageBubblesList {
	className?: string;
	messages: ApiOrderCommentsResponse;
}

export const MessageBubblesList: React.FC<IMessageBubblesList> = props => {
	const { className, messages } = props;

	const paginatedMessageBubbles = (messages: ApiOrderCommentsResponse) => {
		if (messages?.length) {
			return messages;
		}
		return [];
	};

	return (
		<div className={classes(css.messageList, className)}>
			<div className={css.messages}>
				{messages ? (
					paginatedMessageBubbles(messages).map(message => (
						<MessageBubbles
							key={labelToKey(`messageListItem-${message.id}`)}
							{...message}
							className={css.messageCardBorderOffset}
							type={message?.isCurrentUser ? 'current-user' : 'other-user'}
						/>
					))
				) : (
					<div className={css.noMessageBubblesFound}>
						<h3 className={'h6'}>No messages found please clear your filters and try again</h3>
					</div>
				)}
			</div>
		</div>
	);
};
