import React from 'react';
import css from './DoorTemplateIcon.module.scss';
import classes from 'classnames';
import { removePropertiesFromObjects } from 'common/utils/removePropertiesFromObjects';

import { ReactComponent as BiPartingPlusTwoSidelites90Deg } from 'assets/svg/doorTemplates/bi-parting-plus-two-sidelites-90-deg.svg';
import { ReactComponent as BiPartingPlusTwoSidelites } from 'assets/svg/doorTemplates/bi-parting-plus-two-sidelites.svg';
import { ReactComponent as BiPartingWallMount } from 'assets/svg/doorTemplates/bi-parting-wall-mount.svg';
import { ReactComponent as DoubleDoorsPlusLeftSidelitePlusTransom } from 'assets/svg/doorTemplates/double-doors-plus-left-sidelite-plus-transom.svg';
import { ReactComponent as DoubleDoorsPlusLeftSidelite } from 'assets/svg/doorTemplates/double-doors-plus-left-sidelite.svg';
import { ReactComponent as DoubleDoorsPlusRightSidelightPlusTransom } from 'assets/svg/doorTemplates/double-doors-plus-right-sidelite-plus-transom.svg';
import { ReactComponent as DoubleDoorsPlusRightSidelite } from 'assets/svg/doorTemplates/double-doors-plus-right-sidelite.svg';
import { ReactComponent as DoubleDoorsPlusTransom } from 'assets/svg/doorTemplates/double-doors-plus-transom.svg';
import { ReactComponent as DoubleDoorsPlusTwoSidelitesPlusTransom } from 'assets/svg/doorTemplates/double-doors-plus-two-sidelites-plus-transom.svg';
import { ReactComponent as DoubleDoorsPlusTwoSidelites } from 'assets/svg/doorTemplates/double-doors-plus-two-sidelites.svg';
import { ReactComponent as DoubleDoors } from 'assets/svg/doorTemplates/double-doors.svg';
import { ReactComponent as SingleDoorLhPlusSidelite } from 'assets/svg/doorTemplates/single-direction-lh-plus-sidelite.svg';
import { ReactComponent as SingleDoorLhWallMount } from 'assets/svg/doorTemplates/single-direction-lh-wall-mount.svg';
import { ReactComponent as SingleDoorRhPlusSidelite } from 'assets/svg/doorTemplates/single-direction-rh-plus-sidelite.svg';
import { ReactComponent as SingleDoorRhWallMount } from 'assets/svg/doorTemplates/single-direction-rh-wall-mount.svg';
import { ReactComponent as SingleDoorLhPlusLeftSidelitePlusTransom } from 'assets/svg/doorTemplates/single-door-lh-plus-left-sidelite-plus-transom.svg';
import { ReactComponent as SingleDoorLhPlusLeftSidelite } from 'assets/svg/doorTemplates/single-door-lh-plus-left-sidelite.svg';
import { ReactComponent as SingleDoorLhPlusRightSidelitePlusTransom } from 'assets/svg/doorTemplates/single-door-lh-plus-right-sidelite-plus-transom.svg';
import { ReactComponent as SingleDoorLhPlusRightSidelite } from 'assets/svg/doorTemplates/single-door-lh-plus-right-sidelite.svg';
import { ReactComponent as SingleDoorLhPlusTransom } from 'assets/svg/doorTemplates/single-door-lh-plus-transom.svg';
import { ReactComponent as SingleDoorLhPlusTwoSidelitesPlusTransom } from 'assets/svg/doorTemplates/single-door-lh-plus-two-sidelites-plus-transom.svg';
import { ReactComponent as SingleDoorLhPlusTwoSidelites } from 'assets/svg/doorTemplates/single-door-lh-plus-two-sidelites.svg';
import { ReactComponent as SingleDoorLh } from 'assets/svg/doorTemplates/single-door-lh.svg';
import { ReactComponent as SingleDoorRhPlusLeftSidelitePlusTransom } from 'assets/svg/doorTemplates/single-door-rh-plus-left-sidelite-plus-transom.svg';
import { ReactComponent as SingleDoorRhPlusLeftSidelite } from 'assets/svg/doorTemplates/single-door-rh-plus-left-sidelite.svg';
import { ReactComponent as SingleDoorRhPlusRightSidelitePlusTransom } from 'assets/svg/doorTemplates/single-door-rh-plus-right-sidelite-plus-transom.svg';
import { ReactComponent as SingleDoorRhPlusRightSidelite } from 'assets/svg/doorTemplates/single-door-rh-plus-right-sidelite.svg';
import { ReactComponent as SingleDoorRhPlusTransom } from 'assets/svg/doorTemplates/single-door-rh-plus-transom.svg';
import { ReactComponent as SingleDoorRhPlusTwoSidelitesPlusTransom } from 'assets/svg/doorTemplates/single-door-rh-plus-two-sidelites-plus-transom.svg';
import { ReactComponent as SingleDoorRhPlusTwoSidelites } from 'assets/svg/doorTemplates/single-door-rh-plus-two-sidelites.svg';
import { ReactComponent as SingleDoorRh } from 'assets/svg/doorTemplates/single-door-rh.svg';
import { ReactComponent as SingleSidelite } from 'assets/svg/doorTemplates/single-sidelight.svg';
import { ReactComponent as SingleTransom } from 'assets/svg/doorTemplates/single-transom.svg';
import { ReactComponent as TransomBiPartingPlustwoSidelitesGlassMount } from 'assets/svg/doorTemplates/transom-bi-parting-plus-two-sidelites-glass-mount.svg';
import { ReactComponent as TransomLhPlusTwoSideliteGlassMount } from 'assets/svg/doorTemplates/transom-lh-plus-two-sidelites-glass-mount.svg';
import { ReactComponent as TransomRhPlusSideliteGlassMount } from 'assets/svg/doorTemplates/transom-rh-plus-sidelite-glass-mount.svg';
import { ReactComponent as TwoSidelites } from 'assets/svg/doorTemplates/two-sidelites.svg';

export const iconMap = {
	BiPartingPlusTwoSidelites90Deg,
	BiPartingPlusTwoSidelites,
	BiPartingWallMount,
	DoubleDoorsPlusLeftSidelitePlusTransom,
	DoubleDoorsPlusLeftSidelite,
	DoubleDoorsPlusRightSidelightPlusTransom,
	DoubleDoorsPlusRightSidelite,
	DoubleDoorsPlusTransom,
	DoubleDoorsPlusTwoSidelitesPlusTransom,
	DoubleDoorsPlusTwoSidelites,
	DoubleDoors,
	SingleDoorLhPlusSidelite,
	SingleDoorLhWallMount,
	SingleDoorRhPlusSidelite,
	SingleDoorRhWallMount,
	SingleDoorLhPlusLeftSidelitePlusTransom,
	SingleDoorLhPlusLeftSidelite,
	SingleDoorLhPlusRightSidelitePlusTransom,
	SingleDoorLhPlusRightSidelite,
	SingleDoorLhPlusTransom,
	SingleDoorLhPlusTwoSidelitesPlusTransom,
	SingleDoorLhPlusTwoSidelites,
	SingleDoorLh,
	SingleDoorRhPlusLeftSidelitePlusTransom,
	SingleDoorRhPlusLeftSidelite,
	SingleDoorRhPlusRightSidelitePlusTransom,
	SingleDoorRhPlusRightSidelite,
	SingleDoorRhPlusTransom,
	SingleDoorRhPlusTwoSidelitesPlusTransom,
	SingleDoorRhPlusTwoSidelites,
	SingleDoorRh,
	SingleSidelite,
	SingleTransom,
	TransomBiPartingPlustwoSidelitesGlassMount,
	TransomLhPlusTwoSideliteGlassMount,
	TransomRhPlusSideliteGlassMount,
	TwoSidelites
};

export type TDoorTemplateIconTypes = keyof typeof iconMap;

export interface IDoorTemplateIconProps {
	className?: string;
	type: TDoorTemplateIconTypes;
}

export const DoorTemplateIcon: React.FC<IDoorTemplateIconProps & React.SVGProps<SVGSVGElement>> =
	props => {
		const { className, type } = props;

		const iconClasses = classes(css.icon, className, css[type]);

		const iconProperties = {
			...removePropertiesFromObjects(['type'], props),
			className: iconClasses,
			role: 'text'
		};
		const IconTag = iconMap[type];

		return <IconTag {...iconProperties} />;
	};
