import React from 'react';
import css from './QuoteDetailSidebarStatusAndPrice.module.scss';
import classes from 'classnames';

import { Order } from 'api/order';
import { Panel } from 'components/Panel';
import { StatusBar } from 'components/StatusBar/StatusBar';
import { formatAsUSD } from 'common/utils/formatAsUSD';

interface QuoteDetailSidebarStatusAndPriceProps {
	orderData: Order;
	showDiscount?: boolean;
}

export const QuoteDetailSidebarStatusAndPrice: React.FC<QuoteDetailSidebarStatusAndPriceProps> =
	props => {
		const { orderData, showDiscount = false } = props;
		return (
			<>
				{/* Status Bar */}
				{orderData && orderData.status && (
					<StatusBar type={orderData.status} className={css.hideFromPrint} />
				)}

				{/* Order Subtotals & Submit Button */}
				{/* Summary */}
				<Panel
					title={'Summary'}
					description={`${
						orderData?.lineItems?.filter(lineItem => !lineItem.isDeleted).length ?? 0
					} total products`}
				>
					<div>
						{/* Order Subtotal */}
						<div className={classes(css.flexContainer, css.justifySpaceBetween)}>
							<div>Order Subtotal</div>
							<div>{formatAsUSD(orderData?.subtotal ?? 0, true)}</div>
						</div>
						{/* Order Discount */}
						{orderData?.totalDiscounts !== 0 && showDiscount && (
							<div className={classes(css.flexContainer, css.justifySpaceBetween)}>
								<div>Order Discount</div>
								<div>-{formatAsUSD(orderData?.totalDiscounts ?? 0, true)}</div>
							</div>
						)}
					</div>
				</Panel>

				{/* Order Total & Submit Button */}
				<Panel
					title='Order Total'
					className={css.orderTotal}
					description={
						<>
							<span className={classes('h3', css.black)}>
								{showDiscount
									? formatAsUSD(orderData?.orderTotal ?? 0, true)
									: formatAsUSD(orderData?.subtotal ?? 0, true)}
							</span>
						</>
					}
				>
					{orderData?.totalDiscounts !== 0 ? (
						<div className={css.orderDiscountText}>
							<span>
								{showDiscount && (
									<>You save: {formatAsUSD(orderData?.totalDiscounts ?? 0, true)} after discount. </>
								)}
								Shipping and tax will be invoiced separately based on your account terms.
							</span>
						</div>
					) : (
						<span></span>
					)}
					{/* Submit Button */}
				</Panel>
			</>
		);
	};
