import { useState } from 'react';
import { useAxios } from 'providers/Axios/AxiosProvider';
import { useQuery, useQueryClient } from 'react-query';
import { handleResponse } from './api';

export type FileUploadInfo = {
	id: string;
	fileName: string;
	uploadType: string; // Will the the string of the enum below
	isDeleted: boolean;
	createdBy: string;
	createdAt: Date;
	updatedBy: string;
	updatedAt: Date;
	deletedBy: string;
	deletedAt: Date;
};

// NOTE: that the strings returned are NOT customer friendly
// TODO: create a way to transform the strings into customer friendly view
//       based on the mock ups created - can do something like formatOrderStatusForCS
export enum FileUploadTypes {
	// Customers
	AddEditCustomers = 1,
	DeleteCustomers,

	// Customer Discounts / Pricing
	AddEditCustomerPricing,
	DeleteCustomerPricing,

	// Products
	AddEditProducts,
	DeleteProducts,

	// Product Finishes
	AddEditProductFinishes,
	DeleteProductFinishes
}

export type ApiFileUploadsResponse = FileUploadInfo[];

// Gets info about a folder and its immediate children
export const useGetFileUploads = () => {
	const axios = useAxios();

	return useQuery(['fileUploads'], async () => {
		return handleResponse(axios.get<ApiFileUploadsResponse>(`/fileUploads`));
	});
};

// Gets All Upload history for products
export const useGetProductFileUploads = () => {
	const axios = useAxios();

	return useQuery(['productFileUploads'], async () => {
		return handleResponse(axios.get<ApiFileUploadsResponse>(`/product/files`));
	});
};

// Gets All Upload history for companies
export const useGetCompanyFileUploads = () => {
	const axios = useAxios();

	return useQuery(['companyFileUploads'], async () => {
		return handleResponse(axios.get<ApiFileUploadsResponse>(`/company/files`));
	});
};

// Gets All Upload history for sales reps
export const useGetSalesRepFileUploads = () => {
	const axios = useAxios();

	return useQuery(['salesRepFileUploads'], async () => {
		return handleResponse(axios.get<ApiFileUploadsResponse>(`/salesReps/files`));
	});
};

// Gets All Upload history for company pricing
export const useGetCompanyPricingFileUploads = () => {
	const axios = useAxios();

	return useQuery(['companyPricingFileUploads'], async () => {
		return handleResponse(axios.get<ApiFileUploadsResponse>(`/customerDiscount/files`));
	});
};

// Gets All Upload history for product finish pricing
export const useGetProductFinishPricingFileUploads = () => {
	const axios = useAxios();

	return useQuery(['productFinishPricingFileUploads'], async () => {
		return handleResponse(axios.get<ApiFileUploadsResponse>(`/prodFinishPrice/files`));
	});
};

// Gets All Upload history for product finish pricing
export const useGetProductCodeFileUploads = () => {
	const axios = useAxios();

	return useQuery(['productCodeFileUploads'], async () => {
		return handleResponse(axios.get<ApiFileUploadsResponse>(`/productCodes/files`));
	});
};

export type ApiUploadResponse = {
	successfulObjects?: any[];
	failedCsvObjects?: ApiUploadFailedObject[];
};

export type ApiUploadFailedObject = {
	failReason?: string;
	csvRowNumber?: number;
};

export const useUploadFile = (url: string, keysToInvalidate?: string[]) => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	const [isUploading, setIsUploading] = useState(false);

	const upload = async (file: File) => {
		setIsUploading(true);

		const formData = new FormData();
		formData.append('file', file);

		try {
			const response = await axios.post(url, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});

			setIsUploading(false);

			if (response.status === 200) {
				queryClient.invalidateQueries(keysToInvalidate);
				return response.data as ApiUploadResponse;
			} else {
				return { failedCsvObjects: [{ failReason: response.statusText }] } as ApiUploadResponse;
			}
		} catch (err: any) {
			return { failedCsvObjects: [{ failReason: err?.response?.data }] } as ApiUploadResponse;
		}
	};

	return { isUploading, upload };
};

// Handles uploads for adding products
export const useUploadProductFile = () => {
	return useUploadFile(`/product/csv`, ['productFileUploads']);
};

// Handles uploads for updating products
export const useUploadProductUpdateFile = () => {
	return useUploadFile(`/product/update/csv`, ['productFileUploads']);
};

// Handles uploads for deleting products
export const useUploadProductDeleteFile = () => {
	return useUploadFile(`/product/delete/csv`, ['productFileUploads']);
};

// Handles uploads for adding companies
export const useUploadCompanyFile = () => {
	return useUploadFile(`/company/csv`, ['companyFileUploads']);
};

// Handles uploads for updating companies
export const useUploadCompanyUpdateFile = () => {
	return useUploadFile(`/company/update/csv`, ['companyFileUploads']);
};

// Handles uploads for deleting companies
export const useUploadCompanyDeleteFile = () => {
	return useUploadFile(`/company/delete/csv`, ['companyFileUploads']);
};

// Handles uploads for adding sales reps
export const useUploadSalesRepFile = () => {
	return useUploadFile(`/salesRep/csv`, ['salesRepFileUploads']);
};

// Handles uploads for updating sales reps
export const useUploadSalesRepUpdateFile = () => {
	return useUploadFile(`/salesRep/update/csv`, ['salesRepFileUploads']);
};

// Handles uploads for deleting sales reps
export const useUploadSalesRepDeleteFile = () => {
	return useUploadFile(`/salesRep/delete/csv`, ['salesRepFileUploads']);
};

// Handles uploads for adding customer pricing
export const useUploadCompanyPricingFile = () => {
	return useUploadFile(`/customerDiscount/csv`, ['companyPricingFileUploads']);
};

// Handles uploads for updating customer pricing
export const useUploadCompanyPricingUpdateFile = () => {
	return useUploadFile(`/customerDiscount/update/csv`, ['companyPricingFileUploads']);
};

// Handles uploads for deleting customer pricing
export const useUploadCompanyPricingDeleteFile = () => {
	return useUploadFile(`/customerDiscount/delete/csv`, ['companyPricingFileUploads']);
};

// Handles uploads for adding product finish pricing
export const useUploadProductFinishPricingFile = () => {
	return useUploadFile(`/productFinishPrice/csv`, ['productFinishPricingFileUploads']);
};

// Handles uploads for updating product finish pricing
export const useUploadProductFinishPricingUpdateFile = () => {
	return useUploadFile(`/productFinishPrice/update/csv`, ['productFinishPricingFileUploads']);
};

// Handles uploads for deleting product finish pricing
export const useUploadProductFinishPricingDeleteFile = () => {
	return useUploadFile(`/productFinishPrice/delete/csv`, ['productFinishPricingFileUploads']);
};

// Handles uploads for adding product codes
export const useUploadProductCodeFile = () => {
	return useUploadFile(`/productCode/csv`, ['productCodeFileUploads']);
};

// Handles uploads for updating product finish pricing
export const useUploadProductCodeUpdateFile = () => {
	return useUploadFile(`/productCode/update/csv`, ['productCodeFileUploads']);
};

// Handles uploads for deleting product finish pricing
export const useUploadProductCodeDeleteFile = () => {
	return useUploadFile(`/productCode/delete/csv`, ['productCodeFileUploads']);
};
