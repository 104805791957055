import React, { useRef, useState, useEffect } from 'react';
import css from './Tabs.module.scss';
import classes from 'classnames';
import { NavLink } from 'react-router-dom';
import { TNavigationItems, TNavigationItem } from 'common/types/TNavigationItems';
import { Navigate, Routes } from 'react-router-dom';
import { Route, useLocation, useNavigate } from 'react-router';

interface ITabsProps {
	className?: string;
	navigationItems: TNavigationItems;
}
interface ITabItemProps {
	index: number;
	isSelected: boolean;
	navigationItem: TNavigationItem;
	handleKeyup: (event: React.KeyboardEvent<HTMLAnchorElement>, navigationItem: TNavigationItem) => void;
	setRefByIndex: (ref: React.MutableRefObject<HTMLElement>, index: number) => void;
}

const TabItem: React.FC<ITabItemProps> = props => {
	const { index, isSelected, navigationItem, handleKeyup, setRefByIndex } = props;
	const ref = useRef(null);
	useEffect(() => {
		setRefByIndex(ref, index);
	}, [setRefByIndex, index]);
	return (
		<li key={index} role='presentation'>
			<NavLink
				aria-selected={isSelected}
				role='tab'
				ref={ref}
				tabIndex={isSelected === true ? 0 : -1}
				onKeyUp={(event: React.KeyboardEvent<HTMLAnchorElement>) => handleKeyup(event, navigationItem)}
				to={navigationItem.location}
			>
				{navigationItem.label}
			</NavLink>
		</li>
	);
};

export const Tabs: React.FC<ITabsProps> = props => {
	const { className, navigationItems } = props;
	const navigate = useNavigate();
	const location = useLocation();
	const [refs, setRefs] = useState([]);

	const setRefByIndex = (ref: React.MutableRefObject<HTMLElement>, index: number) => {
		refs[index] = ref;
		setRefs(refs);
	};

	const selectTab = (navigationItem: TNavigationItem) => {
		const index = navigationItems.indexOf(navigationItem);
		refs[index].current.focus();
		navigate(navigationItem.location);
	};

	const previousTab = (navigationItem: TNavigationItem) => {
		const index = navigationItems.indexOf(navigationItem);
		if (index > 0) {
			selectTab(navigationItems[index - 1]);
		} else {
			selectTab(navigationItems[navigationItems.length - 1]);
		}
	};

	const nextTab = (navigationItem: TNavigationItem) => {
		const index = navigationItems.indexOf(navigationItem);
		if (index < navigationItems.length - 1) {
			selectTab(navigationItems[index + 1]);
		} else {
			selectTab(navigationItems[0]);
		}
	};

	const handleKeyup = (
		event: React.KeyboardEvent<HTMLAnchorElement>,
		navigationItem: TNavigationItem
	) => {
		event.preventDefault();
		if (event.which === 13) {
			selectTab(navigationItem);
		} else if (event.which === 37) {
			previousTab(navigationItem);
		} else if (event.which === 39) {
			nextTab(navigationItem);
		}
	};

	return (
		<>
			<div className={css.scrollContainer}>
				<ul role='tablist' className={classes(css.tabs, className)}>
					{navigationItems.map((navigationItem: TNavigationItem, index: number) => {
						const isSelected = location.pathname === navigationItem.route;
						return (
							<TabItem
								key={index}
								isSelected={isSelected}
								index={index}
								navigationItem={navigationItem}
								handleKeyup={handleKeyup}
								setRefByIndex={setRefByIndex}
							></TabItem>
						);
					})}
				</ul>
			</div>
			<Routes>
				{navigationItems.map((item: TNavigationItem, index: number) => {
					const ItemComponent = item.component;
					return <Route key={index} path={item.route} element={<ItemComponent />} />;
				})}
				<Route path='/*' element={<Navigate to={navigationItems[0].location} />} />
			</Routes>
		</>
	);
};
