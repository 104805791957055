import React, { useState } from 'react';
import css from './ReferenceNumberOutcomeManagementView.module.scss';
import { useAuth } from 'providers/Auth/AuthProvider';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { ErrorView } from 'views/ErrorView';
import { Button } from 'components/Button';
import { Alert } from 'components/Alert';
import { Panel } from 'components/Panel';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { Spinner } from 'components/Spinner';
import { LoadingDelay } from 'components/LoadingDelay';
import { PageFrame } from 'components/PageFrame';
import { useModal } from 'providers/Modal/ModalProvider';
import { FormFooter } from 'components/Form/FormFooter/FormFooter';
import { Select } from 'components/Form/Select/Select';
import { useGetOpening, useUpdateOpening } from 'api/opening';
import { InputTextArea } from 'components/Form/InputTextArea/InputTextArea';
import { TableLight, TTableLightHeader } from 'components/TableLight/TableLight';
import { iconMap, TDoorTemplateIconTypes } from 'components/DoorTemplateIcon';
import { InputCheckbox } from 'components/Form/InputCheckbox/InputCheckbox';

export const ReferenceNumberOutcomeManagementView = () => {
	const { isLoading } = useAuth();
	const [openingFormSubmitter, setOpeningFormSubmitter] = useState<() => Promise<void> | null>();
	const { openingId } = useParams<{ openingId: string }>();
	const { createModal } = useModal();
	const navigate = useNavigate();

	const {
		data: getOpeningData,
		isLoading: isGetOpeningLoading,
		error: getError
	} = useGetOpening(openingId);

	const { mutateAsync: updateOpening } = useUpdateOpening();

	const handleSaveUpdates = async () => {
		await openingFormSubmitter();
	};

	const formatReferenceNumberList = (data: string): string => {
		// Takes in the expected Comma separated list and formats to add a line break for display
		if (data) {
			return data.split(', ').join(',\n');
		}
		return data;
	};

	const referenceNumbersTableHeaders: TTableLightHeader[] = [
		{
			label: 'Reference Number',
			property: 'referenceNumber'
		},
		{
			label: 'Description',
			property: 'productDescription'
		}
	];

	if (getError) {
		return <ErrorView isInAppFrame={true} />;
	}

	if (isLoading || isGetOpeningLoading) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	return (
		<PageFrame
			title={`${getOpeningData?.opening?.visibleOutcomePath}`}
			backButton={{
				label: 'Back to all',
				action: () => {
					navigate(-1);
				}
			}}
			ContentTopRight={() => (
				<Button
					onClick={event => {
						event.persist();
						handleSaveUpdates();
					}}
				>
					Save Updates
				</Button>
			)}
		>
			<Container>
				<Formik
					initialValues={{
						isInvalid: getOpeningData.opening.isInvalid ?? false,
						icon: getOpeningData.opening.icon ?? '',
						referenceNumberList:
							formatReferenceNumberList(getOpeningData.opening.referenceNumberList) ?? ''
					}}
					validateOnChange={false}
					validateOnBlur={false}
					onSubmit={async (data, { setSubmitting, resetForm }) => {
						setSubmitting(true);
						try {
							const response = await updateOpening({
								id: getOpeningData.opening.id,
								...data,
								icon: data.icon as TDoorTemplateIconTypes
							});
							if (response.invalidReferenceNumbers) {
								createModal({
									heading: 'Error saving reference numbers',
									Content: () => (
										<Alert type={'danger-light'}>
											These reference number(s): {response.invalidReferenceNumbers} were not found,
											please update
										</Alert>
									)
								});
								resetForm({
									values: {
										isInvalid: response.opening.isInvalid,
										icon: response.opening.icon,
										referenceNumberList: formatReferenceNumberList(response.validReferenceNumbers)
									}
								});
							} else {
								createModal({
									heading: 'Opening successfully saved!',
									Content: () => (
										<Alert type={'success-light'}>
											Your changes to the opening were successfully saved.
										</Alert>
									)
								});
							}
							setSubmitting(false);
						} catch (error: any) {
							createModal({
								heading: 'There was an error saving the opening',
								Content: () => <Alert type={'danger-light'}>{error.message}</Alert>
							});
							setSubmitting(false);
						}
					}}
					validationSchema={yup.object().shape({
						referenceNumberList: yup.string().when('isInvalid', {
							is: (isInvalid: boolean) => isInvalid === false,
							then: yup.string().required('Reference Number List is required')
						})
					})}
				>
					{props => {
						// TODO this line here throws a warning - I saw that it should be nested in an Effect but had trouble figuring out how to do that
						setOpeningFormSubmitter(() => props.submitForm);

						return (
							<>
								{/* ICON */}
								<Section>
									<Panel
										title={'Icon Type'}
										description={'Enter an icon URL that will appear for this opening.'}
									>
										<Select
											label={'Icon'}
											name={'icon'}
											inputMessage={'Select an icon'}
											formikProps={props}
										>
											<option value=''>Select an option</option>
											{Object.keys(iconMap).map(templateIcon => (
												<option value={templateIcon} key={templateIcon}>
													{templateIcon}
												</option>
											))}
										</Select>
									</Panel>
								</Section>
								{/* TEMPLATE */}
								<Section>
									<Panel title={'Template'}>
										<Form>
											{/* IS INVALID */}
											<InputCheckbox
												label='Mark as Invalid'
												name={'isInvalid'}
												checked={props.values.isInvalid}
												formikProps={props}
												checkboxSize='medium'
												className={css.boldTextMarginTopBottom}
											></InputCheckbox>
											{/* USER NOTE */}
											<p>
												Note the following are the options for this template and should help you as you
												identify the reference numbers associated with this template:
											</p>
											{/* REFERENCE NUMBERS */}
											<div className={css.refNumberManager}>
												<ul style={{ listStyleType: 'initial', listStylePosition: 'inside' }}>
													<li>
														{' '}
														<b>Mounting Type:</b> {getOpeningData.opening.mountingType}
													</li>
													<li>
														{' '}
														<b>Application:</b> {getOpeningData.opening.application}
													</li>
													<li>
														{' '}
														<b>Door Layout:</b> {getOpeningData.opening.doorLayout}
													</li>
													<li>
														{' '}
														<b>Type:</b> {getOpeningData.opening.type}
													</li>
													<li>
														{' '}
														<b>Closer Type:</b> {getOpeningData.opening.closerType}
													</li>
													<li>
														{' '}
														<b>Rail Profile:</b> {getOpeningData.opening.railProfile}
													</li>
													<li>
														{' '}
														<b>Glass Thickness:</b> {getOpeningData.opening.glassThickness}
													</li>

													<li>
														{' '}
														<b>Header:</b> {getOpeningData.opening.header}
													</li>
													<li>
														{' '}
														<b>Channel Height Top:</b> {getOpeningData.opening.channelHeightTop}
													</li>
													<li>
														{' '}
														<b>Rail Height Top:</b> {getOpeningData.opening.railHeightTop}
													</li>
													<li>
														{' '}
														<b>Channel Height Bottom:</b> {getOpeningData.opening.channelHeightBottom}
													</li>
													<li>
														{' '}
														<b>Rail Height Bottom:</b> {getOpeningData.opening.railHeightBottom}
													</li>
													<li>
														{' '}
														<b>Bottom Lock:</b> {getOpeningData.opening.bottomLock}
													</li>
													<li>
														{' '}
														<b>Pull Handle:</b> {getOpeningData.opening.pullHandle}
													</li>
													<li>
														{' '}
														<b>Additional Function:</b> {getOpeningData.opening.additionalFunction}
													</li>
												</ul>
												<InputTextArea
													label={'Reference Number List'}
													name={'referenceNumberList'}
													placeholder={'Enter the comma separated Reference Number List'}
													value={props.values.referenceNumberList}
													formikProps={props}
													isLabelVisible={false}
													classNameDomInput={css.textarea}
													className={css.textareaControl}
												></InputTextArea>
											</div>
											<FormFooter>
												<Button type='primary' htmlType='submit' isLoading={props.isSubmitting}>
													Save Updates
												</Button>
											</FormFooter>
										</Form>
									</Panel>
								</Section>

								{/* REFERENCE NUMBERS */}
								<Section>
									<Panel
										title={
											getOpeningData?.validReferenceNumbersResponses != null
												? getOpeningData?.validReferenceNumbersResponses?.length.toString() + ' Added'
												: '0 Added'
										}
										description={'Your selected reference numbers for this configuration:'}
									>
										<TableLight
											headers={referenceNumbersTableHeaders}
											data={getOpeningData.validReferenceNumbersResponses}
										></TableLight>
									</Panel>
								</Section>
							</>
						);
					}}
				</Formik>
			</Container>
		</PageFrame>
	);
};
