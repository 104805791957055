import React from 'react';
import css from './TemplateEstimate.module.scss';
import classes from 'classnames';
import { useCreateOpening } from 'providers/CreateOpening/CreateOpeningProvider';
import { Panel } from 'components/Panel/Panel';
import { DoorTemplateIcon } from 'components/DoorTemplateIcon';
import { Input } from 'components/Form/Input/Input';
import { formatAsUSD } from 'common/utils/formatAsUSD';
import { estimatedPriceFromLinesItems } from '../common/utilities';
import { useModal } from 'providers/Modal/ModalProvider';

export const TemplateEstimate = () => {
	const {
		templateOutcomeResponse,
		templateQuantityToOrder,
		setTemplateQuantityToOrder,
		templateEstimatedOrder,
		currentOrder,
		warningModal,
		clearQuoteLineItems,
		currentOrderLineItems
	} = useCreateOpening();

	const { removeModal } = useModal();

	const openingHasQuoteWithLineItems =
		currentOrder && currentOrderLineItems && currentOrderLineItems.length > 0;

	return (
		<div className={css.templateEstimate}>
			{templateEstimatedOrder?.status === 'success' && !openingHasQuoteWithLineItems ? (
				<>
					<h1 className={'h4'}>
						Based on your selections your estimate will include the following and your rough estimated
						price is{' '}
						<span className={css.price}>
							{formatAsUSD(estimatedPriceFromLinesItems(templateEstimatedOrder?.lineItems))}
						</span>
						.
					</h1>
					<h2 className={classes('h5', css.h5)}>
						Please note this is subject to change based on any additional requests you may have for our
						customer service team.
					</h2>
					<Input
						type={'number'}
						name={'qty'}
						min={1}
						value={templateQuantityToOrder}
						label={'Number needed?'}
						onChange={event => {
							const numberNeeded = parseInt(event.target.value);
							if (openingHasQuoteWithLineItems) {
								warningModal(
									'Are you sure you want to update your opening?',
									async () => {
										await clearQuoteLineItems(() => {
											setTemplateQuantityToOrder(numberNeeded);
											removeModal();
										});
									},
									'Change quantity',
									'Changing the number needed for your opening will modify your current estimate',
									null,
									event
								);
							} else {
								setTemplateQuantityToOrder(numberNeeded);
							}
						}}
					/>
					<Panel>
						<span className={css.estimatedParts}>
							{templateOutcomeResponse.opening?.icon && (
								<DoorTemplateIcon type={templateOutcomeResponse.opening.icon} className={css.doorIcon} />
							)}
							<h3 className={classes('h6', css.h6)}>
								Estimated pricing includes (Curious about a part, find it in the price book or in product
								search.)
							</h3>
						</span>
						<ul>
							{templateOutcomeResponse.validReferenceNumbersPricingResponses.map((product, index) => (
								<li key={`${product.referenceNumber}-${index}`}>
									{product.referenceNumber} - {product?.productPricingRsp?.product?.productName}
								</li>
							))}
						</ul>
					</Panel>
				</>
			) : openingHasQuoteWithLineItems ? (
				<>
					<h1 className={'h4'}>
						Based on your selections and any modifications you may have made your estimate will include
						the following and your rough estimated price is{' '}
						<span className={css.price}>{formatAsUSD(currentOrder.orderTotal)}</span>.
					</h1>
					<h2 className={classes('h5', css.h5)}>
						Please note this is subject to change based on any additional requests you may have for our
						customer service team.
					</h2>
					<Input
						type={'number'}
						name={'qty'}
						min={1}
						value={templateQuantityToOrder}
						label={'Number needed?'}
						onChange={event => {
							const numberNeeded = parseInt(event.target.value);
							if (openingHasQuoteWithLineItems) {
								warningModal(
									'Are you sure you want to update your opening?',
									async () => {
										await clearQuoteLineItems(() => {
											setTemplateQuantityToOrder(numberNeeded);
											removeModal();
										});
									},
									'Change quantity',
									'Changing the number needed for your opening will modify your current estimate',
									null,
									event
								);
							} else {
								setTemplateQuantityToOrder(numberNeeded);
							}
						}}
					/>
					<Panel>
						<span className={css.estimatedParts}>
							<h3 className={classes('h6', css.h6)}>
								Estimated pricing includes (Curious about a part, find it in the price book or in product
								search.)
							</h3>
						</span>
						<ul>
							{currentOrder.lineItems?.map((product, index) => (
								<li key={`${product.materialNumber}-${index}`}>
									{product.materialNumber} - ({product.quantity})
								</li>
							))}
						</ul>
					</Panel>
				</>
			) : (
				<>
					<h1 className={'h4'}>
						Continue to order when you're ready to submit your quote details. A dormakaba representative
						will be in contact with you about your custom opening.
					</h1>
					<h2 className={classes('h5', css.h5)}>
						If you have any immediate questions, please contact our customer support team.
					</h2>
				</>
			)}
		</div>
	);
};
