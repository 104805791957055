import React, { useState, useRef, useEffect } from 'react';
import css from './InputTextArea.module.scss';
import classes from 'classnames';
import { removePropertiesFromObjects } from 'common/utils/removePropertiesFromObjects';
import { getUniqueId } from 'common/utils/getUniqueId';
import { Icon, TIconTypes } from 'components/Icon';
import { InputFrame, IInputFrame } from 'components/Form/InputFrame';
import { getProcessedFormikProps } from 'common/utils/getProcessedFromikProps';
import { RemoveProp } from 'common/types/TypeHelpers';
import { replacePropsWithFormikProps } from 'common/utils/replacePropsWithFormikProps';
import { equals } from 'ramda';

export type TTextArea = React.DetailedHTMLProps<
	React.TextareaHTMLAttributes<HTMLTextAreaElement>,
	HTMLTextAreaElement
>;

export type IInputTextAreaProps = {
	classNameDomInput?: string;
	icon?: TIconTypes;
	setRef?: (ref: React.MutableRefObject<any>) => void;
} & TTextArea &
	RemoveProp<RemoveProp<IInputFrame, 'inputId'>, 'messageId'>;

export const InputTextArea: React.FC<IInputTextAreaProps> = props => {
	const {
		label,
		inputMessage,
		errorMessage,
		className,
		id,
		formikProps,
		name,
		icon,
		isLabelVisible,
		setRef,
		classNameDomInput,
		tooltip
	} = props;
	const [inputId] = useState(id ? id : getUniqueId());
	const [messageId] = useState(getUniqueId());
	const { isError, displayedMessage } = getProcessedFormikProps(
		formikProps,
		name,
		errorMessage,
		inputMessage
	);
	const ref = useRef(null);

	const prevErrorMessageRef: React.MutableRefObject<any> = useRef();
	useEffect(() => {
		if (formikProps) {
			prevErrorMessageRef.current = formikProps.errors;
		}
	});
	const prevErrorMessage = prevErrorMessageRef.current;

	// Focus on first error field
	useEffect(() => {
		if (formikProps) {
			const didErrorMessageChange = !equals(prevErrorMessage, formikProps.errors);
			if (didErrorMessageChange) {
				const firstKey = Object.keys(formikProps.errors)[0];
				if (name === firstKey) {
					const firstErrorElement = document.querySelector('.error');
					// @ts-ignore
					firstErrorElement?.focus();
				}
			}
		}
	});

	const inputProps: any = removePropertiesFromObjects(
		[
			'label',
			'inputMessage',
			'errorMessage',
			'errorMessageId',
			'formikProps',
			'isLabelVisible',
			'setRef',
			'tooltip',
			'classNameDomInput'
		],
		props
	);

	const inputPropsWithFormik = replacePropsWithFormikProps<IInputTextAreaProps>(
		inputProps,
		formikProps,
		name
	);

	useEffect(() => {
		if (setRef) {
			setRef(ref);
		}
	}, [setRef]);

	return (
		<InputFrame
			inputId={inputId}
			isLabelVisible={isLabelVisible}
			label={label}
			messageId={messageId}
			inputMessage={inputMessage}
			formikProps={formikProps}
			name={name}
			errorMessage={errorMessage}
			className={className}
			tooltip={tooltip}
		>
			<div className={css.inputContainer}>
				<textarea
					{...inputPropsWithFormik}
					id={inputId}
					aria-describedby={displayedMessage ? messageId : null}
					className={classes(
						classNameDomInput,
						css.input,
						{ [css.error]: isError },
						{ error: isError },
						{ [css.hasIcon]: icon }
					)}
					ref={ref}
				/>
				{icon && <Icon type={icon} className={css.icon} color='green' />}
			</div>
		</InputFrame>
	);
};
