import { Order } from 'api/order';
import React, { useState, useContext, PropsWithChildren } from 'react';

const SingleQuoteContext = React.createContext(null);

export const SingleQuoteProvider: React.FC<PropsWithChildren> = props => {
	const { children } = props;
	const [isQuoteDataValid, setIsQuoteDataValid] = useState<boolean>(false);
	const [isQuoteReadyToSubmit, setIsQuoteReadyToSubmit] = useState<boolean>(false);
	// This should be set if CSR is placing the order controls the status and AS400 status
	const [isOrderBeingPlaced, setIsOrderBeingPlaced] = useState<boolean>(false);
	// This state is used to help supply PUT requests with the most up to date client version of the order
	const [singleQuoteData, setSingleQuoteData] = useState<Order>();
	// when a quote is saved update the status message
	const [showSaveMessage, setShowSaveMessage] = useState<'success' | 'error' | null>();

	return (
		<SingleQuoteContext.Provider
			value={{
				isQuoteDataValid,
				setIsQuoteDataValid,
				setSingleQuoteData,
				singleQuoteData,
				showSaveMessage,
				setShowSaveMessage,
				isQuoteReadyToSubmit,
				setIsQuoteReadyToSubmit,
				isOrderBeingPlaced,
				setIsOrderBeingPlaced
			}}
		>
			{children}
		</SingleQuoteContext.Provider>
	);
};

const useSingleQuote = () => {
	const singleQuoteHelpers: {
		isQuoteDataValid: boolean;
		setIsQuoteDataValid: (isReady: boolean) => void;
		setSingleQuoteData: React.Dispatch<React.SetStateAction<Order>>;
		singleQuoteData: Order;
		showSaveMessage: 'success' | 'error' | null;
		setShowSaveMessage: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
		isQuoteReadyToSubmit: boolean;
		setIsQuoteReadyToSubmit: (isReady: boolean) => void;
		isOrderBeingPlaced: boolean;
		setIsOrderBeingPlaced: (isReady: boolean) => void;
	} = useContext(SingleQuoteContext);

	return singleQuoteHelpers;
};

export { SingleQuoteContext, useSingleQuote };
