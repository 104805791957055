import React, { useState } from 'react';
import css from './TemplateComments.module.scss';
import classes from 'classnames';
import { useCreateOpening } from 'providers/CreateOpening/CreateOpeningProvider';
import { UploadDropZone } from 'components/UploadDropZone';
import { UploadDropZoneLoading } from 'components/UploadDropZoneLoading';
import { InputTextArea } from 'components/Form/InputTextArea';
import { formatAsUSD } from 'common/utils/formatAsUSD';
import { estimatedPriceFromLinesItems } from '../common/utilities';
import { useCreateOrderUpload } from 'api/orderUpload';

export interface TemplateCommentsProps {}

export const TemplateComments: React.FC<TemplateCommentsProps> = props => {
	const {
		templateEstimatedOrder,
		currentOrder,
		currentOrderLineItems,
		templateMessage,
		setTemplateMessage
	} = useCreateOpening();

	const [isUploading, setIsUploading] = useState<boolean>(false);

	const { upload } = useCreateOrderUpload(currentOrder.id);

	const handleFileUpload = () => async (droppedFiles: File[]) => {
		setIsUploading(true);
		// Only grabbing the first file off of droppedFiles as the dropzone should be enforcing only 1 file
		const file = droppedFiles[0];
		await upload(file);
		setIsUploading(false);
	};

	const openingHasQuoteWithLineItems =
		currentOrder && currentOrderLineItems && currentOrderLineItems.length > 0;

	return (
		<>
			<div className={css.section}>
				{templateEstimatedOrder.status === 'success' ? (
					<div className={css.stepHeader}>
						<h1 className={classes(css.stepTitle, 'h2')}>
							Great, based on your general needs, your rough estimated price is{' '}
							<span className={css.price}>
								{openingHasQuoteWithLineItems
									? formatAsUSD(currentOrder.orderTotal)
									: formatAsUSD(estimatedPriceFromLinesItems(templateEstimatedOrder.lineItems))}
							</span>
							.
						</h1>
						<p className={css.stepDescription}>
							Please note this is subject to change based on any additional requests you may have for our
							customer service team.
						</p>
					</div>
				) : (
					<div className={css.stepHeader}>
						<h1 className={classes(css.stepTitle, 'h2')}>
							Do you have any additional information to add?
						</h1>
						<p className={css.stepDescription}>
							Provide us with any additional information that would assist us in finding what you need.
						</p>
					</div>
				)}
			</div>
			<div className={css.section}>
				<h2 className={classes('h5', css.stepQuestion)}>
					Please leave any additional comments for our customer service team below (optional)
				</h2>
				<InputTextArea
					label={'Your message'}
					name={'orderMessage'}
					value={templateMessage}
					onChange={event => {
						setTemplateMessage(event.target.value);
					}}
				/>
			</div>
			<div>
				<h2 className={classes('h5', css.stepQuestion)}>
					Upload any elevation drawings you may have below (optional)
				</h2>
				<p className={css.stepQuestionDescription}>
					Please note these may help our customer service team if you are looking for something more
					specific.
				</p>
				{isUploading ? (
					<UploadDropZoneLoading />
				) : (
					<UploadDropZone
						maxFiles={1}
						maxFileMegabytes={256}
						allowedFileCount={1}
						acceptFileTypes={{
							'text/*': ['.csv'],
							'image/*': ['.jpg', '.jpeg', '.png'],
							'application/*': ['.pdf']
						}}
						onDropSuccess={handleFileUpload()}
					/>
				)}
			</div>
		</>
	);
};
