import React from 'react';
import css from './Footer.module.scss';
import { Link } from 'components/Link/Link';

export const Footer: React.FC = props => {
	return (
		<footer className={css.footer}>
			<p className={css.copy}>&copy; dormakaba Group, {new Date().getFullYear()}</p>
			<ul className={css.footerMenu}>
				<li>
					<Link to='/'>Disclaimer</Link>
				</li>
				<li>
					<Link to='/'>Privacy Policy</Link>
				</li>
			</ul>
		</footer>
	);
};
