import React, { useState, useContext, useCallback, PropsWithChildren } from 'react';
import { Modal } from 'components/Modal';
import { useToggle } from 'common/hooks/useToggle';
import { IModalProps } from 'common/interfaces/IModalProps';

const ModalContext = React.createContext(null);

export const ModalProvider: React.FC<PropsWithChildren> = props => {
	const { children } = props;
	const [modalProps, setModalProps] = useState(null);
	const [isModalLoading, setIsModalLoading] = useState(false);
	const [isModalError, setIsModalError] = useState(false);
	const toggle = useToggle(false);

	const removeModal = useCallback(() => {
		toggle.setIsShown(false);
		setModalProps(null);
		setIsModalError(false);
		setIsModalLoading(false);
	}, [setModalProps, toggle]);

	const createModal = useCallback(
		(props: any) => {
			toggle.setIsShown(true);
			setModalProps(props);
			toggle.setSourceEvent(props.sourceEvent);
		},
		[setModalProps, toggle]
	);

	const setModalLoading = useCallback(
		(isLoading: boolean) => {
			setIsModalLoading(isLoading);
		},
		[setIsModalLoading]
	);

	return (
		<ModalContext.Provider
			value={{
				createModal,
				removeModal,
				setModalLoading,
				setIsModalError,
				isShown: toggle.isShown
			}}
		>
			{modalProps && (
				<Modal
					{...modalProps}
					removeModal={removeModal}
					isShown={toggle.isShown}
					isModalLoading={isModalLoading}
					isModalError={isModalError}
					setModalRef={toggle.setRef}
				/>
			)}
			{children}
		</ModalContext.Provider>
	);
};

const useModal = <T,>() => {
	const modalHelpers: {
		createModal: (modal: IModalProps<T>) => void;
		removeModal: () => {};
		setModalLoading: (isLoading: boolean) => void;
		setIsModalError: (isError: boolean) => void;
	} = useContext(ModalContext);

	return modalHelpers;
};

export { ModalContext, useModal };
