import React from 'react';
import { useModal } from 'providers/Modal/ModalProvider';
import { formatDateTimestamp } from 'common/utils/formatDateTimestamp';
import { Input } from 'components/Form/Input';
import { useCreateOrderQuote } from 'api/order';
import { Button } from 'components/Button';
import { useAuth } from 'providers/Auth';
import { useNavigate } from 'react-router';
import { TButtonTypes } from 'common/types/TButtonTypes';

const initialCreateNewQuoteValues = {
	newQuoteName: `New Quote ${formatDateTimestamp(new Date())}`
};

type CreateNewQuoteButtonProps = {
	type: TButtonTypes;
};

export const CreateNewQuoteButton: React.FC<CreateNewQuoteButtonProps> = props => {
	const { type } = props;
	const { getCompanyId } = useAuth();
	const navigate = useNavigate();
	const { createModal, removeModal, setModalLoading, setIsModalError } =
		useModal<typeof initialCreateNewQuoteValues>();

	const { mutateAsync: createQuote } = useCreateOrderQuote();

	const handleAddToQuoteModal = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		createModal({
			sourceEvent: event,
			heading: 'Start a New Quote',
			Content: props => {
				const { formik } = props;
				return (
					<>
						<p>
							Create a new quote to add product to, if you choose not to name your quote a name will be
							generated based on the date for you:
						</p>
						<form onSubmit={formik.handleSubmit}>
							<Input
								name={'newQuoteName'}
								label={'New Quote Name'}
								placeholder={'Enter quote name'}
								formikProps={formik}
							/>
						</form>
						<p>
							<strong>Please note pricing on quotes is only valid for 30 days.</strong> If more than 30
							days have passed your estimated total could change.
						</p>
					</>
				);
			},
			primaryButtonLabel: 'Search Products',
			primaryButtonAction: async formikSubmit => {
				formikSubmit();
			},
			formikConfig: {
				initialValues: initialCreateNewQuoteValues,
				onSubmit: async formValues => {
					setIsModalError(false);
					setModalLoading(true);
					try {
						await createQuote({
							name: formValues.newQuoteName,
							orderType: 'stock-cpq',
							status: 'NotSubmitted',
							customerId: getCompanyId()
						});
						removeModal();
						navigate('/products/search');
					} catch {
						setIsModalError(true);
					}
					setModalLoading(false);
				}
			}
		});
	};

	return (
		<Button type={type} onClick={event => handleAddToQuoteModal(event)}>
			Start new quote
		</Button>
	);
};
