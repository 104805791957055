import React, { useId } from 'react';
import css from './Toggle.module.scss';
import classes from 'classnames';

export interface IToggle extends React.InputHTMLAttributes<HTMLInputElement> {
	label: string;
	sublabel?: string;
	isLabelVisible?: boolean;
	classNames?: {
		labelText?: string;
	};
	checked?: boolean;
}

export const Toggle = (props: IToggle) => {
	const { label, sublabel, className, isLabelVisible = true, classNames, ...inputProps } = props;
	const id = useId();

	return (
		<div className={css.className}>
			<input
				{...inputProps}
				type='checkbox'
				id={id}
				onChange={event => {
					if (inputProps.onChange) {
						inputProps.onChange(event);
					}
				}}
				checked={inputProps.checked}
				className='screenReaderText'
			/>
			<label htmlFor={id} className={css.label}>
				<div className={classes(css.track, { [css.checked]: inputProps.checked })}>
					<div className={classes(css.indicator, { [css.checked]: inputProps.checked })}></div>
				</div>
				<span
					className={classes(
						css.labelText,
						{ screenReaderText: !isLabelVisible },
						{ [classNames?.labelText]: classNames?.labelText }
					)}
				>
					{label}
					{sublabel ? (
						<>
							<br />
							<i>{sublabel}</i>
						</>
					) : null}
				</span>
			</label>
		</div>
	);
};
