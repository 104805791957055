import React, { useState, useContext, useCallback, useEffect, PropsWithChildren } from 'react';
import { useLocation } from 'react-router';
import { Banner } from 'components/Banner';

const BannerContext = React.createContext(null);

export const BannerProvider: React.FC<PropsWithChildren> = props => {
	const { children } = props;
	const [bannerContent, setBannerContent] = useState(null);
	const location = useLocation();

	const createBanner = useCallback(
		(content: React.ReactNode) => {
			setBannerContent(content);
		},
		[setBannerContent]
	);

	const removeBanner = useCallback(() => {
		setBannerContent(null);
	}, [setBannerContent]);

	useEffect(() => {
		if (!(location?.state as { bannerSuccess: string })?.bannerSuccess) {
			removeBanner();
		}
	}, [location, removeBanner]);

	return (
		<BannerContext.Provider
			value={{
				createBanner,
				removeBanner
			}}
		>
			{bannerContent && <Banner>{bannerContent}</Banner>}
			{children}
		</BannerContext.Provider>
	);
};

// A custom auth hook that taps into the auth context helper methods
const useBanner = () => {
	const bannerHelpers: {
		createBanner: (content: React.ReactNode) => void;
		removeBanner: () => void;
	} = useContext(BannerContext);

	return bannerHelpers;
};

export { BannerContext, useBanner };
