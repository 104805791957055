import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Outlet, useNavigate, useLocation, useParams } from 'react-router';
import { useAuth } from 'providers/Auth';
import { useModal } from 'providers/Modal';
import { useBanner } from 'components/Banner/BannerProvider';
import { calculateDisplayedProductPrice } from 'common/utils/calculateDisplayedProductPrice';
import {
	questionsLength,
	separateInlineQuestionsFromEndingQuestions
} from 'components/CreateOpening/common/utilities';
import { FormikProps } from 'formik';
import { usePrevious } from 'common/hooks/usePrevious';
import {
	Application,
	TemplateSelectionOutcome,
	TemplateQuestionsOutcome,
	OpeningQuestions,
	OpeningTemplateQuestions,
	CreateOpeningStep,
	createOpeningStepsOrdered,
	TemplateApplicationOutcome,
	TemplateEstimatedOrder,
	CreateOpeningSavedLocationState
} from 'components/CreateOpening/common/types';
import {
	OpeningReferenceNumbersWithProductPricingResponse,
	ValidReferenceNumbersPricingResponse
} from 'api/opening';
import {
	swingOpeningTemplateQuestions,
	swingOpeningGlobalQuestions
} from 'components/CreateOpening/common/swingConfig';
import {
	fixedOpeningTemplateQuestions,
	fixedOpeningGlobalQuestions
} from 'components/CreateOpening/common/fixedConfig';
import {
	slidingOpeningTemplateQuestions,
	slidingOpeningGlobalQuestions
} from 'components/CreateOpening/common/slidingConfig';
import { useCreateOrderQuote, Order, useGetOrderById, useUpdateOrder } from 'api/order';
import { useAddOrderComment } from 'api/orderComment';
import {
	LineItem,
	useCreateOrderQuoteLineItem,
	useDeleteLineItem,
	useGetLineItemByOrderIdAsync
} from 'api/lineItem';
import { useGetOpeningByTemplateObj } from 'api/opening';

const CreateOpeningContext = React.createContext(null);

const defaultTemplateApplication: TemplateApplicationOutcome = {
	openingName: '',
	application: 'swing',
	doorWidth: '',
	doorHeight: '',
	clearOpeningWidth: '',
	clearOpeningHeight: ''
};

const defaultTemplateSelectionOutcome: TemplateSelectionOutcome = {
	doorLayout: '',
	glassThickness: '1-2inch',
	finish: '',
	type: '',
	closerType: '',
	mountingType: '',
	header: '',
	additionalFunction: '',
	bottomLock: '',
	channelHeightTop: '',
	channelHeightBottom: '',
	railHeightTop: '',
	railHeightBottom: '',
	railProfile: '',
	pullHandle: 'none',
	pullHandleFinish: '',
	panelWidth: ''
};

export const CreateOpeningProvider: React.FC = props => {
	const { getCompanyId } = useAuth();
	const location = useLocation();
	const navigate = useNavigate();
	const { orderId } = useParams<{ orderId: string }>();
	const { createBanner, removeBanner } = useBanner();
	const { createModal, removeModal } = useModal();
	const [hasLoadedFromRoutePreviously, setHasLoadedFromRoutePreviously] = useState(false);
	const [currentCreateOpeningStep, setCurrentCreateOpeningStep] =
		useState<CreateOpeningStep>('application');
	// Some of the steps have separate Formik forms that need to be submitted, stepFormProps allows for those Forms to be submitted via the CreateOpeningNavigation
	const [stepFormProps, setStepFormProps] = useState<FormikProps<any>>();
	const [isStepFormValid, setIsStepFormValid] = useState<boolean>(false);
	const [showStepFormError, setShowStepFormError] = useState<boolean>(false);
	// application is the first main piece of data needed to control the next step
	const [templateApplicationOutcome, setTemplateApplicationOutcome] =
		useState<TemplateApplicationOutcome>(defaultTemplateApplication);
	const previousApplicationType = usePrevious(templateApplicationOutcome.application);
	const [openingTemplateQuestions, setOpeningTemplateQuestions] = useState<OpeningTemplateQuestions>([]);
	const [openingGlobalQuestions, setOpeningGlobalQuestions] = useState<OpeningQuestions>([]);

	const [templateSelectionStep, setTemplateSelectionStep] = useState<number>(1);
	const [templateSelectionConditionalStepTotal, setTemplateSelectionConditionalStepTotal] =
		useState<number>(0);
	// the second main piece of data, based on application type what is the template outcome
	const [templateSelectionOutcome, setTemplateSelectionOutcome] = useState<TemplateSelectionOutcome>(
		defaultTemplateSelectionOutcome
	);

	const [templateOutcomeResponse, setTemplateOutcomeResponse] =
		useState<OpeningReferenceNumbersWithProductPricingResponse>();

	// the third piece of data is the qty selection from the estimation screen and this will help determine how many of each material number to add to the line items.
	const [templateQuantityToOrder, setTemplateQuantityToOrder] = useState<number>(1);
	const [templateEstimatedOrder, setTemplateEstimatedOrder] = useState<TemplateEstimatedOrder>();
	// const previousTemplateQuantityToOrder = usePrevious(templateQuantityToOrder);

	const { mutateAsync: createQuote } = useCreateOrderQuote();
	const { mutateAsync: createQuoteLineItem } = useCreateOrderQuoteLineItem();

	const [currentOrderId, setCurrentOrderId] = useState<string>(orderId);
	const { mutateAsync: getOpeningByTemplate } = useGetOpeningByTemplateObj();

	const [currentOrderLineItems, setCurrentOrderLineItems] = useState<LineItem[]>();
	const [currentOrder, setCurrentOrder] = useState<Order>();
	const orderLineItems = useGetLineItemByOrderIdAsync(currentOrderId);
	const currentOrderObj = useGetOrderById(currentOrderId);

	const { mutateAsync: deleteLineItem } = useDeleteLineItem();
	const { mutateAsync: updateOrder } = useUpdateOrder();

	// the fourth main piece of data, based on selected template answer some questions
	const [templateQuestionsOutcome, setTemplateQuestionsOutcome] = useState<TemplateQuestionsOutcome>();

	// the fifth main piece of data is optional. Allows the user to leave a message and also upload a drawing. Below is state for the message
	const [templateMessage, setTemplateMessage] = useState<string>();

	const { mutateAsync: addOrderComment } = useAddOrderComment();

	// manage the progress bar state for the wizard
	const [progressCurrentStep, setProgressCurrentStep] = useState<number>(0);
	const [progressTotalSteps, setProgressTotalSteps] = useState<number>(0);

	const warningModal = (
		heading: string,
		primaryButtonAction: (formikSubmit: () => void) => void,
		primaryButtonLabel: string,
		warningModalPrimaryText: string,
		secondaryButtonAction?: () => void,
		event?: any
	) =>
		createModal({
			sourceEvent: event,
			heading: heading,
			Content: () => {
				return (
					<>
						<p>{warningModalPrimaryText}</p>
						<p>
							<strong>
								Please note that this might modify the items estimated now based on your action.
							</strong>
						</p>
					</>
				);
			},
			primaryButtonLabel,
			primaryButtonAction,
			...(secondaryButtonAction ? { secondaryButtonAction } : {})
		});

	// Listen to the currentOrderId and update current lineItem and order state
	useEffect(() => {
		if (orderLineItems.data) {
			setCurrentOrderLineItems(orderLineItems.data);
		}
		if (currentOrderObj.data) {
			setCurrentOrder(currentOrderObj.data);
		}
	}, [setCurrentOrderLineItems, setCurrentOrder, orderLineItems, currentOrderObj]);

	// Listen to show a banner success messages
	useEffect(() => {
		const bannerSuccess = (location.state as { bannerSuccess: { name: string; message: string } })
			?.bannerSuccess;
		if (bannerSuccess) {
			createBanner(
				<>
					<strong>{bannerSuccess.name}</strong> {bannerSuccess.message}
				</>
			);
		}
	}, [location, createBanner]);

	// watcher for a generic initialization via react router state
	useEffect(() => {
		const initialFormStateSetByRouterState = (location.state as CreateOpeningSavedLocationState)
			?.createOpeningInitialState;

		// Right now code assumes setCurrentCreateOpeningStep will always equal 'estimate'
		if (initialFormStateSetByRouterState && !hasLoadedFromRoutePreviously) {
			setTemplateApplicationOutcome(initialFormStateSetByRouterState.createOpeningValues.application);
			setTemplateSelectionOutcome(initialFormStateSetByRouterState.createOpeningValues.template);
			const lastTemplateQuestionStep = questionsLength(
				openingTemplateQuestions,
				initialFormStateSetByRouterState.createOpeningValues.template
			);
			setTemplateSelectionConditionalStepTotal(lastTemplateQuestionStep);
			setTemplateSelectionStep(lastTemplateQuestionStep);
			setTemplateQuantityToOrder(
				initialFormStateSetByRouterState.createOpeningValues.estimation.quantity
			);
			setTemplateQuestionsOutcome(initialFormStateSetByRouterState.createOpeningValues.questions);

			(async () => {
				const response = await getOpeningByTemplate({
					application: initialFormStateSetByRouterState.createOpeningValues.application.application,
					...initialFormStateSetByRouterState.createOpeningValues.template
				});
				setTemplateOutcomeResponse(response);
			})();
			setCurrentCreateOpeningStep(initialFormStateSetByRouterState.createOpeningStep);

			// This useEffect will run a few times (likely two) to get fully init so lets wait for all the above to happen and also this last check to happen and this determines that we are ready to go
			if (openingTemplateQuestions.length > 0) {
				setHasLoadedFromRoutePreviously(true);
			}

			window.history.replaceState({}, document.title);
		}
	}, [
		location,
		openingTemplateQuestions,
		hasLoadedFromRoutePreviously,
		setHasLoadedFromRoutePreviously,
		setTemplateSelectionConditionalStepTotal,
		setTemplateSelectionStep,
		setTemplateApplicationOutcome,
		setTemplateSelectionOutcome,
		setTemplateQuestionsOutcome,
		setCurrentCreateOpeningStep,
		setTemplateOutcomeResponse,
		getOpeningByTemplate
	]);

	// Listens to and controls the state of the progress bar and will blend together conditional step count and fixed step count in the wizard to keep an accurate progress
	useEffect(() => {
		const totalFixedStepCount = createOpeningStepsOrdered.length;
		const totalKnownConditionalSteps = templateSelectionConditionalStepTotal;

		setProgressTotalSteps(totalFixedStepCount + totalKnownConditionalSteps);

		if (currentCreateOpeningStep === 'application') {
			setProgressCurrentStep(0);
		} else {
			const currentOpeningStepIndex = createOpeningStepsOrdered.indexOf(currentCreateOpeningStep);
			setProgressCurrentStep(currentOpeningStepIndex + templateSelectionStep);
		}
	}, [
		currentCreateOpeningStep,
		templateSelectionStep,
		templateSelectionConditionalStepTotal,
		setProgressCurrentStep,
		setProgressTotalSteps
	]);

	// Listens to application change abd swaps out the template questions and the global questions
	useEffect(() => {
		if (templateApplicationOutcome.application !== previousApplicationType) {
			switch (templateApplicationOutcome.application) {
				case 'swing':
					setOpeningTemplateQuestions(swingOpeningTemplateQuestions);
					setOpeningGlobalQuestions(swingOpeningGlobalQuestions);
					break;
				case 'sliding':
					setOpeningTemplateQuestions(slidingOpeningTemplateQuestions);
					setOpeningGlobalQuestions(slidingOpeningGlobalQuestions);
					break;
				case 'fixed':
					setOpeningTemplateQuestions(fixedOpeningTemplateQuestions);
					setOpeningGlobalQuestions(fixedOpeningGlobalQuestions);
					break;
			}
		}
	}, [
		templateApplicationOutcome,
		previousApplicationType,
		setOpeningTemplateQuestions,
		setOpeningGlobalQuestions
	]);

	// listens for a change in the templateOutcomeResponse and . Upon detecting a change update the staged order lineItems based on the configuration selections
	useEffect(() => {
		if (templateOutcomeResponse) {
			// Process Line Items
			const templateEstimatedOrder = processTemplateOpeningToEstimatedOrder(
				'orderID-HERE',
				templateApplicationOutcome,
				templateSelectionOutcome,
				templateOutcomeResponse,
				templateQuantityToOrder
			);

			// Set Line Items
			setTemplateEstimatedOrder(templateEstimatedOrder);
		}
	}, [
		templateOutcomeResponse,
		templateQuantityToOrder,
		templateSelectionOutcome,
		templateApplicationOutcome,
		setTemplateEstimatedOrder
	]);

	const hasEndingQuestions = useMemo(() => {
		const endingQuestions = separateInlineQuestionsFromEndingQuestions(
			openingTemplateQuestions,
			templateQuestionsOutcome
		).endingQuestions;

		return Object.keys(endingQuestions).length > 0;
	}, [openingTemplateQuestions, templateQuestionsOutcome]);

	const clearQuoteLineItems = async (successCallback?: () => void) => {
		if (currentOrderLineItems && currentOrderLineItems.length > 0) {
			const deleteLineItemPromises = currentOrderLineItems.map(lineItem =>
				deleteLineItem({
					id: lineItem.id
				})
			);
			try {
				await Promise.all(deleteLineItemPromises);
				setCurrentOrderLineItems([]);
				if (successCallback) {
					successCallback();
				}
			} catch (error) {
				console.error(`Error clearing quote line items: ${error}`);
			}
		} else {
			if (successCallback) {
				successCallback();
			}
		}
	};

	const updateQuoteFromTemplateEstimatedOrder = async (
		successCallback?: (currentOrderId: string) => void,
		questionsOutcome?: TemplateQuestionsOutcome
	): Promise<void> => {
		const createOpeningOrderSavedState: CreateOpeningSavedLocationState = {
			createOpeningInitialState: {
				createOpeningValues: {
					application: {
						...templateApplicationOutcome
					},
					template: {
						...templateSelectionOutcome
					},
					estimation: {
						quantity: templateQuantityToOrder
					},
					...(questionsOutcome ? { questions: { ...questionsOutcome } } : {})
				},
				createOpeningStep: 'estimate' // this is the only supported option atm and we just store it here
			}
		};
		if (questionsOutcome) {
			setTemplateQuestionsOutcome(questionsOutcome);
		}

		// When this is called there should be an order
		if (currentOrder) {
			const updateOptions = {
				...currentOrder,
				openingSaveStateObj: JSON.stringify(createOpeningOrderSavedState)
			};

			await updateOrder(updateOptions);

			// If there are no line items then we will want to make sure we create new ones.
			// The only way line item changes happen within the create opening is via the create opening flow.
			// ANy change in the create opening flow will warn the user and clear the orders line items.
			// So if line items is 0 then create. If line items exist then customer didn't not make any changes and is just completing their order.
			if (currentOrderLineItems && currentOrderLineItems.length === 0) {
				const createLineItemPromises = templateEstimatedOrder.lineItems.map(lineItem => {
					return createQuoteLineItem({
						...lineItem,
						discountedPricePerUnit:
							lineItem.discountedPricePerUnit !== 0
								? lineItem.discountedPricePerUnit
								: lineItem.pricePerUnit, // if discountedPricePerUnit is 0 then assume that there was no discount therefore set the lineItem's discountedPricePerUnit to the list price cause there is no discount.
						orderId: currentOrder.id
					});
				});
				try {
					await Promise.all(createLineItemPromises);
					createBanner(
						<>
							<strong>Success:</strong> the quote {currentOrder.name} was updated.
						</>
					);
					if (successCallback) {
						successCallback(currentOrder.id);
					}
				} catch (error) {
					createBanner(
						<>
							<strong>Error:</strong> There was an error updating your quote.
						</>
					);
					console.error(`There was an error updating your quote: ${error}`);
				}
			} else {
				if (successCallback) {
					successCallback(currentOrder.id);
				}
			}
		}
	};

	const createQuoteFromTemplateEstimatedOrder = async (
		successCallback: (newOrderId: string) => void
	): Promise<void> => {
		// TODO: This needs to get smarter and handle updates to the existing quote. Right now this only handles creates. But in the scenario of coming back from a saved for later if the user adjusts the qty then we need to adjust the quote.
		// TODO: in this update scenario we can pop the same modal possibly letting the user know that they are making changes and their quote line items will be updated
		// TODO: the update function in this regard should delete all line items on the quote and create new ones.
		// TODO; just noting this here for my brain. I will need to read the orderID off the router and set it in state so areas in the create opening can make decisions on it.
		const createOpeningOrderSavedState: CreateOpeningSavedLocationState = {
			createOpeningInitialState: {
				createOpeningValues: {
					application: {
						...templateApplicationOutcome
					},
					template: {
						...templateSelectionOutcome
					},
					questions: {
						...templateQuestionsOutcome
					},
					estimation: {
						quantity: templateQuantityToOrder
					}
				},
				createOpeningStep: 'estimate' // this is the only supported option atm and we just store it here
			}
		};

		const newQuote = await createQuote({
			name: templateApplicationOutcome.openingName,
			orderType: 'createopening',
			status: 'NotSubmitted',
			customerId: getCompanyId(),
			openingSaveStateObj: JSON.stringify(createOpeningOrderSavedState)
		});
		if (newQuote) {
			const newQuoteId = newQuote.id;
			setCurrentOrderId(newQuoteId);
			const createLineItemPromises = templateEstimatedOrder.lineItems.map(lineItem => {
				return createQuoteLineItem({
					...lineItem,
					discountedPricePerUnit:
						lineItem.discountedPricePerUnit !== 0
							? lineItem.discountedPricePerUnit
							: lineItem.pricePerUnit, // if discountedPricePerUnit is 0 then assume that there was no discount therefore set the lineItem's discountedPricePerUnit to the list price cause there is no discount.
					orderId: newQuoteId
				});
			});
			try {
				await Promise.all(createLineItemPromises);
				createBanner(
					<>
						<strong>Success:</strong> the new quote {newQuote.name} was created.
					</>
				);
				successCallback(newQuoteId);
			} catch (error) {
				createBanner(
					<>
						<strong>Error:</strong> There was an error creating your quote.
					</>
				);
				console.error(`there was an error creating a quote: ${error}`);
			}
		}
	};

	const navigateStep = (direction: 'prev' | 'next') => {
		const currentStepIndex = createOpeningStepsOrdered.indexOf(currentCreateOpeningStep);
		if (direction === 'prev' && currentStepIndex > 0) {
			setCurrentCreateOpeningStep(createOpeningStepsOrdered[currentStepIndex - 1]);
		}
		if (direction === 'next' && currentStepIndex < createOpeningStepsOrdered.length) {
			setCurrentCreateOpeningStep(createOpeningStepsOrdered[currentStepIndex + 1]);
		}
	};

	const navigateTemplateSelectionStep = async (direction: 'prev' | 'next') => {
		// There are no more sub steps withing the Template step. So navigate back to the previous create opening step (application)
		if (direction === 'prev' && templateSelectionStep === 1) {
			navigateStep('prev');
			setTemplateSelectionOutcome(defaultTemplateSelectionOutcome);
			setIsStepFormValid(true); // assume that the previous step was valid cause it was before
		}
		// Detects a true previous action
		if (direction === 'prev' && templateSelectionStep > 1) {
			setTemplateSelectionStep(templateSelectionStep - 1);
			setIsStepFormValid(true); // assume that the previous step was valid cause it was before
		}
		// Detects a true next action
		if (direction === 'next' && templateSelectionStep < templateSelectionConditionalStepTotal) {
			if (isStepFormValid) {
				setShowStepFormError(false);
				// reset form props for the step
				stepFormProps.validateForm();
				stepFormProps.setTouched({});
				setIsStepFormValid(false);

				setTemplateSelectionStep(templateSelectionStep + 1);
			} else {
				setShowStepFormError(true);
			}
		}
		// There are no more sub steps within the Template step. SO handle the submit and navigate to the next create opening step
		if (direction === 'next' && templateSelectionStep === templateSelectionConditionalStepTotal) {
			if (isStepFormValid) {
				setIsStepFormValid(false);
				// Submit the form
				await stepFormProps.submitForm();
				// navigate to the next step in create opening (question)
				navigateStep('next');
				setIsStepFormValid(false);
			}
		}
	};

	const navigateTemplateQuestionStep = async (direction: 'prev' | 'next') => {
		if (direction === 'prev') {
			navigateStep('prev');
		} else {
			await stepFormProps.submitForm();

			if (isStepFormValid || !hasEndingQuestions) {
				setIsStepFormValid(false);
				navigateStep('next');
			}
		}
	};

	// The main handlers for determining the current create opening step
	const onPreviousStepHandler = (event: any) => {
		switch (currentCreateOpeningStep) {
			case 'template':
				navigateTemplateSelectionStep('prev');
				break;
			case 'question':
				navigateTemplateQuestionStep('prev');
				break;
			case 'estimate':
				if (currentOrderId) {
					warningModal(
						'Are you sure you want to leave this page?',
						async () => {
							await clearQuoteLineItems(() => {
								removeModal();
								navigateStep('prev');
							});
						},
						'Continue to go back',
						'You will be navigated to the previous page and any modifications you make to your selection will affect your estimation.',
						null,
						event
					);
				} else {
					navigateStep('prev');
				}
				break;
			case 'comments':
				if (hasEndingQuestions) {
					navigateStep('prev');
				} else {
					setCurrentCreateOpeningStep('estimate');
				}
				break;
			default:
				navigateStep('prev');
				break;
		}
	};

	const onNextStepHandler = async (event: any) => {
		switch (currentCreateOpeningStep) {
			case 'template':
				navigateTemplateSelectionStep('next');
				break;
			case 'application':
				await stepFormProps.submitForm();
				if (isStepFormValid) {
					setIsStepFormValid(false);
					navigateStep('next');
				}
				break;
			case 'estimate':
				if (currentOrderId) {
					await updateQuoteFromTemplateEstimatedOrder(currentOrderId => {
						navigate(`/createopening/${currentOrderId}`);
						navigateStep('next');
						if (hasEndingQuestions) {
							navigateStep('next');
						} else {
							setTemplateQuestionsOutcome(templateQuestionsOutcome);
							setCurrentCreateOpeningStep('comments');
						}
					}, templateQuestionsOutcome);
				} else {
					await createQuoteFromTemplateEstimatedOrder(newOrderId => {
						navigate(`/createopening/${newOrderId}`);
						if (hasEndingQuestions) {
							navigateStep('next');
						} else {
							setTemplateQuestionsOutcome(templateQuestionsOutcome);
							setCurrentCreateOpeningStep('comments');
						}
					});
				}
				break;
			case 'question':
				removeBanner();
				navigateTemplateQuestionStep('next');
				break;
			case 'comments':
				if (currentOrderId && templateMessage) {
					await addOrderComment({
						orderId: currentOrderId,
						commentText: templateMessage
					});
				}
				navigateStep('next');
				break;
			default:
				removeBanner();
				navigateStep('next');
		}
	};

	const resetCreateOpening = (): void => {
		setTemplateApplicationOutcome(defaultTemplateApplication);
		setTemplateSelectionOutcome(defaultTemplateSelectionOutcome);
		setTemplateSelectionConditionalStepTotal(0);
		setTemplateSelectionStep(1);
		setCurrentCreateOpeningStep('application');
		window.history.replaceState({}, document.title);
	};

	// TODO: will need to update this to implement the convertTemplateEstimatedOrderToQuote update path described in TODOs above
	const saveForLater = async (): Promise<void> => {
		try {
			if (currentOrderId) {
				await updateQuoteFromTemplateEstimatedOrder(currentOrderId => {
					navigate('/createopening', {
						state: {
							bannerSuccess: {
								name: `${templateApplicationOutcome.openingName}`,
								message:
									'was successfully saved, you can find it in your quotes list. You can now begin another quote if desired.'
							}
						}
					});
					resetCreateOpening();
				}, templateQuestionsOutcome);
			} else {
				await createQuoteFromTemplateEstimatedOrder(() => {
					navigate('/createopening', {
						state: {
							bannerSuccess: {
								name: `${templateApplicationOutcome.openingName}`,
								message:
									'was successfully saved, you can find it in your quotes list. You can now begin another quote if desired.'
							}
						}
					});
					resetCreateOpening();
				});
			}
		} catch {
			removeBanner();
			createBanner(
				<>
					<strong>Error:</strong> There was an error saving your custom opening.
				</>
			);
		}
	};

	return (
		<CreateOpeningContext.Provider
			value={{
				currentCreateOpeningStep,
				setStepFormProps,
				isStepFormValid,
				setIsStepFormValid,
				showStepFormError,
				setCurrentCreateOpeningStep,
				onPreviousStepHandler,
				onNextStepHandler,
				templateApplicationOutcome,
				setTemplateApplicationOutcome,
				previousApplicationType,
				openingTemplateQuestions,
				openingGlobalQuestions,
				templateSelectionStep,
				setTemplateSelectionStep,
				setTemplateSelectionConditionalStepTotal,
				templateSelectionOutcome,
				setTemplateSelectionOutcome,
				templateOutcomeResponse,
				setTemplateOutcomeResponse,
				templateQuantityToOrder,
				setTemplateQuantityToOrder,
				templateEstimatedOrder,
				templateQuestionsOutcome,
				setTemplateQuestionsOutcome,
				templateMessage,
				setTemplateMessage,
				progressCurrentStep,
				progressTotalSteps,
				updateQuoteFromTemplateEstimatedOrder,
				saveForLater,
				warningModal,
				clearQuoteLineItems,
				currentOrderLineItems,
				currentOrderId,
				currentOrder
			}}
		>
			<Outlet />
		</CreateOpeningContext.Provider>
	);
};

const useCreateOpening = () => {
	const createOpeningHelpers: {
		currentCreateOpeningStep: CreateOpeningStep;
		setStepFormProps: (props: FormikProps<any>) => void;
		isStepFormValid: boolean;
		setIsStepFormValid: (isValid: boolean) => void;
		showStepFormError: boolean;
		setCurrentCreateOpeningStep: (step: CreateOpeningStep) => void;
		onPreviousStepHandler: (event: any) => void;
		onNextStepHandler: (event: any) => Promise<void>;
		templateApplicationOutcome: TemplateApplicationOutcome;
		setTemplateApplicationOutcome: (outcome: TemplateApplicationOutcome) => void;
		previousApplicationType: Application;
		openingTemplateQuestions: OpeningTemplateQuestions;
		openingGlobalQuestions: OpeningQuestions;
		templateSelectionStep: number;
		setTemplateSelectionStep: (step: number) => void;
		setTemplateSelectionConditionalStepTotal: (total: number) => void;
		templateSelectionOutcome: TemplateSelectionOutcome;
		setTemplateSelectionOutcome: (outcome: TemplateSelectionOutcome) => void;
		templateOutcomeResponse: OpeningReferenceNumbersWithProductPricingResponse;
		setTemplateOutcomeResponse: (response: OpeningReferenceNumbersWithProductPricingResponse) => void;
		templateQuantityToOrder: number;
		setTemplateQuantityToOrder: (quantity: number) => void;
		templateEstimatedOrder: TemplateEstimatedOrder;
		templateQuestionsOutcome: TemplateQuestionsOutcome;
		setTemplateQuestionsOutcome: (outcome: TemplateQuestionsOutcome) => void;
		templateMessage: string;
		setTemplateMessage: (message: string) => void;
		progressCurrentStep: number;
		progressTotalSteps: number;
		updateQuoteFromTemplateEstimatedOrder: (
			successCallback?: (currentOrderId: string) => void,
			questionsOutcome?: TemplateQuestionsOutcome
		) => Promise<void>;
		saveForLater: () => Promise<void>;
		warningModal: (
			heading: string,
			primaryButtonAction: (formikSubmit: () => void) => void,
			primaryButtonLabel: string,
			warningModalPrimaryText: string,
			secondaryButtonAction?: () => void,
			event?: any
		) => void;
		clearQuoteLineItems: (successCallback?: () => void) => Promise<void>;
		currentOrderLineItems: LineItem[];
		currentOrderId: string;
		currentOrder: Order;
	} = useContext(CreateOpeningContext);

	return createOpeningHelpers;
};

export { CreateOpeningContext, useCreateOpening };

const processTemplateOpeningToEstimatedOrder = (
	orderId: string,
	application: TemplateApplicationOutcome,
	template: TemplateSelectionOutcome,
	templateOutcome: OpeningReferenceNumbersWithProductPricingResponse,
	quantity: number
): TemplateEstimatedOrder => {
	const width = application.doorWidth;
	const clearOpeningWidth = application.clearOpeningWidth !== '' ? application.clearOpeningWidth : null;
	const finishNumber = template.finish;
	const pullHandleFinishNumber = template.pullHandleFinish !== '' ? template.pullHandleFinish : null;

	let processStatus: 'success' | 'error' = 'success';
	const processedLineItems = templateOutcome.validReferenceNumbersPricingResponses.reduce(
		(reducedLineItems: LineItem[], referenceNumber: ValidReferenceNumbersPricingResponse) => {
			const productInfo = referenceNumber.productPricingRsp;
			const lineItems = reducedLineItems;

			const referenceNumberMaterialNumberIndex = lineItems.findIndex(
				lineItem => lineItem.materialNumber === productInfo.product.materialNumber
			);
			const materialNumberExistsInReducedLineItems = referenceNumberMaterialNumberIndex !== -1;

			if (materialNumberExistsInReducedLineItems) {
				// The material number already exists so we just need to increment the line item qty by the incoming qty selector
				lineItems[referenceNumberMaterialNumberIndex].quantity =
					lineItems[referenceNumberMaterialNumberIndex].quantity + quantity;
				return lineItems;
			} else {
				lineItems.push({
					orderId,
					materialNumber: productInfo.product.materialNumber,
					title: productInfo.product.productName,
					pricePerUnit: (() => {
						const isProductAPull =
							productInfo.product?.categories === 'Pulls, Handles, & Locksets' && pullHandleFinishNumber;
						const doesProductUseClearOpeningWidth =
							productInfo.product?.unitOfMeasure === 'CLEAR OPENING FT' && clearOpeningWidth;
						const finishPricing = productInfo.productPricing?.find(productPrice => {
							const finishToCheck = isProductAPull
								? parseInt(pullHandleFinishNumber)
								: parseInt(finishNumber);

							return productPrice.finishInformation?.finishNumber === finishToCheck;
						});

						if (finishPricing && finishPricing.priceByFinish) {
							// The product using finish when pricing
							const total = calculateDisplayedProductPrice(
								finishPricing.priceByFinish,
								1, // just need the price for 1
								productInfo.product.unitOfMeasure,
								doesProductUseClearOpeningWidth ? clearOpeningWidth : width
							);
							if (total) {
								return total;
							} else {
								processStatus = 'error';
								return 0;
							}
						} else if (
							productInfo.productPricing?.[0]?.priceByMasterData ||
							productInfo.productPricing?.[0]?.priceByMasterData === 0
						) {
							// priceByMasterData === 0 protects free items and allows them to avoid errors and get tallied
							// the product does not have a finish when pricing
							const total = calculateDisplayedProductPrice(
								productInfo.productPricing[0].priceByMasterData,
								1, // just need the price for 1
								productInfo.product.unitOfMeasure,
								doesProductUseClearOpeningWidth ? clearOpeningWidth : width
							);
							if (total || total === 0) {
								// total === 0 protects free items and allows them to avoid errors and get tallied
								return total;
							} else {
								processStatus = 'error';
								return 0;
							}
						} else if (productInfo.productPricing?.[0]?.priceByFinish) {
							// the product did not match by a user chosen finish nor a by master data. This can mean that the product does not come in the users chosen finish so lets default to the first one
							const total = calculateDisplayedProductPrice(
								productInfo.productPricing[0].priceByFinish,
								1, // just need the price for 1
								productInfo.product.unitOfMeasure,
								doesProductUseClearOpeningWidth ? clearOpeningWidth : width
							);
							if (total) {
								return total;
							} else {
								processStatus = 'error';
								return 0;
							}
						} else {
							// ensure the error is set here. All customers should be able to see non discounted prices unless there is an error with master data.
							processStatus = 'error';
							return 0;
						}
					})(),
					discountedPricePerUnit: (() => {
						const isProductAPull =
							productInfo.product?.categories === 'Pulls, Handles, & Locksets' && pullHandleFinishNumber;
						const doesProductUseClearOpeningWidth =
							productInfo.product?.unitOfMeasure === 'CLEAR OPENING FT' && clearOpeningWidth;
						const finishPricing = productInfo.productPricing?.find(productPrice => {
							const finishToCheck = isProductAPull
								? parseInt(pullHandleFinishNumber)
								: parseInt(finishNumber);
							return productPrice.finishInformation?.finishNumber === finishToCheck;
						});

						if (finishPricing && finishPricing.discountedPrice) {
							// The product using finish when pricing
							const total = calculateDisplayedProductPrice(
								finishPricing.discountedPrice,
								1, // just need the price for 1
								productInfo.product.unitOfMeasure,
								doesProductUseClearOpeningWidth ? clearOpeningWidth : width
							);
							if (total) {
								return total;
							} else {
								processStatus = 'error';
								return 0;
							}
						} else if (productInfo.productPricing?.[0]?.discountedPrice) {
							const total = calculateDisplayedProductPrice(
								productInfo.productPricing[0].discountedPrice,
								1, // just need the price for 1
								productInfo.product.unitOfMeasure,
								doesProductUseClearOpeningWidth ? clearOpeningWidth : width
							);
							if (total) {
								return total;
							} else {
								processStatus = 'error';
								return 0;
							}
						} else {
							// no need to return error here cause some customers might not get discounts on that line item.
							return 0;
						}
					})(),
					quantity
				});

				return lineItems;
			}
		},
		[]
	);

	if (processedLineItems.length === 0) {
		processStatus = 'error';
	}

	return {
		status: processStatus,
		lineItems: processedLineItems
	};
};
