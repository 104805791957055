import { formatDate } from './formatDate';

export const formatDateTimestamp = (timestamp: Date) => {
	const newDate = new Date(timestamp);
	const twentyFourHoursFormatted = newDate.getHours();
	const minutes = newDate.getMinutes();
	const ampm = twentyFourHoursFormatted >= 12 ? 'PM' : 'AM';

	const twelveHourFormatted = twentyFourHoursFormatted % 12 ? twentyFourHoursFormatted % 12 : 12; // if 0 that equals 12
	const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;

	return `${formatDate(timestamp)} at ${twelveHourFormatted}:${minutesFormatted} ${ampm}`;
};
