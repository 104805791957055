import React from 'react';
import { labelToKey } from 'common/utils/labelToKey';

export const renderBulletList = (bullets: string[]): React.ReactNode => {
	const parsedBullets = bullets.filter(bullet => bullet !== '' && bullet !== undefined);
	return (
		<ul>
			{parsedBullets.map(bullet => (
				<li key={labelToKey(bullet)}>{bullet}</li>
			))}
		</ul>
	);
};
