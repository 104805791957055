import React from 'react';
import css from './AppFrame.module.scss';
import { Header } from 'components/AppFrame/Header';
import { BannerProvider } from 'components/Banner/BannerProvider';
import { Outlet } from 'react-router';

export const AppFrame = () => {
	return (
		<>
			<div className={css.appframe}>
				<a href='#content' id='skipNavLink' className={css.skipNavigation}>
					Skip Navigation
				</a>

				<Header></Header>
				<BannerProvider>
					<main id='content' className={css.content}>
						<Outlet />
					</main>
				</BannerProvider>
			</div>
		</>
	);
};
