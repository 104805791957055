import React from 'react';
import classes from 'classnames';
import css from './ProgressBar.module.scss';

export interface ProgressbarProps {
	style?: any;
	className?: string;
	progress: number;
	total: number;
	fillClassName?: string;
}

export const ProgressBar = ({ progress, total, fillClassName, className, style }: ProgressbarProps) => {
	const progressPercentage = total > 0 ? (progress / total) * 100 : 0;

	return (
		<div className={classes(css.progressBar, className)} style={style}>
			<div
				className={classes(css.progressAmount, fillClassName)}
				style={{ width: `${progressPercentage}%` }}
			>
				{' '}
			</div>
		</div>
	);
};
