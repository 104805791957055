import { TCreateOpeningIconTypes } from 'components/CreateOpeningIcon';
import { LineItem } from 'api/lineItem';

export type Application = 'swing' | 'fixed' | 'sliding';

// step 1: application
// step 2: template
// step 3: question
// step 4: comments
// step 5: quote
export type CreateOpeningStep =
	| 'application'
	| 'template'
	| 'estimate'
	| 'question'
	| 'comments'
	| 'quote';

export const createOpeningStepsOrdered: CreateOpeningStep[] = [
	'application',
	'template',
	'estimate',
	'question',
	'comments',
	'quote'
];

export type TemplateApplicationOutcome = {
	openingName: any;
	application: Application;
	doorWidth: any;
	doorHeight: any;
	clearOpeningWidth: any;
	clearOpeningHeight: any;
};

export type TemplateSelectionOutcome = {
	doorLayout: any;
	glassThickness: any;
	finish: any; // not used in seed
	type: string;
	closerType: any;
	mountingType: any;
	header: any;
	additionalFunction: any;
	bottomLock: any;
	channelHeightTop: any;
	channelHeightBottom: any;
	railHeightTop: any;
	railHeightBottom: any;
	railProfile: any;
	pullHandle: any; // might need to remove this question on frontend
	pullHandleFinish: any; // not used in seed
	panelWidth: any;
};

export type CreateOpeningSavedLocationState = {
	createOpeningInitialState: {
		createOpeningValues: {
			application: TemplateApplicationOutcome;
			template: TemplateSelectionOutcome;
			questions?: TemplateQuestionsOutcome;
			estimation: {
				quantity: number;
			};
		};
		createOpeningStep: CreateOpeningStep;
	};
};

export type RequestTemplateFromObjectAPIRequest = {
	application: Application;
} & TemplateSelectionOutcome;

export type CreateOpeningAPIRequestType = TemplateApplicationOutcome & TemplateSelectionOutcome;

export type Condition = string | Conditional;

export type Conditional = {
	type: 'OR' | 'AND';
	condition: Condition[]; // ['rootKey:valueKey'] | Conditional
};

export type StepBaseData = {
	label: string;
	labelTooltip?: string;
	key: string;
	conditional?: Conditional;
	areQuestionsInline?: boolean; // if questions then choose whether they show up inline or at the end in a separate step after pricing
	questions?: OpeningQuestions;
	icon?: TCreateOpeningIconTypes;
};

export type Answer = StepBaseData;

export type TemplateQuestion = {
	values: Answer[];
	validator?: any; // yup validator
	labelDescription?: string;
	labelTooltip?: string;
	stepTitle?: string;
	stepDescription?: string;
} & StepBaseData;

export type OpeningTemplateQuestions = TemplateQuestion[];

export type OpeningQuestionSelectOption = { label: string; value: string; key?: string };

export type OpeningQuestion = {
	type: 'text' | 'boolean' | 'select';
	label: string;
	validator?: any;
	key?: string;
	parentKey?: string; // computed key set and used inside template selection step
	options?: OpeningQuestionSelectOption[];
};

export type OpeningQuestions = OpeningQuestion[];

export type TemplateQuestionsOutcome = { [key: string]: any };

export type TemplateEstimatedOrder = {
	status: 'success' | 'error';
	lineItems: LineItem[];
};

export type FormSetupObject = { [key: string]: { initialValue: string; validator: any } };
