import React from 'react';
import css from './Hero.module.scss';
import classes from 'classnames';
import { Container } from 'components/Container/Container';

export interface IHeroProps {
	className?: string;
	content: React.ReactNode;
	image: string;
	imageAltText: string;
}

export const Hero: React.FC<IHeroProps> = props => {
	const { content, image, imageAltText, className } = props;

	return (
		<div className={classes(css.hero, className)}>
			<Container>
				<div className={css.content}>{content}</div>
			</Container>
			<img src={image} alt={imageAltText} />
		</div>
	);
};
