import React, { useState } from 'react';
import css from './TableLight.module.scss';
import classes from 'classnames';
import { Icon } from 'components/Icon/Icon';
import { getUniqueId } from 'common/utils/getUniqueId';
import { TObjectWithStringAsProps } from 'common/types/TObjectWithStringAsProps';

export interface ITableLightProps {
	className?: string;
	headers: TTableLightHeader[];
	data: TObjectWithStringAsProps[];
	rowOnMouseLeave?: (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		index: number,
		data: any
	) => void;
	rowOnMouseEnter?: (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		index: number,
		data: any
	) => void;
	rowOnTouchStart?: (event: React.TouchEvent<HTMLDivElement>, index: number, data: any) => void;
	classNames?: {
		tr?: string;
		td?: string;
	};
	dynamicClassNames?: {
		tr?: (index: number, data: any) => string;
	};
}

export type TTableLightHeader = {
	label: string;
	isLabelScreenReaderHidden?: boolean;
	RenderFunctionLabel?: React.ComponentType;
	property: string;
	isSortable?: boolean;
	renderFunction?: React.FC;
	className?: string;
	tooltip?: string;
};

export const TableLight: React.FC<ITableLightProps> = props => {
	const {
		className,
		data,
		headers,
		rowOnMouseLeave,
		rowOnMouseEnter,
		rowOnTouchStart,
		classNames,
		dynamicClassNames
	} = props;
	const [uniqueId] = useState(getUniqueId());
	return (
		<div className={classes(css.tableLight, className)}>
			<div className={css.tableWrapper}>
				<div className={css.tableDom}>
					<div className={classes(css.tr, { [classNames?.tr]: classNames?.tr })}>
						{headers.map((header, columnIndex) => (
							<div
								key={'0' + columnIndex}
								id={uniqueId + 0 + columnIndex}
								className={classes(css.th, header.className)}
							>
								<div className={css.headerItemInner}>
									<div
										className={classes(css.headerLabel, {
											screenReaderText: header.isLabelScreenReaderHidden
										})}
										aria-hidden={true}
									>
										{header.label}
									</div>
									{header.tooltip && (
										<Icon
											tooltip={header.tooltip}
											marginSize='left-small'
											type='Info'
											size='small'
											color='blue'
										/>
									)}
								</div>
							</div>
						))}
					</div>
					{data.map((row, rowIndex) => (
						<div
							key={'row' + rowIndex}
							className={classes(
								css.tr,
								{ [classNames?.tr]: classNames?.tr },
								{ [dynamicClassNames?.tr(rowIndex, row)]: dynamicClassNames?.tr }
							)}
							onMouseEnter={
								rowOnMouseEnter
									? event => {
											rowOnMouseEnter(event, rowIndex, row);
									  }
									: undefined
							}
							onMouseLeave={
								rowOnMouseLeave
									? event => {
											rowOnMouseLeave(event, rowIndex, row);
									  }
									: undefined
							}
							onTouchStart={
								rowOnTouchStart
									? event => {
											rowOnTouchStart(event, rowIndex, row);
									  }
									: undefined
							}
						>
							{headers.map((header, columnIndex) => {
								const { renderFunction } = header;
								return (
									<div
										key={columnIndex + rowIndex}
										id={uniqueId + (rowIndex + 1) + columnIndex}
										className={classes(css.td, header.className, { [classNames?.td]: classNames?.td })}
									>
										{renderFunction ? renderFunction(row as any) : row[header.property]}
									</div>
								);
							})}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
