import React, { PropsWithChildren } from 'react';
import css from './FormFooter.module.scss';
import classes from 'classnames';

interface IFormFooter {
	className?: string;
}

export const FormFooter: React.FC<PropsWithChildren<IFormFooter>> = props => (
	<div className={classes(css.formFooter, props.className)}>{props.children}</div>
);
