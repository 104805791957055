import React, { useEffect } from 'react';
import { useAuth } from 'providers/Auth/AuthProvider';
import { useLocation } from 'react-router';
import { useGetAllUsers, User, useDeleteUser } from 'api/user';
import { Button } from 'components/Button';
import { Link } from 'components/Link';
import { Panel } from 'components/Panel';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { Spinner } from 'components/Spinner';
import { TTableHeader } from 'components/Table';
import { LoadingDelay } from 'components/LoadingDelay';
import { PageFrame } from 'components/PageFrame';
import { useBanner } from 'components/Banner/BannerProvider';
import { ICurrentTableValues, TableController } from 'components/Table/TableController';
import { UseQueryResult } from 'react-query';
import { useModal } from 'providers/Modal/ModalProvider';
import { formatDate } from 'common/utils/formatDate';
import { sortArrayByProperty } from 'common/utils/sortArrayByProperty';

export const UsersManagementView = () => {
	const { isLoading: isAuthLoading } = useAuth();
	const tableBaseRoute = '/admin/usermanagement/';
	const location = useLocation();
	const { createModal, removeModal, setModalLoading, setIsModalError } = useModal();
	const { createBanner } = useBanner();
	const { mutateAsync: removeUser } = useDeleteUser();

	useEffect(() => {
		const bannerSuccess = (location.state as { bannerSuccess: { name: string; message: string } })
			?.bannerSuccess;
		if (bannerSuccess) {
			createBanner(
				<>
					<strong>{bannerSuccess.name}</strong> {bannerSuccess.message}
				</>
			);
		}
	}, [location, createBanner]);

	const handleRemoveUser = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, user: User) => {
		createModal({
			sourceEvent: event,
			heading: 'Remove User',
			Content: () => (
				<>
					<p>
						Are you sure you want to remove{' '}
						<strong>
							{user.firstName} {user.lastName}
						</strong>
						?
					</p>
					<p>This user will no longer have access to the system if removed.</p>
				</>
			),
			primaryButtonType: 'danger',
			primaryButtonLabel: 'remove',
			primaryButtonAction: async () => {
				setIsModalError(false);
				setModalLoading(true);
				try {
					await removeUser({ ...user });
					removeModal();
					createBanner(
						<>
							<strong>
								{user.firstName} {user.lastName}
							</strong>{' '}
							was successfully removed.
						</>
					);
				} catch {
					setIsModalError(true);
				}
				setModalLoading(false);
			}
		});
	};

	const useQuery = (): UseQueryResult => {
		return useGetAllUsers();
	};

	const headers: TTableHeader[] = [
		{
			label: 'Name',
			property: 'fullName',
			renderFunction: (data: User) => (
				<Link to={`/admin/usermanagement/user/${data.userId}`}>
					{data.firstName} {data.lastName}
				</Link>
			)
		},
		{
			label: 'Email',
			property: 'email'
		},
		{
			label: 'Date Created',
			property: 'createdAt',
			renderFunction: (data: User) => (data.createdAt ? formatDate(data.createdAt, true, true) : '')
		},
		{
			label: 'Last Log in',
			property: 'lastLogin',
			renderFunction: (data: User) => (data.lastLogin ? formatDate(data.lastLogin, true, true) : '')
		},
		{
			label: 'Remove',
			property: 'remove',
			renderFunction: (data: User) => (
				<Button
					type={'link'}
					onClick={event => {
						event.persist();
						handleRemoveUser(event, data);
					}}
				>
					Remove
				</Button>
			)
		}
	];

	const dataSelector = (data: User[], { itemsPerPage, pageNumber }: ICurrentTableValues) => {
		// Default sort for use management
		return sortArrayByProperty(data, 'lastLogin', 'desc');
	};

	if (isAuthLoading) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	return (
		<PageFrame
			title={'User Management'}
			ContentTopRight={() => (
				<Link to={'/admin/usermanagement/user/add'} type={'primary'}>
					Add new user
				</Link>
			)}
		>
			<Container>
				<Section>
					<Panel>
						<TableController
							headers={headers}
							baseRoute={tableBaseRoute}
							itemsPerPage={10}
							useQuery={useQuery}
							isUrlUpdated={true}
							hasPagination={true}
							emptyMessage={'There are no users in the system'}
							dataSelector={dataSelector}
							totalCountSelector={(data: any) => data?.length}
							hasSearch={false}
							tableHandlesPaginatingData={true}
						/>
					</Panel>
				</Section>
			</Container>
		</PageFrame>
	);
};
