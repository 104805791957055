import React from 'react';
import css from './Split.module.scss';
import classes from 'classnames';

interface ISplitContent {
	contentLeft?: React.ReactNode;
	contentRight?: React.ReactNode;
	className?: string;
	isMobileOrientationReversed?: boolean;
	type: 'twoThird_oneThird' | 'oneFourth_threeFourth' | 'threeFourth_oneFourth' | 'oneHalf_oneHalf';
	breakpoint?: 'mobile-up' | 'desktop-up' | 'desktop-large-up' | 'desktop-larger-up';
	classNames?: {
		contentAreaLeft?: string;
		contentAreaRight?: string;
	};
	hasSplitMargin?: boolean;
}

export const Split: React.FC<ISplitContent> = props => {
	const {
		contentLeft,
		contentRight,
		className,
		isMobileOrientationReversed,
		type,
		breakpoint = 'desktop-up',
		hasSplitMargin = true,
		classNames
	} = props;

	return (
		<div className={css[breakpoint]}>
			<div
				className={classes(
					css.splitContent,
					className,
					{
						[css.isMobileOrientationReversed]: isMobileOrientationReversed
					},
					css[type]
				)}
			>
				<div
					className={classes(css.contentArea, css.contentAreaLeft, {
						[classNames?.contentAreaLeft]: classNames?.contentAreaLeft,
						[css.hasSplitMargin]: hasSplitMargin
					})}
				>
					{contentLeft}
				</div>
				<div
					className={classes(css.contentArea, css.contentAreaRight, {
						[classNames?.contentAreaRight]: classNames?.contentAreaRight,
						[css.hasSplitMargin]: hasSplitMargin
					})}
				>
					{contentRight}
				</div>
			</div>
		</div>
	);
};
