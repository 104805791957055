import React, { PropsWithChildren } from 'react';
import css from './GridContainer.module.scss';
import classes from 'classnames';

interface IGridProps {
	columnAmount: '2' | '3' | '4' | '6';
	className?: string;
	style?: React.CSSProperties;
}

export const GridContainer: React.FC<PropsWithChildren<IGridProps>> = props => {
	const { columnAmount, className, children, style } = props;

	return (
		<div
			className={classes(
				css.gridContainer,
				className,
				{ two: columnAmount === '2' },
				{ three: columnAmount === '3' },
				{ four: columnAmount === '4' },
				{ six: columnAmount === '6' }
			)}
			style={style}
		>
			{children}
		</div>
	);
};
