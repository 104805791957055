import { OpeningQuestions, OpeningTemplateQuestions } from './types';
import * as yup from 'yup';

export const swingOpeningTemplateQuestions: OpeningTemplateQuestions = [
	{
		stepTitle: 'Select your door type:',
		label: '',
		key: 'type', // its type to normalize this across the different systems
		validator: {
			type: yup.string().required()
		},
		values: [
			{
				label: 'A/F',
				labelTooltip: 'A/F: Patch Fitting Door or Patch Fitting Door with Bottom Patch Lock',
				key: 'af',
				icon: 'DoorTypeA'
			},
			{
				label: 'BP',
				labelTooltip: 'BP: Top Patch Fitting with Bottom Door Rail',
				key: 'bp',
				icon: 'DoorTypeBp'
			},
			{
				label: 'P',
				labelTooltip: 'P: Door Rails Top and Bottom',
				key: 'p',
				icon: 'DoorTypeP'
			}
		]
	},
	{
		stepTitle: 'Do you require a single door or a paired opening?',
		label: 'Select between single or double door layouts:',
		key: 'doorLayout',
		validator: {
			doorLayout: yup.string().required()
		},
		values: [
			{
				label: 'Single',
				key: 'single'
			},
			{
				label: 'Double',
				key: 'double'
			}
		]
	},
	{
		stepTitle: 'Select your desired Glass Thickness:',
		label:
			'Please note if you are looking for a different thickness, specify other below and you can let our customer service team know before submitting your quote.',
		key: 'glassThickness',
		validator: {
			glassThickness: yup.string().required()
		},
		values: [
			{
				label: '3/8 inch',
				key: '3-8inch'
			},
			{
				label: '1/2 inch',
				key: '1-2inch'
			},
			{
				label: '9/16 inch',
				key: '9-16inch', // hide if doorType is A/F, BP
				conditional: {
					type: 'OR',
					condition: ['type:p']
				}
			},
			{
				label: '5/8 inch',
				key: '5-8inch'
			},
			{
				label: '11/16 inch',
				key: '11-16inch', // hide if doorType is A/F, BP
				conditional: {
					type: 'OR',
					condition: ['type:p']
				}
			},
			{
				label: '3/4 inch',
				key: '3-4inch'
			},
			{
				label: '13/16 inch',
				key: '13-16inch', // hide if doorType is A/F, BP
				conditional: {
					type: 'OR',
					condition: ['type:p']
				}
			}
		]
	},
	{
		stepTitle: 'Select your desired finish:',
		label: 'Available finishes can vary depending on the type of system.',
		key: 'finish',
		validator: {
			finish: yup.string().required()
		},
		values: [
			{
				label: '101 - Clear Anodized',
				key: '101'
			},
			{
				label: '108 - Dark Bronze Anodized',
				key: '108'
			},
			{
				label: '109 - Black Anodized',
				key: '109'
			},
			{
				label: '199 - Special Anodized',
				key: '199',
				conditional: {
					type: 'OR',
					condition: ['type:bp', 'type:p']
				}
			},
			{
				label: '299 - Special Plating',
				key: '299',
				conditional: {
					type: 'OR',
					condition: ['type:bp', 'type:p']
				}
			},
			{
				label: '399 - Special Coating',
				key: '399',
				conditional: {
					type: 'OR',
					condition: ['type:bp', 'type:p']
				}
			},
			{
				label: '503 - Polished Brass without Lacquer',
				key: '503'
			},
			{
				label: '599 - Satin Brass without Lacquer',
				key: '599'
			},
			{
				label: '600 - Satin Bronze without Lacquer',
				key: '600'
			},
			{
				label: '601 - Polished Bronze without Lacquer',
				key: '601'
			},
			{
				label: '700 - Satin Stainless',
				key: '700'
			},
			{
				label: '701 - Polished Stainless',
				key: '701'
			},
			{
				label: '999 - Custom Finish',
				key: '999',
				conditional: {
					type: 'OR',
					condition: ['type:af']
				}
			}
		]
	},
	{
		stepTitle: 'Select your desired header:',
		label: '',
		key: 'header',
		validator: {
			header: yup.string().required()
		},
		values: [
			{
				label: '4-1/8 inch',
				key: '4-1-8inch'
			},
			{
				label: '4-1/2 inch',
				key: '4-1-2inch'
			},
			{
				label: '6 inch',
				key: '6inch'
			},
			{
				label: 'None',
				key: 'none'
			}
		]
	},
	{
		stepTitle: 'Select your desired closer:',
		label:
			'A variety of door closer options are available to ensure your swing door closes safely or can be held open if required.',
		key: 'closerType',
		validator: {
			closerType: yup.string().required()
		},
		values: [
			{
				label: 'BTS75V',
				labelTooltip: 'BTS: In ground floor closer for single and double acting doors.',
				key: 'bts75v',
				icon: 'CloserTypeBts',
				areQuestionsInline: true,
				questions: [
					{
						type: 'select',
						label: 'BTS75V Closer Type',
						key: 'BTS75VCloserType',
						validator: {
							BTS75VCloserType: yup.string().required('Closer type is required')
						},
						options: [
							{
								label: '105° HO',
								value: '105-ho',
								key: '105-ho'
							},
							{
								label: '90° HO',
								value: '90-ho',
								key: '90-ho'
							},
							{
								label: 'NHO',
								value: 'nho',
								key: 'nho'
							},
							{
								label: '90° HO (BF)',
								value: '90-ho-bf',
								key: '90-ho-bf'
							},
							{
								label: 'NHO (BF)',
								value: 'nho-bf',
								key: 'nho-bf'
							}
						]
					}
				]
			},
			{
				label: 'BTS80',
				labelTooltip: 'BTS: In ground floor closer for single and double acting doors.',
				key: 'bts80',
				icon: 'CloserTypeBts',
				areQuestionsInline: true,
				questions: [
					{
						type: 'select',
						label: 'BTS80 Closer Type',
						key: 'BTS80CloserType',
						validator: {
							BTS80CloserType: yup.string().required('Closer type is required')
						},
						options: [
							{
								label: 'ANSI Size 4 (Standard)',
								value: 'ansi-size-4-standard',
								key: 'ansi-size-4-standard'
							},
							{
								label: 'ANSI Size 6 (Strong)',
								value: 'ansi-size-6-strong',
								key: 'ansi-size-6-strong'
							},
							{
								label: 'ANSI Size 3 (BFE)',
								value: 'ansi-size-3-bfe',
								key: 'ansi-size-3-bfe'
							}
						]
					}
				]
			},
			{
				label: 'Free Swing',
				labelTooltip: 'Free Swing: No door closer. Pivots at top and bottom of door.',
				key: 'freeSwing',
				icon: 'CloserTypeFreeSwing'
			},
			{
				label: 'RTS',
				labelTooltip: 'RTS: Overhead concealed closers  for single and double-acting doors.',
				key: 'rts',
				icon: 'CloserTypeRts',
				conditional: {
					type: 'OR',
					condition: ['header:4-1-8inch', 'header:4-1-2inch', 'header:6inch']
				},
				areQuestionsInline: true,
				questions: [
					{
						type: 'select',
						label: 'RTS Closer Type',
						key: 'RTSCloserType',
						validator: {
							RTSCloserType: yup.string().required('Closer type is required')
						},
						options: [
							{
								label: '105˚ HO BFE (5 mm ext.)',
								value: '105-ho-bfe-5mm-ext',
								key: '105-ho-5mm-ext'
							},
							{
								label: '105˚ NHO BFE (5 mm ext.)',
								value: '105-nho-bfe-5mm-ext',
								key: '105-nho-5mm-ext'
							},
							{
								label: '105˚ HO BFI (5 mm ext.)',
								value: '105-ho-bfi-5mm-ext',
								key: '105-ho-5mm-ext'
							},
							{
								label: '105˚ NHO BFI (5 mm ext.)',
								value: '105-nho-bfi-5mm-ext',
								key: '105-nho-5mm-ext'
							},
							{
								label: '90˚ HO (5 mm ext.) Size 3',
								value: '90-ho-5mm-ext-size-3',
								key: '90-ho-5mm-ext-size-3'
							},
							{
								label: '105˚ HO (5 mm ext.) Size 3',
								value: '105-ho-5mm-ext-size-3',
								key: '105-ho-5mm-ext-size-3'
							},
							{
								label: '90deg HO (5mm ext.) Size 2',
								value: '90-ho-5mm-ext-size-2',
								key: '90-ho-5mm-ext-size-2'
							},
							{
								label: '105deg HO (5mm ext.) Size 2',
								value: '105-ho-5mm-ext-size-2',
								key: '105-ho-5mm-ext-size-2'
							},
							{
								label: '105˚ NHO (5 mm ext.) Size 3',
								value: '105-nho-5mm-ext-size-3',
								key: '105-nho-5mm-ext-size-3'
							},
							{
								label: '90˚ NHO(5 mm ext.) Size 3',
								value: '90-nho-5mm-ext-size-3',
								key: '90-nho-5mm-ext-size-3'
							},
							{
								label: '90deg NHO(5mm ext.) Size 2',
								value: '90-nho-5mm-ext-size-2',
								key: '90-nho-5mm-ext-size-2'
							}
						]
					}
				]
			}
		]
	},
	{
		stepTitle: 'Do you need a bottom lock?',
		label: '',
		key: 'bottomLock',
		validator: {
			bottomLock: yup.string().required()
		},
		values: [
			{
				label: 'Yes',
				key: 'yes'
			},
			{
				label: 'No',
				key: 'no'
			}
		]
	},
	{
		stepTitle: 'Select your desired rail profile:',
		label:
			'Rails come in square and tapered options, plus different heights/sizes. Tapered options provide an ADA-compliant solution for bottom rails under 10" high.',
		key: 'railProfile',
		validator: {
			railProfile: yup.string().required()
		},
		conditional: {
			type: 'OR',
			condition: ['type:bp', 'type:p']
		},
		values: [
			{
				label: 'Square',
				key: 'square'
			},
			{
				label: 'Tapered',
				key: 'tapered'
			}
		]
	},
	{
		stepTitle: 'Select your desired rail height on top:',
		label: 'A variety of rail heights are available for top rails.',
		key: 'railHeightTop',
		validator: {
			railHeightTop: yup.string().required()
		},
		conditional: {
			type: 'OR',
			condition: ['type:p']
		},
		values: [
			{
				label: '2-1/2 inch',
				key: '2-1-2inch'
			},
			{
				label: '3-5/8 inch',
				key: '3-5-8inch', // remove tappered rail profile if glass thickness is above 9/16
				conditional: {
					type: 'OR',
					condition: [
						'railProfile:square',
						{
							type: 'AND',
							condition: [
								'railProfile:tapered',
								{
									type: 'OR',
									condition: [
										'glassThickness:3-8inch',
										'glassThickness:1-2inch',
										'glassThickness:9-16inch'
									]
								}
							]
						}
					]
				}
			},
			{
				label: '4 inch',
				key: '4inch', // remove tappered rail profile if glass thickness is above 9/16
				conditional: {
					type: 'OR',
					condition: [
						{
							type: 'AND',
							condition: [
								'railProfile:square',
								{
									type: 'OR',
									condition: [
										'glassThickness:3-8inch',
										'glassThickness:1-2inch',
										'glassThickness:9-16inch',
										'glassThickness:11-16inch',
										'glassThickness:3-4inch',
										'glassThickness:13-16inch'
									]
								}
							]
						},
						{
							type: 'AND',
							condition: [
								'railProfile:tapered',
								{
									type: 'OR',
									condition: [
										'glassThickness:3-8inch',
										'glassThickness:1-2inch',
										'glassThickness:9-16inch'
									]
								}
							]
						}
					]
				}
			},
			{
				label: '6 inch',
				key: '6inch', // There are no tappered rail ever,
				conditional: {
					type: 'OR',
					condition: ['railProfile:square']
				}
			},
			{
				label: '10 inch',
				key: '10inch', // // There are no tappered rail ever, if 13/16 glassThickness is chosen then hide 10inch option
				conditional: {
					type: 'AND',
					condition: [
						'railProfile:square',
						{
							type: 'OR',
							condition: [
								'glassThickness:3-8inch',
								'glassThickness:1-2inch',
								'glassThickness:9-16inch',
								'glassThickness:5-8inch',
								'glassThickness:11-16inch',
								'glassThickness:3-4inch'
							]
						}
					]
				}
			}
		]
	},
	{
		stepTitle: 'Select your desired rail height on bottom:',
		label: 'A variety of rail heights are available for bottom rails.',
		key: 'railHeightBottom',
		validator: {
			railHeightBottom: yup.string().required()
		},
		conditional: {
			type: 'OR',
			condition: ['type:bp', 'type:p']
		},
		values: [
			{
				label: '2-1/2 inch',
				key: '2-1-2inch'
			},
			{
				label: '3-5/8 inch',
				key: '3-5-8inch', // remove tappered rail profile if glass thickness is above 9/16
				conditional: {
					type: 'OR',
					condition: [
						'railProfile:square',
						{
							type: 'AND',
							condition: [
								'railProfile:tapered',
								{
									type: 'OR',
									condition: [
										'glassThickness:3-8inch',
										'glassThickness:1-2inch',
										'glassThickness:9-16inch'
									]
								}
							]
						}
					]
				}
			},
			{
				label: '4 inch',
				key: '4inch', // remove tappered rail profile if glass thickness is above 9/16
				conditional: {
					type: 'OR',
					condition: [
						{
							type: 'AND',
							condition: [
								'railProfile:square',
								{
									type: 'OR',
									condition: [
										'glassThickness:3-8inch',
										'glassThickness:1-2inch',
										'glassThickness:9-16inch',
										'glassThickness:11-16inch',
										'glassThickness:3-4inch',
										'glassThickness:13-16inch'
									]
								}
							]
						},
						{
							type: 'AND',
							condition: [
								'railProfile:tapered',
								{
									type: 'OR',
									condition: [
										'glassThickness:3-8inch',
										'glassThickness:1-2inch',
										'glassThickness:9-16inch'
									]
								}
							]
						}
					]
				}
			},
			{
				label: '6 inch',
				key: '6inch', // There are no tappered rail profile
				conditional: {
					type: 'OR',
					condition: ['railProfile:square']
				}
			},
			{
				label: '10 inch',
				key: '10inch', // There are no tappered rail profile, if 13/16 glassThickness is chosen then hide 10inch option
				conditional: {
					type: 'AND',
					condition: [
						'railProfile:square',
						{
							type: 'OR',
							condition: [
								'glassThickness:3-8inch',
								'glassThickness:1-2inch',
								'glassThickness:9-16inch',
								'glassThickness:5-8inch',
								'glassThickness:11-16inch',
								'glassThickness:3-4inch'
							]
						}
					]
				}
			}
		]
	}
	// Pull handle question are removed at the moment on purpose check with Curtis Massey when to put them back
	// {
	// 	stepTitle: 'Select a desired handle:',
	// 	label: '',
	// 	key: 'pullHandle',
	// 	validator: {
	// 		pullHandle: yup.string().required()
	// 	},
	// 	values: [
	// 		{
	// 			label: 'C-Pulls: 12 inch solid',
	// 			labelTooltip: 'C-Pulls: Form and functionality in a gracefully curved shape',
	// 			key: 'c-pull-12-inch-solid'
	// 		},
	// 		{
	// 			label: 'C-Pulls: 12 inch tube',
	// 			labelTooltip: 'C-Pulls: Form and functionality in a gracefully curved shape',
	// 			key: 'c-pull-12-inch-tube'
	// 		},
	// 		{
	// 			label: 'TG9387 (Stock): 12 inch',
	// 			labelTooltip:
	// 				'TG9387: 1-1/4 inch diameter Non-Locking Ladder Pull, a pull handle with straight supports',
	// 			key: 'tg9387-stock-12-inch'
	// 		},
	// 		{
	// 			label: 'TG9387 (Stock): 18 inch',
	// 			labelTooltip:
	// 				'TG9387: 1-1/4 inch diameter Non-Locking Ladder Pull, a pull handle with straight supports',
	// 			key: 'tg9387-stock-18-inch'
	// 		},
	// 		{
	// 			label: 'TG9387 (Stock): 36 inch',
	// 			labelTooltip:
	// 				'TG9387: 1-1/4 inch diameter Non-Locking Ladder Pull, a pull handle with straight supports',
	// 			key: 'tg9387-stock-36-inch'
	// 		},
	// 		{
	// 			label: 'TG9387 (Stock): 42 inch',
	// 			labelTooltip:
	// 				'TG9387: 1-1/4 inch diameter Non-Locking Ladder Pull, a pull handle with straight supports',
	// 			key: 'tg9387-stock-42-inch'
	// 		},
	// 		{
	// 			label: 'TG9387 (Stock): 60 inch',
	// 			labelTooltip:
	// 				'TG9387: 1-1/4 inch diameter Non-Locking Ladder Pull, a pull handle with straight supports',
	// 			key: 'tg9387-stock-60-inch'
	// 		},
	// 		{
	// 			label: 'TG138: 49 inch',
	// 			labelTooltip:
	// 				'TG138: 1-3/8 inch diameter Non-locking Ladder Pull, a pull handle with straight supports. Design and pull diameter match TG138 Locking Ladder Pull.',
	// 			key: 'tg138-49-inch'
	// 		},
	// 		{
	// 			label: 'TG138: 60 inch',
	// 			labelTooltip:
	// 				'TG138: 1-3/8 inch diameter Non-locking Ladder Pull, a pull handle with straight supports. Design and pull diameter match TG138 Locking Ladder Pull.',
	// 			key: 'tg138-60-inch'
	// 		},
	// 		{
	// 			label: 'TG138: 72 inch',
	// 			labelTooltip:
	// 				'TG138: 1-3/8 inch diameter Non-locking Ladder Pull, a pull handle with straight supports. Design and pull diameter match TG138 Locking Ladder Pull.',
	// 			key: 'tg138-72-inch'
	// 		},
	// 		{
	// 			label: 'TG138: 84 inch',
	// 			labelTooltip:
	// 				'TG138: 1-3/8 inch diameter Non-locking Ladder Pull, a pull handle with straight supports. Design and pull diameter match TG138 Locking Ladder Pull.',
	// 			key: 'tg138-84-inch'
	// 		},
	// 		{
	// 			label: 'TG138 LLP: 49 inch',
	// 			labelTooltip:
	// 				'TG138: 1-3/8 inch diameter Non-locking Ladder Pull, a pull handle with straight supports. Design and pull diameter match TG138 Locking Ladder Pull.',
	// 			key: 'tg138llp-49-inch',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: ['bottomLock:no']
	// 			}
	// 		},
	// 		{
	// 			label: 'TG138 LLP: 60 inch',
	// 			labelTooltip:
	// 				'TG138: 1-3/8 inch diameter Non-locking Ladder Pull, a pull handle with straight supports. Design and pull diameter match TG138 Locking Ladder Pull.',
	// 			key: 'tg138llp-60-inch',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: ['bottomLock:no']
	// 			}
	// 		},
	// 		{
	// 			label: 'TG138 LLP: 72 inch',
	// 			labelTooltip:
	// 				'TG138: 1-3/8 inch diameter Non-locking Ladder Pull, a pull handle with straight supports. Design and pull diameter match TG138 Locking Ladder Pull.',
	// 			key: 'tg138llp-72-inch',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: ['bottomLock:no']
	// 			}
	// 		},
	// 		{
	// 			label: 'TG138 LLP: 84 inch',
	// 			labelTooltip:
	// 				'TG138: 1-3/8 inch diameter Non-locking Ladder Pull, a pull handle with straight supports. Design and pull diameter match TG138 Locking Ladder Pull.',
	// 			key: 'tg138llp-84-inch',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: ['bottomLock:no']
	// 			}
	// 		},
	// 		{
	// 			label: 'Custom Handle',
	// 			labelTooltip: 'Custom handle selection directs request to customer service for assistance.',
	// 			key: 'custom'
	// 		},
	// 		{
	// 			label: 'No Handle',
	// 			labelTooltip: 'No Handle: if you do NOT want a handle select this option',
	// 			key: 'none'
	// 		}
	// 	]
	// },
	// {
	// 	stepTitle: 'What type of handle finish do you desire?',
	// 	label:
	// 		'We provide a wide range of handle types, including glass panics. Some offer custom lengths, and most offer multiple stock options.',
	// 	key: 'pullHandleFinish',
	// 	validator: {
	// 		pullHandleFinish: yup.string().required()
	// 	},
	// 	conditional: {
	// 		type: 'OR',
	// 		condition: [
	// 			'pullHandle:c-pull-12-inch-solid',
	// 			'pullHandle:c-pull-12-inch-tube',
	// 			'pullHandle:tg9387-stock-12-inch',
	// 			'pullHandle:tg9387-stock-18-inch',
	// 			'pullHandle:tg9387-stock-36-inch',
	// 			'pullHandle:tg9387-stock-42-inch',
	// 			'pullHandle:tg9387-stock-60-inch',
	// 			'pullHandle:tg138-49-inch',
	// 			'pullHandle:tg138-60-inch',
	// 			'pullHandle:tg138-72-inch',
	// 			'pullHandle:tg138-84-inch',
	// 			'pullHandle:tg138llp-49-inch',
	// 			'pullHandle:tg138llp-60-inch',
	// 			'pullHandle:tg138llp-72-inch',
	// 			'pullHandle:tg138llp-84-inch'
	// 		]
	// 	},
	// 	values: [
	// 		{
	// 			label: '101 - Clear Anodized',
	// 			key: '101',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: ['pullHandle:c-pull-12-inch-solid', 'pullHandle:c-pull-12-inch-tube']
	// 			}
	// 		},
	// 		{
	// 			label: '108 - Dark Bronze Anodized',
	// 			key: '108',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: ['pullHandle:c-pull-12-inch-solid', 'pullHandle:c-pull-12-inch-tube']
	// 			}
	// 		},
	// 		{
	// 			label: '109 - Black Anodized',
	// 			key: '109',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: ['pullHandle:c-pull-12-inch-solid', 'pullHandle:c-pull-12-inch-tube']
	// 			}
	// 		},
	// 		{
	// 			label: '503 - Polished Brass without Lacquer',
	// 			key: '503',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: ['pullHandle:c-pull-12-inch-solid', 'pullHandle:c-pull-12-inch-tube']
	// 			}
	// 		},
	// 		{
	// 			label: '599 - Satin Brass without Lacquer',
	// 			key: '599',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: ['pullHandle:c-pull-12-inch-solid', 'pullHandle:c-pull-12-inch-tube']
	// 			}
	// 		},
	// 		{
	// 			label: '600 - Satin Bronze without Lacquer',
	// 			key: '600',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: ['pullHandle:c-pull-12-inch-solid', 'pullHandle:c-pull-12-inch-tube']
	// 			}
	// 		},
	// 		{
	// 			label: '601 - Polished Bronze without Lacquer',
	// 			key: '601',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: ['pullHandle:c-pull-12-inch-solid', 'pullHandle:c-pull-12-inch-tube']
	// 			}
	// 		},
	// 		{
	// 			label: '693 - Black Coated',
	// 			key: '693',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: [
	// 					'pullHandle:tg138-49-inch',
	// 					'pullHandle:tg138-60-inch',
	// 					'pullHandle:tg138-72-inch',
	// 					'pullHandle:tg138-84-inch',
	// 					'pullHandle:tg138llp-49-inch',
	// 					'pullHandle:tg138llp-60-inch',
	// 					'pullHandle:tg138llp-72-inch',
	// 					'pullHandle:tg138llp-84-inch'
	// 				]
	// 			}
	// 		},
	// 		{
	// 			label: '700 - Satin Stainless',
	// 			key: '700'
	// 		},
	// 		{
	// 			label: '701 - Polished Stainless',
	// 			key: '701',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: [
	// 					'pullHandle:c-pull-12-inch-solid',
	// 					'pullHandle:c-pull-12-inch-tube',
	// 					'pullHandle:tg138-49-inch',
	// 					'pullHandle:tg138-60-inch',
	// 					'pullHandle:tg138-72-inch',
	// 					'pullHandle:tg138-84-inch',
	// 					'pullHandle:tg138llp-49-inch',
	// 					'pullHandle:tg138llp-60-inch',
	// 					'pullHandle:tg138llp-72-inch',
	// 					'pullHandle:tg138llp-84-inch'
	// 				]
	// 			}
	// 		}
	// 	]
	// }
];

export const swingOpeningGlobalQuestions: OpeningQuestions = [];
