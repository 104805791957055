import React, { PropsWithChildren } from 'react';
import css from './Container.module.scss';
import classes from 'classnames';

interface IContainerProps {
	className?: string;
	hasPaddingBottom?: boolean;
}

export const Container: React.FC<PropsWithChildren<IContainerProps>> = props => {
	const { children, hasPaddingBottom = true, className } = props;

	return (
		<div className={classes(css.container, { [css['hasPaddingBottom']]: hasPaddingBottom }, className)}>
			{children}
		</div>
	);
};
