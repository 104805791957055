import { useState, useEffect } from 'react';

export const useHasDataLoadedOnce = (loading: boolean) => {
	const [hasDataLoadedOnce, setHasDataLoadedOnce] = useState(false);

	useEffect(() => {
		if (!loading && !hasDataLoadedOnce) {
			setHasDataLoadedOnce(true);
		}
	}, [loading, setHasDataLoadedOnce, hasDataLoadedOnce]);

	return hasDataLoadedOnce;
};
