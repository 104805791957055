import React, { PropsWithChildren } from 'react';
import css from './InputFrame.module.scss';
import classes from 'classnames';
import { InputMessage } from '../InputMessage/InputMessage';
import { FormikProps } from 'formik';
import { getProcessedFormikProps } from 'common/utils/getProcessedFromikProps';
import { Fieldset } from '../Fieldset';
import { Icon } from 'components/Icon';
export type TInputLabelPosition = 'labelTop' | 'labelLeft';

export interface IInputFrame {
	inputId: string;
	isLabelVisible?: boolean;
	label: string;
	labelPosition?: TInputLabelPosition;
	messageId: string;
	inputMessage?: string;
	formikProps?: FormikProps<any>;
	name: string;
	errorMessage?: string;
	className?: string;
	labelClass?: string;
	isCheckboxGroup?: boolean;
	tooltip?: string;
	required?: boolean;
}

export const InputFrame: React.FC<PropsWithChildren<IInputFrame>> = props => {
	const {
		children,
		inputId,
		isLabelVisible = true,
		label,
		labelPosition = 'labelTop',
		labelClass,
		messageId,
		inputMessage,
		formikProps,
		name,
		errorMessage,
		className,
		isCheckboxGroup,
		tooltip,
		required
	} = props;

	const { isError, displayedMessage } = getProcessedFormikProps(
		formikProps,
		name,
		errorMessage,
		inputMessage
	);

	const labelClasses = classes(
		css.inputLabel,
		{ [css.labelError]: isError },
		{ screenReaderText: !isLabelVisible },
		labelClass
	);

	if (isCheckboxGroup) {
		return (
			<Fieldset
				className={classes(css.inputFrame, className)}
				legendContent={label}
				legendClassName={labelClasses}
				infoTooltip={tooltip}
			>
				{children}
				{displayedMessage && (
					<InputMessage isError={isError} id={messageId}>
						{displayedMessage}
					</InputMessage>
				)}
			</Fieldset>
		);
	} else {
		return (
			<div className={classes(css.inputFrame, css[labelPosition], className)}>
				<div>
					<label htmlFor={inputId} className={labelClasses}>
						<div className={css.tooltipContainer}>
							<span className={classes(css.label, { [css.isRequired]: required })}>{label}</span>
							{tooltip && (
								<>
									&nbsp;
									<Icon
										className={css.tooltip}
										tooltip={tooltip}
										marginSize='left-small'
										type='Info'
										color='blue'
										size='smallest'
									/>
								</>
							)}
						</div>
					</label>
				</div>
				<div className={css.inputFrameControls}>
					{children}
					{displayedMessage && (
						<InputMessage isError={isError} id={messageId}>
							{displayedMessage}
						</InputMessage>
					)}
				</div>
			</div>
		);
	}
};
