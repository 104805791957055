import React from 'react';
import { Alert } from 'components/Alert';
import { useAuth } from 'providers/Auth/AuthProvider';
import css from './AccountBasicDetailsView.module.scss';
import classes from 'classnames';
import { ErrorView } from 'views/ErrorView';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Detail } from 'components/Detail/Detail';
import { Link } from 'components/Link';
import { Panel } from 'components/Panel';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { Spinner } from 'components/Spinner';
import { LoadingDelay } from 'components/LoadingDelay';
import { PageFrame } from 'components/PageFrame';
import { GridContainer } from 'components/Grid/GridContainer/GridContainer';
import { GridItem } from 'components/Grid/GridItem/GridItem';
import { FormFooter } from 'components/Form/FormFooter/FormFooter';
import { useGetUser, useUpdateUser } from 'api/user';
import { isUserOfPermission } from 'common/utils/isUserOfPermission';

export const AccountBasicDetailsView = () => {
	const { isLoading, user, getPermissions } = useAuth();
	const userId = user.sub;
	const { data: getUserData, isLoading: isGetUserLoading, error: getUserError } = useGetUser(userId);
	const {
		mutateAsync: updateUser,
		error: updateUserError,
		data: updateUserData,
		isLoading: isUpdateUserLoading
	} = useUpdateUser();

	if (getUserError) {
		return <ErrorView isInAppFrame={true} />;
	}

	if (isLoading || isGetUserLoading) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	return (
		<PageFrame title={'Account'}>
			<Section className={css.sectionSearchResults}>
				<Container>
					<div className={css.searchContainer}>
						{/* Side Nav */}
						<div className={css.linksContainer}>
							<div className={css.linksContent} role='region'>
								<div className={css.linksHeader}>
									<span className={classes('h6', css.linksHeaderText)}>Your Account</span>
								</div>
								<div className={css.dropdownList}>
									<li key={1}>
										<Link
											to={'/accountbasicdetails'}
											classNames={{ buttonContent: classes(css.buttonContent, css.active) }}
										>
											Basic Details
										</Link>
									</li>
									<li key={2}>
										<Link to={'/accountpassword'} classNames={{ buttonContent: css.buttonContent }}>
											Password
										</Link>
									</li>
									{isUserOfPermission(['company:read:own'], getPermissions()) && (
										<li key={3}>
											<Link
												to={'/accountcompanydetails'}
												classNames={{ buttonContent: css.buttonContent }}
											>
												Company Details
											</Link>
										</li>
									)}
								</div>
							</div>
						</div>
						<div className={css.resultsContainer}>
							<Panel title={'Your Basic Details'}>
								{updateUserData && !isUpdateUserLoading && (
									<Alert type='success-light'>Details were successfully updated.</Alert>
								)}
								<Formik
									initialValues={{
										firstName: getUserData.firstName,
										lastName: getUserData.lastName,
										email: getUserData?.email
									}}
									validateOnChange={false}
									validateOnBlur={false}
									onSubmit={async (data, { setSubmitting }) => {
										setSubmitting(true);
										const { ...remainingData } = data;
										try {
											await updateUser({
												...getUserData,
												...remainingData
											});
											setSubmitting(false);
										} catch {
											setSubmitting(false);
										}
									}}
									validationSchema={yup.object().shape({
										firstName: yup.string().required('First name is required'),
										lastName: yup.string().required('Last name is required')
									})}
								>
									{props => {
										return (
											<>
												{updateUserError && !props.isSubmitting && (
													<Alert type='danger-light'>
														There was an error updating the user, please try again.
													</Alert>
												)}
												<Form>
													<GridContainer columnAmount='2'>
														{/* First Name */}
														<GridItem>
															<Detail title={'First Name'} type='form'>
																{getUserData.firstName}
															</Detail>
														</GridItem>
														{/* Last Name */}
														<GridItem>
															<Detail title={'Last Name'} type='form'>
																{getUserData.lastName}
															</Detail>
														</GridItem>
														{/* Email */}
														<GridItem>
															<Detail title={'Email'} type='form'>
																{getUserData.email}
															</Detail>
														</GridItem>
													</GridContainer>
													<FormFooter>
														{/* There is no save for now */}
														{/* <Button type='primary' htmlType='submit' isLoading={props.isSubmitting}>
															Save user
														</Button> */}
													</FormFooter>
												</Form>
											</>
										);
									}}
								</Formik>
							</Panel>
						</div>
					</div>
				</Container>
			</Section>
		</PageFrame>
	);
};
