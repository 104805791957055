import React, { useState } from 'react';
import css from './Icon.module.scss';
import classes from 'classnames';
import { TIconColors } from 'common/types/TIconColors';
import { TButtonTypes } from 'common/types/TButtonTypes';
import { removePropertiesFromObjects } from 'common/utils/removePropertiesFromObjects';
import { getUniqueId } from 'common/utils/getUniqueId';

import { ReactComponent as Alert } from 'assets/svg/alert.svg';
import { ReactComponent as ArrowRightAngleUp } from 'assets/svg/arrow-right-angle-up.svg';
import { ReactComponent as ArrowRight } from 'assets/svg/arrow-right.svg';
import { ReactComponent as Bell } from 'assets/svg/bell.svg';
import { ReactComponent as Calendar } from 'assets/svg/calendar.svg';
import { ReactComponent as Camera } from 'assets/svg/camera.svg';
import { ReactComponent as CancelBox } from 'assets/svg/cancel-box.svg';
import { ReactComponent as Cancel } from 'assets/svg/cancel.svg';
import { ReactComponent as CaretDownFilled } from 'assets/svg/caret-down-filled.svg';
import { ReactComponent as CaretDown } from 'assets/svg/caret-down.svg';
import { ReactComponent as CaretLeft } from 'assets/svg/caret-left.svg';
import { ReactComponent as CaretRight } from 'assets/svg/caret-right.svg';
import { ReactComponent as CaretUpFilled } from 'assets/svg/caret-up-filled.svg';
import { ReactComponent as CaretUp } from 'assets/svg/caret-up.svg';
import { ReactComponent as Cart } from 'assets/svg/cart.svg';
import { ReactComponent as CheckCircle } from 'assets/svg/check-circle.svg';
import { ReactComponent as CheckSquare } from 'assets/svg/check-square.svg';
import { ReactComponent as Check } from 'assets/svg/check.svg';
import { ReactComponent as Comment } from 'assets/svg/comment.svg';
import { ReactComponent as Download } from 'assets/svg/download.svg';
import { ReactComponent as Edit } from 'assets/svg/edit.svg';
import { ReactComponent as EllipsisVertical } from 'assets/svg/ellipsis-vertical.svg';
import { ReactComponent as Globe } from 'assets/svg/globe.svg';
import { ReactComponent as Message } from 'assets/svg/message.svg';
import { ReactComponent as Minus } from 'assets/svg/minus.svg';
import { ReactComponent as Info } from 'assets/svg/info.svg';
import { ReactComponent as Information } from 'assets/svg/information.svg';
import { ReactComponent as Phone } from 'assets/svg/phone.svg';
import { ReactComponent as Plus } from 'assets/svg/plus.svg';
import { ReactComponent as Rotate } from 'assets/svg/rotate.svg';
import { ReactComponent as Search } from 'assets/svg/search.svg';
import { ReactComponent as Settings } from 'assets/svg/settings.svg';
import { ReactComponent as SpinnerThin } from 'assets/svg/spinner-thin.svg';
import { ReactComponent as SwapStroke } from 'assets/svg/swap-stroke.svg';
import { ReactComponent as UserCircle } from 'assets/svg/user-circle.svg';
import ResizeObserver from 'resize-observer-polyfill';
import { useLayer, Arrow } from 'react-laag';

export type TIconSize = 'smallest' | 'small' | 'medium' | 'large' | 'largest' | 'giant' | 'custom';

const iconMap = {
	Alert,
	ArrowRightAngleUp,
	ArrowRight,
	Bell,
	Calendar,
	Camera,
	CancelBox,
	Cancel,
	CaretDownFilled,
	CaretDown,
	CaretLeft,
	CaretRight,
	CaretUpFilled,
	CaretUp,
	Cart,
	CheckCircle,
	CheckSquare,
	Check,
	Comment,
	Download,
	Edit,
	EllipsisVertical,
	Globe,
	Message,
	Minus,
	Info,
	Information,
	Phone,
	Plus,
	Rotate,
	Search,
	Settings,
	SpinnerThin,
	SwapStroke,
	UserCircle
};

export type TIconTypes = keyof typeof iconMap;

export interface IIconProps {
	className?: string;
	type: TIconTypes;
	size?: TIconSize;
	color?: TIconColors;
	style?: React.CSSProperties;
	context?: TButtonTypes;
	tooltip?: string;
	preTooltipAccessibilityMessage?: string;
	postTooltipAccessibilityMessage?: string;
	tooltipShownByDefault?: boolean;
	marginSize?:
		| 'right-small'
		| 'right-medium'
		| 'right-large'
		| 'left-small'
		| 'left-medium'
		| 'left-large';
	strokeWidth?: 'none' | 'thin' | 'medium' | 'thick';
}

export const Icon: React.FC<IIconProps & React.SVGProps<SVGSVGElement>> = props => {
	const {
		className,
		type,
		size = 'medium',
		color = 'gray',
		style,
		context,
		tooltip,
		tooltipShownByDefault,
		marginSize,
		preTooltipAccessibilityMessage,
		postTooltipAccessibilityMessage,
		strokeWidth = 'thin'
	} = props;

	const iconClasses = classes(
		css.icon,
		className,
		css[size],
		css[color],
		css[type],
		css[context],
		css[`stroke-width-${strokeWidth}`],
		{ [css[`margin-${marginSize}`]]: marginSize }
	);

	const iconProperties = {
		...removePropertiesFromObjects(
			[
				'type',
				'size',
				'color',
				'context',
				'marginSize',
				'tooltipShownByDefault',
				'preTooltipAccessibilityMessage',
				'postTooltipAccessibilityMessage'
			],
			props
		),
		className: iconClasses,
		style,
		role: 'text'
	};

	const [isOpen, setOpen] = React.useState(tooltipShownByDefault);
	const { layerProps, triggerProps, renderLayer, arrowProps } = useLayer({
		isOpen,
		placement: 'right-center',
		auto: true,
		triggerOffset: 10,
		ResizeObserver
	});

	const [uniqueId] = useState(getUniqueId());
	const [mutableObject] = useState<{
		onMouseLeaveTimeout?: NodeJS.Timeout;
		onMouseEnterTimeout?: NodeJS.Timeout;
		close?: () => void;
	}>({});

	const IconTag = iconMap[type];

	if (tooltip) {
		const tooltipIconProperties: React.SVGProps<SVGSVGElement> = {
			...iconProperties,
			tabIndex: 0,
			'aria-label': 'Tooltip Message.',
			id: `${uniqueId}button`,
			'aria-expanded': isOpen,
			onMouseEnter: () => {
				mutableObject.onMouseEnterTimeout = setTimeout(() => {
					setOpen(true);
				}, 100);
			},
			onMouseLeave: () => {
				clearTimeout(mutableObject.onMouseEnterTimeout);
				mutableObject.onMouseLeaveTimeout = setTimeout(() => {
					setOpen(false);
				}, 500);
			},
			onClick: event => {
				event.stopPropagation();
				setOpen(false);
				setTimeout(() => {
					setOpen(true);
				}, 1);
			},
			onKeyUp: event => {
				if (event.keyCode === 27) {
					setOpen(false);
				} else if (event.keyCode === 13) {
					setOpen(false);
					setTimeout(() => {
						setOpen(true);
					}, 1);
				}
			},
			onFocus: () => {
				setOpen(true);
			},
			onBlur: () => {
				setOpen(false);
			},
			className: classes(
				css.icon,
				className,
				{ [css[`margin-${marginSize}`]]: marginSize },
				iconProperties?.className
			),
			...triggerProps
		};

		return (
			<>
				<IconTag {...iconProperties} {...tooltipIconProperties} />
				{isOpen &&
					renderLayer(
						<div
							{...layerProps}
							className={css.tooltip}
							role='alert'
							id={uniqueId + 'tooltip'}
							onMouseEnter={() => {
								clearTimeout(mutableObject.onMouseLeaveTimeout);
							}}
							onMouseLeave={() => {
								setOpen(false);
							}}
						>
							{preTooltipAccessibilityMessage && (
								<span className='screenReaderText'>{preTooltipAccessibilityMessage}</span>
							)}
							{tooltip}
							{postTooltipAccessibilityMessage && (
								<span className='screenReaderText'>{postTooltipAccessibilityMessage}</span>
							)}

							<Arrow {...arrowProps} backgroundColor='#003594' roundness={1} />
						</div>
					)}
			</>
		);
	}

	return <IconTag {...iconProperties} />;
};
