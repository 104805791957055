export const formatTimestamp = (timestamp: string) => {
	const newDate = new Date(timestamp);
	const twentyFourHoursFormatted = newDate.getHours();
	const minutes = newDate.getMinutes();
	const amOrPm = twentyFourHoursFormatted >= 12 ? 'PM' : 'AM';

	const twelveHourFormatted = twentyFourHoursFormatted % 12 ? twentyFourHoursFormatted % 12 : 12; // if 0 that equals 12
	const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;

	return `${twelveHourFormatted}:${minutesFormatted} ${amOrPm}`;
};
