import React, { useState } from 'react';
import { useAuth } from 'providers/Auth/AuthProvider';
import css from '../CompanyManagementView/CompanyManagementView.module.scss';
import {
	useGetCompanyFileUploads,
	useUploadCompanyFile,
	useUploadCompanyUpdateFile,
	useUploadCompanyDeleteFile,
	ApiFileUploadsResponse
} from 'api/fileUploads';
import { useFileUploadHandler } from 'common/hooks/useFileUploadHandler';
import { Link } from 'components/Link';
import { Panel } from 'components/Panel';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { Spinner } from 'components/Spinner';
import { UploadDropZoneLoading } from 'components/UploadDropZoneLoading';
import { TTableHeader } from 'components/Table';
import { LoadingDelay } from 'components/LoadingDelay';
import { PageFrame } from 'components/PageFrame';
import { Tabs } from 'components/Tabs';
import { TableController } from 'components/Table/TableController';
import { UseQueryResult } from 'react-query';
import { TNavigationItems } from 'common/types/TNavigationItems';
import { UploadDropZone } from 'components/UploadDropZone/UploadDropZone';
import classes from 'classnames';
import { sortArrayByProperty } from 'common/utils/sortArrayByProperty';

type CompanyUploadType = 'addcompany' | 'updatecompany' | 'deletecompany';

export const CompanyManagementView = () => {
	const { isLoading: isAuthLoading } = useAuth();
	const tableBaseRoute = '/admin/companymanagement';

	const [isUploading, setIsUploading] = useState<boolean>(false);

	const { doUpload } = useFileUploadHandler();
	const { upload: addCompanyUpload } = useUploadCompanyFile();
	const { upload: updateCompanyUpload } = useUploadCompanyUpdateFile();
	const { upload: deleteCompanyUpload } = useUploadCompanyDeleteFile();

	const handleFileUpload = (uploadType: CompanyUploadType) => async (droppedFiles: File[]) => {
		setIsUploading(true);
		await doUpload(() => {
			// Only grabbing the first file off of droppedFiles as the dropzone should be enforcing only 1 file
			const file = droppedFiles[0];

			switch (uploadType) {
				case 'addcompany':
					return addCompanyUpload(file);
				case 'updatecompany':
					return updateCompanyUpload(file);
				case 'deletecompany':
					return deleteCompanyUpload(file);
			}
		});
		setIsUploading(false);
	};

	const tabNavigationItems: TNavigationItems = [
		{
			label: 'Add Company',
			location: '/admin/companymanagement/addcompany',
			route: '/addcompany',
			component: () => {
				return (
					<>
						<div className={css.helperTopText}>
							<h2 className={classes('h6', css.boldText, css.marginBottomZero, css.uppercaseText)}>
								getting started
							</h2>
							<p className={classes(css.boldText, css.marginTopZero)}>
								Please note files MUST be uploaded in a specific order (
								<span className={css.italicText}>
									reference your navigation order above or the tips below
								</span>
								):
							</p>
							<ol className={css.paddingLeft}>
								{/* #1 Sales Rep */}
								<li>
									Ensure your <span className={css.boldText}>Sales Representative information</span> has
									been added <span className={css.boldText}>before your Companies</span>
									<ol type='a' className={css.marginTopZero}>
										<li className={css.italicText}>Please note Sales Representative can be empty</li>
									</ol>
								</li>
								{/* #2 Product Codes */}
								<li>
									Ensure your <span className={css.boldText}>Product Codes</span> are uploaded before
									your <span className={css.boldText}>Products</span>
									<ol type='a' className={css.marginTopZero}>
										<li className={css.italicText}>
											Please note all your products MUST have a product code to be valid
										</li>
									</ol>
								</li>
								{/* #3 Company */}
								<li>
									Ensure your <span className={css.boldText}>Company & Product Code</span> are uploaded
									before your <span className={css.boldText}>Company Pricing</span> (includes Customer
									discounts)
									<ol type='a' className={css.marginTopZero}>
										<li className={css.italicText}>
											Please note you MUST have both before creating a discount
										</li>
									</ol>
								</li>
							</ol>
						</div>
						<div className={css.tabContentHeader}>
							<p>This file is for adding new companies to the system. Add your upload here.</p>
							<Link
								to={`${process.env.PUBLIC_URL}/files/CompanyAddUpdate.csv`}
								external
								download={'CompanyAddUpdate.csv'}
								type={'primary'}
							>
								Download Add Template
							</Link>
						</div>
						{isUploading ? (
							<UploadDropZoneLoading />
						) : (
							<UploadDropZone
								maxFiles={1}
								maxFileMegabytes={256}
								allowedFileCount={1}
								acceptFileTypes={{ 'text/*': ['.csv'] }}
								onDropSuccess={handleFileUpload('addcompany')}
							/>
						)}
					</>
				);
			}
		},
		{
			label: 'Delete Company',
			location: '/admin/companymanagement/deletecompany',
			route: '/deletecompany',
			component: () => {
				return (
					<>
						<div className={css.tabContentHeader}>
							<p>This file is for the company IDs you wish to delete. Add your upload here.</p>
							<Link
								to={`${process.env.PUBLIC_URL}/files/CompanyDelete.csv`}
								external
								download={'CompanyDelete.csv'}
								type={'primary'}
							>
								Download Delete Template
							</Link>
						</div>
						{isUploading ? (
							<UploadDropZoneLoading />
						) : (
							<UploadDropZone
								maxFiles={1}
								maxFileMegabytes={256}
								allowedFileCount={1}
								acceptFileTypes={{ 'text/*': ['.csv'] }}
								onDropSuccess={handleFileUpload('deletecompany')}
							/>
						)}
					</>
				);
			}
		},
		{
			label: 'Update Company',
			location: '/admin/companymanagement/updatecompany',
			route: '/updatecompany',
			component: () => {
				return (
					<>
						<div className={css.tabContentHeader}>
							<p>This file is for companies you wish to update. Add your upload here.</p>
							<Link
								to={`${process.env.PUBLIC_URL}/files/CompanyAddUpdate.csv`}
								external
								download={'CompanyAddUpdate.csv'}
								type={'primary'}
							>
								Download Update Template
							</Link>
						</div>
						{isUploading ? (
							<UploadDropZoneLoading />
						) : (
							<UploadDropZone
								maxFiles={1}
								maxFileMegabytes={256}
								allowedFileCount={1}
								acceptFileTypes={{ 'text/*': ['.csv'] }}
								onDropSuccess={handleFileUpload('updatecompany')}
							/>
						)}
					</>
				);
			}
		}
	];

	const useQuery = (): UseQueryResult => {
		return useGetCompanyFileUploads();
	};

	const headers: TTableHeader[] = [
		{
			label: 'File',
			property: 'fileName'
		},
		{
			label: 'Upload Type',
			property: 'uploadType'
		},
		{
			label: 'Date Uploaded',
			property: 'createdAt'
		},
		{
			label: 'Uploaded By',
			property: 'createdBy'
		}
	];

	const dataSelector = (data: ApiFileUploadsResponse) => {
		return sortArrayByProperty(data, 'createdAt', 'desc');
	};

	if (isAuthLoading) {
		return (
			<LoadingDelay>
				{' '}
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	return (
		<>
			<PageFrame
				title={'Company Management'}
				description={
					'There are 3 different files to control all your company datasets. Make sure you upload one at a time as you require.'
				}
			>
				<Container>
					<Tabs navigationItems={tabNavigationItems} />
				</Container>
				<Container>
					<Section>
						<Panel title={'Upload history'}>
							<TableController
								headers={headers}
								baseRoute={tableBaseRoute}
								itemsPerPage={1000000}
								isUrlUpdated={false}
								useQuery={useQuery}
								dataSelector={dataSelector}
								emptyMessage={'No company upload history'}
								hasSearch={false}
							/>
						</Panel>
					</Section>
				</Container>
			</PageFrame>
		</>
	);
};
