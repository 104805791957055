import { pluck, props } from 'ramda';
import { TTableHeader } from 'components/Table';

// Searches the defined columns for a single table row
// Returns true if the value was found, false otherwise
export const searchTableColumns = (searchTerm: string, rowData: any, headers: TTableHeader[]) => {
	// get the field names used in the table, and grab those values
	// then join the values of those fields
	// finally attempt to match the search string
	return props(pluck('property', headers), rowData)
		.join('|')
		.toLowerCase()
		.includes(searchTerm.toLowerCase());
};
