import React from 'react';
import { useAuth } from 'providers/Auth/AuthProvider';
import { useNavigate } from 'react-router';
import { useAddUser } from 'api/user';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { getUniqueId } from 'common/utils/getUniqueId';
import { Button } from 'components/Button';
import { Alert } from 'components/Alert';
import { Panel } from 'components/Panel';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { Spinner } from 'components/Spinner';
import { LoadingDelay } from 'components/LoadingDelay';
import { PageFrame } from 'components/PageFrame';
import { GridContainer } from 'components/Grid/GridContainer/GridContainer';
import { GridItem } from 'components/Grid/GridItem/GridItem';
import { Input } from 'components/Form/Input/Input';
import { FormFooter } from 'components/Form/FormFooter/FormFooter';
import { Select } from 'components/Form/Select/Select';
import { userRoleIdMapping } from 'common/utils/userRoleMapping';

export const UserAddManagementView = () => {
	const { isLoading: isAuthLoading } = useAuth();
	const navigate = useNavigate();
	const { mutateAsync: addUser, error } = useAddUser();

	if (isAuthLoading) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	return (
		<PageFrame
			title={`Add New User`}
			backLocation={{ to: '/admin/usermanagement', label: 'Back to all users' }}
		>
			<Container>
				<Section>
					<Panel title={'Account Details'}>
						<Formik
							initialValues={{
								firstName: '',
								lastName: '',
								email: '',
								role: userRoleIdMapping('Administrator'),
								companyAccountNumber: ''
							}}
							validateOnChange={false}
							validateOnBlur={false}
							onSubmit={async (data, { setSubmitting }) => {
								setSubmitting(true);
								const { role, ...remainingData } = data;
								try {
									await addUser({
										...remainingData,
										verifyEmail: true,
										roleId: role,
										username: remainingData.email,
										password: getUniqueId()
									});
									navigate('/admin/usermanagement', {
										state: {
											bannerSuccess: {
												name: `${data.firstName} ${data.lastName}`,
												message: 'was successfully added.'
											}
										}
									});
								} catch {
									setSubmitting(false);
								}
							}}
							validationSchema={yup.object().shape({
								firstName: yup.string().required('First name is required'),
								lastName: yup.string().required('Last name is required'),
								email: yup.string().email().required('Email is required'),
								role: yup.string().required('Role is required'),
								companyAccountNumber: yup.string().when('role', {
									is: (role: string) => role === userRoleIdMapping('Customer'),
									then: yup.string().required('Customer ship to number is required')
								})
							})}
						>
							{props => {
								return (
									<>
										{error && !props.isSubmitting && (
											<Alert type='danger-light'>
												There was an error creating the user, please try again.
											</Alert>
										)}
										<Form>
											<GridContainer columnAmount='2'>
												<GridItem>
													<Input
														formikProps={props}
														label={'First Name'}
														name={'firstName'}
														placeholder={'Enter the users first name'}
													></Input>
												</GridItem>
												<GridItem>
													<Input
														formikProps={props}
														label={'Last Name'}
														name={'lastName'}
														placeholder={'Enter the users last name'}
													></Input>
												</GridItem>
												<GridItem>
													<Input
														formikProps={props}
														label={'Email'}
														name={'email'}
														placeholder={'Enter the users email'}
													></Input>
												</GridItem>
												<GridItem>
													<Select label={'Role'} name='role' formikProps={props}>
														<option value={userRoleIdMapping('Administrator')}>Administrator</option>
														<option value={userRoleIdMapping('Customer')}>Customer</option>
														<option value={userRoleIdMapping('Customer Service Employee')}>
															Customer Service Employee
														</option>
													</Select>
												</GridItem>
												{props.values.role === userRoleIdMapping('Customer') && (
													<GridItem>
														<Input
															formikProps={props}
															label={'Customer Ship To Number'}
															name={'companyAccountNumber'}
															placeholder={"Enter the user's company customer ship to number"}
														></Input>
													</GridItem>
												)}
											</GridContainer>
											<FormFooter>
												<Button type='primary' htmlType='submit' isLoading={props.isSubmitting}>
													Add user
												</Button>
											</FormFooter>
										</Form>
									</>
								);
							}}
						</Formik>
					</Panel>
				</Section>
			</Container>
		</PageFrame>
	);
};
