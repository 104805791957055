import React from 'react';
import css from './NoImage.module.scss';
import classes from 'classnames';
import { Icon } from 'components/Icon/Icon';

interface INoImage {
	size?: 'small' | 'medium' | 'large' | 'largest';
}

export const NoImage: React.FC<INoImage> = props => {
	const { size = 'largest' } = props;
	return (
		<div className={classes(css.noImage, css[size])}>
			<Icon type={'Camera'} className={css.cameraIcon} size={size} color={'gray'} />
			<span>
				<strong>Image</strong>
				coming soon
			</span>
		</div>
	);
};
