import React, { useEffect, Fragment, useState } from 'react';
import css from './TemplateSelection.module.scss';
import classnames from 'classnames';
import { Formik, Form, useFormikContext } from 'formik';
import * as yup from 'yup';
import { CreateOpeningInputRadio } from '../CreateOpeningInputRadio';
import {
	TemplateQuestion,
	Answer,
	OpeningTemplateQuestions,
	TemplateSelectionOutcome,
	OpeningQuestions
} from '../common/types';
import {
	questionsLength,
	isConditionalTruthy,
	formikInitialValuesFromSetupObject,
	createFormSetupObject,
	makeKey,
	separateTemplateSelectionOutcomeFromInlineQuestions,
	separateInlineQuestionsFromEndingQuestions
} from '../common/utilities';
import { isChecked } from 'common/utils/isChecked';
import { useCreateOpening } from 'providers/CreateOpening';
import { CreateOpeningInputRadioWrapper } from '../CreateOpeningInputRadioWrapper/CreateOpeningInputRadioWrapper';
import { useGetOpeningByTemplateObj } from 'api/opening';
import { Icon } from 'components/Icon';
import { usePrevious } from 'common/hooks/usePrevious';
import { CreateOpeningDynamicQuestion } from '../CreateOpeningDynamicQuestion/CreateOpeningDynamicQuestion';

const isFormFieldVisible = (
	source: TemplateQuestion | Answer,
	currentOpeningStep: number,
	formValues: TemplateSelectionOutcome,
	questionKey: string,
	openingTemplateQuestions: OpeningTemplateQuestions
): boolean => {
	// Need to get a copy of the current known array based on the current values
	const currentArray = openingTemplateQuestions.filter(question =>
		isConditionalTruthy(question.conditional, formValues)
	);
	// turn that array of objects into an array based on the property `key`
	const currentArrayMappedKeys = currentArray.map(question => question.key);
	// what index does the current question
	const currentArrayPositionByKey = currentArrayMappedKeys.indexOf(questionKey) + 1;

	if (currentArrayPositionByKey !== currentOpeningStep) {
		return false;
	}

	return isConditionalTruthy(source.conditional, formValues);
};

// You can't directly update a custom provider from within the form render loop so this watcher component exists to map Formik Context to the Create Opening Provider for this wizard step

const TemplateSelectionFormWatcher: React.FC<{
	templateSelectionQuestionIndex: number;
	previousTemplateSelectionQuestionIndex: number;
	setCurrentStepInlineQuestions: React.Dispatch<React.SetStateAction<OpeningQuestions>>;
	setCurrentStepValidators: React.Dispatch<
		React.SetStateAction<{
			[key: string]: any;
		}>
	>;
}> = props => {
	const {
		templateSelectionQuestionIndex,
		previousTemplateSelectionQuestionIndex,
		setCurrentStepInlineQuestions,
		setCurrentStepValidators
	} = props;

	const {
		openingTemplateQuestions,
		setTemplateSelectionConditionalStepTotal,
		setIsStepFormValid,
		setStepFormProps
	} = useCreateOpening();
	const { ...stepFormProps } = useFormikContext<TemplateSelectionOutcome>();
	const { values, isValid, setFieldValue, setTouched } = stepFormProps;

	useEffect(() => {
		setStepFormProps(stepFormProps);
	}, []); // eslint-disable-line

	// value clearing watcher, clear each previous selection on step on back
	useEffect(() => {
		if (previousTemplateSelectionQuestionIndex > templateSelectionQuestionIndex) {
			const openingTemplateQuestionKeys: string[] = openingTemplateQuestions.map(
				question => question.key
			);
			const openingTemplateQuestionsToClearByKey = openingTemplateQuestionKeys.filter(
				(question, index) => index > templateSelectionQuestionIndex
			);
			openingTemplateQuestionsToClearByKey.forEach(questionKey => {
				if (questionKey === 'pullHandle') {
					setFieldValue('pullHandle', 'none'); // important to set none as default on pullHandle
				} else {
					setFieldValue(questionKey, ''); // other options have empty string
				}
			});
		}
	}, [
		previousTemplateSelectionQuestionIndex,
		templateSelectionQuestionIndex,
		setFieldValue,
		openingTemplateQuestions
	]);

	// validation watcher
	useEffect(() => {
		setIsStepFormValid(isValid);
	}, [isValid, setIsStepFormValid, values]);

	useEffect(() => {
		const currentQuestion = openingTemplateQuestions[templateSelectionQuestionIndex];
		const currentQuestionFormValue = values[currentQuestion.key as keyof TemplateSelectionOutcome];

		if (currentQuestionFormValue && currentQuestionFormValue !== '') {
			setIsStepFormValid(true);
		}
	}, [values, templateSelectionQuestionIndex, openingTemplateQuestions, setIsStepFormValid]);

	useEffect(() => {
		// update the create opening provider with the count of the remaining conditional steps
		setTemplateSelectionConditionalStepTotal(questionsLength(openingTemplateQuestions, values));
	}, [values, openingTemplateQuestions, setTemplateSelectionConditionalStepTotal]);

	// Keep the current template selection step validators and displayed inline questions in sync with formik values
	useEffect(() => {
		setCurrentStepInlineQuestions(
			collectStepInlineQuestions(openingTemplateQuestions[templateSelectionQuestionIndex], values)
		);

		const validators = combineValidators(
			openingTemplateQuestions,
			templateSelectionQuestionIndex,
			values
		);

		setCurrentStepValidators(validators);

		// validateForm();
		setTouched({});
	}, [
		values,
		openingTemplateQuestions,
		templateSelectionQuestionIndex,
		setCurrentStepInlineQuestions,
		setCurrentStepValidators,
		setTouched
	]);

	return null;
};

const combineValidators = (
	openingTemplateQuestions: OpeningTemplateQuestions,
	templateSelectionQuestionIndex: number,
	formikValues?: TemplateSelectionOutcome
): any => {
	const currentStep = openingTemplateQuestions[templateSelectionQuestionIndex];
	// Base validator for the wizard step
	const stepBaseValidators = currentStep.validator;

	let inlineQuestionValidators = {};
	// Get all validators for currentStepsQuestions
	const currentInlineQuestions = collectStepInlineQuestions(currentStep, formikValues);

	// Get all validators for inline non conditional questions
	if (currentInlineQuestions) {
		inlineQuestionValidators = currentInlineQuestions.reduce((inlineQuestionValidators, question) => {
			inlineQuestionValidators[makeKey(question)] = question.validator[makeKey(question)];
			return inlineQuestionValidators;
		}, {} as { [key: string]: any });
	}

	// Get all validators for inline conditional questions

	return {
		...stepBaseValidators,
		...inlineQuestionValidators
	};
};

const collectAllInlineQuestions = (
	openingTemplateQuestions: OpeningTemplateQuestions
): OpeningQuestions =>
	openingTemplateQuestions.reduce((inlineQuestions, templateQuestion) => {
		if (templateQuestion.areQuestionsInline && templateQuestion.questions) {
			if (templateQuestion.areQuestionsInline) {
				templateQuestion.questions.forEach(question => {
					inlineQuestions.push({ ...question });
				});
			}
		}

		templateQuestion.values.forEach(value => {
			if (value.areQuestionsInline && value.questions) {
				if (value.areQuestionsInline) {
					value.questions.forEach(question => {
						// Any questions attached to a value is implied conditional so attach a parentKey
						inlineQuestions.push({ ...question, parentKey: value.key });
					});
				}
			}
		});

		return inlineQuestions;
	}, [] as OpeningQuestions);

// Collect all inline questions for one step and if you pass in formik values it will limit the the return to only ones that should be displayed
const collectStepInlineQuestions = (
	openingTemplateQuestion: TemplateQuestion,
	formikValues?: TemplateSelectionOutcome
): OpeningQuestions => {
	const stepInlineQuestions = collectAllInlineQuestions([openingTemplateQuestion]);
	if (!formikValues) {
		return stepInlineQuestions;
	} else {
		return stepInlineQuestions.filter(
			question =>
				// @ts-ignore
				!question.parentKey || question.parentKey === formikValues[`${openingTemplateQuestion.key}`]
		);
	}
};

export interface TemplateSelectionProps {}

export const TemplateSelection: React.FC<TemplateSelectionProps> = props => {
	const {
		templateSelectionOutcome,
		templateApplicationOutcome,
		setTemplateSelectionOutcome,
		setTemplateOutcomeResponse,
		setTemplateQuestionsOutcome,
		templateQuestionsOutcome,
		openingTemplateQuestions,
		templateSelectionStep,
		showStepFormError
	} = useCreateOpening();

	const [templateSelectionQuestionIndex, setTemplateSelectionQuestionIndex] = useState<number>(0);

	const [currentStepInlineQuestions, setCurrentStepInlineQuestions] = useState<OpeningQuestions>();

	const [currentStepValidators, setCurrentStepValidators] = useState<{ [key: string]: any }>(
		combineValidators(openingTemplateQuestions, templateSelectionQuestionIndex)
	);

	const previousTemplateSelectionQuestionIndex = usePrevious(templateSelectionQuestionIndex);

	const { mutateAsync: getOpeningByTemplate } = useGetOpeningByTemplateObj();

	return (
		<Formik
			initialValues={{
				...templateSelectionOutcome,
				...formikInitialValuesFromSetupObject(
					createFormSetupObject(
						collectAllInlineQuestions(openingTemplateQuestions),
						separateInlineQuestionsFromEndingQuestions(
							openingTemplateQuestions,
							templateQuestionsOutcome
						).inlineQuestions
					)
				)
			}}
			validateOnChange={true}
			enableReinitialize={true}
			validateOnMount={true}
			validateOnBlur={false}
			validationSchema={yup.object().shape(currentStepValidators)}
			onSubmit={async (data, { setSubmitting }) => {
				setSubmitting(true);
				const splitQuestions = separateTemplateSelectionOutcomeFromInlineQuestions(data);
				setTemplateQuestionsOutcome({
					...templateQuestionsOutcome,
					...splitQuestions.inlineQuestions
				});
				setTemplateSelectionOutcome(splitQuestions.templateSelectionOutcome);
				const mergeStepsDataForOpeningRequest = {
					application: templateApplicationOutcome.application,
					...splitQuestions.templateSelectionOutcome
				};
				const response = await getOpeningByTemplate(mergeStepsDataForOpeningRequest);
				setTemplateOutcomeResponse(response);
				setSubmitting(false);
			}}
		>
			{props => {
				return (
					<>
						<Form>
							<>
								{openingTemplateQuestions.map((question, questionIndex) => {
									const isFieldVisible = isFormFieldVisible(
										question,
										templateSelectionStep,
										props.values,
										question.key,
										openingTemplateQuestions
									);
									if (isFieldVisible) {
										// setTimeout Hack here used in formik docs to prevent react warning
										setTimeout(() => {
											setTemplateSelectionQuestionIndex(questionIndex);
										}, 0);
									}
									return (
										<Fragment key={question.key}>
											{isFieldVisible && (
												<>
													<div className={css.questionStep}>
														<div className={css.stepHeader}>
															<h1 className={classnames(css.stepTitle, 'h2')}>
																{question.stepTitle} <span className={css.required}>*</span>
															</h1>
															{question.stepDescription && (
																<p className={css.stepDescription}>{question.stepDescription}</p>
															)}
														</div>
														<div className={css.stepQuestion}>
															<h2 className={'h5'}>
																{question.label}
																{question.labelTooltip && (
																	<>
																		{''}
																		<Icon type={'Info'} color={'blue'} tooltip={question.labelTooltip} />
																	</>
																)}
																<span></span>
															</h2>
															{question.labelDescription && (
																<p className={css.labelDescription}>{question.labelDescription}</p>
															)}
														</div>
													</div>

													<CreateOpeningInputRadioWrapper
														formikProps={props}
														hasError={showStepFormError}
														name={question.key}
													>
														{question.values.map(answer => (
															<Fragment key={`${question.key}-${answer.key}`}>
																{isConditionalTruthy(answer.conditional, props.values) && (
																	<CreateOpeningInputRadio
																		className={classnames(css.openingValue, {
																			[css.openingValueSmall]: question.values.length > 4
																		})}
																		name={question.key}
																		label={answer.label}
																		labelTooltip={answer.labelTooltip}
																		value={answer.key}
																		formikProps={props}
																		icon={answer.icon}
																		checked={isChecked<TemplateSelectionOutcome>(
																			props.values,
																			question.key,
																			answer.key
																		)}
																	/>
																)}
															</Fragment>
														))}
													</CreateOpeningInputRadioWrapper>

													{currentStepInlineQuestions?.length > 0 && (
														<>
															<h3 className={classnames('h4', css.inlineQuestionTitle)}>
																We need to collect some additional details:
															</h3>{' '}
															<CreateOpeningDynamicQuestion
																openingQuestions={currentStepInlineQuestions}
																formikProps={props}
																showViewingCountInLabel={true}
															/>
														</>
													)}
												</>
											)}
										</Fragment>
									);
								})}
								<TemplateSelectionFormWatcher
									templateSelectionQuestionIndex={templateSelectionQuestionIndex}
									previousTemplateSelectionQuestionIndex={previousTemplateSelectionQuestionIndex}
									setCurrentStepInlineQuestions={setCurrentStepInlineQuestions}
									setCurrentStepValidators={setCurrentStepValidators}
								/>
							</>
						</Form>
					</>
				);
			}}
		</Formik>
	);
};
