import React, { PropsWithChildren } from 'react';
import css from './Section.module.scss';
import classes from 'classnames';
import { Icon } from 'components/Icon';

export interface ISectionProps {
	title?: string;
	className?: string;
	titleClassName?: string;
	titleTooltip?: string;
	tooltipShownByDefault?: boolean;
	description?: React.ReactNode | string;
	hasPaddingTop?: boolean;
}

export const Section: React.FC<PropsWithChildren<ISectionProps>> = props => {
	const {
		title,
		children,
		className,
		titleClassName,
		titleTooltip,
		tooltipShownByDefault,
		description,
		hasPaddingTop = true
	} = props;
	return (
		<div className={classes({ [css.hasPaddingTop]: hasPaddingTop }, className)}>
			{title && (
				<div className={css.titleContainer}>
					<h2 className={classes(titleClassName, 'h5', css.title)}>{title}</h2>
					&nbsp;
					{titleTooltip && (
						<Icon
							tooltip={titleTooltip}
							className={css.titleIcon}
							tooltipShownByDefault={tooltipShownByDefault}
							marginSize='left-small'
							type='Info'
							size='small'
							color='blue'
						/>
					)}
				</div>
			)}

			{description && (
				<p className={css.description}>
					{typeof description === 'string' ? description : <>{description}</>}
				</p>
			)}
			<div className={classes(css.content, { [css.hasTitleOrDescription]: title || description })}>
				{children}
			</div>
		</div>
	);
};
