// Cloned from InputRadio component
import React, { useState } from 'react';
import { TInput } from 'common/types/TInput';
import classes from 'classnames';
import { RemoveProp } from 'common/types/TypeHelpers';
import { getUniqueId } from 'common/utils/getUniqueId';
import css from './CreateOpeningInputRadio.module.scss';
import { FormikProps } from 'formik';
import { removePropertiesFromObjects } from 'common/utils/removePropertiesFromObjects';
import { replacePropsWithFormikProps } from 'common/utils/replacePropsWithFormikProps';
import { CreateOpeningIcon, TCreateOpeningIconTypes } from 'components/CreateOpeningIcon';
import { Icon } from 'components/Icon';

export type ICreateOpeningInputRadio = {
	label: string;
	labelTooltip?: string;
	formikProps?: FormikProps<any>;
	className?: string;
	icon?: TCreateOpeningIconTypes;
} & RemoveProp<TInput, 'type'>;

export const CreateOpeningInputRadio: React.FC<ICreateOpeningInputRadio> = props => {
	const { className, label, id, formikProps, name, value, icon, labelTooltip } = props;
	const [inputId] = useState(id ? id : getUniqueId());

	const inputProps: any = removePropertiesFromObjects(
		['label', 'formikProps', 'className', 'labelTooltip'],
		props
	);

	const inputPropsWithFormik = replacePropsWithFormikProps(inputProps, formikProps, name);

	return (
		<div className={classes(css.radio, className)}>
			<input
				{...inputPropsWithFormik}
				id={inputId}
				type='radio'
				value={value}
				className={classes(className, css.input, { [css.labelHasIcon]: icon })}
			/>
			<label htmlFor={inputId} className={css.label}>
				{icon && <CreateOpeningIcon type={icon} />}
				{label}
			</label>
			{labelTooltip && (
				<Icon type={'Info'} color={'blue'} className={css.tooltipIcon} tooltip={labelTooltip} />
			)}
		</div>
	);
};
