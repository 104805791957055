import React, { useState } from 'react';
import { TInput } from 'common/types/TInput';
import classes from 'classnames';
import { RemoveProp } from 'common/types/TypeHelpers';
import { getUniqueId } from 'common/utils/getUniqueId';
import css from './InputRadio.module.scss';
import { FormikProps } from 'formik';
import { removePropertiesFromObjects } from 'common/utils/removePropertiesFromObjects';
import { replacePropsWithFormikProps } from 'common/utils/replacePropsWithFormikProps';

export type IInputRadio = {
	label: string;
	formikProps?: FormikProps<any>;
	className?: string;
} & RemoveProp<TInput, 'type'>;

export const InputRadio: React.FC<IInputRadio> = props => {
	const { className, label, id, formikProps, name, value, required } = props;
	const [inputId] = useState(id ? id : getUniqueId());

	const inputProps: any = removePropertiesFromObjects(['label', 'formikProps', 'className'], props);

	const inputPropsWithFormik = replacePropsWithFormikProps(inputProps, formikProps, name);

	return (
		<div className={classes(css.checkbox, className)}>
			<input
				{...inputPropsWithFormik}
				id={inputId}
				type='radio'
				value={value}
				className={classes(className, css.input)}
			/>
			<label htmlFor={inputId} className={classes(css.label, { [css.isRequired]: required })}>
				{label}
			</label>
		</div>
	);
};
