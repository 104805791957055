import React from 'react';
import css from './QuoteDetails.module.scss';
import { Order } from 'api/order';
import { GridContainer } from 'components/Grid/GridContainer';
import { GridItem } from 'components/Grid/GridItem';
import { Icon } from 'components/Icon';
import { Panel } from 'components/Panel';
import { Section } from 'components/Section';
import { formatDate } from 'common/utils/formatDate';
import { formatOrderStatus } from 'common/utils/formatOrderStatus';

interface QuoteDetailsProps {
	orderData: Order;
}

export const QuoteDetails: React.FC<QuoteDetailsProps> = props => {
	const { orderData } = props;
	return (
		<Section hasPaddingTop={false}>
			<Panel title={'Order Details'}>
				<GridContainer columnAmount='3'>
					{/* Confirmation Number */}
					<GridItem>
						<div className={css.flexContainer}>
							<p className={css.flexContainer}>Confirmation Number </p>
							<Icon
								tooltip={'This confirmation number can help you identify your order later.'}
								tooltipShownByDefault={false}
								marginSize='left-small'
								type='Info'
								size='small'
								color='blue'
							/>
						</div>

						<div>{orderData?.orderConfirmationId}</div>
					</GridItem>
					{/* Order Date */}
					<GridItem>
						<p className={css.flexContainer}>Order Date</p>
						{orderData?.submittedOn !== null ? (
							<div>{formatDate(orderData?.submittedOn, true, true)}</div>
						) : (
							<div>unknown</div>
						)}
					</GridItem>
					{/* Status Details */}
					<GridItem>
						<p className={css.flexContainer}>Status Details</p>
						<div>{formatOrderStatus(orderData?.status)}</div>
					</GridItem>
				</GridContainer>
				<GridContainer columnAmount='3'>
					{/* Shipping Address */}
					<GridItem>
						<p className={css.flexContainer}>Shipping Address </p>
						<div>{orderData?.shipToAddressLine1}</div>
						<div>{orderData?.shipToAddressLine2}</div>
						<div>
							{orderData?.shipToCity}
							{', '}
							{orderData?.shipToState}
						</div>
						<div>{orderData?.shipToZipCode}</div>
					</GridItem>
					{/* PO Number */}
					<GridItem>
						<div className={css.flexContainer}>
							<p className={css.flexContainer}>PO Number </p>
							<Icon
								tooltip={'This PO number can help you identify your order later.'}
								tooltipShownByDefault={false}
								marginSize='left-small'
								type='Info'
								size='small'
								color='blue'
							/>
						</div>

						<div>{orderData?.poNumber}</div>
					</GridItem>
					{/* Top Comments */}
					<GridItem>
						<div className={css.flexContainer}>
							<p className={css.flexContainer}>Top Comments </p>
							<Icon
								tooltip={
									'Top comments allow you to add project specific labels to your order to make delivery easier.' +
									' These labels are printed and placed on the outside of the box when shipping.'
								}
								tooltipShownByDefault={false}
								marginSize='left-small'
								type='Info'
								size='small'
								color='blue'
							/>
						</div>

						<div>{orderData?.shippingTopComment}</div>
					</GridItem>
				</GridContainer>
				{/* Shipping Method & Account Number */}
				<GridContainer columnAmount='3' className={css.flexWrapNone}>
					{/* Shipping Method */}
					<GridItem>
						<p className={css.flexContainer}>Shipping Method</p>
						<div>{orderData?.shippingMethod}</div>
					</GridItem>
					{/* Shipping Account Number */}

					{orderData && orderData.status && orderData.shippingMethod === 'COL' && (
						<GridItem>
							<p className={css.flexContainer}>Shipping Account Number </p>
							<div>{orderData?.shippingAccountNumber}</div>
						</GridItem>
					)}
				</GridContainer>
			</Panel>
		</Section>
	);
};
