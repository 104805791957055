import React, { createContext, useContext, useMemo } from 'react';
import Axios, { AxiosInstance } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { config } from 'config/config';

// Provides easy access to a pre-configured axios with an interceptor to handle auth
// Pretty much taken verbatim from: https://cretezy.com/2020/react-query-axios-authentication
export const AxiosContext = createContext<AxiosInstance>(undefined);

const AxiosProvider = ({ children }: React.PropsWithChildren<unknown>) => {
	const { getAccessTokenSilently } = useAuth0();
	const axios = useMemo(() => {
		const axiosClient = Axios.create({
			baseURL: config.reactAppApiHost,
			headers: {
				'Content-Type': 'application/json'
			}
		});

		axiosClient.interceptors.request.use(async config => {
			// Get the token from local storage directly otherwise the auth provider and axios provider depend on each other
			const authToken = await getAccessTokenSilently({});

			if (authToken) {
				config.headers.Authorization = `Bearer ${authToken}`;
			}

			return config;
		});

		return axiosClient;
	}, [getAccessTokenSilently]);

	return <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>;
};

export const useAxios = () => {
	return useContext(AxiosContext);
};

export default AxiosProvider;
