import React, { PropsWithChildren } from 'react';
import css from './GridItem.module.scss';
import classes from 'classnames';

interface IGridItemProps {
	action?: (() => void) | string;
	hasBorder?: boolean;
	className?: string;
	style?: React.CSSProperties;
	hasDesktopSpace?: boolean;
}

export const GridItem: React.FC<PropsWithChildren<IGridItemProps>> = props => {
	const { action, hasBorder, className, children, style, hasDesktopSpace } = props;

	const gridItemClasses = classes(css.gridItem, { [css.border]: hasBorder }, className, {
		[css.hasDesktopSpace]: hasDesktopSpace
	});

	switch (typeof action) {
		case 'function':
			return (
				<button className={gridItemClasses} onClick={action} style={style}>
					{children}
				</button>
			);
		case 'string':
			return (
				<a className={gridItemClasses} href={action} style={style}>
					{children}
				</a>
			);
		default:
			return (
				<div className={gridItemClasses} style={style}>
					{children}
				</div>
			);
	}
};
