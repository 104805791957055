import React, { useState, useEffect } from 'react';
import { NavigateFunction, useNavigate, useLocation } from 'react-router';
import queryString from 'query-string';
import { useAuth } from 'providers/Auth';
import css from './ProductSearchView.module.scss';
import classes from 'classnames';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { Spinner } from 'components/Spinner';
import { LoadingDelay } from 'components/LoadingDelay';
import { PageFrame } from 'components/PageFrame';
import { InputSearch } from 'components/Form/InputSearch/InputSearch';
import { Button } from 'components/Button/Button';
import { Select } from 'components/Form/Select/Select';
import { ProductList } from 'components/Product/ProductList/ProductList';
import { Icon } from 'components/Icon';
import { useWindowWidth } from 'common/hooks/useWindowWidth';
import { ApiProductsRequestParameters, useSearchProduct } from 'api/product';
import { usePrevious } from 'common/hooks/usePrevious';
import { useRoutePageNumber } from 'common/hooks/useRoutePageNumber';
import { urlConstructor } from 'common/utils/urlConstructor';
import { equals } from 'ramda';

const updateProductSearchHistoryRaw =
	(
		currentSearchParams: ApiProductsRequestParameters & { searchString?: string },
		navigate: NavigateFunction,
		baseRoute: string
	) =>
	(
		propertiesToUpdate: Partial<ApiProductsRequestParameters & { searchString?: string }>,
		type: 'push' | 'replace'
	) => {
		const { searchString, ...params } = currentSearchParams;
		const mergedProperties = {
			...params,
			searchString: searchString,
			...propertiesToUpdate
		};

		const { pageNumber, ...remainingMergedProperties } = mergedProperties;
		const propertiesToConstructUrl = Object.keys(remainingMergedProperties).reduce(
			(acc: { [key: string]: string | number }, key) => {
				if (
					//@ts-ignore
					remainingMergedProperties[key] !== null &&
					//@ts-ignore
					remainingMergedProperties[key] !== undefined &&
					//@ts-ignore
					remainingMergedProperties[key] !== ''
				) {
					//@ts-ignore
					acc[key] = remainingMergedProperties[key];
				}
				return acc;
			},
			{}
		);

		const url = urlConstructor(baseRoute + pageNumber, {
			...propertiesToConstructUrl
		});

		if (type === 'push') {
			navigate(url);
		} else {
			navigate(url, {
				replace: true
			});
		}
	};

const useParsedProductionSearchLocation = () => {
	const location = useLocation();
	const parsedLocation = queryString.parse(location.search);

	return parsedLocation;
};

export const ProductSearchView = () => {
	const baseUrl = '/products/search/';
	const { isLoading: isAuthLoading } = useAuth();
	const navigate = useNavigate();
	const width = useWindowWidth();
	const [isVisible, setIsVisible] = useState<boolean>();
	const parsedProductionSearchLocation = useParsedProductionSearchLocation();
	const pageNumber = useRoutePageNumber(`${baseUrl}:pageNumber`);
	const previousPageNumber = usePrevious(pageNumber);
	const [searchParams, setSearchParams] = useState<
		ApiProductsRequestParameters & { searchString?: string }
	>({
		pageNumber: pageNumber,
		...parsedProductionSearchLocation
	});
	const { searchString, ...params } = searchParams;
	const previousSearchParams = usePrevious(searchParams);

	const { data } = useSearchProduct(searchString || '', params);

	const updateProductSearchHistory = updateProductSearchHistoryRaw(searchParams, navigate, baseUrl);

	const setPageParams = (pageNumber: number): void => setSearchParams({ ...searchParams, pageNumber });

	useEffect(() => {
		if (!equals(searchParams, previousSearchParams)) {
			const { searchString: searchStringToSet, ...paramsToSet } = searchParams;
			if (paramsToSet?.pageNumber !== previousSearchParams?.pageNumber) {
				window.scrollTo(0, 0);
				updateProductSearchHistory({ ...paramsToSet, searchString: searchStringToSet }, 'push');
			} else {
				updateProductSearchHistory({ ...paramsToSet, searchString: searchStringToSet }, 'push');
			}
		}
	}, [previousSearchParams, searchParams, updateProductSearchHistory]);

	useEffect(() => {
		if (pageNumber !== previousPageNumber) {
			setSearchParams({
				...searchParams,
				pageNumber: pageNumber
			});
		}
	}, [pageNumber, previousPageNumber, setSearchParams, searchParams]);

	useEffect(() => {
		if (width < 1025) {
			setIsVisible(false);
		} else if (width >= 1025) {
			setIsVisible(true);
		}
	}, [width]);

	const changeSearchString = (value: string) => {
		if (value !== previousSearchParams.searchString) {
			setSearchParams({ ...searchParams, pageNumber: 1, searchString: value });
		}
	};

	const changeSearchParams = (param: string, value: string) => {
		setSearchParams({
			...searchParams,
			pageNumber: 1,
			[param]: value
		});
	};

	if (isAuthLoading) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	return (
		<PageFrame title={'Search Results'} isContentManagedTitle={true}>
			<Section className={css.searchBox}>
				<Container hasPaddingBottom={false}>
					<InputSearch
						name={'search'}
						label={'Search Products'}
						onChange={event => changeSearchString(event.target.value)}
						value={searchParams.searchString || ''}
					/>
				</Container>
			</Section>
			<Section className={css.sectionSearchResults}>
				<Container>
					<h1 className='h3'>Search Results:</h1>
					<div className={css.searchContainer}>
						<div className={css.filterContainer}>
							<button
								aria-expanded={isVisible}
								onClick={() => {
									setIsVisible(!isVisible);
								}}
								className={css.mobileFilterTrigger}
							>
								<span>0 Filters set</span>
								{isVisible ? <Icon type='Minus' /> : <Icon type='Plus' />}
							</button>
							<div
								className={classes(css.filterContent, {
									[css.isVisible]: isVisible
								})}
								aria-hidden={!isVisible}
								role='region'
							>
								<div className={css.filterHeader}>
									<span className={classes('h6', css.filterHeaderText)}>Narrow results</span>
									<Button
										type={'link'}
										className={css.filterHeaderText}
										onClick={() => {
											setSearchParams({
												...searchParams,
												pageNumber: 1,
												systemType: '',
												glassThickness: '',
												railHeight: '',
												railProfile: '',
												panelWidth: '',
												productCode: ''
											});
										}}
									>
										clear
									</Button>
								</div>
								<div className={css.filters}>
									<Select
										label={'Application'}
										name='systemType'
										onChange={event => changeSearchParams('systemType', event.target.value)}
										value={searchParams?.systemType}
									>
										<option value=''>Choose an option</option>
										<option value='Fixed Panel'>Fixed Panel</option>
										<option value='Sliding Panel'>Sliding Panel</option>
									</Select>
									<Select
										label={'Glass thickness'}
										name='glassThickness'
										onChange={event => changeSearchParams('glassThickness', event.target.value)}
										value={searchParams?.glassThickness}
									>
										<option value=''>Choose an option</option>
										<option value='1/2'>1/2 inch</option>
										<option value='3/4'>3/4 inch</option>
										<option value='3/8'>3/8 inch</option>
										<option value='5/8'>5/8 inch</option>
										<option value='9/16'>9/16 inch</option>
										<option value='11/16'>11/16 inch</option>
										<option value='13/16'>13/16 inch</option>
									</Select>
									<Select
										label={'Rail height'}
										name='railHeight'
										onChange={event => changeSearchParams('railHeight', event.target.value)}
										value={searchParams?.railHeight}
									>
										<option value=''>Choose an option</option>
										<option value='2-1/2"'>2-1/2 inch</option>
										<option value='3-5/8"'>3-5/8 inch</option>
										<option value='4"'>4 inch</option>
										<option value='6"'>6 inch</option>
										<option value='10"'>10 inch</option>
										<option value='Stock Rails'>Stock Rails</option>
									</Select>
									<Select
										label={'Profile'}
										name='railProfile'
										onChange={event => changeSearchParams('railProfile', event.target.value)}
										value={searchParams?.railProfile}
									>
										<option value=''>Choose an option</option>
										<option value='TP'>Tapered</option>
										<option value='SQ'>Square</option>
									</Select>
									<Select
										label={'Panel width'}
										name='panelWidth'
										onChange={event => changeSearchParams('panelWidth', event.target.value)}
										value={searchParams?.panelWidth}
									>
										<option value=''>Choose an option</option>
										<option value='20" to 35"'>20" to 35"</option>
										<option value='36" to 47"'>36" to 47"</option>
										<option value='48" to 59"'>48" to 59"</option>
										<option value='60" to 72"'>60" to 72"</option>
									</Select>
									<Select
										label={'Product Code'}
										name='productCode'
										onChange={event => changeSearchParams('productCode', event.target.value)}
										value={searchParams?.productCode}
									>
										<option value=''>Choose an option</option>
										<option value='AGIL'>AGIL</option>
										<option value='ARCF'>ARCF</option>
										<option value='ARCH'>ARCH</option>
										<option value='ARCR'>ARCR</option>
										<option value='BEYD'>BEYD</option>
										<option value='BTAC'>BTAC</option>
										<option value='BTS'>BTS</option>
										<option value='DEUB'>DEUB</option>
										<option value='DRAC'>DRAC</option>
										<option value='DRMN'>DRMN</option>
										<option value='DRS1'>DRS1</option>
										<option value='DRS2'>DRS2</option>
										<option value='DRS3'>DRS3</option>
										<option value='DRS4'>DRS4</option>
										<option value='DRS6'>DRS6</option>
										<option value='DRS8'>DRS8</option>
										<option value='ED'>ED</option>
										<option value='EPFT'>EPFT</option>
										<option value='FLS'>FLS</option>
										<option value='HDAC'>HDAC</option>
										<option value='HDEU'>HDEU</option>
										<option value='HDR'>HDR</option>
										<option value='HDR2'>HDR2</option>
										<option value='HDR8'>HDR8</option>
										<option value='HDUS'>HDUS</option>
										<option value='HSDR'>HSDR</option>
										<option value='HSW'>HSW</option>
										<option value='HS10'>HS10</option>
										<option value='INTD'>INTD</option>
										<option value='LMPV'>LMPV</option>
										<option value='LOOP'>LOOP</option>
										<option value='MAGP'>MAGP</option>
										<option value='MAGS'>MAGS</option>
										<option value='MANT'>MANT</option>
										<option value='MNAM'>MNAM</option>
										<option value='MNCS'>MNCS</option>
										<option value='MVEO'>MVEO</option>
										<option value='NET'>NET</option>
										<option value='OFPF'>OFPF</option>
										<option value='PED'>PED</option>
										<option value='PRST'>PRST</option>
										<option value='RAIL'>RAIL</option>
										<option value='RODN'>RODN</option>
										<option value='RTAC'>RTAC</option>
										<option value='RTS'>RTS</option>
										<option value='SLDG'>SLDG</option>
										<option value='SPDR'>SPDR</option>
										<option value='TNSR'>TNSR</option>
										<option value='UCHN'>UCHN</option>
										<option value='UNPF'>UNPF</option>
										<option value='VISU'>VISU</option>
									</Select>
								</div>
							</div>
						</div>
						<div className={css.resultsContainer}>
							<ProductList
								products={data}
								pageNumber={pageNumber}
								baseRoute={baseUrl}
								setPageNumber={setPageParams}
							/>
						</div>
					</div>
				</Container>
			</Section>
		</PageFrame>
	);
};
