import React, { ButtonHTMLAttributes, useState, useEffect } from 'react';
import css from './Button.module.scss';
import classes from 'classnames';
import { Spinner } from 'components/Spinner';
import { IButtonBaseProps } from 'common/interfaces/IButtonBaseProps';
import { Icon, IIconProps } from 'components/Icon';
import { useLayer, Placement } from 'react-laag';
import ResizeObserver from 'resize-observer-polyfill';
import { getUniqueId } from 'common/utils/getUniqueId';
import { BadgeIcon } from 'components/BadgeIcon';
import { RemoveProp } from 'common/types/TypeHelpers';
import { removePropertiesFromObjects } from 'common/utils/removePropertiesFromObjects';

export type TTooltipPositions = 'top' | 'right' | 'bottom' | 'left';

// Tooltip Props
interface ITooltipProps {
	tooltipPosition?: TTooltipPositions;
	tooltipMessage?: string;
	tooltipShownByDefault?: boolean;
}

export type IButtonProps = IButtonBaseProps & {
	htmlType?: 'button' | 'submit' | 'reset';
} & RemoveProp<
		React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
		'type'
	> &
	ITooltipProps;

const positionMap: { [position in TTooltipPositions]: Placement } = {
	top: 'top-center',
	right: 'right-center',
	bottom: 'bottom-center',
	left: 'left-center'
};

export const Button = React.forwardRef((props: IButtonProps, ref: any) => {
	const {
		type = 'primary',
		size = 'medium',
		children,
		onClick,
		disabled,
		isLoading,
		loadingLabel = 'Loading',
		tooltipMessage,
		tooltipPosition = 'top',
		className,
		tooltipShownByDefault,
		htmlType,
		iconLeft,
		iconRight,
		classNames,
		focusRingColor = 'blue'
	} = props;

	if (!iconLeft && !iconRight && !tooltipMessage && !children) {
		throw new Error(
			'For accessibility you must include the tooltipMessage when there is an icon and no children.'
		);
	}

	const [uniqueId] = useState(getUniqueId());

	const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout>();

	useEffect(() => {
		return () => {
			clearTimeout(hoverTimeout);
		};
	}, [hoverTimeout]);

	const ButtonContents = () => (
		<>
			{isLoading && (
				<Spinner
					size={type === 'link' || type === 'linkSmall' ? 'smallest' : 'small'}
					type={type === 'danger' || type === 'primary' ? 'lighter' : 'darker'}
					ariaHidden={true}
					className={css.spinner}
				/>
			)}
			{isLoading ? (
				loadingLabel
			) : (
				<span className={classes(css.buttonContent, classNames?.buttonContent)}>
					{(() => {
						if (iconLeft) {
							const iconProps = removePropertiesFromObjects(['badgeAmount'], iconLeft) as IIconProps;
							if (iconLeft.badgeAmount) {
								return (
									<BadgeIcon icon={iconProps} context={type} className={css.iconLeft}>
										{iconLeft.badgeAmount < 100 ? iconLeft.badgeAmount : '99+'}
									</BadgeIcon>
								);
							}
							return (
								<Icon
									{...iconProps}
									context={type}
									className={classes(css.iconLeft, iconLeft.className)}
								></Icon>
							);
						}
					})()}
					{children}
					{iconRight && (
						<Icon
							{...iconRight}
							context={type}
							className={classes(css.iconRight, iconRight.className)}
						></Icon>
					)}
				</span>
			)}
			<div className={classes(css.focusRing, css[focusRingColor], classNames?.focusRing)}></div>
		</>
	);

	const buttonClasses = classes(
		css[type],
		css.button,
		className,
		{
			[css.hasIcon]: iconLeft || iconRight,
			[css.hasChildren]: children,
			[css.isLoading]: isLoading
		},
		css[size],
		'button'
	);

	const propsWithRemovedCustomProps = removePropertiesFromObjects(
		[
			'type',
			'size',
			'children',
			'disabled',
			'isLoading',
			'loadingLabel',
			'tooltipMessage',
			'tooltipPosition',
			'tooltipShownByDefault',
			'htmlType',
			'iconLeft',
			'iconRight',
			'classNames',
			'focusRingColor'
		],
		props
	);

	const derivedButtonProps: ButtonHTMLAttributes<HTMLButtonElement> = {
		type: 'button',
		className: buttonClasses,
		onClick,
		disabled: disabled || isLoading,
		'aria-busy': isLoading,
		'aria-labelledby': tooltipMessage && uniqueId
	};

	const [isOpen, setOpen] = React.useState(tooltipShownByDefault);

	const { layerProps, triggerProps, renderLayer } = useLayer({
		isOpen,
		placement: positionMap[tooltipPosition],
		auto: true,
		triggerOffset: 2,
		ResizeObserver
	});

	const buttonProps: ButtonHTMLAttributes<HTMLButtonElement> = {
		...propsWithRemovedCustomProps,
		...derivedButtonProps
	};
	if (tooltipMessage) {
		return (
			<>
				<button
					{...buttonProps}
					{...triggerProps}
					onFocus={() => {
						setOpen(true);
					}}
					onMouseEnter={() => {
						const timeout = setTimeout(() => {
							setOpen(true);
						}, 100);
						setHoverTimeout(timeout);
					}}
					onMouseLeave={() => {
						setOpen(false);
						clearTimeout(hoverTimeout);
					}}
					onClick={event => {
						onClick(event);
						setOpen(false);
					}}
					onBlur={() => {
						setOpen(false);
					}}
					type={htmlType}
				>
					<ButtonContents />
				</button>
				{isOpen &&
					renderLayer(
						<div {...layerProps} className={css.tooltip} role='tooltip' id={uniqueId}>
							{tooltipMessage}
						</div>
					)}
			</>
		);
	}

	return (
		<button {...buttonProps} id={uniqueId} type={htmlType} ref={ref}>
			<ButtonContents />
		</button>
	);
});
