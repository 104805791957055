import React, { useState } from 'react';
import { removePropertiesFromObjects } from 'common/utils/removePropertiesFromObjects';
import { getUniqueId } from 'common/utils/getUniqueId';
import { InputFrame, IInputFrame } from 'components/Form/InputFrame';
import { getProcessedFormikProps } from 'common/utils/getProcessedFromikProps';
import { replacePropsWithFormikProps } from 'common/utils/replacePropsWithFormikProps';
import { TSelect } from 'common/types/TSelect';
import { RemoveProp } from 'common/types/TypeHelpers';
import classes from 'classnames';
import css from './Select.module.scss';
import { Icon } from 'components/Icon';

export type ISelectProps = {
	className?: string;
	labelClass?: string;
} & TSelect &
	RemoveProp<RemoveProp<IInputFrame, 'inputId'>, 'messageId'>;

export const Select: React.FC<ISelectProps> = props => {
	const {
		children,
		label,
		labelClass,
		inputMessage,
		errorMessage,
		className,
		id,
		formikProps,
		name,
		isLabelVisible,
		tooltip,
		required
	} = props;

	const [inputId] = useState(id ? id : getUniqueId());
	const [messageId] = useState(getUniqueId());
	const { isError } = getProcessedFormikProps(formikProps, name, errorMessage, inputMessage);

	const selectProps: any = removePropertiesFromObjects(
		[
			'children',
			'label',
			'inputMessage',
			'errorMessage',
			'errorMessageId',
			'formikProps',
			'tooltip',
			'isLabelVisible'
		],
		props
	);

	const selectPropsWithFormik = replacePropsWithFormikProps<ISelectProps>(
		selectProps,
		formikProps,
		name
	);

	return (
		<InputFrame
			inputId={inputId}
			isLabelVisible={isLabelVisible}
			label={label}
			labelClass={labelClass}
			messageId={messageId}
			inputMessage={inputMessage}
			formikProps={formikProps}
			name={name}
			errorMessage={errorMessage}
			tooltip={tooltip}
			className={className}
			required={required}
		>
			<div className={css.selectContainer}>
				<select
					{...selectPropsWithFormik}
					id={inputId}
					aria-describedby={messageId}
					className={classes(css.select, { [css.error]: isError }, { error: isError })}
				>
					{children}
				</select>
				<Icon type='CaretDown' className={css.icon} color='red' />
			</div>
		</InputFrame>
	);
};
