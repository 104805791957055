import React from 'react';
import { useAuth } from 'providers/Auth/AuthProvider';
import css from './AccountCompanyDetailsView.module.scss';
import classes from 'classnames';
import { Link } from 'components/Link';
import { Panel } from 'components/Panel';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { Spinner } from 'components/Spinner';
import { TTableHeader } from 'components/Table';
import { LoadingDelay } from 'components/LoadingDelay';
import { PageFrame } from 'components/PageFrame';
import { TableController } from 'components/Table/TableController';
import { GridContainer } from 'components/Grid/GridContainer/GridContainer';
import { GridItem } from 'components/Grid/GridItem/GridItem';
import { Detail } from 'components/Detail/Detail';
import { UseQueryResult } from 'react-query';
import { Company, useGetCompanyById } from 'api/company';
import { isUserOfPermission } from 'common/utils/isUserOfPermission';
import { renderBulletList } from 'common/utils/renderBulletList';

export const AccountCompanyDetailsView = () => {
	const { isLoading, getPermissions, getCompanyId } = useAuth();

	const { data: getCompanyData, isLoading: isGetCompanyLoading } = useGetCompanyById(getCompanyId());

	const tableBaseRoute = '/company-discounts';
	const headers: TTableHeader[] = [
		{
			label: 'PC Code',
			property: 'productCategoryId'
		},
		{
			label: 'Discount',
			property: 'discount'
		},
		{
			label: 'Payment Terms',
			property: 'paymentTerms'
		}
	];
	const useQuery = (): UseQueryResult => {
		return useGetCompanyById(getCompanyId());
	};
	const dataSelector = (data: Company) => {
		return data.customerDiscounts;
	};

	if (isLoading || isGetCompanyLoading) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	return (
		<PageFrame title={'Account'}>
			<Section className={css.sectionSearchResults}>
				<Container>
					<div className={css.searchContainer}>
						{/* Side Nav */}
						<div className={css.linksContainer}>
							<div className={css.linksContent} role='region'>
								<div className={css.linksHeader}>
									<span className={classes('h6', css.linksHeaderText)}>Your Account</span>
								</div>
								<div className={css.dropdownList}>
									<li key={1}>
										<Link to={'/accountbasicdetails'} classNames={{ buttonContent: css.buttonContent }}>
											Basic Details
										</Link>
									</li>
									<li key={2}>
										<Link to={'/accountpassword'} classNames={{ buttonContent: css.buttonContent }}>
											Password
										</Link>
									</li>
									{isUserOfPermission(['company:read:own'], getPermissions()) && (
										<li key={3}>
											<Link
												to={'/accountcompanydetails'}
												classNames={{ buttonContent: classes(css.buttonContent, css.active) }}
											>
												Company Details
											</Link>
										</li>
									)}
								</div>
							</div>
						</div>
						<div className={css.resultsContainer}>
							{/* Company Details */}
							<Section hasPaddingTop={false}>
								<Panel title={'Your Company Details'}>
									<GridContainer columnAmount='2'>
										{/* Account Number */}
										<GridItem>
											<Detail title={'Account Number'} type='form'>
												{getCompanyData.accountNumber}
											</Detail>
										</GridItem>
										{/* Name */}
										<GridItem>
											<Detail title={'Name'} type='form'>
												{getCompanyData.name}
											</Detail>
										</GridItem>
										{/* Address */}
										<GridItem>
											<Detail title={'Address'} type='form'>
												{renderBulletList([
													getCompanyData?.addressLine1 ?? '',
													getCompanyData?.addressLine2 ?? '',
													getCompanyData?.addressLine3 ?? '',
													`${getCompanyData?.addressCity ?? ''}, ${getCompanyData?.addressState ?? ''},
														${getCompanyData?.addressZipCode ?? ''}`
												])}
											</Detail>
										</GridItem>
										{/* Rep Name */}
										<GridItem>
											<Detail title={'Rep Name'} type='form'>
												{getCompanyData.salesRepresentative?.salesPersonName}
											</Detail>
										</GridItem>
									</GridContainer>
								</Panel>
							</Section>
							<Section>
								<Panel title={'Multiplier Pricing'}>
									<TableController
										headers={headers}
										baseRoute={tableBaseRoute}
										itemsPerPage={10000}
										useQuery={useQuery}
										isUrlUpdated={false}
										emptyMessage={'No discounts found!'}
										dataSelector={dataSelector}
										hasSearch={false}
									/>
								</Panel>
							</Section>
						</div>
					</div>
				</Container>
			</Section>
		</PageFrame>
	);
};
