import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { handleResponse } from './api';
import { useAxios } from 'providers/Axios/AxiosProvider';

export interface OrderUpload {
	id: string;
	orderId: string;
	fileName: string;
	path: string;
	contentType?: string;
	length?: number;
	isDeleted: boolean;
	createdBy: string;
	createdAt: Date;
	updatedBy?: string;
	updatedAt?: Date;
	deletedBy?: string;
	deletedAt?: Date;
}

// Upload
export const useCreateOrderUpload = (orderId: string) => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	const [isUploading, setIsUploading] = useState(false);

	const upload = async (file: File) => {
		setIsUploading(true);

		const formData = new FormData();
		formData.append('file', file);

		try {
			const response = await axios.post(`order/${orderId}/upload`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});

			setIsUploading(false);

			if (response.status === 200) {
				queryClient.invalidateQueries(['order', orderId]);
				return response.data;
			} else {
				throw new Error('something happened');
			}
		} catch (err: any) {
			throw new Error('something else happened');
		}
	};

	return { isUploading, upload };
};

// Download
export const useDownloadOrderDownload = (orderId: string, orderUploadId: string) => {
	const axios = useAxios();
	return useQuery(['ordersUploads', `${orderId}-${orderUploadId}`], async () => {
		return handleResponse(axios.get(`/order/${orderId}/upload/${orderUploadId}/download`));
	});
};

export const useGetOrderDownload = () => {
	const axios = useAxios();

	return useMutation(async (getDownload: { orderId: string; orderUploadId: string }): Promise<any> => {
		return axios.get(`/order/${getDownload.orderId}/upload/${getDownload.orderUploadId}/download`, {
			responseType: 'blob'
		});
	});
};
