import React, { useEffect } from 'react';
import './App.scss';
import { AppRoutes } from 'routes';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { MessageProvider } from 'components/Message/MessageProvider';
import { ModalProvider } from 'providers/Modal';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClient } from 'config/queryProvider';
import AxiosProvider from 'providers/Axios';
import AuthProvider from 'providers/Auth';

const App: React.FC = () => {
	useEffect(() => {
		setTimeout(() => {
			console.log('backstopjs_ready');
		}, 1000);
	}, []);

	return (
		<ErrorBoundary>
			<BrowserRouter>
				<AuthProvider>
					<AxiosProvider>
						<QueryClientProvider client={queryClient}>
							<MessageProvider>
								<ModalProvider>{AppRoutes}</ModalProvider>
							</MessageProvider>
							<ReactQueryDevtools initialIsOpen={false} />
						</QueryClientProvider>
					</AxiosProvider>
				</AuthProvider>
			</BrowserRouter>
		</ErrorBoundary>
	);
};

export const handleFirstTab = (event: KeyboardEvent) => {
	if (event.keyCode === 9) {
		// the "I am a keyboard user" key
		document.body.classList.add('user-is-tabbing');
		window.removeEventListener('keydown', handleFirstTab);
	}
};

window.addEventListener('keydown', handleFirstTab);

export default App;
