import React, { useState } from 'react';
import { Button } from 'components/Button';
import { copy } from 'common/utils/copy';
import { IIconProps } from 'components/Icon';

interface ICopyButton {
	contentToCopy: string;
	className?: string;
	iconOveride?: Partial<IIconProps>;
}

export const CopyButton: React.FC<ICopyButton> = props => {
	const { contentToCopy, className, iconOveride } = props;
	const [copyTimeout, setCopyTimeout] = useState<NodeJS.Timeout>();
	const copyText = 'Copy';
	const copiedText = 'Copied';
	const iconCopy: IIconProps = {
		type: 'SwapStroke',
		color: 'blue',
		size: 'medium',
		...iconOveride
	};
	const iconCopied: IIconProps = { type: 'CheckSquare', color: 'blue', ...iconOveride };

	const [icon, setIcon] = useState<IIconProps>(iconCopy);

	const [buttonContent, setButtonContent] = useState(copyText);

	const copyContent = (content: string) => {
		copy(content);

		if (copyTimeout) {
			clearTimeout(copyTimeout);
		}

		setIcon(iconCopied);
		setButtonContent(copiedText);

		const copyTimeoutReference = setTimeout(() => {
			setIcon(iconCopy);
			setButtonContent(copyText);
		}, 2000);
		setCopyTimeout(copyTimeoutReference);
	};

	return (
		<Button type='link' className={className} onClick={() => copyContent(contentToCopy)} iconLeft={icon}>
			{buttonContent}
		</Button>
	);
};
