import React from 'react';
import { useAuth } from 'providers/Auth';
import css from './OrderHistoryView.module.scss';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { Spinner } from 'components/Spinner';
import { LoadingDelay } from 'components/LoadingDelay';
import { PageFrame } from 'components/PageFrame';
import { UseQueryResult } from 'react-query';
import { Order, useGetOrderHistoryByCompanyId } from 'api/order';
import { TTableHeader } from 'components/Table/Table';
import { formatDate } from 'common/utils/formatDate';
import { Panel } from 'components/Panel/Panel';
import { TableController } from 'components/Table/TableController';
import { formatAsUSD } from 'common/utils/formatAsUSD';
import { sortArrayByProperty } from 'common/utils/sortArrayByProperty';
import { Link } from 'components/Link';
import { formatOrderStatus } from 'common/utils/formatOrderStatus';

export const OrderHistoryView = () => {
	const { isLoading: isAuthLoading, getCompanyId } = useAuth();
	const tableBaseRoute = '/order-history/';
	const useQuery = (): UseQueryResult => {
		return useGetOrderHistoryByCompanyId(getCompanyId());
	};
	const headers: TTableHeader[] = [
		{
			label: 'Order Name',
			property: 'name'
		},
		{
			label: 'PO Number',
			property: 'poNumber',
			className: css.smallColumn
		},
		{
			label: 'Date Last Updated',
			property: 'updatedAt',
			className: css.smallColumn,
			renderFunction: (data: Order) => (data.updatedAt ? formatDate(data.updatedAt, true, true) : '')
		},
		{
			label: 'Status',
			property: 'status',
			className: css.smallColumn,
			renderFunction: (data: Order) => formatOrderStatus(data.status)
		},
		{
			label: 'Order Total',
			property: 'orderTotal',
			className: css.smallColumn,
			renderFunction: (data: Order) => (data.orderTotal ? formatAsUSD(data.orderTotal, true) : '')
		},
		{
			label: 'View',
			property: 'view',
			renderFunction: (data: Order) => <Link to={`/order-history/order/${data.id}`}>View</Link>
		}
	];

	const dataSelector = (data: Order[]) => {
		return sortArrayByProperty(data, 'submittedOn', 'desc');
	};

	if (isAuthLoading) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}
	return (
		<PageFrame title={'Order History'}>
			<Container>
				<Section>
					<Panel>
						<TableController
							headers={headers}
							baseRoute={tableBaseRoute}
							itemsPerPage={10}
							useQuery={useQuery}
							isUrlUpdated={true}
							hasPagination={true}
							emptyMessage={'No orders found!'}
							dataSelector={dataSelector}
							totalCountSelector={(data: any) => data?.length}
							hasSearch={false}
							tableHandlesPaginatingData={true}
						/>
					</Panel>
				</Section>
			</Container>
		</PageFrame>
	);
};
