import React, { useState, useEffect } from 'react';
import css from './PaginationForm.module.scss';
import classes from 'classnames';
import { Link } from 'react-router-dom';
import { Icon } from 'components/Icon/Icon';
import { Input } from 'components/Form/Input/Input';
import { usePrevious } from 'common/hooks/usePrevious';

interface IPaginationProps {
	className?: string;
	itemAmount: number;
	itemsPerPage?: number;
	currentPage?: any;
	setPageNumber?: (pageNumber: number) => void;
	linkQueryString?: string;
	baseRoute: string;
}

export const PaginationForm: React.FC<IPaginationProps> = props => {
	const {
		className,
		itemAmount,
		itemsPerPage = 10,
		currentPage = 1,
		setPageNumber,
		linkQueryString = '',
		baseRoute
	} = props;

	const totalPages = Math.ceil(itemAmount / itemsPerPage);
	const previousPage = currentPage - 1;
	const nextPage = currentPage + 1;

	const [paginationFormValue, setPaginationFormValue] = useState(currentPage);
	const previousCurrentPage = usePrevious(currentPage);

	// As page number changes from clicking links or submitting new values, keep the paginationFormValue up-to-date as the currentPage number updates
	useEffect(() => {
		if (currentPage !== previousCurrentPage) {
			setPaginationFormValue(currentPage);
		}
	}, [currentPage, previousCurrentPage, setPaginationFormValue]);

	const handlePageFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const goToPage = parseInt(event.target.value);
		setPaginationFormValue(goToPage);
	};

	const handlePaginationFormValueSubmission = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (paginationFormValue > 0 && paginationFormValue <= totalPages) {
			setPageNumber(paginationFormValue);
		}
	};

	if (currentPage > totalPages || totalPages <= 1) {
		return null;
	}

	return (
		<nav className={css.paginationWrapper} role='navigation' aria-label='Pagination Navigation'>
			<div className={classes(css.pagination, className, 'reset')}>
				{previousPage > 0 && (
					<Link
						aria-label={`Go to previous page, page ${previousPage}`}
						to={baseRoute + previousPage + linkQueryString}
						aria-current={currentPage}
						className={css.link}
						onClick={event => {
							event.preventDefault();
							if (setPageNumber && previousPage > 0) {
								setPageNumber(previousPage);
							}
						}}
					>
						<Icon type={'CaretLeft'} color={'blue'} /> Previous
					</Link>
				)}

				<div className={css.paginationFormContainer}>
					<form
						onSubmit={event => handlePaginationFormValueSubmission(event)}
						className={css.paginationForm}
					>
						<Input
							label='Go to page'
							name='goToPage'
							type={'number'}
							min={1}
							max={totalPages}
							value={paginationFormValue}
							isLabelVisible={false}
							className={css.inputFrame}
							onChange={event => handlePageFormChange(event)}
						/>
						<button type={'submit'} className={css.paginationSubmitButton}>
							Go to Page
						</button>
					</form>
					of {totalPages}
				</div>

				{nextPage <= totalPages && (
					<Link
						aria-label={`Go to next page, page ${nextPage}`}
						to={baseRoute + nextPage + linkQueryString}
						aria-current={currentPage}
						className={css.link}
						onClick={event => {
							event.preventDefault();
							if (setPageNumber && nextPage <= totalPages) {
								setPageNumber(nextPage);
							}
						}}
					>
						Next <Icon type={'CaretRight'} color={'blue'} />
					</Link>
				)}
			</div>
		</nav>
	);
};
