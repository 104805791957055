import React from 'react';
import css from './ProductMarketingIcon.module.scss';
import classes from 'classnames';
import { removePropertiesFromObjects } from 'common/utils/removePropertiesFromObjects';

import { ReactComponent as ClosersAndPivots } from 'assets/svg/productCode/closers-and-pivots.svg';
import { ReactComponent as DemountablePartitionSystems } from 'assets/svg/productCode/demountable-partition-systems.svg';
import { ReactComponent as Headers } from 'assets/svg/productCode/headers.svg';
import { ReactComponent as ManualTopHungSlidingDoorSystem } from 'assets/svg/productCode/manual-top-hung-sliding-door-systems.svg';
import { ReactComponent as PatchFittingsHinges } from 'assets/svg/productCode/patch-fittings-hinges.svg';
import { ReactComponent as PopularSystems } from 'assets/svg/productCode/popular-systems.svg';
import { ReactComponent as PullsHandlesLocksets } from 'assets/svg/productCode/pulls-handles-locksets.svg';
import { ReactComponent as RailsAndChannels } from 'assets/svg/productCode/rails-and-channel.svg';
import { ReactComponent as SpecialtyWallSystems } from 'assets/svg/productCode/specialty-wall-systems.svg';
import { ReactComponent as SwingDoorSystems } from 'assets/svg/productCode/swing-door-systems.svg';

export const iconMap = {
	ClosersAndPivots,
	DemountablePartitionSystems,
	Headers,
	ManualTopHungSlidingDoorSystem,
	PatchFittingsHinges,
	PopularSystems,
	PullsHandlesLocksets,
	RailsAndChannels,
	SpecialtyWallSystems,
	SwingDoorSystems
};

export type TProductMarketingIconTypes = keyof typeof iconMap;

export interface IProductMarketingIconProps {
	className?: string;
	type: TProductMarketingIconTypes;
}

export const ProductMarketingIcon: React.FC<IProductMarketingIconProps & React.SVGProps<SVGSVGElement>> =
	props => {
		const { className, type } = props;

		const iconClasses = classes(css.icon, className, css[type]);

		const iconProperties = {
			...removePropertiesFromObjects(['type'], props),
			className: iconClasses,
			role: 'text'
		};
		const IconTag = iconMap[type];

		return <IconTag {...iconProperties} />;
	};
