import { TSortDirections } from 'common/types/TSortDirections';
import { TActiveSort } from 'components/Table/Table';

const getOppositeSortDirection = (sortDirection: TSortDirections): TSortDirections =>
	sortDirection === 'desc' ? 'asc' : 'desc';

export const getUpdatedTableActiveSort = (
	currentActiveSort: TActiveSort,
	eventColumnIndex: number
): TActiveSort => {
	const isSortColumnSame = eventColumnIndex === currentActiveSort.columnIndex;
	const sortDirection = isSortColumnSame
		? getOppositeSortDirection(currentActiveSort.sortDirection)
		: 'desc';
	const columnIndex = eventColumnIndex;
	return {
		sortDirection,
		columnIndex
	};
};
