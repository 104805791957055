import React from 'react';
import { ApiUploadFailedObject } from 'api/fileUploads';
import { CopyButton } from 'components/CopyButton/CopyButton';

import css from './UploadErrorModal.module.scss';

export const UploadErrorModal = ({
	generalErrors,
	rowErrors
}: {
	generalErrors?: ApiUploadFailedObject[];
	rowErrors?: ApiUploadFailedObject[];
}) => {
	const generalContent = generalErrors.reduce((result, error) => {
		return `${result}${error.failReason}\n`;
	}, '');

	const rowContent = rowErrors.reduce((result, error) => {
		return `${result}Row ${error.csvRowNumber}: ${error.failReason}\n`;
	}, '');

	return (
		<div className={css.uploadErrorModal}>
			<div className={css.uploadErrorModalContent}>
				{generalErrors?.length > 0 && <pre>{generalContent}</pre>}
				{rowErrors?.length > 0 && <pre>{rowContent}</pre>}
			</div>
			<div>
				<CopyButton contentToCopy={generalContent + rowContent} />
			</div>
		</div>
	);
};
