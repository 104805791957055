import React, { useEffect } from 'react';
import css from './TemplateQuestions.module.scss';
import classes from 'classnames';
import { Formik, Form, useFormikContext } from 'formik';
import * as yup from 'yup';
import {
	createFormSetupObject,
	formikInitialValuesFromSetupObject,
	separateInlineQuestionsFromEndingQuestions
} from '../common/utilities';
import {
	OpeningQuestions,
	OpeningTemplateQuestions,
	TemplateSelectionOutcome,
	FormSetupObject
} from '../common/types';
import { useCreateOpening } from 'providers/CreateOpening/CreateOpeningProvider';
import { CreateOpeningDynamicQuestion } from '../CreateOpeningDynamicQuestion/CreateOpeningDynamicQuestion';

// Look through the current OpeningTemplateQuestions based on the current TemplateSelectionOutcome and find any questions where the chosen value has additional contextual questions to ask the customer.
const constructValueBasedQuestions = (
	formValues: TemplateSelectionOutcome,
	openingQuestions: OpeningTemplateQuestions
): OpeningQuestions => {
	let constructedQuestions: OpeningQuestions = [];

	for (const templateQuestion in formValues) {
		// @ts-ignore
		if (formValues[templateQuestion] !== '') {
			// @ts-ignore
			const templateQuestionValue = formValues[templateQuestion] as string;
			const questionToValueCheck = openingQuestions.filter(
				question => question.key === templateQuestion
			);

			// There should only ever been one question selected from questionToValueCheck filter
			if (questionToValueCheck.length === 1) {
				const valuesWithQuestions = questionToValueCheck[0].values.filter(
					// Only return the question there are questions and the question is not inline
					value => value.key === templateQuestionValue && value.questions && !value.areQuestionsInline
				);
				for (let i = 0; i < valuesWithQuestions.length; i++) {
					constructedQuestions = constructedQuestions.concat(valuesWithQuestions[i].questions);
				}
			} else {
				// NOTE: right now due to product complexity the fixed config contains a duplicate of the same question. This does currently reduce the ability for these types of questions that are duplicated from having TemplateQuestions attached to them. If you currently are not seeing your question then it might be attached to duplicated option.
			}
		}
	}

	return constructedQuestions;
};

// This function should be used to combine all arrays of questions from the various sources so they can be loaded into Formik and iterated on in the template
const processTemplateQuestions = (
	openingGlobalQuestions: OpeningQuestions,
	formValues: TemplateSelectionOutcome,
	openingQuestions: OpeningTemplateQuestions
): OpeningQuestions => {
	// For now this is a simple merge two arrays together but can change as needed
	const valueQuestions = constructValueBasedQuestions(formValues, openingQuestions);
	return valueQuestions.concat(openingGlobalQuestions);
};

// You can't directly update a custom provider from within the form render loop so this watcher component exists to map Formik Context to the Create Opening Provider for this wizard step
const TemplateQuestionsFormWatcher: React.FC = props => {
	const { setStepFormProps, setIsStepFormValid } = useCreateOpening();
	const { ...stepFormProps } = useFormikContext();
	const { isValid, touched } = stepFormProps;

	useEffect(() => {
		// set this step's form submitter
		setStepFormProps(stepFormProps);
	}, []); // eslint-disable-line

	useEffect(() => {
		// @ts-ignore
		const isFormTouched = Object.keys(touched).some(field => touched[field]);

		if (isValid && isFormTouched) {
			setIsStepFormValid(isValid);
		}
	}, [isValid, touched, setIsStepFormValid]);

	return null;
};

export interface TemplateQuestionsProps {}

export const TemplateQuestions: React.FC<TemplateQuestionsProps> = props => {
	const {
		openingGlobalQuestions,
		openingTemplateQuestions,
		templateSelectionOutcome,
		templateQuestionsOutcome,
		setTemplateQuestionsOutcome,
		updateQuoteFromTemplateEstimatedOrder
	} = useCreateOpening();

	const templateQuestions = processTemplateQuestions(
		openingGlobalQuestions,
		templateSelectionOutcome,
		openingTemplateQuestions
	);

	const formikTemplateQuestions = createFormSetupObject(
		templateQuestions,
		separateInlineQuestionsFromEndingQuestions(openingTemplateQuestions, templateQuestionsOutcome)
			.endingQuestions
	);

	//
	const questionValidatorSchema = (questions: FormSetupObject) =>
		Object.keys(questions).reduce((acc, key) => {
			acc[key] = questions[key].validator[key];
			return acc;
		}, {} as { [key: string]: any });

	return (
		<Formik
			initialValues={{
				...formikInitialValuesFromSetupObject(formikTemplateQuestions)
			}}
			validateOnChange={true}
			validateOnMount={true}
			validationSchema={yup.object().shape(questionValidatorSchema(formikTemplateQuestions))}
			onSubmit={async (data, { setSubmitting }) => {
				const combinedData = {
					...templateQuestionsOutcome,
					...data
				};

				setSubmitting(true);
				await updateQuoteFromTemplateEstimatedOrder(() => {
					setTemplateQuestionsOutcome(combinedData);
					setSubmitting(false);
				}, combinedData);
			}}
		>
			{props => {
				return (
					<>
						<Form>
							<>
								<div className={css.section}>
									<div className={css.stepHeader}>
										<h1 className={classes(css.stepTitle, 'h2')}>
											Tell us a bit more about your opening.
										</h1>
									</div>
								</div>
								<CreateOpeningDynamicQuestion openingQuestions={templateQuestions} formikProps={props} />
								<TemplateQuestionsFormWatcher />
							</>
						</Form>
					</>
				);
			}}
		</Formik>
	);
};
