import React, { useState, PropsWithChildren } from 'react';
import css from './Banner.module.scss';
import { CSSTransition } from 'react-transition-group';
import { Alert } from 'components/Alert';
import { useBanner } from 'components/Banner/BannerProvider';

export const Banner: React.FC<PropsWithChildren> = props => {
	const { children } = props;
	const { removeBanner } = useBanner();
	const [isShown, setIsShown] = useState(true);

	return (
		<CSSTransition in={isShown} timeout={400} unmountOnExit onExited={() => removeBanner()}>
			<Alert className={css.banner} isDismissible={true} setIsShown={setIsShown} hasMargin={false}>
				{children}
			</Alert>
		</CSSTransition>
	);
};
