import React, { PropsWithChildren } from 'react';
import css from './Alert.module.scss';
import classes from 'classnames';
import { Button } from 'components/Button/Button';
import { TAlertTypes } from 'common/types/TAlertTypes';
export interface IAlertProps {
	className?: string;
	type?: TAlertTypes;
	isDismissible?: boolean;
	hasMargin?: boolean;
	setIsShown?: React.Dispatch<React.SetStateAction<boolean>>;
	accessibilityRole?: 'alert' | 'status';
}

export const Alert: React.FC<PropsWithChildren<IAlertProps>> = props => {
	const {
		type = 'info',
		className,
		children,
		isDismissible = false,
		hasMargin = true,
		setIsShown,
		accessibilityRole = 'alert'
	} = props;

	return (
		<div
			className={classes(
				css.alert,
				css[type],
				className,
				{ [css['isDismissible']]: isDismissible },
				{ [css['noMargin']]: !hasMargin }
			)}
			role={accessibilityRole}
		>
			<div>{children}</div>
			{isDismissible && (
				<Button
					type='link'
					onClick={() => setIsShown(false)}
					iconLeft={{
						type: 'Cancel',
						color: 'white'
					}}
					tooltipMessage={'Dismiss Message'}
					className={css.dismissMessage}
				></Button>
			)}
		</div>
	);
};
