import React from 'react';
import css from './InputSearch.module.scss';
import classes from 'classnames';
import { Input, IInputProps } from 'components/Form/Input';
import { RemoveProp } from 'common/types/TypeHelpers';

type IInputSearchProps = RemoveProp<IInputProps, 'icon'>;

export const InputSearch: React.FC<IInputSearchProps> = props => {
	const {
		classNameDomInput,
		classNameDomInputIcon,
		placeholder = 'Search by keyword, product number, or name',
		...restInputSearchProps
	} = props;
	return (
		<Input
			icon='Search'
			placeholder={placeholder}
			classNameDomInput={classes(css.inputSearch, classNameDomInput)}
			classNameDomInputIcon={classes(css.inputSearchIcon, classNameDomInputIcon)}
			{...restInputSearchProps}
		/>
	);
};
