import {
	OpeningTemplateQuestions,
	TemplateSelectionOutcome,
	Conditional,
	OpeningQuestion,
	OpeningQuestionSelectOption,
	OpeningQuestions,
	FormSetupObject
} from '../common/types';
import { LineItem } from 'api/lineItem';
import { labelToKey } from 'common/utils/labelToKey';

const isTokenTruthy = (token: string, formValues: TemplateSelectionOutcome): boolean => {
	const splitCondition = token.split(':');
	// @ts-ignore
	if (formValues[`${splitCondition[0]}`] === splitCondition[1]) {
		return true;
	}
	return false;
};

export const isConditionalTruthy = (
	conditional: Conditional,
	formValues: TemplateSelectionOutcome
): boolean => {
	if (conditional) {
		const conditions = conditional.condition;
		switch (conditional.type) {
			case 'OR':
				for (let i = 0; i < conditions.length; i++) {
					// if type is a string then its a simple token to check the truthy-ness
					if (typeof conditions[i] === 'string') {
						const stringCondition = conditions[i] as string; // set to keep TS from yelling
						if (isTokenTruthy(stringCondition, formValues)) {
							return true;
						}
					} else {
						if (isConditionalTruthy(conditions[i] as Conditional, formValues)) {
							return true;
						}
					}
				}
				// default the OR conditional type to return false until a true statement is encountered somewhere above
				return false;
			case 'AND':
				// First process all the results of the tokens or conditions
				const evaluatedConditions = conditions.map(condition => {
					if (typeof condition === 'string') {
						if (isTokenTruthy(condition, formValues)) {
							return true;
						}
						return false;
					} else {
						return isConditionalTruthy(condition, formValues);
					}
				});

				// Take the processed boolean array and if any results are false then the whole AND operation resolves as false
				for (let i = 0; i < evaluatedConditions.length; i++) {
					if (evaluatedConditions[i] !== true) {
						return false;
					}
				}
				// default the AND conditional type to return true until a false result turns the logical AND false
				return true;
		}
	} else {
		// if conditional is null then assume true
		return true;
	}
};

export const questionsLength = (
	source: OpeningTemplateQuestions,
	formValues: TemplateSelectionOutcome
) => source.filter(question => isConditionalTruthy(question.conditional, formValues)).length;

export const estimatedPriceFromLinesItems = (lineItems: LineItem[]): number =>
	lineItems
		? lineItems.reduce((total: number, lineItem: LineItem) => {
				if (lineItem.discountedPricePerUnit > 0) {
					return total + lineItem.discountedPricePerUnit * lineItem.quantity;
				} else if (lineItem.pricePerUnit > 0) {
					return total + lineItem.pricePerUnit * lineItem.quantity;
				} else {
					return total;
				}
		  }, 0)
		: 0;

// Makes a key based on a label name
export const makeKey = (source: OpeningQuestion | OpeningQuestionSelectOption) =>
	source.key ? source.key : labelToKey(source.label);

// Iterate over the list of questions and construct a FormSetupObject object ot be used to setup the form initial values and validators defined in the config object
export const createFormSetupObject = (
	questions: OpeningQuestions,
	templateQuestionsOutcome: { [key: string]: string }
): FormSetupObject =>
	questions.reduce((formattedQuestions, question) => {
		const questionKey = makeKey(question);

		const preFilledInitialValue = templateQuestionsOutcome[questionKey]
			? templateQuestionsOutcome[questionKey]
			: null;

		switch (question.type) {
			case 'text':
				// @ts-ignore
				formattedQuestions[`${questionKey}`] = {
					initialValue: preFilledInitialValue ?? '',
					validator: question.validator
				}; // format and set the fields initialValue
				break;
			case 'boolean':
				// @ts-ignore
				formattedQuestions[`${questionKey}`] = {
					initialValue: preFilledInitialValue ?? 'yes',
					validator: question.validator
				}; // format and set the fields initialValue
				break;
			case 'select':
				// @ts-ignore
				formattedQuestions[`${questionKey}`] = {
					initialValue: preFilledInitialValue ?? '',
					validator: question.validator
				}; // format and set the fields initialValue
				break;
		}
		return formattedQuestions;
	}, {});

export const formikInitialValuesFromSetupObject = (questions: FormSetupObject) =>
	Object.keys(questions).reduce((acc, key) => {
		acc[key] = questions[key].initialValue;
		return acc;
	}, {} as { [key: string]: string });

export const separateTemplateSelectionOutcomeFromInlineQuestions = (data: {
	[key: string]: string;
}): {
	templateSelectionOutcome: TemplateSelectionOutcome;
	inlineQuestions: { [key: string]: string };
} => {
	const templateSelectionOutcome: TemplateSelectionOutcome = {
		doorLayout: data['doorLayout'] || null,
		glassThickness: data['glassThickness'] || null,
		finish: data['finish'] || null,
		type: data['type'] || '',
		closerType: data['closerType'] || null,
		mountingType: data['mountingType'] || null,
		header: data['header'] || null,
		additionalFunction: data['additionalFunction'] || null,
		bottomLock: data['bottomLock'] || null,
		channelHeightTop: data['channelHeightTop'] || null,
		channelHeightBottom: data['channelHeightBottom'] || null,
		railHeightTop: data['railHeightTop'] || null,
		railHeightBottom: data['railHeightBottom'] || null,
		railProfile: data['railProfile'] || null,
		pullHandle: data['pullHandle'] || null,
		pullHandleFinish: data['pullHandleFinish'] || null,
		panelWidth: data['panelWidth'] || null
	};

	const inlineQuestions: { [key: string]: string } = {};
	Object.keys(data).forEach(key => {
		if (!(key in templateSelectionOutcome)) {
			inlineQuestions[key] = data[key];
		}
	});

	return {
		templateSelectionOutcome,
		inlineQuestions
	};
};

export const separateInlineQuestionsFromEndingQuestions = (
	openingTemplateQuestions: OpeningTemplateQuestions,
	allQuestions?: { [key: string]: string }
): { inlineQuestions: { [key: string]: string }; endingQuestions: { [key: string]: string } } => {
	const inlineQuestions: { [key: string]: string } = {};
	const endingQuestions: { [key: string]: string } = {};

	if (allQuestions) {
		Object.keys(allQuestions).forEach(key => {
			let isQuestionKeyInline = false;
			openingTemplateQuestions.forEach(templateQuestion => {
				const hasQuestionKey = templateQuestion.questions?.findIndex(question => question.key === key);

				if (hasQuestionKey !== -1) {
					isQuestionKeyInline = true;
				}
			});

			if (isQuestionKeyInline) {
				inlineQuestions[key] = allQuestions[key];
			}
		});
	}

	return {
		inlineQuestions,
		endingQuestions
	};
};
