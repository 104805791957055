export const config = {
	supportEmail: 'support@dormakaba.com',
	supportPhone: '1-866-DORMAKABA',
	authEnv: process.env.REACT_APP_AUTH_ENV,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	authClientId: process.env.REACT_APP_AUTH_CLIENT_ID,
	authAudience: process.env.REACT_APP_AUTH_AUDIENCE,
	authCustomClaimNamespace: process.env.REACT_APP_AUTH_CUSTOM_CLAIM_NAMESPACE,
	reactAppApiHost: process.env.REACT_APP_API_HOST
};
