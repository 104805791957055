// Cloned from InputRadio component
import React from 'react';
import css from './CreateOpeningDynamicQuestion.module.scss';
import { FormikProps } from 'formik';
import { OpeningQuestions } from '../common/types';
import { makeKey } from '../common/utilities';
import { isChecked } from 'common/utils/isChecked';
import { Select } from 'components/Form/Select';
import { Input } from 'components/Form/Input/Input';
import { InputRadio } from 'components/Form/InputRadio/InputRadio';

export type ICreateOpeningDynamicQuestion = {
	openingQuestions: OpeningQuestions;
	formikProps: FormikProps<any>;
	showViewingCountInLabel?: boolean;
};

export const CreateOpeningDynamicQuestion: React.FC<ICreateOpeningDynamicQuestion> = props => {
	const { openingQuestions, formikProps, showViewingCountInLabel = false } = props;

	return (
		<>
			{openingQuestions.map((question, index) => {
				const labelToShow = showViewingCountInLabel
					? `${index + 1} of ${openingQuestions.length}: ${question.label}`
					: question.label;
				switch (question.type) {
					case 'text':
						return (
							<Input
								key={makeKey(question)}
								labelClass={css.labelClass}
								label={labelToShow}
								className={css.dynamicQuestionInputs}
								name={makeKey(question)}
								aria-invalid={
									// @ts-ignore
									formikProps.errors[`${makeKey(question)}`] &&
									// @ts-ignore
									formikProps.touched[`${makeKey(question)}`]
										? 'true'
										: null
								}
								formikProps={formikProps}
							/>
						);
					case 'boolean':
						return (
							<div className={css.dynamicQuestionInputs}>
								<p className={css.labelClass}>{labelToShow}</p>
								<InputRadio
									name={makeKey(question)}
									label={'Yes'}
									value={'yes'}
									formikProps={formikProps}
									checked={isChecked(formikProps.values, makeKey(question), 'yes')}
								/>
								<InputRadio
									name={makeKey(question)}
									label={'No'}
									value={'no'}
									formikProps={formikProps}
									checked={isChecked(formikProps.values, makeKey(question), 'no')}
								/>
							</div>
						);
					case 'select':
						return (
							<Select
								label={labelToShow}
								labelClass={css.labelClass}
								name={makeKey(question)}
								inputMessage={'Select an option'}
								formikProps={formikProps}
								key={makeKey(question)}
								className={css.dynamicQuestionInputs}
							>
								<option value=''>Select an option</option>
								{question.options.map(selectOption => (
									<option value={makeKey(selectOption)} key={makeKey(selectOption)}>
										{selectOption.label}
									</option>
								))}
							</Select>
						);
					default:
						return false;
				}
			})}
		</>
	);
};
