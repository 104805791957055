import React, { useEffect } from 'react';
import css from './TemplateApplication.module.scss';
import classnames from 'classnames';
import { Formik, Form, useFormikContext } from 'formik';
import * as yup from 'yup';
import { useCreateOpening } from 'providers/CreateOpening/CreateOpeningProvider';
import { CreateOpeningInputRadio } from '../CreateOpeningInputRadio';
import { CreateOpeningInputRadioWrapper } from '../CreateOpeningInputRadioWrapper/CreateOpeningInputRadioWrapper';
import { Input } from 'components/Form/Input';
import { Icon } from 'components/Icon';
import { Section } from 'components/Section';
import { GridContainer } from 'components/Grid/GridContainer/GridContainer';
import { GridItem } from 'components/Grid/GridItem/GridItem';
import { isChecked } from 'common/utils/isChecked';
import { Application } from '../common/types';

// You can't directly update a custom provider from within the form render loop so this watcher component exists to map Formik Context to the Create Opening Provider for this wizard step
const TemplateApplicationFormWatcher: React.FC = props => {
	const { setStepFormProps, setIsStepFormValid } = useCreateOpening();
	const { ...stepFormProps } = useFormikContext();
	const { isValid, touched } = stepFormProps;

	useEffect(() => {
		// set this step's form submitter inside create an opening provider
		setStepFormProps(stepFormProps);
	}, []); // eslint-disable-line

	useEffect(() => {
		// @ts-ignore
		const isFormTouched = Object.keys(touched).some(field => touched[field]);

		if (isValid && isFormTouched) {
			setIsStepFormValid(isValid);
		}
	}, [isValid, touched, setIsStepFormValid]);
	return null;
};

export interface TemplateApplicationProps {}

export const TemplateApplication: React.FC<TemplateApplicationProps> = props => {
	const { setTemplateApplicationOutcome, templateApplicationOutcome } = useCreateOpening();

	return (
		<Formik
			initialValues={{
				...templateApplicationOutcome
			}}
			validateOnChange={true}
			validateOnMount={true}
			onSubmit={async (data, { setSubmitting }) => {
				setSubmitting(true);
				console.log('TemplateApplicationOutcome: ', data);
				setTemplateApplicationOutcome(data);
				setSubmitting(false);
			}}
			validationSchema={yup.object().shape({
				application: yup.string().required('Must choose a system'),
				clearOpeningHeight: yup.string().when('application', {
					is: (application: Application) => application !== 'fixed',
					then: yup.string().required('Clear opening height is required')
				}),
				clearOpeningWidth: yup.string().when('application', {
					is: (application: Application) => application !== 'fixed',
					then: yup.string().required('Clear opening width is required')
				}),
				doorHeight: yup.string().required('Door or fixed wall height is required'),
				doorWidth: yup.string().required('Door or fixed wall width is required'),
				openingName: yup.string().required('First name is required')
			})}
		>
			{props => {
				return (
					<Form>
						<>
							<div className={css.section}>
								<div className={css.stepHeader}>
									<h1 className={classnames(css.stepTitle, 'h2')}>
										Let us help you create a quote for your opening.
									</h1>
									<p className={css.stepDescription}>
										We just need to know a few things to get started.
									</p>
								</div>
							</div>
							<div className={css.section}>
								<h2 className={classnames('h5', css.stepQuestion)}>
									Name your opening <span className={css.required}>*</span>{' '}
									<Icon
										type={'Info'}
										color={'blue'}
										tooltip={
											'The name of your opening will help you identify it within your list later.'
										}
									/>
								</h2>

								<Input
									type={'text'}
									name={'openingName'}
									value={props.values.openingName}
									label={'Name'}
									formikProps={props}
									placeholder={'Enter name'}
								/>
							</div>

							<h2 className={classnames('h5', css.stepQuestion)}>
								What type do system do you need? <span className={css.required}>*</span>{' '}
								<Icon
									type={'Info'}
									color={'blue'}
									tooltip={
										'Choose a swing/pivot door, a sliding door, or fixed wall. Swing doors are available  for interior or exterior applications. Sliding and fixed wall systems are only available for interior applications.'
									}
								/>
							</h2>

							<CreateOpeningInputRadioWrapper formikProps={props} name={'application'}>
								<CreateOpeningInputRadio
									className={css.openingValue}
									name={'application'}
									label={'Swing/Pivot Door'}
									labelTooltip={
										'Swing/Pivot Doors: Your door can either be single or double-acting. The standard option is center hung—which allows the doors to swing in both directions—but flush mount (offset pivots) is available in advanced options.'
									}
									value={'swing'}
									formikProps={props}
									checked={isChecked(props.values, 'application', 'swing')}
								/>
								<CreateOpeningInputRadio
									className={css.openingValue}
									name={'application'}
									label={'Fixed Wall'}
									labelTooltip={
										'Fixed Walls: These are the non-operable glass panels within an opening. They are split into individual sidelites which are full-height panels (typically on the side of doors or a glass wall), transoms (panels over swing or sliding doors), or sidelites with a notch (sidelites that include a cutout for openings that include knee walls), or other intrusions into the opening.'
									}
									value={'fixed'}
									formikProps={props}
									checked={isChecked(props.values, 'application', 'fixed')}
								/>
								<CreateOpeningInputRadio
									className={css.openingValue}
									name={'application'}
									label={'Sliding Door'}
									labelTooltip={
										'Sliding Doors: This style door minimizes spatial requirements, and offers single-direction and bi-parting options.'
									}
									value={'sliding'}
									formikProps={props}
									checked={isChecked(props.values, 'application', 'sliding')}
								/>
							</CreateOpeningInputRadioWrapper>

							<Section>
								<h2 className={classnames('h5', css.stepQuestion)}>
									Tell us your door or fixed wall dimensions <span className={css.required}>*</span>
								</h2>
								<GridContainer columnAmount={'2'}>
									<GridItem>
										<Input
											type={'number'}
											name={'doorWidth'}
											value={props.values.doorWidth}
											label={'Finished Opening Width (FOW) (FEET)'}
											min={0}
											formikProps={props}
											placeholder={'Enter width'}
										/>
									</GridItem>
									<GridItem>
										<Input
											type={'number'}
											name={'doorHeight'}
											value={props.values.doorHeight}
											label={'Finished Opening Height (FOH) (feet)'}
											min={0}
											formikProps={props}
											placeholder={'Enter height'}
										/>
									</GridItem>
								</GridContainer>

								{props.values.application !== 'fixed' && (
									<>
										<h2 className={classnames('h5', css.stepQuestion)}>
											Tell us your clear opening dimensions <span className={css.required}>*</span>{' '}
											<Icon
												type={'Info'}
												color={'blue'}
												tooltip={
													'The clear opening dimensions are of space you walk through when the door is fully open, without obstructions.'
												}
											/>
										</h2>
										<GridContainer columnAmount={'2'}>
											<GridItem>
												<Input
													type={'number'}
													name={'clearOpeningWidth'}
													value={props.values.clearOpeningWidth}
													label={'Door Opening Width (DOW) (FEET)'}
													min={0}
													formikProps={props}
													placeholder={'Enter width'}
												/>
											</GridItem>
											<GridItem>
												<Input
													type={'number'}
													name={'clearOpeningHeight'}
													value={props.values.clearOpeningHeight}
													label={'Door Opening Height (DOH) (FEET)'}
													min={0}
													formikProps={props}
													placeholder={'Enter height'}
												/>
											</GridItem>
										</GridContainer>
									</>
								)}
							</Section>

							<TemplateApplicationFormWatcher />
						</>
					</Form>
				);
			}}
		</Formik>
	);
};
