import { useMutation, useQuery, useQueryClient } from 'react-query';
import { handleResponse } from './api';
import { useAxios } from 'providers/Axios/AxiosProvider';
import { Order } from './order';
import { User } from './user';

export type OrderComment = {
	// general fields
	id: string;
	isDeleted: boolean;
	createdBy: string;
	createdAt: Date;
	updatedBy: string;
	updatedAt: Date;
	deletedBy: string;
	deletedAt: Date;

	// UI only field - the currently logged in users comments
	isCurrentUser?: boolean;
	createdByUser?: User;

	// order message / comment specific
	commentText: string; // content of the message - max len 1500
	orderId: string; // GUID Id of the Order
	order?: Order;
};

export type OrderCommentAdd = {
	orderId: string;
	commentText: string; // max len 1500
};

export type ApiOrderCommentsResponse = OrderComment[];

// Create
export const useAddOrderComment = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (orderComment: OrderCommentAdd) => {
			return handleResponse(axios.post<OrderCommentAdd>('/orderComment', orderComment));
		},
		{
			onSuccess: (data, orderComment) => {
				queryClient.invalidateQueries(['orderComments']);
				queryClient.invalidateQueries(['order', `${orderComment.orderId}`]);
				queryClient.invalidateQueries(['orderCommentsByOrder', `${orderComment.orderId}`]);
			}
		}
	);
};

// Read
export const useGetAllOrderComments = () => {
	const axios = useAxios();
	return useQuery(['orderComments'], async () => {
		return handleResponse(axios.get<ApiOrderCommentsResponse>(`/orderComment`));
	});
};

export const useGetOrderCommentsByOrderId = (orderId: string) => {
	const axios = useAxios();
	return useQuery(['orderCommentsByOrder', `${orderId}`], async () => {
		return handleResponse(axios.get<ApiOrderCommentsResponse>(`/orderComment/order/${orderId}`));
	});
};
