import React, { useEffect, useRef, PropsWithChildren } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'components/Link';
import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import classes from 'classnames';
import css from './PageFrame.module.scss';
import { Breadcrumbs, IBreadcrumbsProps } from 'components/Breadcrumbs';
import { Container } from 'components/Container/Container';
import { Footer } from 'components/AppFrame/Footer';

interface IPageFrame {
	title: string;
	description?: React.ReactNode | string;
	isContentManagedTitle?: boolean; // If true then all PageFrame spacing and expectation of handling the H1 is removed and PageFrame only manages the HTML title
	backLocation?: { to: string; label: string }; // Use backButton or backLocation not both
	backButton?: { action: () => void; label: string }; // Use backButton or backLocation not both
	breadcrumbs?: IBreadcrumbsProps;
	ContentTopRight?: React.FC;
	HTMLTitle?: string;
	showFooter?: boolean;
	classNames?: {
		pageFrame?: string;
		pageFrameTop?: string;
		contentTopRight?: string;
		content?: string;
		title?: string;
	};
}

export const PageFrame: React.FC<PropsWithChildren<IPageFrame>> = props => {
	const {
		title,
		description,
		isContentManagedTitle = false,
		children,
		backLocation,
		backButton,
		ContentTopRight,
		classNames,
		HTMLTitle,
		showFooter = true,
		breadcrumbs
	} = props;
	const titleRef = useRef<HTMLHeadingElement>();
	useEffect(() => {
		if (!isContentManagedTitle) {
			titleRef.current.focus();
		}
	}, [isContentManagedTitle]);
	return (
		<div
			className={classes(
				css.pageFrame,
				{ [css.isContentManagedTitle]: isContentManagedTitle },
				classNames?.pageFrame
			)}
		>
			<div className={classes(css.pageFrameContent, { [css.addFlexSpace]: !showFooter })}>
				{breadcrumbs && <Breadcrumbs className={css.breadcrumbs} {...breadcrumbs}></Breadcrumbs>}
				<Helmet>
					<title>{`${HTMLTitle || title} | Dormakaba`}</title>
				</Helmet>
				{(!isContentManagedTitle || description || backLocation || backButton || ContentTopRight) && (
					<Container className={classes(css.pageFrameTop, classNames?.pageFrameTop)}>
						<div className={css.pageFrameTopInner}>
							{!isContentManagedTitle && (
								<h1
									className={classes('h3', css.pageFrameTitle, classNames?.title)}
									ref={titleRef}
									tabIndex={-1}
								>
									{title}
								</h1>
							)}
							{description &&
								(typeof description === 'string' ? <p>{description}</p> : <>{description}</>)}

							{backLocation && (
								<Link to={backLocation.to} className={css.backLocation}>
									<Icon
										type='CaretLeft'
										color='black'
										size='smallest'
										className={css.backLocationIcon}
									></Icon>
									{backLocation.label}
								</Link>
							)}

							{backButton && (
								<Button type={'link'} className={css.backLocation} onClick={backButton.action}>
									<Icon
										type='CaretLeft'
										color='black'
										size='smallest'
										className={css.backLocationIcon}
									></Icon>
									{backButton.label}
								</Button>
							)}
						</div>
						{ContentTopRight && (
							<div className={classes(css.contentTopRight, classNames?.contentTopRight)}>
								<ContentTopRight />
							</div>
						)}
					</Container>
				)}
				<div className={classes([css.content, classNames?.content])}>{children}</div>
			</div>
			{showFooter && <Footer />}
		</div>
	);
};
