import { OrderStatus } from 'api/order';

export const formatOrderStatus = (status: OrderStatus): string => {
	switch (status) {
		// they will not see Not Submitted but just in-case
		case 'NotSubmitted':
			return 'Not Submitted';
		case 'InReview':
			return 'In Review';
		case 'OrderPlaced':
			return 'Order Placed';
		default:
			return status;
	}
};
