import React from 'react';
import css from './CreateOpeningIcon.module.scss';
import classes from 'classnames';
import { removePropertiesFromObjects } from 'common/utils/removePropertiesFromObjects';

import { ReactComponent as CloserTypeBts } from 'assets/svg/createOpening/closer-type-bts.svg';
import { ReactComponent as CloserTypeFreeSwing } from 'assets/svg/createOpening/closer-type-freeswing.svg';
import { ReactComponent as CloserTypeRts } from 'assets/svg/createOpening/closer-type-rts.svg';
import { ReactComponent as DoorTypeA } from 'assets/svg/createOpening/door-type-a.svg';
import { ReactComponent as DoorTypeBp } from 'assets/svg/createOpening/door-type-bp.svg';
import { ReactComponent as DoorTypeF } from 'assets/svg/createOpening/door-type-f.svg';
import { ReactComponent as DoorTypeP } from 'assets/svg/createOpening/door-type-p.svg';

const iconMap = {
	CloserTypeBts,
	CloserTypeFreeSwing,
	CloserTypeRts,
	DoorTypeA,
	DoorTypeBp,
	DoorTypeF,
	DoorTypeP
};

export type TCreateOpeningIconTypes = keyof typeof iconMap;

export interface ICreateOpeningIconProps {
	className?: string;
	type: TCreateOpeningIconTypes;
}

export const CreateOpeningIcon: React.FC<ICreateOpeningIconProps & React.SVGProps<SVGSVGElement>> =
	props => {
		const { className, type } = props;

		const iconClasses = classes(css.icon, className, css[type]);

		const iconProperties = {
			...removePropertiesFromObjects(['type'], props),
			className: iconClasses,
			role: 'text'
		};
		const IconTag = iconMap[type];

		return <IconTag {...iconProperties} />;
	};
