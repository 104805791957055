import { useState } from 'react';
import { AxiosResponse } from 'axios';
import { useQueryClient } from 'react-query';

export type BlobResponse = {
	blob: Blob;
	suggestedFilename?: string;
};

// Common response handling code
export async function handleResponse<T>(response: Promise<AxiosResponse<T>>): Promise<T> {
	const result = await response;

	// TODO - any error handling?

	if (result.status === 200) {
		return result.data;
	}
}

// Wrapper around an axios call that sets loading and error states
// Use where react-query isn't appropriate
// Add the axios call to the queryFn parameter
//
// ex.
// return useRequest<BlobResponse>(
// 	() => { return axios.get(`/lists/${listId}/export`); },
// 	{
// 		success: response => {
// 			[do something with the response if needed]
// 		}
// 	}
// );
export const useAxiosRawRequest = <TResult = unknown, TAxios = unknown>(
	requestFn: () => Promise<AxiosResponse<TAxios>>,
	options?: { success?: (response: AxiosResponse<TAxios>) => TResult }
) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);

	return {
		isLoading,
		isError,
		execute: async (): Promise<TResult> => {
			setIsLoading(true);
			setIsError(false);

			try {
				const response = await requestFn();

				setIsLoading(false);

				if (response.status === 200) {
					if (options.success) {
						return options.success(response);
					}
				} else {
					setIsError(true);
				}
			} catch (e) {
				console.error(e);

				setIsLoading(false);
				setIsError(true);
			}

			return null;
		}
	};
};

export const useClearCache = () => {
	const queryClient = useQueryClient();

	const clearCache = () => {
		queryClient.clear();
	};

	return { clearCache };
};
