import { config } from 'config';

export type UserRole = 'Administrator' | 'Customer Service Employee' | 'Customer';

export const userRoleIdMapping = (role: UserRole): string => {
	const authEnv = config.authEnv as 'DEV' | 'UAT' | 'PROD';
	switch (role) {
		case 'Administrator':
			if (authEnv === 'DEV') {
				return 'rol_NixcZ1canGjVXQtc';
			} else if (authEnv === 'UAT') {
				return 'rol_5kgofF7YA3D08Ist';
			} else {
				return 'rol_64x2dkIzqhHydOsz';
			}
		case 'Customer':
			if (authEnv === 'DEV') {
				return 'rol_9q2EyOfkTltHPfpn';
			} else if (authEnv === 'UAT') {
				return 'rol_ejMrrcxqWDyGEyOn';
			} else {
				return 'rol_psIp74NBdWvmxaXS';
			}
		case 'Customer Service Employee':
			if (authEnv === 'DEV') {
				return 'rol_Z1YZqQaYQpdl2HUt';
			} else if (authEnv === 'UAT') {
				return 'rol_C7YMToMvOnXkUGkx';
			} else {
				return 'rol_wPVJkucPW5pX9Emp';
			}
	}
};
