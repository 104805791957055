import React from 'react';
import css from './UploadDropZoneLoading.module.scss';
import { Spinner } from 'components/Spinner/Spinner';

export const UploadDropZoneLoading = () => {
	return (
		<div className={css.uploadDropZoneLoading}>
			<Spinner size={'large'} type={'dark'} />
		</div>
	);
};
