/**
 *
 * Takes in Formik props and determines if a given value is currently checked for a field
 *
 */

export const isChecked = <T>(formValues: T, key: string, value: string): boolean => {
	//@ts-ignore
	if (formValues[`${key}`] === value) {
		return true;
	}
	return false;
};
