import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import css from './ReferenceNumberManagementView.module.scss';
import classes from 'classnames';
import { useAuth } from 'providers/Auth/AuthProvider';
import { useLocation } from 'react-router';
import { Link } from 'components/Link';
import { Panel } from 'components/Panel';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { Spinner } from 'components/Spinner';
import { TTableHeader } from 'components/Table';
import { LoadingDelay } from 'components/LoadingDelay';
import { PageFrame } from 'components/PageFrame';
import { useBanner } from 'components/Banner/BannerProvider';
import { ICurrentTableValues, TableController } from 'components/Table/TableController';
import { UseQueryResult } from 'react-query';
import { formatDate } from 'common/utils/formatDate';
import { Opening, OpeningPagedResponse, useGetOpenings } from 'api/opening';
import { Select } from 'components/Form/Select/Select';
import { InputCheckbox } from 'components/Form/InputCheckbox/InputCheckbox';

type TApplicationType = 'fixed' | 'swing' | 'sliding' | '';
type TGlassThicknessType =
	| '3-8inch'
	| '1-2inch'
	| '9-16inch'
	| '5-8inch'
	| '11-16inch'
	| '3-4inch'
	| '13-16inch'
	| '';

type TReferenceNumberManagementFilterOptions = {
	noPullHandles?: boolean;
	isInvalid?: boolean;
	isCompleted?: boolean;
	application?: TApplicationType;
	glassThickness?: TGlassThicknessType;
};

export const ReferenceNumberManagementView = () => {
	const { isLoading: isAuthLoading } = useAuth();
	const tableBaseRoute = '/admin/referencenumbermanagement/';
	const location = useLocation();
	const filtersSetByRouter = queryString.parse(location?.search);
	const { createBanner } = useBanner();

	const [filterOptions, setFilterOptions] = useState<TReferenceNumberManagementFilterOptions>({
		...filtersSetByRouter
	});

	useEffect(() => {
		const bannerSuccess = (location.state as { bannerSuccess: { name: string; message: string } })
			?.bannerSuccess;
		if (bannerSuccess) {
			createBanner(
				<>
					<strong>{bannerSuccess.name}</strong> {bannerSuccess.message}
				</>
			);
		}
	}, [location, createBanner]);

	const useQuery = ({
		itemsPerPage,
		pageNumber
	}: // queryStringObject
	ICurrentTableValues): UseQueryResult => {
		console.log('pageNumber from view: ', pageNumber);
		const noPullHandles = filterOptions.noPullHandles ? filterOptions.noPullHandles : null;
		const isInvalid = filterOptions.isInvalid ? filterOptions.isInvalid : null;
		const isCompleted = filterOptions.isCompleted ? filterOptions.isCompleted : null;
		const application = filterOptions.application !== '' ? filterOptions.application : null;
		const glassThickness = filterOptions.glassThickness !== '' ? filterOptions.glassThickness : null;
		return useGetOpenings(
			pageNumber,
			itemsPerPage,
			noPullHandles,
			isInvalid,
			isCompleted,
			application,
			glassThickness
		);
	};

	const headers: TTableHeader[] = [
		{
			label: '# Id',
			property: 'numberId',
			className: css.extraSmallColumn,
			renderFunction: (data: Opening) => `${data.numberId}`
		},
		{
			label: 'Valid Opening',
			property: 'isInvalid',
			className: css.extraSmallColumn,
			renderFunction: (data: Opening) => (
				<span className={classes(css.status, css[data.isInvalid === true ? 'redText' : ''])}>
					{data.isInvalid === true ? 'Invalid' : 'Valid'}
				</span>
			)
		},
		{
			label: 'Configuration Outcome',
			property: 'visibleOutcomePath',
			renderFunction: (data: Opening) => `${data.visibleOutcomePath}`
		},
		{
			label: 'Status',
			property: '',
			className: css.smallColumn,
			renderFunction: (data: Opening) => (
				<span
					className={classes(
						css.status,
						css[data.referenceNumberList == null ? 'redText' : 'greenText']
					)}
				>
					{data.referenceNumberList == null ? 'Not Started' : 'Completed'}
				</span>
			)
		},
		{
			label: 'Date Modified',
			property: 'updatedAt',
			className: css.smallColumn,
			renderFunction: (data: Opening) => (data.updatedAt ? formatDate(data.updatedAt, true, true) : '')
		},
		{
			label: 'Manage',
			property: 'manage',
			className: css.smallColumn,
			renderFunction: (data: Opening) => (
				<Link to={`/admin/referencenumbermanagement/opening/${data.id}`}>Manage</Link>
			)
		}
	];

	const dataSelector = (data: OpeningPagedResponse) => {
		return data?.openings;
	};

	const totalCountSelector = (openings: Opening[], tableData: any, originalData: any) => {
		return originalData?.totalRecords;
	};

	if (isAuthLoading) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	return (
		<PageFrame
			title={'Reference Number Management'}
			description={
				<>
					<p className={css.marginBottomZero}>
						Below you will see every possible outcome for the 'Create an Opening' customer questionnaire.
					</p>
					<p className={css.boldTextMarginTopZero}>
						Please note each one of these outcomes will need to be assigned reference numbers in order
						for pricing to be calculated for customers (if not assigned customers can still complete the
						questionnaire, they will just not have pricing before submitting for customer service
						review).
					</p>
					<p>
						Click 'Manage', on the right-hand side of the table below to edit the outcome details (name,
						optional icon, and assign reference numbers so pricing will appear to customers when
						completing the questionnaire).
					</p>
				</>
			}
		>
			<Container>
				<Section>
					<Panel>
						<TableController
							totalCountSelector={totalCountSelector}
							headers={headers}
							baseRoute={tableBaseRoute}
							itemsPerPage={10}
							useQuery={useQuery}
							isUrlUpdated={true}
							hasPagination={true}
							emptyMessage={'There were no openings found'}
							dataSelector={dataSelector}
							hasSearch={false}
							isTitleInTable={false}
							queryStringObject={filterOptions}
							additionalActions={[
								<div className={css.displayFlex}>
									{/* APPLICATION */}
									<div>
										<p>Application:</p>
									</div>
									<div>
										<Select
											label='{Copy.filesFilterLabel}'
											isLabelVisible={false}
											name='tableFilter'
											onChange={(event: any) => {
												setFilterOptions({
													...filterOptions,
													application: event.target.value
												});
											}}
											value={filterOptions.application}
										>
											<option value=''>All</option>
											<option value='fixed'>Fixed</option>
											<option value='swing'>Swing</option>
											<option value='sliding'>Sliding</option>
										</Select>
									</div>
									{/* GLASS THICKNESS */}
									<div>
										<p>Glass:</p>
									</div>
									<div>
										<Select
											label='{Copy.filesFilterLabel}'
											isLabelVisible={false}
											name='tableFilterGlass'
											onChange={(event: any) => {
												setFilterOptions({
													...filterOptions,
													glassThickness: event.target.value
												});
											}}
											value={filterOptions.glassThickness}
										>
											<option value=''>All</option>
											<option value='3-8inch'>3-8inch</option>
											<option value='1-2inch'>1-2inch</option>
											<option value='9-16inch'>9-16inch</option>
											<option value='5-8inch'>5-8inch</option>
											<option value='11-16inch'>11-16inch</option>
											<option value='3-4inch'>3-4inch</option>
											<option value='13-16inch'>13-16inch</option>
										</Select>
									</div>
									{/* DOOR PULLS */}
									<div>
										<p>Exclude Pulls:</p>
									</div>
									<div>
										<InputCheckbox
											checkboxSize='medium'
											checked={filterOptions.noPullHandles ? filterOptions.noPullHandles : false}
											onChange={event => {
												const checkValue = event.target.checked;
												setFilterOptions({
													...filterOptions,
													noPullHandles: checkValue
												});
											}}
											label='Exclude Pull Handles'
											isLabelHidden={true}
										></InputCheckbox>
									</div>
									{/* INVALID OPENINGS */}
									<div>
										<p>Exclude Invalid:</p>
									</div>
									<div>
										<InputCheckbox
											checkboxSize='medium'
											checked={filterOptions.isInvalid ? filterOptions.isInvalid : false}
											onChange={event => {
												const checkValue = event.target.checked;
												setFilterOptions({
													...filterOptions,
													isInvalid: checkValue
												});
											}}
											label='Exclude Invalid'
											isLabelHidden={true}
										></InputCheckbox>
									</div>
									{/* COMPLETED */}
									<div>
										<p>Exclude Completed:</p>
									</div>
									<div>
										<InputCheckbox
											checkboxSize='medium'
											checked={filterOptions.isCompleted ? filterOptions.isCompleted : false}
											onChange={event => {
												const checkValue = event.target.checked;
												setFilterOptions({
													...filterOptions,
													isCompleted: checkValue
												});
											}}
											label='Exclude Completed'
											isLabelHidden={true}
										></InputCheckbox>
									</div>
								</div>
							]}
						/>
					</Panel>
				</Section>
			</Container>
		</PageFrame>
	);
};
