import React from 'react';
import { ApiUploadFailedObject, ApiUploadResponse } from 'api/fileUploads';
import { UploadErrorModal } from 'components/UploadErrorModal/UploadErrorModal';
import { useModal } from 'providers/Modal/ModalProvider';

export const useFileUploadHandler = () => {
	const { createModal: createUploadSuccessModal } = useModal();
	const { createModal: createUploadErrorModal, removeModal: removeUploadErrorModal } = useModal();

	const doUpload = async (uploadFn: () => Promise<ApiUploadResponse>) => {
		try {
			const uploadResult = await uploadFn();

			if (uploadResult?.failedCsvObjects?.length > 0) {
				showCsvErrorsModal(uploadResult.failedCsvObjects);
			} else {
				createUploadSuccessModal({
					heading: 'File upload successful',
					Content: () => <p>Your uploaded file was successfully sent and processed by the server.</p>
				});
			}
		} catch (err: any) {
			console.error(err);
			showCsvErrorsModal([{ failReason: `Problem uploading file - ${err?.message}` }]);
		}
	};

	// Displays a modal informing the user of any structural errors in the file
	const showCsvErrorsModal = (errorsToShow: ApiUploadFailedObject[]) => {
		const generalErrors = errorsToShow.filter(error => !error.csvRowNumber);
		const rowErrors = errorsToShow.filter(error => error.csvRowNumber).sort(error => error.csvRowNumber);

		createUploadErrorModal({
			heading: 'Problems detected in CSV upload',
			Content: () => <UploadErrorModal generalErrors={generalErrors} rowErrors={rowErrors} />,
			primaryButtonLabel: 'Ok',
			hasSecondaryButton: false,
			primaryButtonAction: () => removeUploadErrorModal()
		});
	};

	return { doUpload };
};
