import React from 'react';
import { labelToKey } from 'common/utils/labelToKey';
import { PaginationForm } from 'components/PaginationForm';
import { ProductCard } from '../ProductCard/ProductCard';
import css from './ProductList.module.scss';
import classes from 'classnames';
import { ApiProductsResponse } from 'api/product';

interface IProductList {
	className?: string;
	products: ApiProductsResponse;
	itemsPerPage?: number;
	baseRoute?: string; // required for pagination
	pageNumber: number;
	setPageNumber: (pageNumber: number) => void;
}

export const ProductList: React.FC<IProductList> = props => {
	const { className, products, baseRoute, itemsPerPage = 10, pageNumber, setPageNumber } = props;

	const totalProducts = products ? products.totalRecords : 0;
	const firstItemLocation = Math.min((pageNumber - 1) * itemsPerPage + 1, totalProducts);
	const lastItemLocation = Math.min(pageNumber * itemsPerPage, totalProducts);
	const paginatedProducts = (products: ApiProductsResponse) => {
		if (products?.listOfProductsWithPricing?.length) {
			return products.listOfProductsWithPricing;
		}
		return [];
	};

	return (
		<div className={classes(css.productList, className)}>
			<div className={css.productListHeader}>
				<h2 className={'h6'}>
					Viewing {firstItemLocation}-{lastItemLocation} of {totalProducts}
				</h2>
			</div>
			<div className={css.products}>
				{products ? (
					paginatedProducts(products).map(product => (
						<ProductCard
							key={labelToKey(`productListItem-${product.product.materialNumber}`)}
							{...product}
							className={css.productCardBorderOffset}
						/>
					))
				) : (
					<div className={css.noProductsFound}>
						<h3 className={'h6'}>No products found please clear your filters and try again</h3>
					</div>
				)}
			</div>
			<div className={css.productListFooter}>
				<PaginationForm
					itemsPerPage={itemsPerPage}
					currentPage={pageNumber}
					itemAmount={totalProducts}
					baseRoute={baseRoute}
					setPageNumber={setPageNumber}
				/>
			</div>
		</div>
	);
};
