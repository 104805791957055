import React from 'react';
import { TDropdownItem } from 'common/types/TDropdownItem';
import { DropdownList } from 'components/DropdownList';
import { Icon } from 'components/Icon';
import { useAuth } from 'providers/Auth';
import css from './DropdownAccount.module.scss';
import classes from 'classnames';
import { useNavigate } from 'react-router';

interface IDropdownAccountProps {
	isOpen?: boolean;
}

export const DropdownAccount: React.FC<IDropdownAccountProps> = props => {
	const { isOpen } = props;
	const { logout, user: currentUser } = useAuth();
	const navigate = useNavigate();

	const accountDropdownItems: TDropdownItem[] = [
		{ title: 'Account settings', action: () => navigate('/accountbasicdetails') },
		{ title: 'Log out', action: logout }
	];

	return (
		<DropdownList
			type='control'
			items={accountDropdownItems}
			iconRight={{
				type: 'CaretDown',
				size: 'small',
				color: 'blue'
			}}
			buttonClassName={css.dropdownAccountButton}
			size='small'
			className={css.dropdownAccount}
			classNames={{ dropdownContentArea: css.dropdownAccountContentArea }}
			ContentTop={() => (
				<div className={css.profileContent}>
					<Icon size='large' className={css.buttonLabelProfileIcon} type='UserCircle'></Icon>
					<div>
						<h2 className={classes(css.name, 'h5')}>{currentUser.name}</h2>
						<p className={css.username}>{currentUser.email}</p>
					</div>
				</div>
			)}
			dropdownPosition='right'
			isOpen={isOpen}
		>
			Account
			<span className='screenReaderText'>Account Dropdown</span>
		</DropdownList>
	);
};
