import React from 'react';
import { useAuth } from 'providers/Auth/AuthProvider';
import { useNavigate, useParams } from 'react-router';
import { useGetUser, User, useDeleteUser, useUpdateUser, useRequestPasswordReset } from 'api/user';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { ErrorView } from 'views/ErrorView';
import { Button } from 'components/Button';
import { Alert } from 'components/Alert';
import { Panel } from 'components/Panel';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { Spinner } from 'components/Spinner';
import { LoadingDelay } from 'components/LoadingDelay';
import { PageFrame } from 'components/PageFrame';
import { useModal } from 'providers/Modal/ModalProvider';
import { GridContainer } from 'components/Grid/GridContainer/GridContainer';
import { GridItem } from 'components/Grid/GridItem/GridItem';
import { Input } from 'components/Form/Input/Input';
import { FormFooter } from 'components/Form/FormFooter/FormFooter';
import { Select } from 'components/Form/Select/Select';
import { Detail } from 'components/Detail/Detail';
import { userRoleIdMapping } from 'common/utils/userRoleMapping';

export const UserManagementView = () => {
	const { isLoading } = useAuth();
	const navigate = useNavigate();
	const { userId } = useParams<{ userId: string }>();
	const { createModal, removeModal, setModalLoading, setIsModalError } = useModal();
	const { mutateAsync: removeUser } = useDeleteUser();
	const {
		mutateAsync: requestPasswordReset,
		error: requestPasswordUpdateError,
		data: requestPasswordUpdateData,
		isLoading: requestPasswordUpdateIsLoading
	} = useRequestPasswordReset();
	const {
		mutateAsync: updateUser,
		error: updateUserError,
		data: updateUserData,
		isLoading: isUpdateUserLoading
	} = useUpdateUser();
	const { data: getUserData, isLoading: isGetUserLoading, error: getUserError } = useGetUser(userId);

	const handleRemoveUser = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, user: User) => {
		createModal({
			sourceEvent: event,
			heading: 'Remove User',
			Content: () => (
				<>
					<p>
						Are you sure you want to remove{' '}
						<strong>
							{user.firstName} {user.lastName}
						</strong>
						?
					</p>
					<p>This user will no longer have access to the system if removed.</p>
				</>
			),
			primaryButtonType: 'danger',
			primaryButtonLabel: 'remove',
			primaryButtonAction: async () => {
				setIsModalError(false);
				setModalLoading(true);
				try {
					await removeUser({ ...user });
					navigate('/admin/usermanagement', {
						state: {
							bannerSuccess: {
								name: `${user.firstName} ${user.lastName}`,
								message: 'was successfully removed.'
							}
						}
					});
					removeModal();
				} catch {
					setIsModalError(true);
				}
				setModalLoading(false);
			}
		});
	};

	if (getUserError) {
		return <ErrorView isInAppFrame={true} />;
	}

	if (isLoading || isGetUserLoading) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	return (
		<PageFrame
			title={`${getUserData.firstName} ${getUserData.lastName}`}
			backLocation={{ to: '/admin/usermanagement', label: 'Back to all users' }}
			ContentTopRight={() => (
				<Button
					type={'danger'}
					onClick={event => {
						event.persist();
						handleRemoveUser(event, getUserData);
					}}
				>
					Remove User
				</Button>
			)}
		>
			<Container>
				<Section>
					<Panel title={'Account Details'}>
						{updateUserData && !isUpdateUserLoading && (
							<Alert type='success-light'>Details were successfully updated.</Alert>
						)}
						<Formik
							initialValues={{
								firstName: getUserData.firstName,
								lastName: getUserData.lastName,
								role: getUserData?.roleId,
								companyAccountNumber: getUserData?.company?.accountNumber
							}}
							validateOnChange={false}
							validateOnBlur={false}
							onSubmit={async (data, { setSubmitting }) => {
								setSubmitting(true);
								const { role, ...remainingData } = data;
								try {
									await updateUser({
										...getUserData,
										...remainingData,
										roleId: role
									});
									setSubmitting(false);
								} catch {
									setSubmitting(false);
								}
							}}
							validationSchema={yup.object().shape({
								firstName: yup.string().required('First name is required'),
								lastName: yup.string().required('Last name is required'),
								role: yup.string().required('Role is required'),
								companyAccountNumber: yup.string().when('role', {
									is: (role: string) => role === userRoleIdMapping('Customer'),
									then: yup.string().required('Customer ship to number is required')
								})
							})}
						>
							{props => {
								return (
									<>
										{updateUserError && !props.isSubmitting && (
											<Alert type='danger-light'>
												There was an error updating the user, please try again.
											</Alert>
										)}
										<Form>
											<GridContainer columnAmount='2'>
												<GridItem>
													<Input
														formikProps={props}
														label={'First Name'}
														name={'firstName'}
														placeholder={'Enter your first name'}
													></Input>
												</GridItem>
												<GridItem>
													<Input
														formikProps={props}
														label={'Last Name'}
														name={'lastName'}
														placeholder={'Enter your last name'}
													></Input>
												</GridItem>
												<GridItem>
													<Detail title={'Email'} type='form'>
														{getUserData.email}
													</Detail>
												</GridItem>
												<GridItem>
													<Select label={'Role'} name='role' formikProps={props}>
														<option value={userRoleIdMapping('Administrator')}>Administrator</option>
														<option value={userRoleIdMapping('Customer')}>Customer</option>
														<option value={userRoleIdMapping('Customer Service Employee')}>
															Customer Service Employee
														</option>
													</Select>
												</GridItem>
												{props.values.role === userRoleIdMapping('Customer') && (
													<GridItem>
														<Input
															formikProps={props}
															label={'Customer Ship To Number'}
															name={'companyAccountNumber'}
															placeholder={"Enter the user's company customer ship to number"}
														></Input>
													</GridItem>
												)}
											</GridContainer>
											<FormFooter>
												<Button type='primary' htmlType='submit' isLoading={props.isSubmitting}>
													Save user
												</Button>
											</FormFooter>
										</Form>
									</>
								);
							}}
						</Formik>
					</Panel>
				</Section>
				<Section>
					<Panel title={'Password Reset'}>
						{requestPasswordUpdateData && !requestPasswordUpdateIsLoading && (
							<Alert type='success-light'>Password reset was successfully sent.</Alert>
						)}
						{requestPasswordUpdateError && !requestPasswordUpdateIsLoading && (
							<Alert type='danger-light'>
								There was an error requesting a new password for the user, please try again.
							</Alert>
						)}
						<p>
							Clicking the button below will send the user a password reset to the email address listed
							above.
						</p>
						<Button onClick={() => requestPasswordReset(getUserData)}>Send Password Reset</Button>
					</Panel>
				</Section>
			</Container>
		</PageFrame>
	);
};
