import { OpeningQuestions, OpeningTemplateQuestions } from './types';
import * as yup from 'yup';

export const slidingOpeningTemplateQuestions: OpeningTemplateQuestions = [
	{
		stepTitle: 'Select your system type:',
		label: '',
		key: 'type',
		validator: {
			type: yup.string().required()
		},
		values: [
			{
				label: 'MUTO',
				labelTooltip: 'MUTO: Full featured, multifunction sliding door system',
				key: 'muto'
			},
			{
				label: 'RS-120',
				labelTooltip: 'RS-120: A lightweight yet rugged track and frameless sliding system',
				key: 'rs120'
			},
			{
				label: 'DRS-120',
				labelTooltip:
					'DRS-120: A lightweight yet rugged track and sliding system including top and bottom DRS rails',
				key: 'drs120'
			}
		]
	},
	{
		stepTitle: 'Do you require a single door or a paired opening?',
		label: 'Select between single or double door layouts:',
		key: 'doorLayout',
		values: [
			{
				label: 'Single',
				key: 'single'
			},
			{
				label: 'Double',
				key: 'double'
			}
		]
	},
	{
		stepTitle: 'Select your desired mounting type:',
		label:
			'Headers are available in a variety of widths and mounting options and give your door and sidelites the support and security they need.',
		key: 'mountingType',
		values: [
			{
				label: 'Ceiling Mount with Sidelite',
				labelTooltip:
					'Ceiling Mount with Sidelite: Hardware is installed to the ceiling and contains both fixed and sliding panels',
				key: 'ceilingMountSidelite',
				questions: [
					{
						type: 'text',
						label: 'Number of Fixed Panels',
						key: 'NumberOfFixedPanels',
						validator: {
							NumberOfFixedPanels: yup.string().required('Number of fixed panels is required')
						}
					}
				]
			},
			{
				label: 'Ceiling Mount',
				labelTooltip: 'Ceiling Mount: installed to the ceiling',
				key: 'ceilingMount',
				questions: [
					{
						type: 'text',
						label: 'Number of Fixed Panels',
						key: 'NumberOfFixedPanels',
						validator: {
							NumberOfFixedPanels: yup.string().required('Number of fixed panels is required')
						}
					}
				]
			},
			{
				label: 'Wall Mount',
				labelTooltip: 'Wall Mount: installed on the finished wall (sliding systems only)',
				key: 'wallMount',
				conditional: {
					type: 'OR',
					condition: ['type:muto', 'type:rs120']
				}
			},
			{
				label: 'Glass Mount',
				labelTooltip:
					'Glass Mount: Hardware is installed on sidelites, but under the transom panel (aka. floating) (glass fab. Formula to determine # of holes)',
				key: 'glassMount',
				conditional: {
					type: 'OR',
					condition: ['type:muto']
				},
				questions: [
					{
						type: 'text',
						label: 'Number of Fixed Panels',
						key: 'NumberOfFixedPanels',
						validator: {
							NumberOfFixedPanels: yup.string().required('Number of fixed panels is required')
						}
					}
				]
			}
		]
	},
	{
		stepTitle: 'Select your desired Glass Thickness:',
		label:
			'Please note if you are looking for a different thickness, specify other below and you can let our customer service team know before submitting your quote.',
		labelTooltip: 'MUTO Max door thickness 17/32 - MUTO Glass Mount Fixed glass max thickness 3/4',
		key: 'glassThickness',
		validator: {
			glassThickness: yup.string().required()
		},
		values: [
			{
				label: '3/8 inch',
				key: '3-8inch'
			},
			{
				label: '1/2 inch',
				key: '1-2inch'
			},
			{
				label: '9/16 inch',
				key: '9-16inch',
				conditional: {
					type: 'OR',
					condition: ['type:muto']
				}
			},
			{
				label: '5/8 inch',
				key: '5-8inch',
				conditional: {
					type: 'OR',
					condition: ['mountingType:glassMount']
				}
			},
			{
				label: '3/4 inch',
				key: '3-4inch',
				conditional: {
					type: 'OR',
					condition: ['mountingType:glassMount']
				}
			}
		]
	},
	// add new question panelWidth for drs120 and rs120
	{
		stepTitle: 'Select your desired panel width:',
		label: '',
		key: 'panelWidth',
		validator: {
			panelWidth: yup.string().required()
		},
		conditional: {
			type: 'OR',
			condition: ['type:drs120', 'type:rs120']
		},
		values: [
			{
				label: '20-35 inch',
				key: '20-35-inch'
			},
			{
				label: '36-47 inch',
				key: '36-47-inch'
			},
			{
				label: '48-59 inch',
				key: '48-59-inch'
			},
			{
				label: '60-72 inch',
				key: '60-72-inch'
			}
		]
	},
	{
		stepTitle: 'Select your desired finish:',
		label: 'Available finishes can vary depending on the type of system.',
		key: 'finish',
		validator: {
			finish: yup.string().required()
		},
		values: [
			{
				label: '101 - Clear Anodized',
				key: '101',
				conditional: {
					type: 'OR',
					condition: ['type:rs120', 'type:drs120']
				}
			},
			{
				label: '108 - Dark Bronze Anodized',
				key: '108',
				conditional: {
					type: 'OR',
					condition: ['type:rs120', 'type:drs120']
				}
			},
			{
				label: '109 - Black Anodized',
				key: '109',
				conditional: {
					type: 'OR',
					condition: ['type:drs120']
				}
			},
			{
				label: '113 - Sim. Satin Stainless Steel',
				key: '113',
				conditional: {
					type: 'OR',
					condition: ['type:muto']
				}
			},
			{
				label: '150 - Clear Anodized',
				key: '150',
				conditional: {
					type: 'OR',
					condition: ['type:muto']
				}
			},
			{
				label: '199 - Special Anodized',
				key: '199',
				conditional: {
					type: 'OR',
					condition: ['type:muto']
				}
			},
			{
				label: '399 - Special Coating',
				key: '399',
				conditional: {
					type: 'OR',
					condition: ['type:muto']
				}
			},
			{
				label: '503 - Polished Brass without Lacquer',
				key: '503',
				conditional: {
					type: 'OR',
					condition: ['type:drs120']
				}
			},
			{
				label: '599 - Satin Brass without Lacquer',
				key: '599',
				conditional: {
					type: 'OR',
					condition: ['type:drs120']
				}
			},
			{
				label: '600 - Satin Bronze without Lacquer',
				key: '600',
				conditional: {
					type: 'OR',
					condition: ['type:drs120']
				}
			},
			{
				label: '601 - Polished Bronze without Lacquer',
				key: '601',
				conditional: {
					type: 'OR',
					condition: ['type:drs120']
				}
			},
			{
				label: '693 - Black (Coated)',
				key: '693',
				conditional: {
					type: 'OR',
					condition: ['type:muto']
				}
			},
			{
				label: '700 - Satin Stainless',
				key: '700',
				conditional: {
					type: 'OR',
					condition: ['type:rs120', 'type:drs120']
				}
			},
			{
				label: '701 - Polished Stainless',
				key: '701',
				conditional: {
					type: 'OR',
					condition: ['type:rs120', 'type:drs120']
				}
			},
			{
				label: '711 - Black Anodized',
				key: '711',
				conditional: {
					type: 'OR',
					condition: ['type:muto']
				}
			},
			{
				label: '999 - Custom Finish',
				key: '999',
				conditional: {
					type: 'OR',
					condition: ['type:rs120', 'type:drs120']
				}
			}
		]
	},
	{
		stepTitle: 'Select your desired rail profile',
		label:
			'Rails come in square and tapered options, plus different heights/sizes. Tapered options provide an ADA-compliant solution for bottom rails under 10" high.',
		key: 'railProfile',
		validator: {
			railProfile: yup.string().required()
		},
		conditional: {
			type: 'OR',
			condition: ['type:drs120']
		},
		values: [
			{
				label: 'Square',
				key: 'square'
			},
			{
				label: 'Tapered',
				key: 'tapered'
			}
		]
	},
	{
		stepTitle: 'Select your desired rail height on top:',
		label: 'A variety of rail heights are available for top rails.',
		key: 'railHeightTop',
		validator: {
			railHeightTop: yup.string().required()
		},
		conditional: {
			type: 'OR',
			condition: ['type:drs120']
		},
		values: [
			{
				label: '2-1/2 inch',
				key: '2-1-2inch'
			},
			{
				label: '3-5/8 inch',
				key: '3-5-8inch', // remove tapered rail profile if glass thickness is above 9/16
				conditional: {
					type: 'OR',
					condition: [
						'railProfile:square',
						{
							type: 'AND',
							condition: [
								'railProfile:tapered',
								{
									type: 'OR',
									condition: [
										'glassThickness:3-8inch',
										'glassThickness:1-2inch',
										'glassThickness:9-16inch'
									]
								}
							]
						}
					]
				}
			},
			{
				label: '4 inch',
				key: '4inch', // remove tapered rail profile if glass thickness is above 9/16
				conditional: {
					type: 'OR',
					condition: [
						{
							type: 'AND',
							condition: [
								'railProfile:square',
								{
									type: 'OR',
									condition: [
										'glassThickness:3-8inch',
										'glassThickness:1-2inch',
										'glassThickness:9-16inch',
										'glassThickness:3-4inch'
									]
								}
							]
						},
						{
							type: 'AND',
							condition: [
								'railProfile:tapered',
								{
									type: 'OR',
									condition: [
										'glassThickness:3-8inch',
										'glassThickness:1-2inch',
										'glassThickness:9-16inch'
									]
								}
							]
						}
					]
				}
			},
			{
				label: '6 inch',
				key: '6inch', // There are no tapered rail
				conditional: {
					type: 'OR',
					condition: ['railProfile:square']
				}
			},
			{
				label: '10 inch',
				key: '10inch', // There are no tapered rail, if 13/16 glassThickness is chosen then hide 10inch option
				conditional: {
					type: 'AND',
					condition: [
						'railProfile:square',
						{
							type: 'OR',
							condition: [
								'glassThickness:3-8inch',
								'glassThickness:1-2inch',
								'glassThickness:9-16inch',
								'glassThickness:5-8inch',
								'glassThickness:11-16inch',
								'glassThickness:3-4inch'
							]
						}
					]
				}
			}
		]
	},
	{
		stepTitle: 'Select your desired rail height on bottom:',
		label: 'A variety of rail heights are available for bottom rails.',
		key: 'railHeightBottom',
		validator: {
			railHeightBottom: yup.string().required()
		},
		conditional: {
			type: 'OR',
			condition: ['type:drs120']
		},
		values: [
			{
				label: '2-1/2 inch',
				key: '2-1-2inch',
				conditional: {
					type: 'OR',
					condition: ['type:drs120']
				}
			},
			{
				label: '3-5/8 inch',
				key: '3-5-8inch',
				conditional: {
					type: 'OR',
					condition: [
						'railProfile:square',
						{
							type: 'AND',
							condition: [
								'railProfile:tapered',
								{
									type: 'OR',
									condition: [
										'glassThickness:3-8inch',
										'glassThickness:1-2inch',
										'glassThickness:9-16inch'
									]
								}
							]
						}
					]
				} // remove tapered rail profile if glass thickness is above 9/16
			},
			{
				label: '4 inch',
				key: '4inch',
				conditional: {
					type: 'OR',
					condition: [
						{
							type: 'AND',
							condition: [
								'railProfile:square',
								{
									type: 'OR',
									condition: [
										'glassThickness:3-8inch',
										'glassThickness:1-2inch',
										'glassThickness:9-16inch',
										'glassThickness:3-4inch'
									]
								}
							]
						},
						{
							type: 'AND',
							condition: [
								'railProfile:tapered',
								{
									type: 'OR',
									condition: [
										'glassThickness:3-8inch',
										'glassThickness:1-2inch',
										'glassThickness:9-16inch'
									]
								}
							]
						}
					]
				} // remove tapered rail profile if glass thickness is above 9/16
			},
			{
				label: '6 inch',
				key: '6inch',
				conditional: {
					type: 'AND',
					condition: [
						'type:drs120',
						{
							type: 'OR',
							condition: ['railProfile:square']
						}
					] // There are no tapered rail
				}
			},
			{
				label: '10 inch',
				key: '10inch',
				conditional: {
					type: 'AND',
					condition: [
						'railProfile:square',
						{
							type: 'OR',
							condition: [
								'glassThickness:3-8inch',
								'glassThickness:1-2inch',
								'glassThickness:9-16inch',
								'glassThickness:5-8inch',
								'glassThickness:11-16inch',
								'glassThickness:3-4inch'
							]
						}
					]
				} // There are no tapered rail, if 13/16 glassThickness is chosen then hide 10inch option
			}
		]
	},
	{
		stepTitle: 'Do you need additional function?',
		label: 'Select additional function if required:',
		key: 'additionalFunction',
		validator: {
			additionalFunction: yup.string().required()
		},
		conditional: {
			type: 'OR',
			condition: [
				'type:muto',
				{
					type: 'AND',
					condition: ['type:rs120', 'doorLayout:double']
				}
			]
		},
		values: [
			{
				label: 'DORMOTION',
				key: 'dormotion',
				conditional: {
					type: 'OR',
					condition: ['type:muto']
				},
				questions: [
					{
						type: 'select',
						label: 'Select which DORMOTION you want',
						key: 'DORMOTION',
						validator: {
							DORMOTION: yup.string().required('DORMOTION Selection is required')
						},
						options: [
							{
								label: 'XL150',
								value: 'dormotion-xl150'
							},
							{
								label: 'XL80',
								value: 'dormotion-xl80'
							}
						]
					}
				]
			},
			{
				label: 'Syncro',
				key: 'syncro',
				conditional: {
					type: 'OR',
					condition: ['doorLayout:double']
				}
			},
			{
				label: 'None',
				key: 'none'
			}
		]
	},
	{
		stepTitle: 'Do you need a bottom lock?',
		label: '',
		key: 'bottomLock',
		validator: {
			bottomLock: yup.string().required()
		},
		values: [
			{
				label: 'Yes',
				key: 'yes'
			},
			{
				label: 'No',
				key: 'no'
			}
		]
	}
	// Pull handle question are removed at the moment on purpose check with Curtis Massey when to put them back
	// {
	// 	stepTitle: 'Select a desired handle:',
	// 	label: '',
	// 	key: 'pullHandle',
	// 	validator: {
	// 		pullHandle: yup.string().required()
	// 	},
	// 	values: [
	// 		{
	// 			label: 'C-Pulls: 12 inch solid',
	// 			labelTooltip: 'C-Pulls: Form and functionality in a gracefully curved shape',
	// 			key: 'c-pull-12-inch-solid'
	// 		},
	// 		{
	// 			label: 'C-Pulls: 12 inch tube',
	// 			labelTooltip: 'C-Pulls: Form and functionality in a gracefully curved shape',
	// 			key: 'c-pull-12-inch-tube'
	// 		},
	// 		{
	// 			label: 'TG9387 (Stock): 12 inch',
	// 			labelTooltip:
	// 				'TG9387: 1-1/4 inch diameter Non-Locking Ladder Pull, a pull handle with straight supports',
	// 			key: 'tg9387-stock-12-inch'
	// 		},
	// 		{
	// 			label: 'TG9387 (Stock): 18 inch',
	// 			labelTooltip:
	// 				'TG9387: 1-1/4 inch diameter Non-Locking Ladder Pull, a pull handle with straight supports',
	// 			key: 'tg9387-stock-18-inch'
	// 		},
	// 		{
	// 			label: 'TG9387 (Stock): 36 inch',
	// 			labelTooltip:
	// 				'TG9387: 1-1/4 inch diameter Non-Locking Ladder Pull, a pull handle with straight supports',
	// 			key: 'tg9387-stock-36-inch'
	// 		},
	// 		{
	// 			label: 'TG9387 (Stock): 42 inch',
	// 			labelTooltip:
	// 				'TG9387: 1-1/4 inch diameter Non-Locking Ladder Pull, a pull handle with straight supports',
	// 			key: 'tg9387-stock-42-inch'
	// 		},
	// 		{
	// 			label: 'TG9387 (Stock): 60 inch',
	// 			labelTooltip:
	// 				'TG9387: 1-1/4 inch diameter Non-Locking Ladder Pull, a pull handle with straight supports',
	// 			key: 'tg9387-stock-60-inch'
	// 		},
	// 		{
	// 			label: 'TG138: 49 inch',
	// 			labelTooltip:
	// 				'TG138: 1-3/8 inch diameter Non-locking Ladder Pull, a pull handle with straight supports. Design and pull diameter match TG138 Locking Ladder Pull.',
	// 			key: 'tg138-49-inch'
	// 		},
	// 		{
	// 			label: 'TG138: 60 inch',
	// 			labelTooltip:
	// 				'TG138: 1-3/8 inch diameter Non-locking Ladder Pull, a pull handle with straight supports. Design and pull diameter match TG138 Locking Ladder Pull.',
	// 			key: 'tg138-60-inch'
	// 		},
	// 		{
	// 			label: 'TG138: 72 inch',
	// 			labelTooltip:
	// 				'TG138: 1-3/8 inch diameter Non-locking Ladder Pull, a pull handle with straight supports. Design and pull diameter match TG138 Locking Ladder Pull.',
	// 			key: 'tg138-72-inch'
	// 		},
	// 		{
	// 			label: 'TG138: 84 inch',
	// 			labelTooltip:
	// 				'TG138: 1-3/8 inch diameter Non-locking Ladder Pull, a pull handle with straight supports. Design and pull diameter match TG138 Locking Ladder Pull.',
	// 			key: 'tg138-84-inch'
	// 		},
	// 		{
	// 			label: 'TG138 LLP: 49 inch',
	// 			labelTooltip:
	// 				'TG138: 1-3/8 inch diameter Non-locking Ladder Pull, a pull handle with straight supports. Design and pull diameter match TG138 Locking Ladder Pull.',
	// 			key: 'tg138llp-49-inch',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: ['bottomLock:no']
	// 			}
	// 		},
	// 		{
	// 			label: 'TG138 LLP: 60 inch',
	// 			labelTooltip:
	// 				'TG138: 1-3/8 inch diameter Non-locking Ladder Pull, a pull handle with straight supports. Design and pull diameter match TG138 Locking Ladder Pull.',
	// 			key: 'tg138llp-60-inch',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: ['bottomLock:no']
	// 			}
	// 		},
	// 		{
	// 			label: 'TG138 LLP: 72 inch',
	// 			labelTooltip:
	// 				'TG138: 1-3/8 inch diameter Non-locking Ladder Pull, a pull handle with straight supports. Design and pull diameter match TG138 Locking Ladder Pull.',
	// 			key: 'tg138llp-72-inch',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: ['bottomLock:no']
	// 			}
	// 		},
	// 		{
	// 			label: 'TG138 LLP: 84 inch',
	// 			labelTooltip:
	// 				'TG138: 1-3/8 inch diameter Non-locking Ladder Pull, a pull handle with straight supports. Design and pull diameter match TG138 Locking Ladder Pull.',
	// 			key: 'tg138llp-84-inch',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: ['bottomLock:no']
	// 			}
	// 		},
	// 		{
	// 			label: 'Custom Handle',
	// 			labelTooltip: 'Custom handle selection directs request to customer service for assistance.',
	// 			key: 'custom'
	// 		},
	// 		{
	// 			label: 'No Handle',
	// 			labelTooltip: 'No Handle: if you do NOT want a handle select this option',
	// 			key: 'none'
	// 		}
	// 	]
	// },
	// {
	// 	stepTitle: 'What type of handle finish do you desire?',
	// 	label:
	// 		'We provide a wide range of handle types, including glass panics. Some offer custom lengths, and most offer multiple stock options.',
	// 	key: 'pullHandleFinish',
	// 	validator: {
	// 		pullHandleFinish: yup.string().required()
	// 	},
	// 	conditional: {
	// 		type: 'OR',
	// 		condition: [
	// 			'pullHandle:c-pull-12-inch-solid',
	// 			'pullHandle:c-pull-12-inch-tube',
	// 			'pullHandle:tg9387-stock-12-inch',
	// 			'pullHandle:tg9387-stock-18-inch',
	// 			'pullHandle:tg9387-stock-36-inch',
	// 			'pullHandle:tg9387-stock-42-inch',
	// 			'pullHandle:tg9387-stock-60-inch',
	// 			'pullHandle:tg138-49-inch',
	// 			'pullHandle:tg138-60-inch',
	// 			'pullHandle:tg138-72-inch',
	// 			'pullHandle:tg138-84-inch',
	// 			'pullHandle:tg138llp-49-inch',
	// 			'pullHandle:tg138llp-60-inch',
	// 			'pullHandle:tg138llp-72-inch',
	// 			'pullHandle:tg138llp-84-inch'
	// 		]
	// 	},
	// 	values: [
	// 		{
	// 			label: '101 - Clear Anodized',
	// 			key: '101',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: ['pullHandle:c-pull-12-inch-solid', 'pullHandle:c-pull-12-inch-tube']
	// 			}
	// 		},
	// 		{
	// 			label: '108 - Dark Bronze Anodized',
	// 			key: '108',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: ['pullHandle:c-pull-12-inch-solid', 'pullHandle:c-pull-12-inch-tube']
	// 			}
	// 		},
	// 		{
	// 			label: '109 - Black Anodized',
	// 			key: '109',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: ['pullHandle:c-pull-12-inch-solid', 'pullHandle:c-pull-12-inch-tube']
	// 			}
	// 		},
	// 		{
	// 			label: '503 - Polished Brass without Lacquer',
	// 			key: '503',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: ['pullHandle:c-pull-12-inch-solid', 'pullHandle:c-pull-12-inch-tube']
	// 			}
	// 		},
	// 		{
	// 			label: '599 - Satin Brass without Lacquer',
	// 			key: '599',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: ['pullHandle:c-pull-12-inch-solid', 'pullHandle:c-pull-12-inch-tube']
	// 			}
	// 		},
	// 		{
	// 			label: '600 - Satin Bronze without Lacquer',
	// 			key: '600',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: ['pullHandle:c-pull-12-inch-solid', 'pullHandle:c-pull-12-inch-tube']
	// 			}
	// 		},
	// 		{
	// 			label: '601 - Polished Bronze without Lacquer',
	// 			key: '601',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: ['pullHandle:c-pull-12-inch-solid', 'pullHandle:c-pull-12-inch-tube']
	// 			}
	// 		},
	// 		{
	// 			label: '693 - Black Coated',
	// 			key: '693',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: [
	// 					'pullHandle:tg138-49-inch',
	// 					'pullHandle:tg138-60-inch',
	// 					'pullHandle:tg138-72-inch',
	// 					'pullHandle:tg138-84-inch',
	// 					'pullHandle:tg138llp-49-inch',
	// 					'pullHandle:tg138llp-60-inch',
	// 					'pullHandle:tg138llp-72-inch',
	// 					'pullHandle:tg138llp-84-inch'
	// 				]
	// 			}
	// 		},
	// 		{
	// 			label: '700 - Satin Stainless',
	// 			key: '700'
	// 		},
	// 		{
	// 			label: '701 - Polished Stainless',
	// 			key: '701',
	// 			conditional: {
	// 				type: 'OR',
	// 				condition: [
	// 					'pullHandle:c-pull-12-inch-solid',
	// 					'pullHandle:c-pull-12-inch-tube',
	// 					'pullHandle:tg138-49-inch',
	// 					'pullHandle:tg138-60-inch',
	// 					'pullHandle:tg138-72-inch',
	// 					'pullHandle:tg138-84-inch',
	// 					'pullHandle:tg138llp-49-inch',
	// 					'pullHandle:tg138llp-60-inch',
	// 					'pullHandle:tg138llp-72-inch',
	// 					'pullHandle:tg138llp-84-inch'
	// 				]
	// 			}
	// 		}
	// 	]
	// }
];

export const slidingOpeningGlobalQuestions: OpeningQuestions = [];
