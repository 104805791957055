import React from 'react';
import css from './TableMetaActions.module.scss';
import classes from 'classnames';

interface ITableMetaActions {
	actions?: React.ReactElement<any>[];
	className?: string;
}

export const TableMetaActions: React.FC<ITableMetaActions> = props => {
	const { actions, className } = props;

	return (
		<div className={classes(css.tableMetaActions, className)}>
			{actions.map((action, index) => {
				return React.cloneElement(action, {
					className: classes(action.props.className, css.action),
					key: index
				});
			})}
		</div>
	);
};
