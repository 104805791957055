import React, { useState, useEffect, useRef } from 'react';
import { useMatch, useLocation, useNavigate } from 'react-router';
import { PaginationForm } from 'components/PaginationForm';
import { coerceObjectPropertyToNumber } from 'common/utils/coerceObjectPropertyToNumber';
import queryString from 'query-string';
import { Table, TOnClick, TActiveSort, IQueryStrings, ITableProps } from 'components/Table';
import { TableMetaActions } from 'components/Table/TableMetaActions';
import { InputSearch } from 'components/Form/InputSearch/InputSearch';
import { urlConstructor } from 'common/utils/urlConstructor';
import { getUpdatedTableActiveSort } from 'common/utils/getUpdatedTableActiveSort';
import { TableMetaContent } from 'components/Table/TableMetaContent/TableMetaContent';
import { RemoveProp } from 'common/types/TypeHelpers';
import css from 'components/Table/TableController.module.scss';
import { UseQueryResult } from 'react-query';
import { useHasDataLoadedOnce } from 'common/hooks/useHasDataLoadedOnce';
import { usePrevious } from 'common/hooks/usePrevious';

// Set text to text to use as aria-role below to avoid eslint error // eslint-disable-next-line doesn't work in this instance for an unknown reason
const text = 'text';

export interface ICurrentTableValues {
	hasDataLoadedOnce?: boolean;
	activeSort?: TActiveSort;
	itemsPerPage?: number;
	pageNumber?: number;
	sortProperty?: string;
	formattedSearchQuery?: string;
	searchQuery?: string;
	isLoading: boolean;
	totalCount: number;
}

type TSelector = (data: any, queryInput?: ICurrentTableValues, rawData?: any) => any;

interface ITableController {
	baseRoute: string;
	itemsPerPage: number;
	useQuery: (...any: any) => UseQueryResult<any, unknown>;
	isUrlUpdated: boolean;
	emptyMessage: string;
	dataSelector: TSelector;
	totalCountSelector?: TSelector;
	setCurrentTableValues?: (data: ICurrentTableValues) => void;
	defaultSortColumn?: number;
	defaultSortDirection?: 'asc' | 'desc';
	hasSearch?: boolean;
	searchPlaceholder?: string;
	hasPagination?: boolean;
	paginationUpdatesScroll?: false | 'topOfTable' | 'topOfPage';
	additionalActions?: React.ReactElement<any>[];
	queryStringObject?: object;
	idProperty?: string;
	tableHandlesPaginatingData?: boolean;
	isTitleInTable?: boolean;
}

type ITableControllerTableProps = RemoveProp<
	ITableProps,
	'data' | 'contentTopRight' | 'title' | 'contentBottomRight' | 'onClick' | 'activeSort' | 'message'
>;

const getFormattedSearchQuery = (searchQuery: string) => {
	return '%' + searchQuery.replace(/%/g, '\\%') + '%';
};

const useRoutePageNumber = (parameter: string) => {
	const navigate = useNavigate();
	const route = useMatch(parameter);
	const pageNumber = Number(route?.params?.pageNumber || 1);
	if (isNaN(pageNumber)) {
		navigate('/');
	}
	return pageNumber;
};

export const useTableProperties = (baseRoute: string, defaultTableProperties?: ICurrentTableValues) => {
	const pageNumber = useRoutePageNumber(`${baseRoute}:pageNumber`);
	const tableProperties = useState<ICurrentTableValues>({
		itemsPerPage: 10,
		pageNumber,
		formattedSearchQuery: getFormattedSearchQuery(defaultTableProperties?.searchQuery || ''),
		...defaultTableProperties
	});

	return tableProperties;
};

export const TableController: React.FC<
	ITableController & ITableControllerTableProps /*& ITableControllerTableProps */
> = props => {
	const {
		headers,
		baseRoute,
		itemsPerPage,
		useQuery,
		isUrlUpdated,
		link,
		emptyMessage,
		dataSelector,
		totalCountSelector,
		setCurrentTableValues: externalSetCurrentTableValues,
		defaultSortColumn,
		defaultSortDirection,
		hasSearch = 'true',
		searchPlaceholder = 'Search data in any column across all pages',
		hasPagination = 'true',
		paginationUpdatesScroll = 'topOfTable',
		isSelectable,
		setClearSelection,
		onSelectionChange,
		className,
		type,
		contentClassnames,
		additionalActions = [],
		isFirstColumnHiddenOnMobile = false,
		isSingleColumnOnMobile = false,
		queryStringObject,
		idProperty = 'id',
		tableHandlesPaginatingData,
		isTitleInTable = true
	} = props;

	const location = useLocation();
	const navigate = useNavigate();
	const {
		sortDirection = defaultSortDirection,
		columnIndex = defaultSortColumn,
		searchQuery: searchQueryQueryParam = ''
	} = coerceObjectPropertyToNumber(queryString.parse(location.search), 'columnIndex') as IQueryStrings;
	const routePageNumber = useRoutePageNumber(`${baseRoute}:pageNumber`);
	const [pageNumber, setPageNumber] = useState(routePageNumber);
	const [activeSort, setActiveSort] = useState<TActiveSort>({ sortDirection, columnIndex });
	const sortProperty =
		typeof activeSort.columnIndex === 'number' ? headers[activeSort.columnIndex]?.property : undefined;
	const [searchQuery, setSearchQuery] = useState(searchQueryQueryParam);
	const [searchInputValue, setSearchInputValue] = useState(searchQueryQueryParam);
	const formattedSearchQuery = getFormattedSearchQuery(searchQuery);
	const baseUrl = baseRoute + pageNumber;
	const tableRef = useRef(null);
	const tableTitleRef = useRef(null);
	const [currentTableValues, setCurrentTableValues] = useState({
		itemsPerPage,
		pageNumber,
		sortProperty,
		activeSort,
		formattedSearchQuery,
		searchQuery,
		hasDataLoadedOnce: false,
		error: undefined,
		isLoading: false,
		totalCount: null
	});

	const { data, isLoading, error, isFetching } = useQuery(currentTableValues);

	const selectedData = dataSelector(data, currentTableValues);

	const getTotalCount = () => {
		if (totalCountSelector) {
			const count = totalCountSelector(selectedData, currentTableValues, data);
			if (typeof count === 'number') {
				return count;
			}
		} else {
			return null;
		}
	};

	const totalCount = getTotalCount();
	const previousTotalCount = usePrevious(totalCount);
	const firstItemLocation = Math.min((pageNumber - 1) * itemsPerPage + 1, totalCount);
	const lastItemLocation = Math.min(pageNumber * itemsPerPage, totalCount);
	// const getQueryString = urlConstructor('', { ...activeSort, searchQuery, ...queryStringObject });
	// NOTE: will need to rework the above line to handle column sorts and searchQuery in url params only queryStringObject supported currently
	const getQueryString = urlConstructor('', { ...queryStringObject });
	// const url = urlConstructor(baseUrl, { ...activeSort, searchQuery, ...queryStringObject });
	// NOTE: will need to rework the above line to handle column sorts and searchQuery in url params only queryStringObject supported currently
	const url = urlConstructor(baseUrl, { ...queryStringObject });
	const hasDataLoadedOnce = useHasDataLoadedOnce(isLoading);

	const [hasInitialPushStateBeenBypassed, setHasInitialPushStateBeenBypassed] = useState(false);

	useEffect(() => {
		if (
			typeof totalCount !== 'undefined' &&
			typeof previousTotalCount !== 'undefined' &&
			previousTotalCount !== totalCount
		) {
			setPageNumber(1);
		}
	}, [totalCount, previousTotalCount]);

	//Update URL if variables update
	useEffect(() => {
		// Table routing only supports route push and not replace
		if (hasInitialPushStateBeenBypassed) {
			if (isUrlUpdated) {
				if (paginationUpdatesScroll) {
					if (paginationUpdatesScroll === 'topOfTable') {
						if (tableRef && tableRef.current) {
							tableRef.current.scrollIntoView();
						}
					} else {
						window.scrollTo(0, 0);
					}
				}
				navigate(url);
			}
		} else {
			setHasInitialPushStateBeenBypassed(true);
		}
		// eslint-disable-next-line
	}, [pageNumber, searchQuery, isUrlUpdated, queryStringObject, tableRef]);

	const tableClickHandler: TOnClick = tableClickData => {
		setActiveSort(getUpdatedTableActiveSort(activeSort, tableClickData.columnIndex));
	};

	const searchInputHandler: React.ReactEventHandler<HTMLInputElement> = event => {
		const searchInputValue = (event.target as HTMLInputElement).value;
		setSearchInputValue(searchInputValue);
		if (searchInputValue === '') {
			setSearchQuery(searchInputValue);
			setPageNumber(1);
		}
	};

	const searchFormHandler: React.ReactEventHandler<HTMLFormElement> = event => {
		setSearchQuery(searchInputValue);
		setPageNumber(1);
		event.preventDefault();
	};

	const paginatedSelectedData = tableHandlesPaginatingData
		? (() => {
				if (selectedData?.length) {
					const start = (pageNumber - 1) * itemsPerPage;
					const end = start + itemsPerPage;
					return selectedData.slice(start, end);
				}
				return [];
		  })()
		: selectedData;

	const getTableMessage = () => {
		if (totalCount === 0 && !isLoading) {
			if (searchQuery) {
				return `We could not find a match for “${searchQuery}”. Please try another search.`;
			} else if (selectedData?.length === 0) {
				return emptyMessage;
			}
		}
		return null;
	};

	//Keeps external and internal state in sync
	useEffect(() => {
		const currentTableValues = {
			hasDataLoadedOnce,
			activeSort,
			itemsPerPage,
			pageNumber,
			sortProperty,
			formattedSearchQuery,
			searchQuery,
			error,
			data,
			isLoading,
			totalCount
		};
		if (externalSetCurrentTableValues) {
			externalSetCurrentTableValues(currentTableValues);
		}
		setCurrentTableValues(currentTableValues);
	}, [
		hasDataLoadedOnce,
		activeSort,
		itemsPerPage,
		pageNumber,
		sortProperty,
		formattedSearchQuery,
		searchQuery,
		error,
		setCurrentTableValues,
		externalSetCurrentTableValues,
		data,
		isLoading,
		totalCount
	]);

	const getTableActions = () => {
		if (hasSearch) {
			return [
				...additionalActions,
				<div>
					<form onSubmit={searchFormHandler}>
						<button type='submit' style={{ display: 'none' }}></button>
						<InputSearch
							label={'Search'}
							name={'number'}
							isLabelVisible={false}
							value={searchInputValue}
							onChange={searchInputHandler}
							className={css.searchInputField}
							placeholder={searchPlaceholder}
							onBlur={() => {
								setSearchQuery(searchInputValue);
							}}
						></InputSearch>
					</form>
				</div>
			];
		} else {
			return additionalActions;
		}
	};

	const tableActions = getTableActions();

	return (
		<>
			{hasDataLoadedOnce && !error && (
				<>
					{paginationUpdatesScroll === 'topOfTable' && (
						<span className={css.scrollReference} ref={tableRef}></span>
					)}
					{!isTitleInTable && hasPagination && totalCount > 0 && (
						<div className={css.marginBottom}>
							<span className={css.topTitle} role={text} ref={tableTitleRef} tabIndex={-1}>
								{`Viewing ${firstItemLocation}–${lastItemLocation} of ${totalCount}`}
							</span>
						</div>
					)}
					<Table
						className={className}
						contentClassnames={contentClassnames}
						headers={headers}
						data={paginatedSelectedData}
						title={
							isTitleInTable &&
							hasPagination &&
							totalCount > 0 && (
								<span role={text} ref={tableTitleRef} tabIndex={-1}>
									{`Viewing ${firstItemLocation}–${lastItemLocation} of ${totalCount}`}
								</span>
							)
						}
						contentTopRight={
							tableActions &&
							tableActions.length > 0 && (
								<TableMetaActions
									className={type === 'notification' && css.notificationContentTop}
									actions={tableActions}
								/>
							)
						}
						contentBottomRight={
							hasPagination &&
							!link && (
								<TableMetaContent
									paddingSize={'small'}
									contentRight={
										<PaginationForm
											baseRoute={baseRoute}
											itemsPerPage={itemsPerPage}
											itemAmount={totalCount}
											currentPage={pageNumber}
											setPageNumber={setPageNumber}
											linkQueryString={getQueryString}
										></PaginationForm>
									}
								></TableMetaContent>
							)
						}
						onClick={tableClickHandler}
						activeSort={activeSort}
						link={link}
						message={getTableMessage()}
						type={type}
						isSelectable={isSelectable}
						setClearSelection={setClearSelection}
						onSelectionChange={onSelectionChange}
						isFirstColumnHiddenOnMobile={isFirstColumnHiddenOnMobile}
						isSingleColumnOnMobile={isSingleColumnOnMobile}
						idProperty={idProperty}
						displayLoadingOverlay={isFetching}
					></Table>
				</>
			)}
		</>
	);
};
