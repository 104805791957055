import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router';
import css from './Header.module.scss';
import classes from 'classnames';
import { DropdownAccount } from 'components/AppFrame/DropdownAccount';
import { DropdownSupport } from 'components/AppFrame/DropdownSupport';
import { Link } from 'components/Link/Link';
import { disableBodyScroll, enableBodyScroll } from 'common/utils/bodyScrollLock';
import { ReactComponent as DormakabaLogo } from 'assets/svg/dormakaba-logo.svg';
import { Formik, Form } from 'formik';
import { InputSearch } from 'components/Form/InputSearch/InputSearch';
import { useAuth } from 'providers/Auth/AuthProvider';
import { isUserOfPermission } from 'common/utils/isUserOfPermission';

interface IHeaderProps {
	className?: string;
}

export const Header: React.FC<IHeaderProps> = props => {
	const { getPermissions } = useAuth();
	const navigate = useNavigate();
	const [isMobileMenuShown, setIsMobileMenuShown] = useState(false);
	const { className } = props;
	useEffect(() => {
		const sidebarRef = document.querySelector('#sidebar');
		if (isMobileMenuShown) {
			disableBodyScroll(sidebarRef);
		} else {
			enableBodyScroll(sidebarRef);
		}
	}, [isMobileMenuShown]);

	return (
		<>
			<div className={classes(css.headerSuperNav, css.hideFromPrint)}>
				{isUserOfPermission(['company:read:own'], getPermissions()) && (
					<>
						<DropdownSupport />
						<Link classNames={{ buttonContent: css.companyLink }} to='/accountcompanydetails'>
							Company Details
						</Link>
					</>
				)}
				<DropdownAccount />
			</div>
			<div className={css.headerContainer}>
				<div className={classes(css.header, className)} id='header'>
					<div className={css.headerNav}>
						<Link
							to='/'
							classNames={{ buttonContent: css.logoLink }}
							aria-label='Dormakaba Estimation Tool Home'
						>
							<DormakabaLogo />
						</Link>
						{isUserOfPermission(['company:read:own'], getPermissions()) && (
							<div className={css.headerNavRight}>
								<Formik
									initialValues={{ searchString: undefined }}
									onSubmit={async (data, { setSubmitting }) => {
										setSubmitting(true);
										navigate({
											pathname: '/products/search',
											search: `?searchString=${data.searchString}`
										});
										setSubmitting(false);
									}}
								>
									{props => {
										return (
											<Form>
												<InputSearch
													name={'searchString'}
													label={'Search products'}
													formikProps={props}
													labelPosition={'labelLeft'}
													classNameDomInput={classes(css.headerSearch, css.paddingRightImportant)}
													classNameDomInputIcon={css.paddingRightImportant}
												/>
											</Form>
										);
									}}
								</Formik>
							</div>
						)}
					</div>

					<button
						onClick={() => {
							setIsMobileMenuShown(!isMobileMenuShown);
						}}
						className={classes(css.menuToggle, css.hideFromPrint)}
						aria-controls='primary-menu'
						aria-expanded='false'
					>
						<div className={css.target}>
							<span></span>
							<span></span>
							<span></span>
						</div>
					</button>
				</div>
				<div
					className={classes(css.headerMenu, css.hideFromPrint, { [css.showMenu]: isMobileMenuShown })}
				>
					{isUserOfPermission(['create:users'], getPermissions()) && (
						<ul>
							{/* Sales Rep */}
							<li>
								<NavLink
									className={navData => (navData.isActive ? classes(css.current, css.link) : css.link)}
									to='/admin/salesrepmanagement'
									onClick={() => {
										setIsMobileMenuShown(false);
									}}
								>
									{/* show the navigation text as stacked */}
									<p className={css.headerLinksParagraph}>Sales Rep</p>
									<p className={css.headerLinksParagraph}>Management</p>
								</NavLink>
							</li>
							{/* Company */}
							<li>
								<NavLink
									className={navData => (navData.isActive ? classes(css.current, css.link) : css.link)}
									to='/admin/companymanagement'
									onClick={() => {
										setIsMobileMenuShown(false);
									}}
								>
									<p className={css.headerLinksParagraph}>Company</p>
									<p className={css.headerLinksParagraph}>Management</p>
								</NavLink>
							</li>
							{/* Product Code */}
							<li>
								<NavLink
									className={navData => (navData.isActive ? classes(css.current, css.link) : css.link)}
									to='/admin/productcodemanagement'
									onClick={() => {
										setIsMobileMenuShown(false);
									}}
								>
									<p className={css.headerLinksParagraph}>Product Code</p>
									<p className={css.headerLinksParagraph}>Management</p>
								</NavLink>
							</li>
							{/* Product */}
							<li>
								<NavLink
									className={navData => (navData.isActive ? classes(css.current, css.link) : css.link)}
									to='/admin/productmanagement'
									onClick={() => {
										setIsMobileMenuShown(false);
									}}
								>
									<p className={css.headerLinksParagraph}>Product</p>
									<p className={css.headerLinksParagraph}>Management</p>
								</NavLink>
							</li>
							{/* Company Pricing */}
							<li>
								<NavLink
									className={navData => (navData.isActive ? classes(css.current, css.link) : css.link)}
									to='/admin/companypricingmanagement'
									onClick={() => {
										setIsMobileMenuShown(false);
									}}
								>
									<p className={css.headerLinksParagraph}>Company Pricing</p>
									<p className={css.headerLinksParagraph}>Management</p>
								</NavLink>
							</li>
							{/* Product Finish Pricing */}
							<li>
								<NavLink
									className={navData => (navData.isActive ? classes(css.current, css.link) : css.link)}
									to='/admin/productfinishpricingmanagement'
									onClick={() => {
										setIsMobileMenuShown(false);
									}}
								>
									<p className={css.headerLinksParagraph}>Product Finish Pricing</p>
									<p className={css.headerLinksParagraph}>Management</p>
								</NavLink>
							</li>
							{/* Reference Number */}
							<li>
								<NavLink
									className={navData => (navData.isActive ? classes(css.current, css.link) : css.link)}
									to='/admin/referencenumbermanagement'
									onClick={() => {
										setIsMobileMenuShown(false);
									}}
								>
									<p className={css.headerLinksParagraph}>Reference Number</p>
									<p className={css.headerLinksParagraph}>Management</p>
								</NavLink>
							</li>
							{/* User */}
							<li>
								<NavLink
									className={navData => (navData.isActive ? classes(css.current, css.link) : css.link)}
									to='/admin/usermanagement'
									onClick={() => {
										setIsMobileMenuShown(false);
									}}
								>
									<p className={css.headerLinksParagraph}>User</p>
									<p className={css.headerLinksParagraph}>Management</p>
								</NavLink>
							</li>
						</ul>
					)}

					{isUserOfPermission(['company:read:own'], getPermissions()) && (
						<ul>
							<li>
								<NavLink
									className={navData => (navData.isActive ? classes(css.current, css.link) : css.link)}
									to='/products/search'
									onClick={() => {
										setIsMobileMenuShown(false);
									}}
								>
									Search products
								</NavLink>
							</li>
							<li>
								<NavLink
									className={navData => (navData.isActive ? classes(css.current, css.link) : css.link)}
									to='/createopening'
									onClick={() => {
										setIsMobileMenuShown(false);
									}}
								>
									Create an opening
								</NavLink>
							</li>
							<li>
								<NavLink
									className={navData => (navData.isActive ? classes(css.current, css.link) : css.link)}
									to='/quotes'
									onClick={() => {
										setIsMobileMenuShown(false);
									}}
								>
									Quotes
								</NavLink>
							</li>
							<li>
								<NavLink
									className={navData => (navData.isActive ? classes(css.current, css.link) : css.link)}
									to='/order-history'
									onClick={() => {
										setIsMobileMenuShown(false);
									}}
								>
									Order History
								</NavLink>
							</li>
						</ul>
					)}

					{isUserOfPermission(['lineItem:modify:all'], getPermissions()) && (
						<ul>
							<li>
								<NavLink
									className={navData => (navData.isActive ? classes(css.current, css.link) : css.link)}
									to='/cs/quotes'
									onClick={() => {
										setIsMobileMenuShown(false);
									}}
								>
									New Quotes
								</NavLink>
							</li>
							<li>
								<NavLink
									className={navData => (navData.isActive ? classes(css.current, css.link) : css.link)}
									to='/cs/orders/active'
									onClick={() => {
										setIsMobileMenuShown(false);
									}}
								>
									Active Orders
								</NavLink>
							</li>
							<li>
								<NavLink
									className={navData => (navData.isActive ? classes(css.current, css.link) : css.link)}
									to='/cs/orders/completed'
									onClick={() => {
										setIsMobileMenuShown(false);
									}}
								>
									Completed Orders
								</NavLink>
							</li>
						</ul>
					)}
				</div>
			</div>
		</>
	);
};
