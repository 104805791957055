import React, { useState, useEffect } from 'react';
import css from './Table.module.scss';
import classes from 'classnames';
import { Link, ILinkProps } from 'components/Link';
import { Icon } from 'components/Icon/Icon';
import { getUniqueId } from 'common/utils/getUniqueId';
import { debounce } from 'common/utils/debounce';
import { TSortDirections } from 'common/types/TSortDirections';
import { RemoveProp } from 'common/types/TypeHelpers';
import { TableMetaContent, ITableMetaContentContentClassNames } from './TableMetaContent';
import { TObjectWithStringAsProps } from 'common/types/TObjectWithStringAsProps';
import { InputCheckbox } from 'components/Form/InputCheckbox';
import { CheckboxButton, SelectionType } from 'components/Form/CheckboxButton';

export type TTableLink = RemoveProp<RemoveProp<ILinkProps, 'type'>, 'size'> & { label: string };

export interface ITableProps {
	className?: string;
	headers: TTableHeader[];
	data: (TObjectWithStringAsProps & { id: number | string })[];
	contentTopRight?: React.ReactNode;
	title?: string | React.ReactNode;
	link?: TTableLink;
	contentBottomRight?: React.ReactNode;
	onClick?: TOnClick;
	activeSort?: TActiveSort;
	type?: 'data' | 'notification';
	message?: string;
	isSelectable?: boolean;
	onSelectionChange?: (selections: string[]) => void;
	contentClassnames?: {
		tableMetaContentTop?: ITableMetaContentContentClassNames;
	};
	setClearSelection?: any;
	isFirstColumnHiddenOnMobile?: boolean;
	isSingleColumnOnMobile?: boolean;
	idProperty?: string;
	displayLoadingOverlay?: boolean;
}

export type TActiveSort = {
	columnIndex: number;
	sortDirection: TSortDirections;
};

export type IQueryStrings = {
	searchQuery?: string;
} & TActiveSort;

export type TOnClick = (data: { rowIndex: number; columnIndex: number; value: string }) => void;

export type TTableHeader = {
	label: string;
	isLabelScreenReaderHidden?: boolean;
	RenderFunctionLabel?: React.ComponentType;
	property: string;
	isSortable?: boolean;
	renderFunction?: (data: any) => React.ReactNode;
	className?: string;
	tooltip?: string;
};

const setToEqualHeight = (firstId: string, secondId: string) => {
	const firstElement = document.getElementById(firstId);
	const secondElement = document.getElementById(secondId);

	firstElement.style.height = '';
	secondElement.style.height = '';

	const columnOneHeight = firstElement.offsetHeight;
	const columnTwoHeight = secondElement.offsetHeight;

	const largestHeight = Math.max(columnOneHeight, columnTwoHeight);
	firstElement.style.height = largestHeight.toString() + 'px';
	secondElement.style.height = largestHeight.toString() + 'px';
};

export type TTableSelection = { [id: string]: boolean };

export const Table: React.FC<ITableProps> = props => {
	const {
		className,
		data,
		headers,
		contentTopRight,
		title,
		link,
		contentBottomRight,
		onClick,
		activeSort,
		type = 'data',
		isSelectable,
		onSelectionChange,
		contentClassnames,
		setClearSelection,
		message,
		isFirstColumnHiddenOnMobile = false,
		isSingleColumnOnMobile = false,
		idProperty = 'id',
		displayLoadingOverlay
	} = props;
	const [uniqueId] = useState(getUniqueId());
	const [selection, setSelection] = useState<TTableSelection>({});
	const [selectedItemsIds, setSelectedItemsIds] = useState([]);

	useEffect(() => {
		setSelectedItemsIds(
			Object.entries(selection)
				.filter(([key, value]) => value)
				.map(([id]) => id)
		);
	}, [selection, setSelectedItemsIds]);

	const selectedItemAmount = selectedItemsIds.length;

	const selectionAmountType = ((): SelectionType => {
		if (!selectedItemAmount) {
			return 'no';
		} else if (selectedItemAmount === data.length) {
			return 'yes';
		} else {
			return 'partial';
		}
	})();

	useEffect(() => {
		if (type === 'data') {
			const handleResize = () => {
				const totalTableRows = data?.length + 1; //Add one for the table header
				const columnOneindex = isFirstColumnHiddenOnMobile ? 1 : 0;
				const columnTwoindex = isFirstColumnHiddenOnMobile ? 2 : 1;

				for (let rowIndex = 0; rowIndex < totalTableRows; rowIndex++) {
					const columnOneTDId = uniqueId + rowIndex + columnOneindex;
					const columnTwoTDId = uniqueId + rowIndex + columnTwoindex;
					setToEqualHeight(columnOneTDId, columnTwoTDId);
				}
			};
			handleResize();

			const debouncedHandleResize = debounce(handleResize, 200, false);

			window.addEventListener('resize', debouncedHandleResize);
			return () => {
				window.removeEventListener('resize', debouncedHandleResize);
			};
		}
	}, [data, uniqueId, type, isFirstColumnHiddenOnMobile]);

	useEffect(() => {
		const clearSelection = () => {
			return () => {
				setSelection({});
			};
		};
		if (setClearSelection) {
			setClearSelection(clearSelection);
		}
	}, [setClearSelection, setSelection]);

	const toggleMasterCheckbox = () => {
		if (selectionAmountType === 'yes') {
			setSelection({});
		} else {
			const allSelected = data.reduce(
				(accumulator, dataItem) => ({
					...accumulator,
					[dataItem.id]: true
				}),
				{}
			);
			setSelection(allSelected);
		}
	};

	useEffect(() => {
		if (onSelectionChange) {
			onSelectionChange(selectedItemsIds);
		}
	}, [onSelectionChange, selectedItemsIds]);

	return (
		<div className={classes(css.table, className, css[type])}>
			{(title || contentTopRight) && (
				<TableMetaContent
					contentLeft={<h3 className={css.title}>{title}</h3>}
					contentRight={contentTopRight}
					className={css.header}
					contentClassNames={contentClassnames?.tableMetaContentTop}
				></TableMetaContent>
			)}
			<div
				className={classes(css.tableWrapper, {
					[css.isSingleColumnOnMobile]: isSingleColumnOnMobile
				})}
			>
				<div className={css.tableScroller}>
					<table
						className={classes(css.tableDom, {
							[css.isFirstColumnHiddenOnMobile]: isFirstColumnHiddenOnMobile
						})}
					>
						<thead
							className={classes({
								[css.screenReaderTextMobile]: type === 'notification'
							})}
						>
							<tr className={css.tr}>
								{isSelectable && (
									<th id={uniqueId + 0 + '00'} className={classes(css.columnSelect, css.th)}>
										<span className='screenReaderText'>Row selection</span>
										<CheckboxButton
											className={css[selectionAmountType]}
											type={selectionAmountType}
											onClick={toggleMasterCheckbox}
											aria-labelledby='masterCheckboxLabel'
										></CheckboxButton>
										<label className='screenReaderText' aria-hidden={true} id='masterCheckboxLabel'>
											{selectionAmountType === 'yes' &&
												'All items in table selected. Click this button to unselect all items.'}
											{selectionAmountType === 'partial' &&
												'Some items in table selected. Click this button to select all items.'}
											{selectionAmountType === 'no' &&
												'No items in table selected. Click this button to select all items.'}
										</label>
									</th>
								)}
								{headers.map((header, columnIndex) => (
									<th
										key={'0' + columnIndex}
										id={uniqueId + 0 + columnIndex}
										className={classes(css.th, header.className)}
										aria-sort={
											header.isSortable
												? activeSort?.columnIndex === columnIndex
													? activeSort.sortDirection === 'desc'
														? 'descending'
														: 'ascending'
													: 'none'
												: 'none'
										}
									>
										{header.isSortable ? (
											<button
												className={css.headerItemInner}
												onClick={() =>
													onClick({
														rowIndex: 0,
														columnIndex,
														value: header.label
													})
												}
											>
												<div
													className={classes(css.headerLabel, {
														screenReaderText: header.isLabelScreenReaderHidden
													})}
												>
													{header.label}
												</div>
												{header.tooltip && (
													<Icon
														tooltip={header.tooltip}
														marginSize='left-small'
														type='Info'
														size='small'
														color='blue'
													/>
												)}
												{activeSort?.columnIndex === columnIndex ? (
													<Icon
														size='smallest'
														className={css.sortIcon}
														type={
															activeSort.sortDirection === 'desc' ? 'CaretDownFilled' : 'CaretUpFilled'
														}
														color='red'
													></Icon>
												) : (
													<Icon
														size='smallest'
														className={css.sortIcon}
														type='CaretDown'
														color='red'
													></Icon>
												)}
											</button>
										) : (
											<div className={css.headerItemInner}>
												<div
													className={classes(css.headerLabel, {
														screenReaderText: header.isLabelScreenReaderHidden
													})}
												>
													{header.label}
												</div>
												{header.tooltip && (
													<Icon
														tooltip={header.tooltip}
														marginSize='left-small'
														type='Info'
														size='small'
														color='blue'
													/>
												)}
											</div>
										)}
									</th>
								))}
							</tr>
						</thead>
						<tbody className={css.tableBody}>
							{data?.map((row, rowIndex) => (
								<tr key={`${row[idProperty]}${rowIndex}`} className={css.tr}>
									{isSelectable && (
										<td id={uniqueId + '0100'} className={classes(css.columnSelect, css.td)}>
											<InputCheckbox
												label={'Is notification selected'}
												value='notificationSelection'
												onKeyPress={event => {
													if (event.which === 13) {
														setSelection({
															...selection,
															[row[idProperty]]: selection[row[idProperty]]
																? !selection[row[idProperty]]
																: true
														});
													}
												}}
												onChange={event => {
													setSelection({
														...selection,
														[row[idProperty]]: event.target.checked
													});
												}}
												checked={selection[row[idProperty]] || false}
												isLabelHidden
											></InputCheckbox>
										</td>
									)}
									{headers.map((header, columnIndex) => {
										const { renderFunction } = header;
										return (
											<td
												key={`${columnIndex}${row[idProperty]}`}
												id={uniqueId + (rowIndex + 1) + columnIndex}
												className={classes(css.td, header.className)}
											>
												{renderFunction ? renderFunction(row as any) : row[header.property]}
											</td>
										);
									})}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
			<div
				className={classes(css.tableOverlay, {
					[css.displayLoadingOverlay]: displayLoadingOverlay
				})}
			/>
			{message && <div className={css.message}>{message}</div>}
			{contentBottomRight && (
				<TableMetaContent contentRight={contentBottomRight} className={css.footer}>
					{contentBottomRight}
				</TableMetaContent>
			)}
			{link && (
				<Link
					{...link}
					className={css.link}
					type='primary'
					classNames={{ focusRing: css.mainLinkFocusRing }}
				>
					{link.label}
				</Link>
			)}
		</div>
	);
};
