import React, { useState, useEffect } from 'react';
import { useMatch } from 'react-router';
import { useAuth } from 'providers/Auth';
import css from './ProductSingleView.module.scss';
import classes from 'classnames';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { Spinner } from 'components/Spinner';
import { LoadingDelay } from 'components/LoadingDelay';
import { PageFrame } from 'components/PageFrame';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { GridContainer } from 'components/Grid/GridContainer/GridContainer';
import { GridItem } from 'components/Grid/GridItem/GridItem';
import { Input } from 'components/Form/Input';
import { Select } from 'components/Form/Select';
import { Button } from 'components/Button';
import { formatAsUSD } from 'common/utils/formatAsUSD';
import { formatDateTimestamp } from 'common/utils/formatDateTimestamp';
import { calculateDisplayedProductPrice } from 'common/utils/calculateDisplayedProductPrice';
import { NoImage } from 'components/NoImage/NoImage';
import { PricingResponse, ProductPricingResponse, useGetProduct } from 'api/product';
import { useGetOrderQuotesByCompanyId, useCreateOrderQuote } from 'api/order';
import { LineItem, useCreateOrderQuoteLineItem } from 'api/lineItem';
import { labelToKey } from 'common/utils/labelToKey';
import { ErrorView } from 'views/views';
import { useModal } from 'providers/Modal/ModalProvider';
import { useBanner } from 'components/Banner/BannerProvider';
import { sortArrayByProperty } from 'common/utils/sortArrayByProperty';

type PricingDisplayOutput = {
	status: 'success' | 'error';
	discountedPrice: number;
	discountAmount: number;
	listPrice: number;
};

type ProductPricingOptions = { finishNumber?: number; width: number; quantity: number };

const initialCreateNewQuoteValues = {
	newQuoteName: `New Quote ${formatDateTimestamp(new Date())}`
};

export const ProductSingleView = () => {
	const { isLoading: isAuthLoading, getCompanyId } = useAuth();
	const { createModal, removeModal, setModalLoading, setIsModalError } =
		useModal<typeof initialCreateNewQuoteValues>();
	const { createBanner } = useBanner();

	const route = useMatch('/products/product/:id');
	const productId = String(route?.params?.id || 0);
	const [productOptions, setProductOptions] = useState<ProductPricingOptions>({
		finishNumber: null,
		width: 0,
		quantity: 1
	});
	const [productDisplayedPrices, setProductDisplayedPrices] = useState<PricingDisplayOutput>({
		status: 'success',
		discountedPrice: 0,
		discountAmount: 0,
		listPrice: 0
	});
	const [isInitiallyLoaded, setIsInitiallyLoaded] = useState<boolean>(false);

	const {
		data: productData,
		isLoading: productIsLoading,
		isError: productIsError
	} = useGetProduct(productId);
	const {
		data: quoteData,
		isLoading: quoteIsLoading,
		isError: quoteIsError
	} = useGetOrderQuotesByCompanyId(getCompanyId());
	const { mutateAsync: createQuote } = useCreateOrderQuote();
	const { mutateAsync: createQuoteLineItem } = useCreateOrderQuoteLineItem();

	// Helper function to add product to quotes
	const addProductToQuote = async (
		product: ProductPricingResponse,
		method: 'create' | 'update' = 'create',
		quote: { id?: string; name?: string } // depending on the call (update needs string GUID) (create will take the string and make it the quote name)
	) => {
		// calculatedListPrice will be used as the pricePerUnit and also will fallback is discount is null to set the discountedPricePerUnit correctly.
		const calculatedListPrice =
			determinePricing(product, { ...productOptions, quantity: 1 }).listPrice || 0;
		// Handle the two type of mutations based on create or update
		const productLineItemData: LineItem = {
			orderId: 'placeholder-will-get-replaced-when-adding-below',
			materialNumber: product.product.materialNumber,
			title: product.product.productName,
			pricePerUnit: calculatedListPrice,
			discountedPricePerUnit:
				determinePricing(product, { ...productOptions, quantity: 1 }).discountedPrice ||
				calculatedListPrice,
			quantity: productOptions.quantity,
			lineItemDetails: (() => {
				return JSON.stringify({
					...(productOptions.width > 0 ? { width: `${productOptions.width}ft` } : {}),
					...(productOptions.finishNumber ? { finish: `${productOptions.finishNumber}` } : {})
				});
			})()
		};

		switch (method) {
			case 'create':
				const newQuote = await createQuote({
					name: quote?.name,
					orderType: 'stock-cpq',
					status: 'NotSubmitted',
					customerId: getCompanyId()
				});
				if (newQuote) {
					await createQuoteLineItem({
						...productLineItemData,
						orderId: newQuote.id
					});
					removeModal();
					createBanner(
						<>
							<strong>Success:</strong> the new quote {newQuote.name} was created.
						</>
					);
				}
				break;
			case 'update':
				await createQuoteLineItem({
					...productLineItemData,
					orderId: quote.id
				});
				removeModal();
				createBanner(
					<>
						<strong>Success:</strong> {product.product.productName} has been added to {quote.name}.
					</>
				);
				break;
			default:
				break;
		}
	};

	const handleAddToQuoteModal = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const hasQuotesAndNoErrorsAndNotLoading =
			quoteData && quoteData.length > 0 && !quoteIsError && !quoteIsLoading;
		createModal({
			sourceEvent: event,
			heading: 'Add to Quote',
			Content: props => {
				const { formik } = props;
				const filteredQuotes = sortArrayByProperty(
					quoteData.filter(quote => quote.status === 'NotSubmitted'),
					'updatedAt',
					'desc'
				);
				const hasQuotes = filteredQuotes.length > 0 && hasQuotesAndNoErrorsAndNotLoading;
				return (
					<>
						{hasQuotes && (
							<>
								<p>Select an existing NotSubmitted quote to add this product to below:</p>
								<ul className={css.quoteSelector}>
									{filteredQuotes.map(quote => (
										<li key={quote.id}>
											<Button
												type={'link'}
												onClick={async () =>
													await addProductToQuote(productData, 'update', {
														id: quote.id,
														name: quote.name
													})
												}
												className={css.quoteSelectorButton}
												classNames={{
													buttonContent: css.quoteSelectorButtonContent
												}}
											>
												{quote.name}
											</Button>
										</li>
									))}
								</ul>
							</>
						)}
						<p>{hasQuotes ? 'Or c' : 'C'}reate a new quote to add this product to:</p>
						<form onSubmit={formik.handleSubmit}>
							<Input
								name={'newQuoteName'}
								label={'New Quote Name'}
								placeholder={'Enter quote name'}
								formikProps={formik}
							/>
						</form>
					</>
				);
			},
			primaryButtonLabel: 'create quote',
			primaryButtonAction: async formikSubmit => {
				formikSubmit();
			},
			formikConfig: {
				initialValues: initialCreateNewQuoteValues,
				onSubmit: async formValues => {
					setIsModalError(false);
					setModalLoading(true);
					try {
						await addProductToQuote(productData, 'create', { name: formValues.newQuoteName });
					} catch {
						setIsModalError(true);
					}
					setModalLoading(false);
				}
			}
		});
	};

	// Set initial form state && product price
	useEffect(() => {
		if (!productIsLoading && !isInitiallyLoaded) {
			if (
				productData &&
				productData.product &&
				productData.product.unitOfMeasure !== 'FREE' &&
				productData.productPricing &&
				productData.productPricing.length > 0
			) {
				// Supports the initial load of non FREE unit of measure products
				const initialProductOptions = {
					finishNumber: productData.productPricing[0].finishInformation?.finishNumber,
					width:
						productData.product.unitOfMeasure === 'FT' ||
						productData.product.unitOfMeasure === 'CLEAR OPENING FT'
							? 1
							: 0,
					quantity: 1
				};

				setProductOptions(initialProductOptions);
				const prices = determinePricing(productData, initialProductOptions);
				setProductDisplayedPrices({
					...prices
				});
				setIsInitiallyLoaded(true);
			} else if (productData && productData.product && productData.product.unitOfMeasure === 'FREE') {
				// Support initial load of FREE unit of measure products
				setProductDisplayedPrices({
					status: 'success',
					discountAmount: 0,
					discountedPrice: 0,
					listPrice: 0
				});
				setIsInitiallyLoaded(true);
			}
		}
	}, [
		productIsLoading,
		productData,
		setProductOptions,
		productOptions,
		isInitiallyLoaded,
		setIsInitiallyLoaded
	]);

	const handleProductOptionsChange = (propName: string, propValue: string) => {
		const newProductPricingOptions = {
			...productOptions,
			[propName]: propName === 'width' ? propValue : parseFloat(propValue)
		};

		setProductOptions(newProductPricingOptions);
		const prices = determinePricing(productData, newProductPricingOptions);
		setProductDisplayedPrices({
			...prices
		});
	};

	if (isAuthLoading || productIsLoading) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	if (productIsError) {
		return <ErrorView isInAppFrame={true} />;
	}

	return (
		<PageFrame
			title={productData.product.productName ?? productData.product.materialNumber}
			isContentManagedTitle={true}
			classNames={{ pageFrame: css.productSingleView }}
		>
			<Section>
				<Container>
					<Breadcrumbs
						navigationItems={[
							{
								label: 'Product Search',
								location: '/products/search'
							},
							{
								label: productData.product.productName
							}
						]}
					/>
				</Container>
				<Container>
					<GridContainer columnAmount='2'>
						<GridItem>
							<div className={css.productImageContainer}>
								<NoImage />
							</div>
						</GridItem>
						<GridItem className={css.productDetails}>
							<h1 className='h3'>{productData.product.productName}</h1>
							<h2 className='h5'>Part number: {productData.product.materialNumber}</h2>
							{/* Depending on if the customer has a discount */}
							{productDisplayedPrices.status !== 'error' && (
								<>
									{productDisplayedPrices.discountedPrice ? (
										// Discount
										<div className={css.productDiscount}>
											<h3 className='h4'>
												{formatAsUSD(productDisplayedPrices?.discountedPrice, true)}{' '}
												<small>with your multiplier</small>
											</h3>
											<p>
												{formatAsUSD(productDisplayedPrices?.listPrice, true)} list price /{' '}
												<span>
													You save {formatAsUSD(productDisplayedPrices?.discountAmount, true)} after
													discount
												</span>
											</p>
										</div>
									) : (
										// No Discount ?? they don't have a discount so don't show anything ? (Madison/I think so)
										<div className={css.productDiscount}>
											<h3 className='h4'>{formatAsUSD(productDisplayedPrices?.listPrice, true)}</h3>
										</div>
									)}
								</>
							)}

							<div className={css.productContent}>
								{renderBulletList([
									productData.product.bulletPoint1,
									productData.product.bulletPoint2,
									productData.product.bulletPoint3,
									productData.product.bulletPoint4,
									productData.product.bulletPoint5
								])}
							</div>
							{productDisplayedPrices.status !== 'error' && (
								<div className={css.ecomControls}>
									<div className={css.selectors}>
										<Input
											label={'Quantity'}
											name={'quantity'}
											type={'number'}
											min={1}
											className={classes(css.qty, css.ecomInput)}
											value={productOptions.quantity}
											onChange={event => handleProductOptionsChange('quantity', event.target.value)}
											onBlur={() => {
												//@ts-ignore
												if (productOptions.quantity === '' || isNaN(productOptions.quantity)) {
													setProductOptions({
														...productOptions,
														quantity: 1
													});
												}
											}}
										/>
										{productData.product &&
											(productData.product.unitOfMeasure === 'FT' ||
												productData.product.unitOfMeasure === 'CLEAR OPENING FT') && (
												<Input
													label={'Width (feet)'}
													name={'width'}
													className={classes(css.width, css.ecomInput)}
													value={productOptions.width}
													onChange={event => handleProductOptionsChange('width', event.target.value)}
													errorMessage={productOptions.width * 0 !== 0 ? 'width must be a number' : null}
													onBlur={() => {
														//@ts-ignore
														if (productOptions.width === '' || isNaN(productOptions.width)) {
															setProductOptions({
																...productOptions,
																width: 1
															});
														}
													}}
												/>
											)}
										{productData.productPricing && productData.productPricing.length > 1 && (
											<Select
												label={'Finish'}
												name={'finish'}
												className={css.finish}
												value={productOptions.finishNumber}
												onChange={event =>
													handleProductOptionsChange('finishNumber', event.target.value)
												}
											>
												{renderFinishOptions(productData.productPricing)}
											</Select>
										)}
									</div>
									<Button
										type={'primary'}
										className={css.button}
										onClick={event => handleAddToQuoteModal(event)}
									>
										Add to Quote
									</Button>
								</div>
							)}
						</GridItem>
					</GridContainer>
				</Container>
			</Section>
		</PageFrame>
	);
};

const renderBulletList = (bullets: string[]) => {
	const parsedBullets = bullets.filter(bullet => bullet !== '' && bullet !== undefined);
	return (
		<ul>
			{parsedBullets.map(bullet => (
				<li key={labelToKey(bullet)}>{bullet}</li>
			))}
		</ul>
	);
};

const renderFinishOptions = (pricingResponses: PricingResponse[]) => {
	const finishList = pricingResponses.map(x => x.finishInformation);
	return (
		<>
			{finishList.map(finish => (
				<option key={labelToKey(finish.finishNumber.toString())} value={finish.finishNumber}>
					{finish.finishNumber} - {finish.description}
				</option>
			))}
		</>
	);
};

const determinePricing = (
	productPricing: ProductPricingResponse,
	options: ProductPricingOptions = { quantity: 1, width: 0 }
): PricingDisplayOutput => {
	const productUnitOfMeasure = productPricing.product?.unitOfMeasure;
	const quantity = options.quantity;
	const width = options.width;
	const finishNumber = options?.finishNumber;
	if (typeof quantity !== 'number') {
		console.error('Could not find pricing, quantity must be a number');
		return {
			status: 'error',
			discountedPrice: 0,
			discountAmount: 0,
			listPrice: 0
		};
	}
	// Dev Note: finishNumber === 0 is specifically to protect against the finish number 0 from reading as false
	if (finishNumber || finishNumber === 0) {
		const finishPricing = productPricing.productPricing.find(
			productPrice => productPrice.finishInformation.finishNumber === finishNumber
		);

		if (finishPricing && finishPricing.discountedPrice) {
			return {
				status: 'success',
				discountedPrice: finishPricing.discountedPrice
					? calculateDisplayedProductPrice(
							finishPricing.discountedPrice,
							quantity,
							productUnitOfMeasure,
							width
					  )
					: null,
				discountAmount: finishPricing.discountedPrice
					? calculateDisplayedProductPrice(
							finishPricing.priceByFinish - finishPricing.discountedPrice,
							quantity,
							productUnitOfMeasure,
							width
					  )
					: null,
				listPrice: finishPricing.priceByFinish
					? calculateDisplayedProductPrice(
							finishPricing.priceByFinish,
							quantity,
							productUnitOfMeasure,
							width
					  )
					: null
			};
		} else if (finishPricing && finishPricing.priceByFinish) {
			return {
				status: 'success',
				discountedPrice: null,
				discountAmount: null,
				listPrice: finishPricing.priceByFinish
					? calculateDisplayedProductPrice(
							finishPricing.priceByFinish,
							quantity,
							productUnitOfMeasure,
							width
					  )
					: null
			};
		} else {
			console.error(`Could not find pricing for finish number ${finishNumber}`);
			return {
				status: 'error',
				discountedPrice: 0,
				discountAmount: 0,
				listPrice: 0
			};
		}
	} else {
		if (productPricing.productPricing[0].discountedPrice) {
			return {
				status: 'success',
				discountedPrice: calculateDisplayedProductPrice(
					productPricing.productPricing[0].discountedPrice,
					quantity,
					productUnitOfMeasure,
					width
				),
				discountAmount: calculateDisplayedProductPrice(
					productPricing.productPricing[0].priceByMasterData -
						productPricing.productPricing[0].discountedPrice,
					quantity,
					productUnitOfMeasure,
					width
				),
				listPrice: calculateDisplayedProductPrice(
					productPricing.productPricing[0].priceByMasterData,
					quantity,
					productUnitOfMeasure,
					width
				)
			};
		} else if (productPricing.productPricing[0].priceByMasterData) {
			return {
				status: 'success',
				discountedPrice: 0,
				discountAmount: 0,
				listPrice: calculateDisplayedProductPrice(
					productPricing.productPricing[0].priceByMasterData,
					quantity,
					productUnitOfMeasure,
					width
				)
			};
		} else {
			console.error(`Could not find pricing for ${productPricing.product.materialNumber}`);
			return {
				status: 'error',
				discountedPrice: 0,
				discountAmount: 0,
				listPrice: 0
			};
		}
	}
};
