import React, { PropsWithChildren } from 'react';
import { FormikProps } from 'formik';
import classes from 'classnames';
import css from './CreateOpeningInputRadioWrapper.module.scss';
import { getProcessedFormikProps } from 'common/utils/getProcessedFromikProps';
import { InputMessage } from 'components/Form/InputMessage/InputMessage';

export type ICreateOpeningInputRadioWrapperProps = {
	className?: string;
	formikProps?: FormikProps<any>;
	hasError?: boolean;
	name: string;
};

export const CreateOpeningInputRadioWrapper: React.FC<
	PropsWithChildren<ICreateOpeningInputRadioWrapperProps>
> = props => {
	const { className, children, formikProps, name, hasError } = props;
	const { isError } = getProcessedFormikProps(formikProps, name, null, null);

	return (
		<>
			{isError ||
				(hasError && (
					<InputMessage isError={isError || hasError}>
						A selection is required to advance to the next step.
					</InputMessage>
				))}
			<div className={classes(css.wrapper, className)}>{children}</div>
		</>
	);
};
