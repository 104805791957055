import { useAxios } from 'providers/Axios/AxiosProvider';
import { useQuery } from 'react-query';
import { handleResponse } from './api';
import { CustomerDiscount } from './customerDiscounts';
import { SalesRepresentative } from './salesRepresentative';

export type Company = {
	accountNumber: string;
	name: string;
	customerNumber?: string;
	salesPersonCode?: number; //ID
	isActive: boolean;
	addressLine1: string;
	addressLine2: string;
	addressLine3: string;
	addressCity: string;
	addressState: string;
	addressZipCode: string;
	id: string;
	isDeleted: boolean;
	createdBy: Date;
	createdAt: Date;
	updatedBy: string;
	updatedAt: Date;
	deletedBy: string;
	deletedAt: Date;

	customerDiscounts: CustomerDiscount;
	salesRepresentative: SalesRepresentative;
};

type Companies = Company[];

export const useGetAllCompanies = () => {
	const axios = useAxios();

	return useQuery(['companies'], async () => {
		return handleResponse(axios.get<Companies>('/company'));
	});
};

export const useGetCompanyById = (id: string) => {
	const axios = useAxios();

	return useQuery(['company', `${id}`], async () => {
		return handleResponse(axios.get<Company>(`/company/${id}`));
	});
};
