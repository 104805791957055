import { ascend, descend, prop, sort } from 'ramda';

export type TSortDirection = 'desc' | 'asc';

export const sortArrayByProperty = <T extends { [propertyName: string]: any }>(
	data: T[],
	propToSort: string,
	sortDirection: TSortDirection = 'desc'
): T[] => {
	if (typeof data !== 'undefined') {
		return sort((sortDirection === 'desc' ? descend : ascend)(prop(propToSort)), data);
	} else {
		return [];
	}
};
